import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SectionInfo from "./Partial/Section/Info";
import { getCronJob } from "helpers/backendHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [cronJob, setCronJob] = useState(null);
  const [cronJobError, setCronJobError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshCronJob();
  }, [id]);

  /********** OTHER **********/

  const refreshCronJob = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getCronJob(id)
      .then(response => {
        setCronJob(response.cronJob);
      })
      .catch(ex => {
        setCronJobError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_cron_jobs) && <div className="page-content">
      {!!cronJob && <React.Fragment>
        <MetaTitle>{cronJob.name} | Cron Jobs</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(cronJob)} />
          <Row>
            <Col>
              <SectionInfo
                cronJob={cronJob}
                refreshHandler={refreshCronJob}
                isLoadInProgress={isLoadInProgress}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !cronJob && <Preloader className="inner" />}
      {cronJobError && <Error error={cronJobError} title404="Cron job not found" />}
    </div>}
    {iAmNotGranted(perms.view_cron_jobs) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = cronJob => [{
  title: 'CRON JOBS',
  url: route(routes.list_cron_jobs),
}, {
  title: cronJob.name,
}];

export default View;
