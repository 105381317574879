import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader, UncontrolledTooltip } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import { formatTimestamp, formats } from "helpers/dateHelper";
import CardPreloader from "components/Shared/CardPreloader";
import { getQbBillItem, getQbInvoiceItem } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";
import { NotFoundException, QB_UNABLE_REFRESH_TOKEN } from "helpers/errorHelper";


const ViewInfo = props => {

  const { isRefreshing, additionalFee, toggleEditMode } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [qbInvoiceItem, setQbInvoiceItem] = useState(null);
  const [qbBillItem, setQbBillItem] = useState(null);
  const [isInvoiceItemLoadInProgress, setIsInvoiceItemLoadInProgress] = useState(false);
  const [isBillItemLoadInProgress, setIsBillItemLoadInProgress] = useState(false);

  useEffect(() => {
    if (!!additionalFee.qbInvoiceItemId && iAmGranted(perms.view_quickbooks)) {
      refreshInvoiceItem();
    }
    if (!!additionalFee.qbBillItemId && iAmGranted(perms.view_quickbooks)) {
      refreshBillItem();
    }
  }, [additionalFee.id]);

  const refreshInvoiceItem = () => {
    setIsInvoiceItemLoadInProgress(true);
    getQbInvoiceItem(additionalFee.qbInvoiceItemId)
      .then(response => {
        setQbInvoiceItem(response);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        if (ex instanceof NotFoundException) {
          showError('Invoice item not found');
          return;
        }
        showError('Unable to load invoice item from QuickBooks');
      })
      .finally(() => {
        setIsInvoiceItemLoadInProgress(false);
      });
  }

  const refreshBillItem = () => {
    setIsBillItemLoadInProgress(true);
    getQbBillItem(additionalFee.qbBillItemId)
      .then(response => {
        setQbBillItem(response);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        if (ex instanceof NotFoundException) {
          showError('Bill item not found');
          return;
        }
        showError('Unable to load bill item from QuickBooks');
      })
      .finally(() => {
        setIsBillItemLoadInProgress(false);
      });
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{additionalFee.name}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_additional_fees) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit fee
              </Button>}
              <Link to={route(routes.list_additional_fees)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <label className="mb-0">Name</label>
            <div>{additionalFee.name}</div>
          </Col>
          <Col>
            <label className="mb-0">Price</label>
            <div>{additionalFee.isPercentage ? additionalFee.price + '%' : '$' + additionalFee.price}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="mb-2">QuickBooks Invoice Item</label>
            <div>
              {!!additionalFee.qbInvoiceItemId
                ? additionalFee.qbInvoiceItemId
                : (!additionalFee.qbBillItemId
                  ? <React.Fragment>
                    <i id="invoice-item-warning" className="mdi mdi-alert-circle text-danger"></i>
                    <UncontrolledTooltip placement="top" target="invoice-item-warning">Please add either an invoice item or a bill item</UncontrolledTooltip>
                  </React.Fragment>
                  : '--')}
              {!!qbInvoiceItem && ` - ${qbInvoiceItem.Name}`}
              {isInvoiceItemLoadInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
            </div>
          </Col>
          <Col>
            <label className="mb-2">QuickBooks Bill Item</label>
            <div>
              {!!additionalFee.qbBillItemId
                ? additionalFee.qbBillItemId
                : (!additionalFee.qbInvoiceItemId
                  ? <React.Fragment>
                    <i id="bill-item-warning" className="mdi mdi-alert-circle text-danger"></i>
                    <UncontrolledTooltip placement="top" target="bill-item-warning">Please add either an invoice item or a bill item</UncontrolledTooltip>
                  </React.Fragment>
                  : '--')}
              {!!qbBillItem && ` - ${qbBillItem.Name}`}
              {isBillItemLoadInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label className="mb-2">Created</label>
            <div>{formatTimestamp(additionalFee.createdTs, formats.DATETIME)}</div>
          </Col>
          <Col>
            <label className="mb-2">Updated</label>
            <div>{formatTimestamp(additionalFee.updatedTs, formats.DATETIME)}</div>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  additionalFee: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
