import specialInstructionsIcon from "../../../../assets/images/special-instructions-icon.svg";
import React from "react";
import PropTypes from "prop-types";
import {newLineTextFormat} from "../../../../helpers/utilHelper";

const SpecialInstructionsLabel = ({instructions, className }) => {

  // Split the text into separate lines
  let lines = instructions?.split('\n');

  return(<div className={`special-instructions-label ${className ? className : ''}`}>
    <>
      <img className='me-2' src={specialInstructionsIcon}/>Group Special Instructions
      <div className='spacial-instructions-tooltip'>
        <div className='arrow-up'></div>
        <div className='tooltip-title mb-2'>Special Instructions</div>
        {newLineTextFormat(lines).map((line, index) => <p className='mb-1' key={index}>{line}</p>)}
      </div>
    </>
  </div>)
}

SpecialInstructionsLabel.propTypes = {
  instructions: PropTypes.string,
  className: PropTypes.string,
}
export default SpecialInstructionsLabel;