import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { acceptAllInkSignDocuments, doAcceptAllInkSignDocumentsCleanup, doOrderSingleCleanup, getFullOrder, getOrderWithCustomerSignersAndNotaries } from "store/actions";
import InkSignDocs from "../Partial/InkSign/Docs";
import OrderNotary from "model/orderNotary";
import Order from "model/order";
import { NOT_ALL_DOCS_RETURNED_BY_NOTARY, UnprocessableEntityException } from "helpers/errorHelper";
import Confirmation from "components/Shared/Confirmation";
import { showError, showSuccess } from "helpers/utilHelper";
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';

const ViewInkSign = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { accepted, acceptError, isAcceptInProgress } = useSelector(state => state.OrderDoc.AcceptAll);

  // is the confirmation dialog visible or not
  // used to show/hide the seal warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getOrderWithCustomerSignersAndNotaries(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
      dispatch(doAcceptAllInkSignDocumentsCleanup());
    }
  }, [id]);

  // runs whenever the 'allAccepted' flag changes
  // which happens after a seal-order attempt
  useEffect(() => {
    if (accepted === true) {
      refreshOrder();
      showSuccess(`All documents have been accepted`);
    } else if (accepted === false) {
      if (acceptError instanceof UnprocessableEntityException) {
        if (acceptError.code == NOT_ALL_DOCS_RETURNED_BY_NOTARY) {
          showError('Not all docs have been shipped by the notary');
          return;
        }
      }
      showError('Unable accept all documents');
    }
  }, [accepted]);

  // in order for all docs to be accepted, they must have been shipped by the notary
  const allCanBeAccepted = () => !!order?.notaries?.find(notary => !notary.isInactive && notary.status == OrderNotary.STATUS_ORDER_COMPLETE) && order?.status == Order.STATUS_PENDING_INK_SIGN;

  /********** OTHER **********/

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">
                        Ink-Signed Documents
                        {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
                          <><img id={`doc-delivery-shipping`} src={docDeliveryShippingIcon} className="ms-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping`}>Documents shipping</UncontrolledTooltip></>
                        }{order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
                          <><img id={`doc-delivery-upload`} src={docDeliveryUploadIcon} className="ms-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload`}>Documents upload</UncontrolledTooltip></>
                        }
                      </div>
                    </Col>
                    {iAmGranted(perms.edit_orders) && <Col className="text-end">
                      {allCanBeAccepted() && <Button type="button" color="primary" className="me-3" onClick={() => setIsConfirmationVisible(true)} disabled={isAcceptInProgress}>
                        {isAcceptInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                        Accept All Documents for Dealer
                      </Button>}
                    </Col>}
                  </Row>
                </CardHeader>
                <CardBody>
                  <InkSignDocs key={'InkSignDocs'} order={order} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isConfirmationVisible && <Confirmation
          confirmBtnText="Accept All"
          reverseButtons={false}
          onConfirm={() => {
            setIsConfirmationVisible(false);
            dispatch(acceptAllInkSignDocuments(order.id))
          }}
          onCancel={() => setIsConfirmationVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to accept all ink-sign documents on behalf of the dealer?</span>
        </Confirmation>}
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Ink-Signed contracts',
}];

export default ViewInkSign;
