import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Label, Input, FormFeedback, Button, Card, CardHeader, CardBody } from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Col from "components/Shared/Col";
import { doOrderFormCleanup, updateOrderVehicle } from "store/actions";
import { nullsToEmptyStrings, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import config from "config";

const FormEditVehicle = props => {

  const { defaultValues, finishedHandler } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    vehicles: [],
    ...nullsToEmptyStrings(defaultValues, true),
  };

  const vehicleValidationSchema = {
    stockNum: Yup.string().trim().required('Field is required'),
    vin: Yup.string().trim().required('Field is required'),
    yearMakeModel: Yup.string().trim(),
  };

  const formValidationSchema = {
    vehicles: Yup.array().of(
      Yup.object().shape(vehicleValidationSchema)
    ).min(1, 'Please add at least one vehicle'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => dispatch(updateOrderVehicle(values, props.id)),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderFormCleanup());
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess('Vehicle information has been saved');
      finishedHandler(true);
    } else if (saved === false) {
      showError('Unable to save vehicle information');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // on change event handler that converts user input to uppercase letters
  const convertToUpperCaseOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, event.target.value.toUpperCase());
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => {
    const errors = formik.errors;
    if (errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][fieldName]) {
      delete errors[arrayName][index][fieldName];
      formik.setStatus(errors);
    }
  }

  const addRow = arrayHelpers => {
    onFieldFocused(null, 'vehicles');
    arrayHelpers.push(getEmptyVehicle());
  }

  /********** OTHER **********/

  // returns an empty vehicle object
  const getEmptyVehicle = () => ({
    stockNum: '',
    vin: '',
    yearMakeModel: '',
  });

  const canAddVehicles = () => formik.values.vehicles.length < config.ORDER_MAX_VEHICLES;

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Vehicle Information</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Form className="pt-4">
          <FormikProvider value={formik}>
            <FieldArray name="vehicles" render={arrayHelpers => <React.Fragment>
              {formik.values.vehicles.map((vehicles, index) => <div className={classnames('card-section', { 'blue pt-3 pb-1': index > 0, 'mt-1': index > 1 })} key={index}>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>Stock Number *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`vehicles.${index}.stockNum`}
                      onChange={convertToUpperCaseOnChange}
                      onFocus={() => onArrayFieldFocused('vehicles', index, 'stockNum')}
                      value={formik.values.vehicles[index].stockNum}
                      invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.stockNum}
                    />
                    {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.stockNum && <FormFeedback type="invalid">{formik.errors.vehicles[index].stockNum}</FormFeedback>}
                  </Col>
                  <Col xl="6">
                    <Label>V.I.N. # *</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`vehicles.${index}.vin`}
                      onChange={convertToUpperCaseOnChange}
                      onFocus={() => onArrayFieldFocused('vehicles', index, 'vin')}
                      value={formik.values.vehicles[index].vin}
                      invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.vin}
                    />
                    {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.vin && <FormFeedback type="invalid">{formik.errors.vehicles[index].vin}</FormFeedback>}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>Vehicle year, make, model</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name={`vehicles.${index}.yearMakeModel`}
                      onChange={formik.handleChange}
                      onFocus={() => onArrayFieldFocused('vehicles', index, 'yearMakeModel')}
                      value={formik.values.vehicles[index].yearMakeModel}
                      invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.yearMakeModel}
                    />
                    {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.yearMakeModel && <FormFeedback type="invalid">{formik.errors.vehicles[index].yearMakeModel}</FormFeedback>}
                  </Col>
                  {!!index && <Col xl="6" className="d-flex justify-content-end align-items-end">
                    <Button type="button" color="link" onClick={() => arrayHelpers.remove(index)}>Remove</Button>
                  </Col>}
                </Row>
              </div>)}
              {!!formik.errors.vehicles && typeof formik.errors.vehicles === 'string' && <div className="card-section">
                <FormFeedback type="invalid" className="d-block">{formik.errors.vehicles}</FormFeedback>
              </div>}
              {canAddVehicles() && <div className="card-section mt-4 mb-4">
                <Row>
                  <Col>
                    <Button type="button" color="primary" onClick={() => addRow(arrayHelpers)}>+ Additional Vehicles</Button>
                  </Col>
                </Row>
              </div>}
            </React.Fragment>} />
          </FormikProvider>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormEditVehicle.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

export default FormEditVehicle;