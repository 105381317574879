import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardBody } from 'reactstrap';
import Col from 'components/Shared/Col';
import Order from 'model/order';
import seal from 'assets/images/mav-seal.svg';
import Sealing from './Sealing';

const SealBadge = props => {

  const { order } = props;

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  return <React.Fragment>
    {isQueuedForSealing() && <Sealing />}
    {isSealed() && <Col className="text-end">
      <img src={seal} className="mav-seal-badge mb-3" />
    </Col>}
    {sealFailed() && <Col className="text-end">
      <Card className="text-start d-inline-block bg-danger text-white">
        <CardBody>
          <Row>
            <Col>
              <div className="card-title mb-0">Sealing failed</div>
              Unable to seal order
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <i className="mdi mdi-block-helper seal-err-icon"></i>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>}
  </React.Fragment>
}

SealBadge.propTypes = {
  order: PropTypes.object.isRequired,
};

export default SealBadge;
