/********** DATATABLE **********/

export const GET_PAYMENT_DT = "GET_PAYMENT_DT"
export const GET_PAYMENT_DT_OK = "GET_PAYMENT_DT_OK"
export const GET_PAYMENT_DT_ERR = "GET_PAYMENT_DT_ERR"
export const DO_PAYMENT_DT_CLEANUP = "DO_PAYMENT_DT_CLEANUP"

/********** DATATABLE FILTERS **********/

export const APPLY_PAYMENT_DT_FILTERS = "APPLY_PAYMENT_DT_FILTERS"
export const PATCH_PAYMENT_DT_FILTERS = "PATCH_PAYMENT_DT_FILTERS"
export const CLEAR_PAYMENT_DT_FILTERS = "CLEAR_PAYMENT_DT_FILTERS"
export const UNSET_PAYMENT_DT_FILTERS = "UNSET_PAYMENT_DT_FILTERS"
export const DO_PAYMENT_DT_FILTERS_CLEANUP = "DO_PAYMENT_DT_FILTERS_CLEANUP"

