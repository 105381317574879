import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_DEALER_GROUP_DT,
  GET_DEALER_GROUP_LIST,
} from "./actionTypes";

import {
  getDealerGroupDtOk,
  getDealerGroupDtErr,
  getDealerGroupListOk,
  getDealerGroupListErr,
} from "./actions";

import {
  getDealerGroupDt,
  getDealerGroups,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetDealerGroupDt({ payload: { params } }) {
  try {
    const response = yield call(getDealerGroupDt, params);
    yield put(getDealerGroupDtOk(response));
  } catch (error) {
    yield put(getDealerGroupDtErr(error));
  }
}

/********* LIST *********/

function* onGetDealerGroupList() {
  try {
    const response = yield call(getDealerGroups);
    yield put(getDealerGroupListOk(response));
  } catch (error) {
    yield put(getDealerGroupListErr(error));
  }
}

function* dealerGroupSaga() {
  yield takeEvery(GET_DEALER_GROUP_DT, onGetDealerGroupDt);
  yield takeEvery(GET_DEALER_GROUP_LIST, onGetDealerGroupList);
}

export default dealerGroupSaga;