import { useEffect, useState } from "react";
import { perms, useAccess } from "context/access";
import Message from "model/message";

// custom hook that returns the list of channels the user has access to
// besides the default permissions to each channel, we need to consider if it is an order with notary or not
const useAllowedChannels = isNotaryRequired => {

  const { iAmGranted } = useAccess();

  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const channelMap = Message.getChannelMap();
    const allowedChannels = Object.keys(channelMap).reduce((allowedChannelMap, channel) => {
      const channelId = parseInt(channel);
      if (iAmGranted(perms.view_message_channel, { channelId, isNotaryRequired })) {
        allowedChannelMap[channelId] = channelMap[channelId];
      }
      return allowedChannelMap;
    }, {});
    setChannels(allowedChannels);
  }, [isNotaryRequired]);

  return channels;
};

export default useAllowedChannels;