import React from 'react';
import PropTypes from 'prop-types';
import { AccessDeniedException, NotFoundException, BadRequestException, LocalException, NetworkErrorException } from 'helpers/errorHelper';
import AccessDenied from './AccessDenied';
import NotFound from './NotFound';
import BadRequest from './BadRequest';
import ServerError from './ServerError';
import LocalError from './LocalError';
import NetworkError from './NetworkError';

const Error = props => {

  if (props.error instanceof AccessDeniedException) {
    return <AccessDenied
      title={props.title403 || props.title}
      btnLink={props.btnLink403 || props.btnLink}
      btnText={props.btnText403 || props.btnText}
    />;
  }

  if (props.error instanceof NotFoundException) {
    return <NotFound
      title={props.title404 || props.title}
      btnLink={props.btnLink404 || props.btnLink}
      btnText={props.btnText404 || props.btnText}
    />;
  }

  if (props.error instanceof BadRequestException) {
    return <BadRequest
      title={props.title400 || props.title}
      btnLink={props.btnLink400 || props.btnLink}
      btnText={props.btnText400 || props.btnText}
    />;
  }

  if (props.error instanceof NetworkErrorException) {
    return <NetworkError
      title={props.title}
      btnLink={props.btnLink}
      btnText={props.btnText}
    />;
  }

  if (props.error instanceof LocalException) {
    return <LocalError
      title={props.title || props.error.message}
      btnLink={props.btnLink}
      btnText={props.btnText}
    />;
  }

  return <ServerError
    title={props.title500 || props.title}
    btnLink={props.btnLink500 || props.btnLink}
    btnText={props.btnText500 || props.btnText}
  />;
}

Error.propTypes = {
  error: PropTypes.any,
  title: PropTypes.string,
  btnLink: PropTypes.string,
  btnText: PropTypes.string,
  title403: PropTypes.string,
  btnLink403: PropTypes.string,
  btnText403: PropTypes.string,
  title404: PropTypes.string,
  btnLink404: PropTypes.string,
  btnText404: PropTypes.string,
  title400: PropTypes.string,
  btnLink400: PropTypes.string,
  btnText400: PropTypes.string,
  title500: PropTypes.string,
  btnLink500: PropTypes.string,
  btnText500: PropTypes.string,
}

export default Error;