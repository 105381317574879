import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { getOrderSignerFieldLabel } from 'helpers/utilHelper';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import ExpanderToggle from '../ExpanderToggle';
import ExpanderDetails from '../ExpanderDetails';

const OrderSignerUpdated = props => {

  const { log } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        <AuthorName log={log} /> updated signer <b>{log.subject.firstName} {log.subject.lastName}</b>.
        <p className="mb-0">
          <EventTime log={log} />
          <ExpanderToggle {...props} {...{ isExpanded, setIsExpanded }} />
        </p>
      </div>
    </div>
    <ExpanderDetails {...props} {...{ isExpanded }}>
      <Table className="table-borderless mb-0 w-auto">
        <tbody>
          {Object.entries(log.newValues).map(entry => {
            return <tr key={entry[0]}>
              <th className="pt-2 pb-0">{getOrderSignerFieldLabel(entry[0])}</th>
              <td className="pt-2 pb-0">{log.oldValues[entry[0]] || '--'}</td>
              <td className="pt-2 pb-0"><i className="bx bx-right-arrow-alt"></i></td>
              <td className="pt-2 pb-0">{entry[1]}</td>
            </tr>
          })}
        </tbody>
      </Table>
    </ExpanderDetails>
  </React.Fragment>
}

OrderSignerUpdated.propTypes = {
  log: PropTypes.object,
}

export default OrderSignerUpdated;