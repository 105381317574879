import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderNotaryDocsUploaded = props => {

	const { log } = props;

	return <div className="d-flex">
		<AuthorAvatar log={log} />
		<div className="flex-grow-1">
			<AuthorName log={log} /> uploaded the docs.
			<p className="mb-0"><EventTime log={log} /></p>
		</div>
	</div>
}

OrderNotaryDocsUploaded.propTypes = {
	log: PropTypes.object,
}

export default OrderNotaryDocsUploaded;