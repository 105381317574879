import React from "react";
import PropTypes from 'prop-types';
import {Row, Col, Button, Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import specialInstructionsIcon from '../../../../assets/images/special-instructions-icon.svg';
import {newLineTextFormat} from "../../../../helpers/utilHelper";

const ViewSectionSpecialInstructions = ({ isRefreshing, dealerGroup, toggleEditMode }) => {
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** OTHERS **********/
  let text = dealerGroup?.specialInstructions;
  // Split the text into separate lines
  let lines = text?.split('\n');

  /********** EVENT HANDLERS **********/

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <CardTitle><img className='me-2' src={specialInstructionsIcon}/>Group Special Instructions</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_special_instructions) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="instruction-description mb-4 ps-2">
          {dealerGroup?.specialInstructions
            ? newLineTextFormat(lines).map((line, index) => (
              <p className='mb-1' key={index}>{line}</p>
            ))
            : 'No special instructions added.'}
        </div>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewSectionSpecialInstructions.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerGroup: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewSectionSpecialInstructions;
