import React from "react";
import PropTypes from 'prop-types';
import {Row, Col, Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import specialInstructionsIcon from '../../../../assets/images/special-instructions-icon.svg';
import {newLineTextFormat} from "../../../../helpers/utilHelper";

const ViewSectionSpecialInstructions = ({ isRefreshing, dealerStore }) => {

  /********** OTHERS **********/
  let text = dealerStore?.dealerGroupSpecialInstructions;
  // Split the text into separate lines
  let lines = text?.split('\n');

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <CardTitle><img className='me-2' src={specialInstructionsIcon}/>Group Special Instructions</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="instruction-description mb-4 ps-2">
          {text ? newLineTextFormat(lines).map((line, index) => (
              <p className='mb-1' key={index}>{line}</p>
            ))
            : 'No special instructions added.'}
        </div>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewSectionSpecialInstructions.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerStore: PropTypes.object,
};

export default ViewSectionSpecialInstructions;
