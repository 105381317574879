import React from 'react';
import PropTypes from 'prop-types';
import EventTime from '../EventTime';

const OrderSealFailed = props => {

  const { log } = props;

  return <div className="d-flex">
    <div className="flex-grow-1">
      Order seal failed.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

OrderSealFailed.propTypes = {
  log: PropTypes.object,
}

export default OrderSealFailed;