import {
  GET_BIDDING_DT,
  GET_BIDDING_DT_ERR,
  GET_BIDDING_DT_OK,
  DO_BIDDING_DT_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getBiddingDt = params => ({
  type: GET_BIDDING_DT,
  payload: { params },
});

export const getBiddingDtOk = response => ({
  type: GET_BIDDING_DT_OK,
  payload: { response },
});

export const getBiddingDtErr = error => ({
  type: GET_BIDDING_DT_ERR,
  payload: { error },
});

export const doBiddingDtCleanup = () => ({
  type: DO_BIDDING_DT_CLEANUP,
});
