export default class CronJob {

  static STATUS_ERROR = -1;
  static STATUS_INSTALLED = 0;
  static STATUS_RUNNING = 1;
  static STATUS_SUCCESS = 2;

  static getStatusMap() {
    return {
      [this.STATUS_ERROR]: 'Error',
      [this.STATUS_INSTALLED]: 'Installed',
      [this.STATUS_RUNNING]: 'Running',
      [this.STATUS_SUCCESS]: 'Success',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }
}