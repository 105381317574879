import React, { useEffect } from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";
import { doOrderSingleCleanup, getOrderWithCustomerSigners } from "store/actions";
import { useParams } from "react-router-dom";
import FormEditNotaryBids from "../Partial/Form/Edit/NotaryBids";
import OrderInfoAndServices from "components/Shared/BiddingOrderDetails";

const ViewNotaryManualSearch = () => {

  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getOrderWithCustomerSigners(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col><Breadcrumbs breadcrumbItems={breadcrumbs(order)} /></Col>
            <Col className="text-end">
              <OrderInfoAndServices order={order} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">Notary Assignment</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <FormEditNotaryBids />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: "Edit order",
  url: route(routes.view_order, order.id),
}, {
  title: "Notary",
  url: route(routes.view_order_notary, order.id),
}, {
  title: "Manual search",
}];

export default ViewNotaryManualSearch;