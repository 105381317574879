import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import FormBillingEdit from '../Form/Billing/Edit';
import ViewBilling from '../View/Billing';
import { getQbDealers } from "../../../../helpers/backendHelper";
import { QB_UNABLE_REFRESH_TOKEN } from 'helpers/errorHelper';
import { showError } from 'helpers/utilHelper';

const SectionBilling = props => {

  const { isLoadBusy, id, dealerStore, refreshHandler } = props;

  /********** STATE **********/
  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [qbDealers, setQbDealers] = useState([]);
  const [qbDealersError, setQbDealersError] = useState(null);

  /********** EFFECTS **********/

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  useEffect(() => {
    getQBDealersList();
  }, []);

  /********** HANDLERS **********/
  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      triggerRefresh();
    }
  }

  const triggerRefresh = () => {
    setIsRefreshing(true);
    refreshHandler();
  }

  const getQBDealersList = () => {
    getQbDealers()
      .then(response => {
        setQbDealers(response.QueryResponse.Customer);
      })
      .catch(err => {
        if (err.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        setQbDealersError(err)
      });
  }

  return <React.Fragment>
    {isEditModeActive && <Card>
      <CardBody className="pt-3">
        <FormBillingEdit id={id} defaultValues={dealerStore} finishedHandler={toggleEditMode} qbDealers={qbDealers} qbDealersError={qbDealersError} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewBilling dealerStore={dealerStore} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} triggerRefresh={triggerRefresh} />}
  </React.Fragment>
}

SectionBilling.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionBilling;