import React from "react";
import PropTypes from "prop-types"
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editCredential } from "store/actions";


const FormCredentialEdit = ({ children, credential }) => {

  const dispatch = useDispatch();

  /********** FORM CONFIG **********/

  // default values of the form fields
  const formInitialValues = {
    type: credential?.type,
    commissionState: credential?.commissionState,
    commissionNo: credential?.commissionNo,
    file: null,
    expiry: !!credential?.expirationTs ? 1 : 0,
    countiesCovered: credential?.countiesCovered,
    commissionState2: credential?.commissionState2,
    driverLicenseNo: credential?.driverLicenseNo,
    expirationTs: !!credential.expirationTs ? credential.expirationTs : null
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
          formData.append(key, values[key]);
        });
        dispatch(editCredential(credential.id, formData));
      }}
    >
      {children}
    </Formik>)
}

const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

const validationSchema = Yup.object({
  type: Yup.number(),
  file: Yup.mixed()
    .test("fileType", "File must be a pdf or an image", value => !value || allowedFileTypes.includes(value.type)),
});

FormCredentialEdit.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  credential: PropTypes.object.isRequired,
}

export default FormCredentialEdit;