import { combineReducers } from "redux";
import {
  REFRESH_APP_SETTINGS,
  REFRESH_APP_SETTINGS_OK,
  REFRESH_APP_SETTINGS_ERR,
  DO_REFRESH_APP_ASETTINGS_CLEANUP,
} from "./actionTypes";

/********** REFRESH **********/

const defaultRefreshState = {
  refreshed: null,
  refreshError: null,
  isRefreshInProgress: false,
};

const Refresh = (state = defaultRefreshState, action) => {
  switch (action.type) {
    case REFRESH_APP_SETTINGS:
      state = {
        ...state,
        refreshed: null,
        refreshError: null,
        isRefreshInProgress: true,
      };
      break
    case REFRESH_APP_SETTINGS_OK:
      state = {
        ...state,
        refreshed: true,
        isRefreshInProgress: false,
      };
      break
    case REFRESH_APP_SETTINGS_ERR:
      state = {
        ...state,
        refreshed: false,
        refreshError: action.payload.error,
        isRefreshInProgress: false,
      };
      break
    case DO_REFRESH_APP_ASETTINGS_CLEANUP:
      state = { ...defaultRefreshState };
      break
  }
  return state;
}

export default combineReducers({
  Refresh,
});