import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = props => {

  const { title, breadcrumbItems } = props;

  const itemLength = breadcrumbItems?.length;

  return <Row>
    <Col xs="12">
      <div className="page-title-box d-flex align-items-center justify-content-between">
        {!!title && <h4 className="mb-0 font-size-18">{title}</h4>}
        {!!itemLength && <ol className="breadcrumb m-0">
          {breadcrumbItems.map((item, key) => (
            <BreadcrumbItem key={key} active={key + 1 === itemLength}>
              {!!item.url && <Link to={item.url}>{item.title}</Link>}
              {!item.url && <span>{item.title}</span>}
            </BreadcrumbItem>
          ))}
        </ol>}
      </div>
    </Col>
  </Row>
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
}

export default Breadcrumb
