import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { Alert, FormFeedback } from "reactstrap";
import { components } from "react-select";
import { showError, toSelectOptions } from "helpers/utilHelper";
import Tag from "model/tag";
import SupportCase from "model/supportCase";
import SpinnerChase from "components/Shared/SpinnerChase";
import { createTag, deleteTag, getSuggestedTagList, getTagList, getTags } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";
import classnames from "classnames";

const ViewTags = ({ supportCase }) => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState(null);
  const [isTagsLoadInProgress, setIsTagsLoadInProgress] = useState(false);
  const [list, setList] = useState([]);
  const [listError, setListError] = useState(null);
  const [suggestedList, setSuggestedList] = useState([]);
  const [isSuggestedListLoadInProgress, setIsSuggestedListLoadInProgress] = useState(false);
  // toggle edit mode
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  const isResolved = SupportCase.isResolved(supportCase.status);

  /********** OTHER **********/

  const tagOptions = toSelectOptions(list);
  const tagIds = tags.map(t => t.id);

  const alreadyIncludedTag = id => tagIds.includes(id);

  const filteredSuggestedList = suggestedList?.filter(tag => !alreadyIncludedTag(tag.id));

  const refreshTags = () => {
    setIsTagsLoadInProgress(true);
    // make the initial remote call to get the tags data
    getTags(supportCase.id)
      .then(response => {
        setTags(response.tags);
      })
      .catch(ex => {
        setTagsError(ex);
      })
      .finally(() => {
        setIsTagsLoadInProgress(false);
      });
  };

  const addTag = (values) => {
    createTag(supportCase.id, { tagIds: values })
      .then(response => {
        refreshTags();
      })
      .catch(ex => {
        showError("Unable to add tag");
      })
  }

  // delete-store event handler
  // make the remote call to delete the store
  const removeTag = (id) => {
    deleteTag(id)
      .then(response => {
        refreshTags();
      })
      .catch(ex => {
        showError("Unable to remove tag");
      })
  };

  const refreshTagList = () => {
    getTagList(Tag.CAT_SUPPORT_CASE)
      .then(response => {
        setList(response.tags);
      })
      .catch(ex => {
        setListError(ex);
      })
  }

  const refreshSuggestedTagList = () => {
    setIsSuggestedListLoadInProgress(true);
    // make the initial remote call to get the tags data
    getSuggestedTagList()
      .then(response => {
        setSuggestedList(response.tags);
      })
      .catch(ex => {
        showError("Unable to load suggested tag list");
      })
      .finally(() => {
        setIsSuggestedListLoadInProgress(false);
      });
  };

  /********** HANDLERS **********/

  // toggles the edit mode on and off
  const toggleEditMode = () => {
    setIsEditModeActive(prevMode => !prevMode);
  };

  const handleSelectOption = selected => {
    const newValues = selected.filter(s => !alreadyIncludedTag(s.value)).map(o => o.value);
    addTag(newValues);
  };

  const handleSelectTag = id => () => {
    if (!alreadyIncludedTag(id)) addTag(id);
  };

  const handleRemoveTag = id => () => {
    if (iAmNotGranted(perms.edit_support_cases)) {
      return;
    }
    if (!isResolved) removeTag(id);
  };

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get selected tags
    refreshTags();
    // get tag options
    refreshTagList();
    // get suggested tags
    refreshSuggestedTagList();
  }, []);

  return <div className="p-2">
    <div className="position-relative d-flex">
      <h6 className="mb-0">
        {isEditModeActive ? "Selected Tags" : "Tags"}
      </h6>
      {isTagsLoadInProgress && <SpinnerChase className="xxs mx-3 my-1" />}
      {isEditModeActive && <span className="close-tag-edit-btn" onClick={toggleEditMode}>
        <i className="mdi mdi-close" />
      </span>}
    </div>
    <div className="pt-3">
      {tagsError && <Alert color="danger" className="fade show text-center">
        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load tags
      </Alert>}
      {!tagsError && !tags?.length && (isEditModeActive || isResolved || iAmNotGranted(perms.edit_support_cases)) && "No tags selected"}
      {!tagsError && tags.map(tag => (
        <span
          key={tag.id}
          className={classnames("badge badge-lg rounded-pill bg-success me-2 my-1 px-2", { "cursor-pointer": iAmGranted(perms.edit_support_cases) })}
          onClick={handleRemoveTag(tag.supportCaseTagId)}
        >
          <span className="me-1">{tag.name}</span> {!isResolved && iAmGranted(perms.edit_support_cases) && <i className="mdi mdi-close" />}
        </span>))}
      {iAmGranted(perms.edit_support_cases) && !isEditModeActive && !isResolved && <span className="cursor-pointer badge badge-lg rounded-pill bg-dark me-2 my-1" onClick={toggleEditMode}>+ Add tags</span>}
    </div>
    {isEditModeActive && <div>
      {!!filteredSuggestedList.length && <div className="pt-3">
        <div className="d-flex">
          <h6 className="mb-0">Suggested Tags</h6>
          {(isSuggestedListLoadInProgress || isTagsLoadInProgress) && <SpinnerChase className="xxs mx-3 my-1" />}
        </div>
        <div className="py-3">
          {filteredSuggestedList.map(tag => (
            <span
              key={tag.id}
              className="cursor-pointer badge badge-lg rounded-pill me-2 my-1 px-2 bg-info"
              onClick={handleSelectTag([tag.id])}
            >
              {tag.name}
            </span>))}
        </div>
      </div>}
      <div className="pt-3 pb-2">
        <CreatableSelect
          classNamePrefix="select2-selection"
          isMulti
          isClearable={false}
          controlShouldRenderValue={false}
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          value={tagOptions?.filter(option => tagIds.includes(option.value))}
          onChange={handleSelectOption}
          options={tagOptions}
          placeholder="Search other tags"
          className={!!listError && 'is-invalid'} />
        {!!listError && <FormFeedback type="invalid">Unable to load tags</FormFeedback>}
      </div>
    </div>}
  </div>
}

const DropdownIndicator = components.DropdownIndicator

const CustomDropdownIndicator = props => {
  return <DropdownIndicator {...props}><i className="bx bx-search-alt search-icon font-size-18" /></DropdownIndicator>
}

ViewTags.propTypes = {
  supportCase: PropTypes.object
};

export default ViewTags;
