import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionInfo from "./Partial/Section/Info";
import SectionImage from "./Partial/Section/Image";
import SectionManagedStores from "./Partial/Section/ManagedStores";
import { getUser } from "helpers/backendHelper";
import { toUserModel } from "helpers/utilHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [user, setUser] = useState(null);
  const [userError, setUserError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshUser();
  }, []);

  /********** OTHER **********/

  const refreshUser = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getUser(id)
      .then(response => {
        setUser(toUserModel(response.user));
      })
      .catch(ex => {
        setUserError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_users) && <div className="page-content">
      {user && <React.Fragment>
        <MetaTitle>{user.fullName} | Users</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(user)} />
          <Row>
            <Col xxl="8">
              <SectionInfo id={id} user={user} refreshHandler={refreshUser} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xxl="4">
              <SectionImage user={user} />
            </Col>
          </Row>
          {iAmGranted(perms.view_user_managed_stores) && user.isAccountManager() && (
            <Row>
              <Col>
                <SectionManagedStores />
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !user && <Preloader className="inner" />}
      {userError && <Error error={userError} title404="User not found" />}
    </div>}
    {iAmNotGranted(perms.view_users) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = user => [{
  title: 'USERS',
  url: route(routes.list_users),
}, {
  title: user.fullName,
}];

export default View;
