import React, { useEffect, useState } from 'react';
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, Button } from "reactstrap";
import SpinnerChase from 'components/Shared/SpinnerChase';
import config from 'config';
import { getInitialsFromName } from 'helpers/utilHelper';
import UserAvatarWithActivityStatus from 'components/Shared/UserAvatarWithActivityStatus';
import { getDealerStoreUsers } from "helpers/backendHelper";

const SectionUsers = props => {

  const { id } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [users, setUsers] = useState([]);
  const [usersError, setUsersError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get regions list
    getDealerStoreUserList();
  }, [props.id]);

  /********** EVENT HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(expanded => !expanded);

  /********** OTHER **********/

  const getTableRows = () => isExpanded ? users : users.slice(0, config.COLLAPSED_TBL_ROWS);

  const canExpand = () => !isExpanded && users.length > config.COLLAPSED_TBL_ROWS;

  const canCollapse = () => isExpanded;

  const getDealerStoreUserList = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the users data
    getDealerStoreUsers(id)
      .then(response => {
        setUsers(response.users);
      })
      .catch(ex => {
        setUsersError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Users</CardTitle>
          </Col>
          <Col md="auto">
            <div className="text-end">
              {iAmGranted(perms.create_dealer_store_users) && <Link to={route(routes.new_dealer_store_user, props.id)} className="btn btn-outline-primary">
                <i className="mdi mdi-plus me-1" />Add user
              </Link>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {getTableRows().length > 0 && getTableRows().map((entry, index) => (
              <tr key={index}>
                <td>
                  <UserAvatarWithActivityStatus
                    avatar={{
                      id: entry.id,
                      image: entry.image,
                      initials: getInitialsFromName(entry.fullName),
                    }}
                    activityStatus={{
                      status: entry.activityStatus,
                    }}
                    size="sm"
                    className="in-table me-3" />
                  <Link to={route(routes.view_user, entry.id)}>{entry.fullName}</Link>
                </td>
                <td>{entry.email}</td>
                <td>{entry.userRoleName}</td>
                <td><i className={'mdi dtable-icon ' + (entry.isActive ? 'mdi-check-circle' : 'mdi-cancel')} /></td>
              </tr>
            ))}
            {getTableRows().length == 0 && !usersError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">No users found</td>
            </tr>}
            {!!usersError && <tr>
              <td className="table-empty" colSpan="4">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load users
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="text-center">
          {canExpand() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1"></i>View more</Button>}
          {canCollapse() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-up me-1"></i>View less</Button>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionUsers.propTypes = {
  id: PropTypes.number.isRequired,
}

export default SectionUsers;