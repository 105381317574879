import React, { useState, useEffect } from "react";
import { Card, CardHeader, Row, Col, CardBody, Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { getSimilarOrders } from "helpers/backendHelper";
import { Link, } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { showError } from "helpers/utilHelper";

const SectionDuplicateOrders = ({ id, order }) => {

  /********** STATE **********/
  const [duplicates, setDuplicates] = useState([]);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  /********** EFFECTS **********/
  useEffect(() => {
    getDuplicates();
  }, [])

  /********** OTHER **********/
  const getDuplicates = () => {
    getSimilarOrders(id)
      .then(resp => setDuplicates(resp.orders))
      .catch(err => showError("Unable to possible frauds"))
  }

  // Checking if the name is the same
  const checkNameDuplicate = (duplicate) => {
    const mainSigner = order.signers.find(signer => signer.position === 1);

    if (mainSigner.fullName === duplicate.customerName) {
      return true;
    }
    return false;
  }

  // Checking if the phone is the same
  const checkPhoneDuplicate = (duplicate) => {
    const mainSigner = order.signers.find(signer => signer.position === 1);

    if (mainSigner.phone === duplicate.customerPhone) {
      return true;
    }
    return false;
  }

  // Checking if the email is the same
  const checkEmailDuplicate = (duplicate) => {
    const mainSigner = order.signers.find(signer => signer.position === 1);

    if (mainSigner.email === duplicate.customerEmail) {
      return true;
    }
    return false;
  }

  return <React.Fragment>{!!duplicates?.length && <Card className="duplicate-orders-section">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row onClick={() => setCollapseIsOpen(prev => !prev)}>
        <Col>
          <div className="card-title mb-4 mt-2">Possible Frauds</div>
        </Col>
        <Col xs="auto" className="text-end">
          <i className={`bx bx-chevron-down font-size-36 collapse-chevron ${collapseIsOpen && 'rotate-chevron'}`} />
        </Col>
      </Row>
    </CardHeader>
    <Collapse isOpen={collapseIsOpen}>
      <CardBody>
        <div className="duplicate-orders-table">
          <div className="table-head mb-4">
            <div className="table-row">
              <div className="table-data text-center">Signing ID</div>
              <div className="table-data text-center">Customer Name</div>
              <div className="table-data text-center">Phone Number</div>
              <div className="table-data text-center">Email</div>
              <div className="table-data text-center">Address</div>
              <div className="table-data text-center">Dealership</div>
              <div className="table-data text-center">Order Date</div>
            </div>
          </div>
          <div className="table-body">
            {duplicates?.map((duplicateOrder, index) => <div key={index} className="table-row">
              <div className="table-data text-center"><Link to={route(routes.view_order, duplicateOrder.id)}>{duplicateOrder.id}</Link></div>
              <div className={`table-data text-center ${checkNameDuplicate(duplicateOrder) && 'check-label'}`}>{duplicateOrder.customerName}</div>
              <div className={`table-data text-center ${checkPhoneDuplicate(duplicateOrder) && 'check-label'}`}>{duplicateOrder.customerPhone}</div>
              <div className={`table-data text-center ${checkEmailDuplicate(duplicateOrder) && 'check-label'}`}>{duplicateOrder.customerEmail}</div>
              <div className="table-data text-center">{duplicateOrder.customerAddress || '--'}</div>
              <div className="table-data text-center"><Link to={route(routes.view_dealer_store, duplicateOrder.dealerStoreId)}>{duplicateOrder.dealerStoreName}</Link></div>
              <div className="table-data text-center">{formatTimestamp(duplicateOrder.submittedTs, formats.DATETIME)}</div>
            </div>)}
          </div>
        </div>
      </CardBody>
    </Collapse>
  </Card>}
  </React.Fragment>
}

SectionDuplicateOrders.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object
}

export default SectionDuplicateOrders;