import { deleteNotification, getNotification, readNotification, unreadNotification, deleteManyNotifications, getNotificationDt, readManyNotifications, unreadManyNotifications, getNotificationList } from "helpers/backendHelper";
import { deleteNotificationErr, deleteNotificationOk, getNotificationErr, getNotificationOk, markNotificationErr, markNotificationReadOk, markNotificationUnreadOk, deleteNotificationsErr, deleteNotificationsOk, getNotificationDtErr, getNotificationDtOk, getNotificationListErr, getNotificationListOk, markNotificationsErr, markNotificationsReadOk, markNotificationsUnreadOk } from "./actions";
import { DELETE_NOTIFICATION, GET_NOTIFICATION, MARK_NOTIFICATION_READ, MARK_NOTIFICATION_UNREAD, DELETE_NOTIFICATIONS, GET_NOTIFICATION_DT, GET_NOTIFICATION_LIST, MARK_NOTIFICATIONS_READ, MARK_NOTIFICATIONS_UNREAD } from "./actionTypes";
import { takeEvery, put, call } from 'redux-saga/effects';

/********** DATATABLE **********/

function* onGetNotificationDt({ payload: { params } }) {
  try {
    const response = yield call(getNotificationDt, params);
    yield put(getNotificationDtOk(response));
  } catch (error) {
    yield put(getNotificationDtErr(error));
  }
}

function* onMarkNotificationsRead({ payload: { ids } }) {
  try {
    const response = yield call(readManyNotifications, { ids });
    yield put(markNotificationsReadOk(response));
  } catch (error) {
    yield put(markNotificationsErr(error))
  }
}

function* onMarkNotificationsUnread({ payload: { ids } }) {
  try {
    const response = yield call(unreadManyNotifications, { ids });
    yield put(markNotificationsUnreadOk(response));
  } catch (error) {
    yield put(markNotificationsErr(error))
  }
}

function* onDeleteNotifications({ payload: { ids } }) {
  try {
    yield call(deleteManyNotifications, { ids });
    yield put(deleteNotificationsOk());
  } catch (error) {
    yield put(deleteNotificationsErr(error));
  }
}

/********** LIST **********/

function* onGetNotificationList() {
  try {
    const response = yield call(getNotificationList);
    yield put(getNotificationListOk(response));
  } catch (error) {
    yield put(getNotificationListErr(error));
  }
}

/********** SINGLE **********/

function* onGetNotification({ payload: { id } }) {
  try {
    const response = yield call(getNotification, id);
    yield put(getNotificationOk(response));
  } catch (error) {
    yield put(getNotificationErr(error));
  }
}

function* onDeleteNotification({ payload: { id } }) {
  try {
    yield call(deleteNotification, id);
    yield put(deleteNotificationOk());
  } catch (error) {
    yield put(deleteNotificationErr(error));
  }
}

function* onMarkNotificationRead({ payload: { id } }) {
  try {
    const response = yield call(readNotification, id);
    yield put(markNotificationReadOk(response));
  } catch (error) {
    yield put(markNotificationErr(error))
  }
}

function* onMarkNotificationUnread({ payload: { id } }) {
  try {
    const response = yield call(unreadNotification, id);
    yield put(markNotificationUnreadOk(response));
  } catch (error) {
    yield put(markNotificationErr(error))
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATION_DT, onGetNotificationDt);

  yield takeEvery(MARK_NOTIFICATIONS_READ, onMarkNotificationsRead);
  yield takeEvery(MARK_NOTIFICATIONS_UNREAD, onMarkNotificationsUnread);

  yield takeEvery(DELETE_NOTIFICATIONS, onDeleteNotifications);

  yield takeEvery(GET_NOTIFICATION_LIST, onGetNotificationList);

  yield takeEvery(GET_NOTIFICATION, onGetNotification);
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification);

  yield takeEvery(MARK_NOTIFICATION_READ, onMarkNotificationRead);
  yield takeEvery(MARK_NOTIFICATION_UNREAD, onMarkNotificationUnread);
}

export default notificationSaga;
