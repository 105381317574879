import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import Select from "react-select";
import { canPayWithCheckOptionsForStores, isObject, nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { DEALER_STORE_CANNOT_SWITCH_TO_PAYMENT_PLAN_WITH_SUBSCRIPTION, DEALER_STORE_CANNOT_UPDATE_PAYMENT_PLAN_WHILE_SETUP_IN_PROGRESS, ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { perms, useAccess } from "context/access";
import { getPaymentPlans, updateDealerStoreBilling } from "helpers/backendHelper";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";

const FormBillingEdit = props => {

  const { defaultValues, id, finishedHandler, qbDealers, qbDealersError } = props;

  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [paymentPlansError, setPaymentPlansError] = useState(null);

  /********** FORM CONFIG **********/

  // billing email is an array of email addresses or null
  // however to fit into the textarea it must become a string
  let defaultBillingEmail = defaultValues.billingEmail;
  if (!!defaultBillingEmail && Array.isArray(defaultBillingEmail)) {
    defaultBillingEmail = defaultBillingEmail.join(', ');
  }

  const formInitialValues = {
    paymentPlanId: '',
    billingName: '',
    billingPhone: '',
    billingPhone2: '',
    billingAccountNo: '',
    maxPaymentPlanDays: '',
    maxPaymentPlanOrders: '',
    qbCustomerId: '',
    canPayWithCheck: '',
    coupaName: '',
    coupaAccount: '',
    coupaStoreId: '',
    ...nullsToEmptyStrings(defaultValues),
    billingEmail: defaultBillingEmail || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      paymentPlanId: Yup.number().required('Field is required'),
      // user may submit one or multiple comma-separated email addresses
      // so we leave the complex validation to the backend
      billingEmail: Yup.string().trim(),
      billingPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      billingPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => saveDealerStoreBilling(values, id),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the payment plan field options
    getPaymentPlansList();
  }, []);

  // runs whenever 'paymentPlansError' changes
  useEffect(() => {
    if (paymentPlansError) {
      // set an error on the form field
      formik.setFieldError('paymentPlanId', 'Unable to load payment plans');
    }
  }, [paymentPlansError]);

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  useEffect(() => {
    if (qbDealersError) {
      // set an error on the form field
      formik.setFieldError('qbCustomerId', 'Unable to load stores');
    }
  }, [qbDealersError]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreBilling = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreBilling(values, id)
      .then(response => {
        showSuccess(`Dealer store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        // enable the save button
        formik.setSubmitting(false);
        if (ex.code == DEALER_STORE_CANNOT_SWITCH_TO_PAYMENT_PLAN_WITH_SUBSCRIPTION) {
          showError('Changing the payment plan requires managing Stripe subscriptions and is not possible from here');
          return;
        }
        if (ex.code == DEALER_STORE_CANNOT_UPDATE_PAYMENT_PLAN_WHILE_SETUP_IN_PROGRESS) {
          showError('Cannot change the payment plan while a payment setup is in progress');
          return;
        }
        showError('Unable to save dealer store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  // prepares the list of groups to be used as select options
  const getPaymentPlanOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(paymentPlans)];

  const getPaymentPlansList = () => {
    // make the initial remote call to get the user data
    getPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(ex => {
        setPaymentPlansError(ex);
      })
  };

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Billing</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Billing contact</Label>
            <Col sm={7}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="billingName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingName} invalid={!!formik.errors.billingName} />
              {!!formik.errors.billingName && <FormFeedback type="invalid">{formik.errors.billingName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Email</Label>
            <Col sm={7}>
              <TextareaAutosize className={classnames('form-control', { 'is-invalid': !!formik.errors.billingEmail })} placeholder="ex. john@domain.com" name="billingEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingEmail} />
              {!!formik.errors.billingEmail && <FormFeedback type="invalid">{isObject(formik.errors.billingEmail) ? Object.values(formik.errors.billingEmail)[0] : formik.errors.billingEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Phone</Label>
            <Col sm={7}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone} invalid={!!formik.errors.billingPhone} />
              {!!formik.errors.billingPhone && <FormFeedback type="invalid">{formik.errors.billingPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Alternate phone</Label>
            <Col sm={7}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone2} invalid={!!formik.errors.billingPhone2} />
              {!!formik.errors.billingPhone2 && <FormFeedback type="invalid">{formik.errors.billingPhone2}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Account number</Label>
            <Col sm={7}>
              <Input type="text" className="form-control" placeholder="ex. 94205617" name="billingAccountNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingAccountNo} invalid={!!formik.errors.billingAccountNo} />
              {!!formik.errors.billingAccountNo && <FormFeedback type="invalid">{formik.errors.billingAccountNo}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Payment plan days</Label>
            <Col sm={7}>
              <Input
                type="text"
                className="form-control"
                placeholder={iAmGranted(perms.edit_payment_plans) ? "ex. 25" : "--"}
                name="maxPaymentPlanDays"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.maxPaymentPlanDays}
                invalid={!!formik.errors.maxPaymentPlanDays}
                readOnly={!iAmGranted(perms.edit_payment_plans)}
              />
              {!!formik.errors.maxPaymentPlanDays && <FormFeedback type="invalid">{formik.errors.maxPaymentPlanDays}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Payment plan orders</Label>
            <Col sm={7}>
              <Input
                type="text"
                className="form-control"
                placeholder={iAmGranted(perms.edit_payment_plans) ? "ex. 25" : "--"}
                name="maxPaymentPlanOrders"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.maxPaymentPlanOrders}
                invalid={!!formik.errors.maxPaymentPlanOrders}
                readOnly={!iAmGranted(perms.edit_payment_plans)}
              />
              {!!formik.errors.maxPaymentPlanOrders && <FormFeedback type="invalid">{formik.errors.maxPaymentPlanOrders}</FormFeedback>}
            </Col>
          </Row>
          {iAmGranted(perms.edit_store_payment_plan) && <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Edit payment plan *</Label>
            <Col sm={7}>
              <Select
                classNamePrefix="select2-selection"
                name="paymentPlanId"
                options={getPaymentPlanOptions()}
                onChange={selected => formik.setFieldValue('paymentPlanId', selected.value)}
                onFocus={e => onFieldFocused(e, 'paymentPlanId')}
                value={getPaymentPlanOptions().find(option => option.value === formik.values.paymentPlanId)}
                className={!!formik.errors.paymentPlanId && 'is-invalid'} />
              {!!formik.errors.paymentPlanId && <FormFeedback type="invalid">{formik.errors.paymentPlanId}</FormFeedback>}
            </Col>
          </Row>}
          <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">QuickBooks Customer</Label>
            <Col sm={7}>
              {iAmGranted(perms.edit_dealer_stores_QB) ? <>
                <Select
                  classNamePrefix="select2-selection"
                  name="qbCustomerId"
                  options={qbDealers}
                  getOptionLabel={option => option.DisplayName}
                  getOptionValue={option => option.Id}
                  onChange={selected => formik.setFieldValue("qbCustomerId", +selected.Id)}
                  onFocus={e => onFieldFocused(e, "qbCustomerId")}
                  value={qbDealers.find(option => option.Id == formik.values.qbCustomerId)}
                  className={!!formik.errors.qbCustomerId && "is-invalid"} />
                {!!formik.errors.qbCustomerId && <FormFeedback type="invalid">{formik.errors.qbCustomerId}</FormFeedback>}
              </> : <Input disabled value={defaultValues.qbCustomerId || '--'} />}
            </Col>
          </Row>
          {iAmGranted(perms.edit_can_pay_with_check) && <Row className="mb-4">
            <Label className="col-sm-5 col-form-label">Enable Check Payments</Label>
            <Col sm={7}>
              {!!defaultValues.dealerGroupId
                ? (
                  <Input disabled value={defaultValues.dealerGroupCanPayWithCheck ? "Enabled" : "Disabled"} />
                )
                : (
                  <Select
                    classNamePrefix="select2-selection"
                    name="canPayWithCheck"
                    options={canPayWithCheckOptionsForStores}
                    onChange={selected => formik.setFieldValue("canPayWithCheck", selected.value)}
                    value={canPayWithCheckOptionsForStores.find(option => option.value === formik.values.canPayWithCheck)}
                    className={!!formik.errors.canPayWithCheck && "is-invalid"}
                  />
                )}
              {!!formik.errors.canPayWithCheck && <FormFeedback type="invalid">{formik.errors.canPayWithCheck}</FormFeedback>}
            </Col>
          </Row>}
          {iAmGranted(perms.edit_coupa_information) && defaultValues.usesCoupa && <>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Coupa Name (Accounting name)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaName"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaName}
                  invalid={!!formik.errors.coupaName}
                />
                {!!formik.errors.coupaName && <FormFeedback type="invalid">{formik.errors.coupaName}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Coupa Account (Default account)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaAccount"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaAccount}
                  invalid={!!formik.errors.coupaAccount}
                />
                {!!formik.errors.coupaAccount && <FormFeedback type="invalid">{formik.errors.coupaAccount}</FormFeedback>}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-5 col-form-label">Hyperion number (Store ID)</Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  name="coupaStoreId"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.coupaStoreId}
                  invalid={!!formik.errors.coupaStoreId}
                />
                {!!formik.errors.coupaStoreId && <FormFeedback type="invalid">{formik.errors.coupaStoreId}</FormFeedback>}
              </Col>
            </Row></>}
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormBillingEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
  qbDealers: PropTypes.array,
  qbDealersError: PropTypes.string,
};

export default FormBillingEdit;