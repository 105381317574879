import React from "react";
import PropTypes from "prop-types";
import { Row, Card, CardBody } from "reactstrap";
import Col from "components/Shared/Col";

const MessagesCard = ({ description, status, id }) => {

  const renderError = () => {
    return <Card id={id} className="text-start d-inline-block bg-danger text-white">
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center">
            <div className="card-title mb-0">{description}</div>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <i className="mdi mdi-alert-circle-outline seal-err-icon"></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  }

  const renderWarning = () => {
    return <Card id={id} className="text-start d-inline-block bg-warning text-white">
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center">
            <div className="card-title mb-0">{description}</div>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <i className="mdi mdi-alert-circle-outline seal-err-icon"></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  }

  const renderSuccess = () => {
    return <Card id={id} className="text-start d-inline-block bg-success text-white">
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center">
            <div className="card-title mb-0">{description}</div>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <i className="mdi mdi-check-circle-outline font-size-24"></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  }

  const renderProgress = () => {
    return <Card id={id} className="text-start d-inline-block">
      <CardBody>
        <Row>
          <Col className="d-flex align-items-center">{description}</Col>
          <Col xs="auto" className="d-flex align-items-center">
            <i className="fas fa-spinner fa-spin seal-spinner-icon"></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  }

  const renderCardMessage = (messageStatus) => {
    switch (messageStatus) {
      case 'error':
        return renderError();
      case 'warning':
        return renderWarning();
      case 'progress':
        return renderProgress();
      case 'success':
        return renderSuccess();
    }
  }

  return renderCardMessage(status);

}

MessagesCard.propTypes = {
  description: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.string
}

export default MessagesCard;