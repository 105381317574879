import React from "react";
import PropTypes from "prop-types";
import { formatPhone } from "helpers/utilHelper";
import emailIcon from "../../../../assets/images/order/email-icon.png";
import phoneIcon from "../../../../assets/images/order/phone-icon.svg";
import OrderNotary from "../../../../model/orderNotary";

const InformationHeader = ({ order, orderKey }) => {
	const phone = order.signers[0].phone;

	return <React.Fragment>
		<div className="mav-header-container">
			<div className="header-row">
				<div className="header-subtitle fw-bold">Customer:</div>
				<div>
					<span
						className={`badge badge-sm rounded-pill px-2 py-1 ${OrderNotary.getNotaryInfoStatusColor(order.notaries[orderKey].status)}`}>
						{OrderNotary.getStatusName(order.notaries[orderKey].status)}
					</span>
				</div>
			</div>

			<div className="header-title"><strong>{order.signers[0].fullName}</strong></div>
			<div className="header-row align-items-start">
				<div className="header-section">
					<img className='header-section-icon' alt="icon" src={phoneIcon} />
					<div className="phone-number mb-1">{formatPhone(phone)}</div>
				</div>

				<div className="header-section justify-content-end">
					<img className='header-section-icon' alt="icon" src={emailIcon} />
					<div className="email mb-1">{order.signers[0].email}</div>
				</div>
			</div>
		</div>

		{/*	Generate Additional Signer Header Section */}
		{!!order.signers[1] && <div className="mav-header-container signer">
			<div className="header-row">
				<div className="header-subtitle">Additional signer:</div>
			</div>

			<div className="header-title"><strong>{order.signers[1].fullName}</strong></div>
			<div className="header-row align-items-start">
				<div className="header-section">
					<img className='header-section-icon' alt="icon" src={phoneIcon} />
					<div className="phone-number mb-1">{formatPhone(phone)}</div>
				</div>
				<div className="header-section justify-content-end">
					<img className='header-section-icon' alt="icon" src={emailIcon} />
					<div className="email mb-1">{order.signers[1].email}</div>
				</div>
			</div>
		</div>}
	</React.Fragment>
};

InformationHeader.propTypes = {
	order: PropTypes.object.isRequired,
	orderKey: PropTypes.number
};

export default InformationHeader;