import {
  DO_ORDER_DOC_NORM_LIST_CLEANUP,
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_OK,
  GET_ORDER_DOC_NORM_LIST_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  UPLOAD_ORDER_DOC,
  UPLOAD_ORDER_DOC_OK,
  UPLOAD_ORDER_DOC_ERR,
  ADD_ORDER_DOC,
  ADD_ORDER_DOC_OK,
  ADD_ORDER_DOC_ERR,
  DO_ORDER_DOC_FORM_CLEANUP,

  ACCEPT_ALL_INK_SIGN_DOCS,
  ACCEPT_ALL_INK_SIGN_DOCS_OK,
  ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
} from "./actionTypes";

/********** NORMALIZED LIST **********/

export const getESignOrderDocNormList = orderId => ({
  type: GET_E_SIGN_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getInkSignOrderDocNormList = orderId => ({
  type: GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  payload: { orderId },
});

export const getFinanceOrderDocNormList = orderId => ({
  type: GET_FINANCE_ORDER_DOC_NORM_LIST,
  payload: { orderId },
})

export const getSupportingOrderDocNormList = orderId => ({
  type: GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  payload: { orderId },
})

export const getOrderDocNormListOk = response => ({
  type: GET_ORDER_DOC_NORM_LIST_OK,
  payload: { response },
});

export const getOrderDocNormListErr = error => ({
  type: GET_ORDER_DOC_NORM_LIST_ERR,
  payload: { error },
});

export const getOrderDocNormListItem = id => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const getOrderDocNormListItemOk = (id, response) => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const getOrderDocNormListItemErr = (id, error) => ({
  type: GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const deleteOrderDocNormListItem = id => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const deleteOrderDocNormListItemOk = (id, response) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const deleteOrderDocNormListItemErr = (id, error) => ({
  type: DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const reprocessOrderDocNormListItem = id => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  payload: { id },
});

export const reprocessOrderDocNormListItemOk = (id, response) => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const reprocessOrderDocNormListItemErr = (id, error) => ({
  type: REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const doOrderDocNormListCleanup = () => ({
  type: DO_ORDER_DOC_NORM_LIST_CLEANUP,
});

/********** FORM **********/

export const uploadOrderDoc = (data, orderId) => ({
  type: UPLOAD_ORDER_DOC,
  payload: { data, orderId },
});

export const uploadOrderDocOk = response => ({
  type: UPLOAD_ORDER_DOC_OK,
  payload: { response },
});

export const uploadOrderDocErr = error => ({
  type: UPLOAD_ORDER_DOC_ERR,
  payload: { error },
});

export const addOrderDoc = (data, orderId) => ({
  type: ADD_ORDER_DOC,
  payload: { data, orderId },
});

export const addOrderDocOk = response => ({
  type: ADD_ORDER_DOC_OK,
  payload: { response },
});

export const addOrderDocErr = error => ({
  type: ADD_ORDER_DOC_ERR,
  payload: { error },
});

export const doOrderDocFormCleanup = () => ({
  type: DO_ORDER_DOC_FORM_CLEANUP,
});

/************* ACCEPT ALL *************/

export const acceptAllInkSignDocuments = (orderId) => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS,
  payload: { orderId },
});

export const acceptAllInkSignDocumentsOk = response => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS_OK,
  payload: { response },
});

export const acceptAllInkSignDocumentsErr = error => ({
  type: ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  payload: { error },
});

export const doAcceptAllInkSignDocumentsCleanup = () => ({
  type: DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
});