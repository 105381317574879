import React from "react";
import PropTypes from "prop-types";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { perms, useAccess } from "context/access";

const DataTableTabs = ({ activeTab, toggleActiveTab }) => {

  const { iAmNotGranted } = useAccess();

  return <Nav tabs className="nav-tabs-custom mb-4 d-inline-flex">
    <NavItem>
      <NavLink
        style={{ cursor: "pointer" }}
        className={classnames({ active: activeTab === "my", })}
        onClick={() => { toggleActiveTab("my") }}>
        <span className="d-none d-sm-block">My cases</span>
      </NavLink>
    </NavItem>
    {iAmNotGranted(perms.view_own_scheduled_support_cases) && <NavItem>
      <NavLink
        style={{ cursor: "pointer" }}
        className={classnames({ active: activeTab === "all", })}
        onClick={() => { toggleActiveTab("all") }}>
        <span className="d-none d-sm-block">All cases</span>
      </NavLink>
    </NavItem>}
  </Nav >
}

DataTableTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleActiveTab: PropTypes.func.isRequired,
}

export default DataTableTabs;