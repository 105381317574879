import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import { ValidationException } from "helpers/errorHelper";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import Confirmation from "components/Shared/Confirmation";
import { updateAdditionalFee } from "helpers/backendHelper";

const FormEdit = props => {

  const { defaultValues, id, finishedHandler,
    qbInvoiceItems, qbInvoiceItemsError, qbBillItems, qbBillItemsError } = props;

  /********** STATE **********/

  // is the confirmation dialog visible or not
  // used to show/hide the save warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  // holds form values after validation and until the user accepts the confirmation
  const [tempFormValues, setTempFormValues] = useState();

  /********** FORM CONFIG **********/

  const formInitialValues = {
    name: '',
    price: '',
    qbInvoiceItemId: '',
    qbBillItemId: '',
    isPercentage: false,
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Field is required'),
      price: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      qbInvoiceItemId: Yup.number()
        .test('anyQbIdRequired', 'Either an invoice or a bill item is required', (value, context) => {
          return !!context.parent.qbInvoiceItemId || !!context.parent.qbBillItemId;
        }),
      qbBillItemId: Yup.number(),
    }),
    onSubmit: values => {
      setTempFormValues(values);
      setIsConfirmationVisible(true);
    },
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  // runs whenever 'qbInvoiceItemsError' changes
  useEffect(() => {
    if (qbInvoiceItemsError) {
      // set an error on the form field
      formik.setFieldError('qbInvoiceItemId', 'Unable to load invoice items');
    }
  }, [qbInvoiceItemsError]);

  // runs whenever 'qbBillItemsError' changes
  useEffect(() => {
    if (qbBillItemsError) {
      // set an error on the form field
      formik.setFieldError('qbBillItemId', 'Unable to load bill items');
    }
  }, [qbBillItemsError]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  const saveAdditionalFee = () => {
    setIsSaveInProgress(true);
    updateAdditionalFee(tempFormValues, id)
      .then(response => {
        showSuccess(`Additional Fee "${tempFormValues.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save additional fee');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const getInvoiceItemsOptions = () => [{ Name: 'Choose', Id: '' }, ...qbInvoiceItems];

  const getBillItemsOptions = () => [{ Name: 'Choose', Id: '' }, ...qbBillItems];

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save fee
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Trip Fee" name="name" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.name} invalid={!!formik.errors.name} />
                  {!formik.errors.name && <small className="form-text text-muted">This is what the user will see when selecting an additional fee</small>}
                  {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Price ({formik.values.isPercentage ? '%' : '$'}) *</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 20.50" name="price" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.price} invalid={!!formik.errors.price} />
                  {!formik.errors.price && <small className="form-text text-muted">A value of 0 equals no charge</small>}
                  {!!formik.errors.price && <FormFeedback type="invalid">{formik.errors.price}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Is Percentage</Label>
                <Col sm={9}>
                  <div className="form-check form-switch form-switch-lg mb-3">
                    <Input type="checkbox" className="form-check-input" id="percentageSwitch" name="isPercentage" onChange={formik.handleChange} defaultChecked={formik.values.isPercentage} />
                    <Label className="form-check-label" htmlFor="percentageSwitch" />
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">QuickBooks Invoice Item</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="qbInvoiceItemId"
                    options={getInvoiceItemsOptions()}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.Id}
                    onChange={selected => formik.setFieldValue("qbInvoiceItemId", +selected.Id)}
                    onFocus={e => onFieldFocused(e, "qbInvoiceItemId")}
                    value={getInvoiceItemsOptions().find(option => option.Id == formik.values.qbInvoiceItemId)}
                    className={!!formik.errors.qbInvoiceItemId && "is-invalid"} />
                  {!!formik.errors.qbInvoiceItemId && <FormFeedback type="invalid">{formik.errors.qbInvoiceItemId}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">QuickBooks Bill Item</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="qbBillItemId"
                    options={getBillItemsOptions()}
                    getOptionLabel={option => option.Name}
                    getOptionValue={option => option.Id}
                    onChange={selected => formik.setFieldValue("qbBillItemId", +selected.Id)}
                    onFocus={e => onFieldFocused(e, "qbBillItemId")}
                    value={getBillItemsOptions().find(option => option.Id == formik.values.qbBillItemId)}
                    className={!!formik.errors.qbBillItemId && "is-invalid"} />
                  {!!formik.errors.qbBillItemId && <FormFeedback type="invalid">{formik.errors.qbBillItemId}</FormFeedback>}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Save"
      reverseButtons={false}
      onConfirm={() => {
        setIsConfirmationVisible(false)
        saveAdditionalFee();
        setTempFormValues(null);
      }}
      onCancel={() => {
        setIsConfirmationVisible(false);
        setTempFormValues(null);
        formik.setSubmitting(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to save additional fee &quot;{formik.values.name}&quot;? Changes will be pushed to Stripe!</span>
    </Confirmation>}
  </React.Fragment>
}

FormEdit.propTypes = {
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
  id: PropTypes.number,
  qbInvoiceItems: PropTypes.array,
  qbInvoiceItemsError: PropTypes.string,
  qbBillItems: PropTypes.array,
  qbBillItemsError: PropTypes.string,
};

export default FormEdit;