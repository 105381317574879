/********** DATATABLE **********/

export const GET_PAYMENT_PLAN_DT = 'GET_PAYMENT_PLAN_DT'
export const GET_PAYMENT_PLAN_DT_OK = 'GET_PAYMENT_PLAN_DT_OK'
export const GET_PAYMENT_PLAN_DT_ERR = 'GET_PAYMENT_PLAN_DT_ERR'
export const DO_PAYMENT_PLAN_DT_CLEANUP = 'DO_PAYMENT_PLAN_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_PAYMENT_PLAN_DT_FILTERS = 'APPLY_PAYMENT_PLAN_DT_FILTERS'
export const CLEAR_PAYMENT_PLAN_DT_FILTERS = 'CLEAR_PAYMENT_PLAN_DT_FILTERS'

/********* LIST *********/

export const GET_PAYMENT_PLAN_LIST = 'GET_PAYMENT_PLAN_LIST'
export const GET_PAYMENT_PLAN_LIST_OK = 'GET_PAYMENT_PLAN_LIST_OK'
export const GET_PAYMENT_PLAN_LIST_ERR = 'GET_PAYMENT_PLAN_LIST_ERR'
export const DO_PAYMENT_PLAN_LIST_CLEANUP = 'DO_PAYMENT_PLAN_LIST_CLEANUP'