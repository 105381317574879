import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";

const AccountingNotes = ({ statement }) => {

  return <Row>
    {!!statement && !!statement.accountingNotes && <Col lg="12">
      <Card>
        <CardBody>
          <div className="card-notes border-0 p-0">
            <h1 className="title">Accounting notes</h1>
            <div className="alert-msg">{statement.accountingNotes}</div>
          </div>
        </CardBody>
      </Card>
    </Col>}
  </Row>
}

AccountingNotes.propTypes = {
  statement: PropTypes.object,
};

export default AccountingNotes;