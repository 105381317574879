import React, { useRef } from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { Button } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { localDateToTimezoneTs, timezoneTsToLocalDate } from "helpers/dateHelper";

const DateRangePicker = ({ onChange, value, onClear }) => {
  // define a ref for the Flatpickr, so that we can programmatically open it on click on the button
  const fp = useRef(null);

  const handleChange = selected => {
    let newStart;
    let newEnd;

    // if start was selected, convert it to local time zone
    if (selected[0]) {
      newStart = localDateToTimezoneTs(selected[0]);
    }

    // if end was selected, convert it to local time zone
    if (selected[1]) {
      // set time to end of day
      selected[1].setHours(23, 59, 59, 999);
      newEnd = localDateToTimezoneTs(selected[1]);
    }

    // we need to convert the dates before sending them to the component
    // because Flatpickr is using the Date object internally and that always uses the local time of the computer
    onChange([newStart, newEnd].filter(date => !!date));
  };

  return (
    <div>
      <Button
        color="primary"
        className="py-1 mb-1"
        onClick={() => {
          if (!fp?.current?.flatpickr) return;
          fp.current.flatpickr.open();
        }}
      >
        <div className="d-flex align-items-center">
          <i className="mdi mdi-calendar me-2 font-size-16" />
          {!!value.length
            ? (
              <>
                {value.map(ts => timezoneTsToLocalDate(ts).toLocaleDateString("en-US", { dateStyle: "medium" })).join(" - ")}
                {value.length === 1 && " - "}
                <span onClick={onClear}>
                  <i className="mdi mdi-close ms-2 font-size-16" />
                </span>
              </>
            )
            : (
              <i className="fas fa-angle-down font-size-16" />
            )
          }
        </div>
      </Button>
      <Flatpickr
        ref={fp}
        className="form-control d-block invisible p-0"
        options={{
          mode: "range",
          dateFormat: "F j, Y",
        }}
        style={{ height: 0 }}
        value={value.map(ts => timezoneTsToLocalDate(ts))}
        onChange={handleChange}
      />
    </div>
  )
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  onClear: PropTypes.func.isRequired,
};

export default DateRangePicker;