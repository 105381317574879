import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, CardTitle, Card, CardBody, FormFeedback } from "reactstrap";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import * as Yup from "yup";
import { useAuth } from "context/auth";
import { updateNotaryAdditionalInfo } from "helpers/backendHelper";
import Select from "react-select";

const FormEditBilling = props => {

  const { id, finishedHandler, notary, qbNotaries, qbNotariesError } = props;

  // get user info
  const { user } = useAuth();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    qbVendorId: "",
    ...nullsToEmptyStrings(notary),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => saveNotaryAdditionalInfo(values, id),
  });


  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError("Form has errors");
    }
  }, [formik.isValid]);

  // runs whenever 'qbServicesError' changes
  useEffect(() => {
    if (qbNotariesError) {
      // set an error on the form field
      formik.setFieldError('qbVendorId', 'Unable to load notaries');
    }
  }, [qbNotariesError]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveNotaryAdditionalInfo = (values, id) => {
    setIsSaveInProgress(true);
    updateNotaryAdditionalInfo(values, id)
      .then(response => {
        showSuccess(`Notary "${values.userFullName}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError("Unable to save notary");
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <Card className="expand-v">
    <CardBody>
      <div className="pb-4">
        <Row>
          <Col>
            <CardTitle>Billing/Payment Information</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <Form>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Billing/Payment Address</Label>
              <Col sm={8} className="pt-2">
                <Input className="form-control" value={notary.billingAddress || "---"} disabled />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">5 digit Zip Code</Label>
              <Col sm={8} className="pt-2">
                <Input className="form-control" value={notary.billingZip || "---"} disabled />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">City</Label>
              <Col sm={8} className="pt-2">
                <Input className="form-control" value={notary.billingCity || "---"} disabled />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">State</Label>
              <Col sm={8} className="pt-2">
                <Input className="form-control" value={notary.billingState || "---"} disabled />
              </Col>
            </Row>
            <Row className="mb-4 align-items-center">
              <Label className="col-sm-4 col-form-label">QuickBooks Vendor</Label>
              <Col sm={8} className="pt-2">
                <Select
                  classNamePrefix="select2-selection"
                  name="qbVendorId"
                  options={qbNotaries}
                  getOptionLabel={option => option.DisplayName}
                  getOptionValue={option => option.Id}
                  onChange={selected => formik.setFieldValue("qbVendorId", +selected.Id)}
                  onFocus={e => onFieldFocused(e, "qbVendorId")}
                  value={qbNotaries.find(option => option.Id == formik.values.qbVendorId)}
                  className={!!formik.errors.qbVendorId && "is-invalid"} />
                {!!formik.errors.qbVendorId && <FormFeedback type="invalid">{formik.errors.qbVendorId}</FormFeedback>}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

FormEditBilling.propTypes = {
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
  notary: PropTypes.object,
  qbNotaries: PropTypes.array,
  qbNotariesError: PropTypes.string,
};

export default FormEditBilling;