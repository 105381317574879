import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionInfo from "./Partial/Section/Info";
import SectionRegions from "./Partial/Section/Regions";
import SectionManagers from "./Partial/Section/Managers";
import SectionSpecialInstructions from "./Partial/Section/SpecialInstructions";
import { getDealerGroup } from "helpers/backendHelper";
import SectionStores from "./Partial/Section/Stores";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [dealerGroup, setDealerGroup] = useState(null);
  const [dealerGroupError, setDealerGroupError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the group data
    refreshDealerGroup();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerGroup = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerGroup(id)
      .then(response => {
        setDealerGroup(response.dealerGroup);
      })
      .catch(ex => {
        setDealerGroupError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_dealer_groups) && <div className="page-content">
      {dealerGroup && <React.Fragment>
        <MetaTitle>{dealerGroup.name} | Dealer groups</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerGroup)} />
          <Row>
            <Col xs="12" xxxl="10" xxxxl="8">
              <SectionInfo id={id} dealerGroup={dealerGroup} refreshHandler={refreshDealerGroup} isLoadBusy={isLoadInProgress} />
            </Col>
            <Col xxl="4">
              <SectionRegions id={parseInt(id)} />
            </Col>
            <Col xxl="8" xxxl="6" xxxxl="8">
              <SectionManagers id={parseInt(id)} />
              <SectionStores id={parseInt(id)} />
            </Col>
            <Col xs="12" xxxl="10" xxxxl="4">
              <SectionSpecialInstructions id={id} dealerGroup={dealerGroup} refreshHandler={refreshDealerGroup} isLoadBusy={isLoadInProgress} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !dealerGroup && <Preloader className="inner" />}
      {dealerGroupError && <Error error={dealerGroupError} title404="Dealer group not found" />}
    </div>}
    {iAmNotGranted(perms.view_dealer_groups) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = dealerGroup => [{
  title: 'DEALER GROUPS',
  url: route(routes.list_dealer_groups),
}, {
  title: dealerGroup.name,
}];

export default View;
