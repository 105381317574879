import React from 'react';
import PropTypes from 'prop-types';
import SpinnerChase from './SpinnerChase';

const Preloader = props => {

  return <div id="preloader" className={props.className}>
    <div id="status">
      <SpinnerChase />
    </div>
  </div>;
}

Preloader.propTypes = {
  className: PropTypes.string,
}

export default Preloader;