import {
  getESignOrderDocs,
  getFinanceOrderDocs,
  getSupportingOrderDocs,
  getInkSignOrderDocs,
  getOrderDoc,
  deleteOrderDoc,
  reprocessOrderDoc,
  uploadOrderDoc,
  addOrderDoc,
  acceptAllInkSignDocs,
} from "helpers/backendHelper";
import {
  getOrderDocNormListOk,
  getOrderDocNormListErr,
  getOrderDocNormListItemErr,
  getOrderDocNormListItemOk,
  deleteOrderDocNormListItemOk,
  deleteOrderDocNormListItemErr,
  reprocessOrderDocNormListItemOk,
  reprocessOrderDocNormListItemErr,
  uploadOrderDocErr,
  uploadOrderDocOk,
  addOrderDocOk,
  addOrderDocErr,
  acceptAllInkSignDocumentsOk,
  acceptAllInkSignDocumentsErr,
} from "./actions";
import {
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  UPLOAD_ORDER_DOC,
  ADD_ORDER_DOC,
  ACCEPT_ALL_INK_SIGN_DOCS,
  DO_ORDER_DOC_NORM_LIST_CLEANUP,
} from "./actionTypes";
import { takeEvery, put, call, take, race } from 'redux-saga/effects';
import EventEmitter from 'helpers/eventsHelper';

/********** NORMALIZED LIST **********/

function* onGetESignOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getESignOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetInkSignOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getInkSignOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(getOrderDoc, id);
    yield put(getOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(getOrderDocNormListItemErr(id, error));
  }
}

function* onDeleteOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(deleteOrderDoc, id);
    yield put(deleteOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(deleteOrderDocNormListItemErr(id, error));
  }
}

function* onReprocessOrderDocNormListItem({ payload: { id } }) {
  try {
    const response = yield call(reprocessOrderDoc, id);
    yield put(reprocessOrderDocNormListItemOk(id, response));
  } catch (error) {
    yield put(reprocessOrderDocNormListItemErr(id, error));
  }
}

function* onGetFinanceOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getFinanceOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

function* onGetSupportingOrderDocNormList({ payload: { orderId } }) {
  try {
    const response = yield call(getSupportingOrderDocs, orderId);
    yield put(getOrderDocNormListOk(response));
  } catch (error) {
    yield put(getOrderDocNormListErr(error));
  }
}

/********** FORM **********/

function* onUploadOrderDoc({ payload: { data, orderId } }) {
  try {
    const response = yield call(uploadOrderDoc, data, orderId, {
      onUploadProgress: ev => EventEmitter.emit('orderDoc.uploadProgress', ev)
    });
    yield put(uploadOrderDocOk(response));
  } catch (error) {
    yield put(uploadOrderDocErr(error));
  }
}

function* onAddOrderDoc({ payload: { data, orderId } }) {
  try {
    const response = yield call(addOrderDoc, data, orderId);
    yield put(addOrderDocOk(response));
  } catch (error) {
    yield put(addOrderDocErr(error));
  }
}

/********** ACCEPT ALL **********/

function* onAcceptAllInkSignDocs({ payload: { orderId } }) {
  try {
    const response = yield call(acceptAllInkSignDocs, orderId);
    yield put(acceptAllInkSignDocumentsOk(response));
  } catch (error) {
    yield put(acceptAllInkSignDocumentsErr(error));
  }
}

// wrapper overview saga that cancels task when a conflicting action is dispatched
function cancelOnCleanup(cleanupActionType, task) {
  return function* startOverviewSaga(params) {
    yield race({
      task: call(task, params), // call task to mutate state
      cancel: take(cleanupActionType), // cancel task when cleanup action is dispatched
    });
  }
}

function* orderDocSaga() {
  yield takeEvery(GET_E_SIGN_ORDER_DOC_NORM_LIST, onGetESignOrderDocNormList);
  yield takeEvery(GET_INK_SIGN_ORDER_DOC_NORM_LIST, onGetInkSignOrderDocNormList);
  yield takeEvery(GET_ORDER_DOC_NORM_LIST_ITEM, onGetOrderDocNormListItem);
  yield takeEvery(DELETE_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onDeleteOrderDocNormListItem));
  yield takeEvery(REPROCESS_ORDER_DOC_NORM_LIST_ITEM, cancelOnCleanup(DO_ORDER_DOC_NORM_LIST_CLEANUP, onReprocessOrderDocNormListItem));
  yield takeEvery(GET_FINANCE_ORDER_DOC_NORM_LIST, onGetFinanceOrderDocNormList);
  yield takeEvery(GET_SUPPORTING_ORDER_DOC_NORM_LIST, onGetSupportingOrderDocNormList);
  yield takeEvery(UPLOAD_ORDER_DOC, onUploadOrderDoc);
  yield takeEvery(ADD_ORDER_DOC, onAddOrderDoc);
  yield takeEvery(ACCEPT_ALL_INK_SIGN_DOCS, onAcceptAllInkSignDocs);
}

export default orderDocSaga;