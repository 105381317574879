/********** DATATABLE **********/

export const GET_NOTIFICATION_DT = 'GET_NOTIFICATION_DT'
export const GET_NOTIFICATION_DT_OK = 'GET_NOTIFICATION_DT_OK'
export const GET_NOTIFICATION_DT_ERR = 'GET_NOTIFICATION_DT_ERR'
export const DO_NOTIFICATION_DT_CLEANUP = 'DO_NOTIFICATION_DT_CLEANUP'
export const CHANGE_NOTIFICATIONS_PAGE = 'CHANGE_NOTIFICATIONS_PAGE'
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ'
export const MARK_NOTIFICATIONS_READ_OK = 'MARK_NOTIFICATIONS_READ_OK'
export const MARK_NOTIFICATIONS_UNREAD = 'MARK_NOTIFICATIONS_UNREAD'
export const MARK_NOTIFICATIONS_UNREAD_OK = 'MARK_NOTIFICATIONS_UNREAD_OK'
export const MARK_NOTIFICATIONS_ERR = 'MARK_NOTIFICATIONS_ERR'
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'
export const DELETE_NOTIFICATIONS_OK = 'DELETE_NOTIFICATIONS_OK'
export const DELETE_NOTIFICATIONS_ERR = 'DELETE_NOTIFICATIONS_ERR'

/********** LIST **********/

export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST'
export const GET_NOTIFICATION_LIST_OK = 'GET_NOTIFICATION_LIST_OK'
export const GET_NOTIFICATION_LIST_ERR = 'GET_NOTIFICATION_LIST_ERR'
export const DO_NOTIFICATION_LIST_CLEANUP = 'DO_NOTIFICATION_LIST_CLEANUP'

/********** SINGLE **********/

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATION_OK = 'GET_NOTIFICATION_OK'
export const GET_NOTIFICATION_ERR = 'GET_NOTIFICATION_ERR'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_OK = 'DELETE_NOTIFICATION_OK'
export const DELETE_NOTIFICATION_ERR = 'DELETE_NOTIFICATION_ERR'
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ'
export const MARK_NOTIFICATION_READ_OK = 'MARK_NOTIFICATION_READ_OK'
export const MARK_NOTIFICATION_UNREAD = 'MARK_NOTIFICATION_UNREAD'
export const MARK_NOTIFICATION_UNREAD_OK = 'MARK_NOTIFICATION_UNREAD_OK'
export const MARK_NOTIFICATION_ERR = 'MARK_NOTIFICATION_ERR'
export const DO_NOTIFICATION_SINGLE_CLEANUP = 'DO_NOTIFICATION_SINGLE_CLEANUP'