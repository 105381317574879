import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, FormFeedback, CardTitle, Label } from "reactstrap";
import { nullsToEmptyStrings, showBriefError, showError, showSuccess, dictionaryToSelectOptions } from "helpers/utilHelper";
import DealerGroup from "model/dealerGroup";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import specialInstructionsIcon from "../../../../../assets/images/special-instructions-icon.svg";
import { updateDealerSpecialInstructions } from "../../../../../helpers/backendHelper";
import { ValidationException } from "../../../../../helpers/errorHelper";

const FormSpecialInstructionsEdit = ({ id, defaultValues, finishedHandler }) => {

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** OTHER **********/

  const getSpecialInstructionsTargetsOptions = () => dictionaryToSelectOptions(DealerGroup.getSpecialInstructionsTargets());

  /********** FORM CONFIG **********/

  const formInitialValues = {
    specialInstructions: '',
    specialInstructionsAppliesTo: [],
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      specialInstructions: Yup.string().trim(),
    }),
    onSubmit: values => saveSpecialInstructions(values, id)
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  const saveSpecialInstructions = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerSpecialInstructions(values, id)
      .then(response => {
        showSuccess('Dealer special instructions has been saved');
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save dealer special instructions');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <CardTitle><img className='me-2' src={specialInstructionsIcon} />Group Special Instructions</CardTitle>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="my-2">
            <Label>Applies To:</Label>
            <Select
              isMulti
              classNamePrefix="select2-selection"
              name="specialInstructionsAppliesTo"
              options={getSpecialInstructionsTargetsOptions()}
              onChange={selected => formik.setFieldValue('specialInstructionsAppliesTo', selected?.map(item => item.value))}
              onFocus={e => onFieldFocused(e, 'specialInstructionsAppliesTo')}
              value={getSpecialInstructionsTargetsOptions().filter(option => formik.values.specialInstructionsAppliesTo?.includes(option.value))}
              className={!!formik.errors.specialInstructionsAppliesTo && 'is-invalid'} />
              {!!formik.errors.specialInstructionsAppliesTo && <FormFeedback type="invalid">{formik.errors.specialInstructionsAppliesTo}</FormFeedback>}
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save Special Instructions
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <div className='instruction-description mb-4'>Write your special instructions here. Provide any specific guidance, notes, or important information related to your group.  Please be concise and informative to ensure clear communication.
                Your instructions will help streamline processes and improve coordination within your group.
              </div>
              <TextareaAutosize minRows={7} maxRows={10} className='form-control' name="specialInstructions" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.specialInstructions} />
              {!!formik.errors.specialInstructions && <FormFeedback type="invalid">{formik.errors.specialInstructions}</FormFeedback>}
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormSpecialInstructionsEdit.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

export default FormSpecialInstructionsEdit;