import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Map, Marker } from "google-maps-react";
import { useGoogleApi } from "context/google";
import { useParams } from "react-router-dom";
import notaryMapIcon from 'assets/images/notary-map-icon.svg';
import customerMapIcon from 'assets/images/user-map-icon.svg';

// component that shows multiple pins on the map
const GoogleMiniMap = props => {

  let { id } = useParams();
  id = parseInt(id);

  let { locations, center } = props;

  const mapRef = useRef();

  const { google } = useGoogleApi();

  let smallScreenBounds = new google.maps.LatLngBounds();
  let fullScreenBounds = new google.maps.LatLngBounds();
  let maxWindowSize = false;

  // we need to extend the bounds in order to "zoom out" and cover all markers
  // this effect is obtain by calling the `fitBounds` method when map is ready
  for (var i = 0; i < locations.length; i++) {
    fullScreenBounds.extend({ lat: locations[i].latitude, lng: locations[i].longitude });
  }

  fullScreenBounds.extend({ lat: center.latitude, lng: center.longitude });
  smallScreenBounds.extend({ lat: center.latitude, lng: center.longitude });

  // when map is ready, zoom out to center bounds
  const onMapReady = (_mapProps, map) => {
    fitBounds(map, smallScreenBounds);
  };

  const fitBounds = (map, bounds) => {
    // don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
      var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    map.fitBounds(bounds);
  }

  const onBoundsChanged = (_mapProps, map) => {
    const $mapContainer = $(mapRef.current.mapRef.current).children().children('.gm-style');
    if ($mapContainer.height() == window.innerHeight && !maxWindowSize) {
      fitBounds(map, fullScreenBounds);
      const oldZoom = mapRef.current.map.getZoom();
      // when toggling full screen, the map actual size does not change
      // fit bounds will not take into accound the visible size
      // so we have to increase zoom manually
      mapRef.current.map.setZoom(oldZoom + 3)
      maxWindowSize = true;
    }
    if ($mapContainer.height() < window.innerHeight && maxWindowSize) {
      fitBounds(map, smallScreenBounds);
      maxWindowSize = false;
    }
  }

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false
  }

  const style = {
    borderRadius: "10px"
  }

  return (
    <div
      id="gmaps-markers"
      className='gmap mini-map'
      style={{ position: "relative" }}
    >
      <Map
        ref={mapRef}
        google={google}
        style={{ width: "100%", height: "100%", ...style }}
        onReady={onMapReady}
        onBoundsChanged={onBoundsChanged}
        {...options}

      >
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={{ lat: location.latitude, lng: location.longitude }}
            icon={{
              url: notaryMapIcon, //custom icon for notary
              scaledSize: new google.maps.Size(45, 55), // icon size
            }}
          />))}
        <Marker
          key={'customer'}
          icon={{ url: customerMapIcon, scaledSize: new google.maps.Size(45, 55) }}
          position={{ lat: center.latitude, lng: center.longitude }}
        />
      </Map>
    </div >
  )
}

GoogleMiniMap.propTypes = {
  center: PropTypes.object,
  locations: PropTypes.array,
}

export default GoogleMiniMap;