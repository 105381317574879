import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Col from "components/Shared/Col";
import { formatPhone, getGranularStatusName, showError, showSuccess } from "helpers/utilHelper";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import { route, routes } from 'helpers/routeHelper';
import { useNavigate, useParams } from 'react-router-dom';
import Confirmation from 'components/Shared/Confirmation';
import { ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD, ServerErrorException } from 'helpers/errorHelper';
import { refreshOrderFees, refreshOrderStatus, reloadOrderDealerSigners } from "helpers/backendHelper";
import { perms, useAccess } from 'context/access';
import SpinnerChase from 'components/Shared/SpinnerChase';

const SectionOrderInfo = ({ order, refreshHandler }) => {

  const { id } = useParams();
  const { iAmGranted } = useAccess();

  // router hook that helps redirect
  const navigate = useNavigate();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isReloadInProgress, setIsReloadInProgress] = useState(false);
  // used to show/hide the reload signers confirmation
  const [isReloadConfirmationVisible, setIsReloadConfirmationVisible] = useState(false);
  const [isStatusRefreshInProgress, setIsStatusRefreshInProgress] = useState(false);
  const [isFeesRefreshInProgress, setIsFeesRefreshInProgress] = useState(false);

  // reload-signers event handler
  const reloadSigners = () => {
    setIsReloadInProgress(true);
    // make the initial remote call to get the user data
    reloadOrderDealerSigners(id)
      .then(response => {
        showSuccess("Signers have been reloaded");
        navigate(route(routes.view_order, order.id));
      })
      .catch(ex => {
        if (ex instanceof ServerErrorException) {
          if (ex.code == ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD) {
            showError('Signers can no longer be reloaded at this stage');
            return;
          }
        }
        showError("Unable to reload signers");
      })
      .finally(() => {
        setIsReloadInProgress(false);
      });
  };

  const doRefreshOrderStatus = () => {
    setIsStatusRefreshInProgress(true);
    refreshOrderStatus(id)
      .then(response => {
        showSuccess("Status has been refreshed");
        refreshHandler();
      })
      .catch(ex => {
        showError("Unable to refresh order status");
      })
      .finally(() => {
        setIsStatusRefreshInProgress(false);
      });
  };

  const doRefreshOrderFees = () => {
    setIsFeesRefreshInProgress(true);
    refreshOrderFees(id)
      .then(response => {
        showSuccess("Fees have been refreshed");
        refreshHandler();
      })
      .catch(ex => {
        showError("Unable to refresh order fees");
      })
      .finally(() => {
        setIsFeesRefreshInProgress(false);
      });
  };

  return <>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Order information</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => setIsReloadConfirmationVisible(true)} disabled={isReloadInProgress}>Reload Signers</DropdownItem>
                {iAmGranted(perms.refresh_order_statuses) && <DropdownItem onClick={doRefreshOrderStatus} disabled={isStatusRefreshInProgress}>Refresh Status</DropdownItem>}
                {iAmGranted(perms.refresh_order_statuses) && <DropdownItem onClick={doRefreshOrderFees} disabled={isFeesRefreshInProgress}>Refresh Fees</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4">
        <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">Order ID</td>
              <td className="section-row-value">{order.id}</td>
            </tr>
            <tr>
              <td className="section-row-label">Order Status</td>
              <td className="section-row-value">
                {isStatusRefreshInProgress && <SpinnerChase className="xs" />}
                {!isStatusRefreshInProgress && getGranularStatusName(order.granularStatus)}
              </td>
            </tr>
            <tr>
              <td className="section-row-label">Order Date</td>
              <td className="section-row-value">{formatTimestamp(order.createdTs, formats.DATETIME)}</td>
            </tr>
            <tr>
              <td className="section-row-label">Person submitting order</td>
              <td className="section-row-value">
                <Link to={route(routes.view_user, order.userId)}>{order.userFullName}</Link>
              </td>
            </tr>
            <tr>
              <td className="section-row-label">T&C date and time of agreement</td>
              <td className="section-row-value">{order.termsAcceptedTs ? formatTimestamp(order.termsAcceptedTs, formats.DATETIME) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Phone number<br />of person submitting order</td>
              <td className="section-row-value">{order.userPhone ? formatPhone(order.userPhone) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Group Name</td>
              <td className="section-row-value">
                {!!order.dealerGroupName && <Link to={route(routes.view_dealer_group, order.dealerGroupId)}>{order.dealerGroupName}</Link>}
                {!order.dealerGroupName && '--'}
              </td>
            </tr>
            <tr>
              <td className="section-row-label">General Manager Name</td>
              <td className="section-row-value">{order.generalManagerName || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">General Manager Email</td>
              <td className="section-row-value">{order.generalManagerEmail || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Dealership</td>
              <td className="section-row-value">
                <Link to={route(routes.view_dealer_store, order.dealerStoreId)}>{order.dealerStoreName}</Link>
              </td>
            </tr>
            <tr>
              <td className="section-row-label">Dealership phone number</td>
              <td className="section-row-value">{formatPhone(order.dealerStorePhone) || "--"}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
    {isReloadConfirmationVisible && <Confirmation
      confirmBtnText="Reload"
      reverseButtons={false}
      onConfirm={() => {
        setIsReloadConfirmationVisible(false);
        reloadSigners();
      }}
      onCancel={() => setIsReloadConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to reload the signers of this order? All e-signature fields will be reset!</span>
    </Confirmation>}
  </>
}

SectionOrderInfo.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionOrderInfo;
