import { takeEvery, put, call } from "redux-saga/effects";

import {
  REFRESH_APP_SETTINGS,
} from "./actionTypes";

import {
  refreshAppSettingsOk,
  refreshAppSettingsErr,
} from "./actions";

import {
  refreshAppSettings,
} from "helpers/backendHelper";

/********* REFRESH *********/

function* onRefreshAppSettings() {
  try {
    const response = yield call(refreshAppSettings);
    yield put(refreshAppSettingsOk(response));
  } catch (error) {
    yield put(refreshAppSettingsErr(error));
  }
}

function* settingsSaga() {
  yield takeEvery(REFRESH_APP_SETTINGS, onRefreshAppSettings);
}

export default settingsSaga;