export default class SupportCaseManualReview {

  static ELDERLY = 1;
  static NO_MOBILE = 2;
  static OLD_PHONE = 3;
  static NO_TECH = 4;
  static NON_ENGLISH = 5;

  static getManualReviewMap() {
    return {
      [this.ELDERLY]: 'Elderly Person',
      [this.NO_MOBILE]: 'No Cellphone',
      [this.OLD_PHONE]: 'Old Cellphone',
      [this.NO_TECH]: 'Non-tech Savvy',
      [this.NON_ENGLISH]: 'Different Language',
    };
  }

  static getManualReviewName(id) {
    return this.getManualReviewMap()[id];
  }
}