import {
  GET_SUPPORT_CASE_DT,
  GET_SUPPORT_CASE_DT_OK,
  GET_SUPPORT_CASE_DT_ERR,
  DO_SUPPORT_CASE_DT_CLEANUP,
  SET_SUPPORT_CASE_DT_ACTIVE_TAB,

  APPLY_SUPPORT_CASE_DT_FILTERS,
  CLEAR_SUPPORT_CASE_DT_FILTERS,
  PATCH_SUPPORT_CASE_DT_FILTERS,
  UNSET_SUPPORT_CASE_DT_FILTERS,

} from "./actionTypes";

/********** DATATABLE **********/

export const getSupportCaseDt = params => ({
  type: GET_SUPPORT_CASE_DT,
  payload: { params },
});

export const getSupportCaseDtOk = response => ({
  type: GET_SUPPORT_CASE_DT_OK,
  payload: { response },
});

export const getSupportCaseDtErr = error => ({
  type: GET_SUPPORT_CASE_DT_ERR,
  payload: { error },
});

export const doSupportCaseDtCleanup = () => ({
  type: DO_SUPPORT_CASE_DT_CLEANUP,
});

export const setSupportCaseDtActiveTab = tab => ({
  type: SET_SUPPORT_CASE_DT_ACTIVE_TAB,
  payload: { tab },
});

/********** DATATABLE FILTERS **********/

export const applySupportCaseDtFilters = filters => ({
  type: APPLY_SUPPORT_CASE_DT_FILTERS,
  payload: { filters },
});

export const clearSupportCaseDtFilters = () => ({
  type: CLEAR_SUPPORT_CASE_DT_FILTERS,
});

export const patchSupportCaseDtFilters = filters => ({
  type: PATCH_SUPPORT_CASE_DT_FILTERS,
  payload: { filters },
});

export const unsetSupportCaseDtFilters = () => ({
  type: UNSET_SUPPORT_CASE_DT_FILTERS,
});