import React from 'react';
import { Col as BsCol } from "reactstrap";

const Col = props => {

  return <BsCol
    widths={['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl']}
    {...props} />
}

export default Col;