import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderSignerDeleted = props => {

  const { log } = props;

  return <div className="d-flex">
    <AuthorAvatar log={log} />
    <div className="flex-grow-1">
      <AuthorName log={log} /> deleted signer <b>{log.subject.firstName} {log.subject.lastName}</b>.
      <p className="mb-0"><EventTime log={log} /></p>
    </div>
  </div>
}

OrderSignerDeleted.propTypes = {
  log: PropTypes.object,
}

export default OrderSignerDeleted;