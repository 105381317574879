import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import SpinnerChase from './SpinnerChase';

const CardPreloader = props => {

  return <div className={classnames('card-preloader', props.className)}>
    <SpinnerChase className="sm" />
  </div>;
}

CardPreloader.propTypes = {
  className: PropTypes.string,
}

export default CardPreloader;