import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import classnames from "classnames";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap";
import { ValidationException } from "helpers/errorHelper";
import { showBriefError, showError, showSuccess } from "helpers/utilHelper";
import TextareaAutosize from 'react-textarea-autosize';
import Confirmation from "components/Shared/Confirmation";
import { createPaymentPlan } from "helpers/backendHelper";

const FormNew = props => {

  const { successRoute, cancelRoute } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // is the confirmation dialog visible or not
  // used to show/hide the save warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  // holds form values after validation and until the user accepts the confirmation
  const [tempFormValues, setTempFormValues] = useState();

  /********** FORM CONFIG **********/

  const formInitialValues = {
    name: '',
    subscriptionPrice: '',
    remotePrice: '',
    instorePrice: '',
    numOfFreeRemote: '',
    numOfFreeInstore: '',
    isPublic: false,
    description: '',
    maxRecommendedVolume: '',
    isFreeTrial: false,
    maxDays: '',
    maxOrders: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Field is required'),
      subscriptionPrice: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      remotePrice: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      instorePrice: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      numOfFreeRemote: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      numOfFreeInstore: Yup.number().required('Field is required').min(0, 'Must be greater than or equal to 0'),
      isPublic: Yup.boolean(),
      description: Yup.string().trim(),
      maxRecommendedVolume: Yup.number().min(1, 'Must be greater than or equal to 1'),
      isFreeTrial: Yup.boolean(),
      maxDays: Yup.number().min(1, 'Must be greater than or equal to 1'),
      maxOrders: Yup.number().min(1, 'Must be greater than or equal to 1'),
    }),
    onSubmit: values => {
      setTempFormValues(values);
      setIsConfirmationVisible(true);
    },
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const savePaymentPlan = () => {
    setIsSaveInProgress(true);
    createPaymentPlan(tempFormValues)
      .then(response => {
        showSuccess(`Plan "${tempFormValues.name}" has been saved`);
        navigate(successRoute);
      })
      .catch(ex => {
        showError('Unable to save plan');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save plan
              </Button>
              <Link to={cancelRoute} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Name</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Standard Plan" name="name" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.name} invalid={!!formik.errors.name} />
                  {!formik.errors.name && <small className="form-text text-muted">This is what the user will see when selecting a plan</small>}
                  {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Subscription Fee</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 50" name="subscriptionPrice" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.subscriptionPrice} invalid={!!formik.errors.subscriptionPrice} />
                  {!formik.errors.subscriptionPrice && <small className="form-text text-muted">Amount that will be changed each month. A value of &quot;0&quot; equals no monthly charge</small>}
                  {!!formik.errors.subscriptionPrice && <FormFeedback type="invalid">{formik.errors.subscriptionPrice}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Remote Transaction Fee</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 1.5" name="remotePrice" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.remotePrice} invalid={!!formik.errors.remotePrice} />
                  {!formik.errors.remotePrice && <small className="form-text text-muted">Amount that will be charged for each remote signing. A value of &quot;0&quot; will result in remote transactions being free of charge</small>}
                  {!!formik.errors.remotePrice && <FormFeedback type="invalid">{formik.errors.remotePrice}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">InStore Transaction Fee</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 1.5" name="instorePrice" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.instorePrice} invalid={!!formik.errors.instorePrice} />
                  {!formik.errors.instorePrice && <small className="form-text text-muted">Amount that will be charged for each in-store signing. A value of &quot;0&quot; will result in in-store transactions being free of charge</small>}
                  {!!formik.errors.instorePrice && <FormFeedback type="invalid">{formik.errors.instorePrice}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Remote Transactions Included</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 10" name="numOfFreeRemote" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.numOfFreeRemote} invalid={!!formik.errors.numOfFreeRemote} />
                  {!formik.errors.numOfFreeRemote && <small className="form-text text-muted">Number of monthly remote transactions that will be included in the subscription fee and therefore will not be charged a transaction fee</small>}
                  {!!formik.errors.numOfFreeRemote && <FormFeedback type="invalid">{formik.errors.numOfFreeRemote}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">InStore Transactions Included</Label>
                <Col sm={9}>
                  <Input type="number" min={0} className="form-control" placeholder="ex. 10" name="numOfFreeInstore" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.numOfFreeInstore} invalid={!!formik.errors.numOfFreeInstore} />
                  {!formik.errors.numOfFreeInstore && <small className="form-text text-muted">Number of monthly in-store transactions that will be included in the subscription fee and therefore will not be charged a transaction fee</small>}
                  {!!formik.errors.numOfFreeInstore && <FormFeedback type="invalid">{formik.errors.numOfFreeInstore}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Is public</Label>
                <Col sm={9}>
                  <div className="form-check form-switch form-switch-lg">
                    <Input type="checkbox" className="form-check-input" id="isPublicSwitch" name="isPublic" onChange={formik.handleChange} defaultChecked={formik.values.isPublic} />
                    <Label className="form-check-label" htmlFor="isPublicSwitch" />
                  </div>
                  <small className="form-text text-muted">Whether the plan is visible in the list of available plans a user can select from. Non-public plans are not visible to users but can be assigned to dealer stores by admins</small>
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Is free trial</Label>
                <Col sm={9}>
                  <div className="form-check form-switch form-switch-lg">
                    <Input type="checkbox" className="form-check-input" id="isFreeTrialSwitch" name="isFreeTrial" onChange={formik.handleChange} defaultChecked={formik.values.isFreeTrial} />
                    <Label className="form-check-label" htmlFor="isFreeTrialSwitch" />
                  </div>
                  <small className="form-text text-muted">Whether the plan is a free trial plan</small>
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Limit no. of days</Label>
                <Col sm={9}>
                  <Input type="number" min={1} className="form-control" placeholder="ex. 10" name="maxDays" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.maxDays} invalid={!!formik.errors.maxDays} />
                  {!formik.errors.maxDays && <small className="form-text text-muted"> Number of days after which this plan will expire and the user will have to switch to another plan. A value of &quot;0&quot; means unlimited</small>}
                  {!!formik.errors.maxDays && <FormFeedback type="invalid">{formik.errors.maxDays}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Limit no. of orders</Label>
                <Col sm={9}>
                  <Input type="number" min={1} className="form-control" placeholder="ex. 10" name="maxOrders" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.maxOrders} invalid={!!formik.errors.maxOrders} />
                  {!formik.errors.maxOrders && <small className="form-text text-muted"> Total number of orders included in the plan. When this number is reached, new orders can no longer be created. A value of &quot;0&quot; means unlimited</small>}
                  {!!formik.errors.maxOrders && <FormFeedback type="invalid">{formik.errors.maxOrders}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Description</Label>
                <Col sm={9}>
                  <TextareaAutosize maxRows={15} className={classnames('form-control', { 'is-invalid': !!formik.errors.description })} name="description" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.description} />
                  {!formik.errors.description && <small className="form-text text-muted">List of services included / not included in the plan. Items not included should be prefixed with &apos;x &apos;</small>}
                  {!!formik.errors.description && <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Max recommended volume</Label>
                <Col sm={9}>
                  <Input type="number" min={1} className="form-control" placeholder="ex. 5" name="maxRecommendedVolume" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.maxRecommendedVolume} invalid={!!formik.errors.maxRecommendedVolume} />
                  {!formik.errors.maxRecommendedVolume && <small className="form-text text-muted">Number of monthly orders for which this plan will be marked as the recommended option</small>}
                  {!!formik.errors.maxRecommendedVolume && <FormFeedback type="invalid">{formik.errors.maxRecommendedVolume}</FormFeedback>}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Save"
      reverseButtons={false}
      onConfirm={() => {
        setIsConfirmationVisible(false)
        savePaymentPlan();
        setTempFormValues(null);
      }}
      onCancel={() => {
        setIsConfirmationVisible(false);
        setTempFormValues(null);
        formik.setSubmitting(false);
      }}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to save this payment plan? Changes will be pushed to Stripe!</span>
    </Confirmation>}
  </React.Fragment >
}

FormNew.propTypes = {
  successRoute: PropTypes.string.isRequired,
  cancelRoute: PropTypes.string.isRequired,
};

export default FormNew;