import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardHeader, Button, Input } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import CardPreloader from "components/Shared/CardPreloader";
import Select from "react-select";

const ViewProcessInfo = props => {

  const { process, refreshHandler, channel, setChannelHandler, lines, setLinesHandler, isLoadInProgress } = props;

  const getChannelOptions = () => [{
    label: "out",
    value: "out",
  }, {
    label: "err",
    value: "err",
  }];

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <Select
              classNamePrefix="select2-selection"
              options={getChannelOptions()}
              onChange={selected => setChannelHandler(selected.value)}
              value={getChannelOptions().find(option => option.value == channel)}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '100px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }),
              }} />
            <Input
              type="number"
              className="form-control d-inline-block align-middle ms-3"
              placeholder="No. of lines"
              onChange={e => setLinesHandler(e.target.value)}
              value={lines}
              style={{ width: '100px' }} />
            <Button type="button" color="primary" className="ms-3" onClick={refreshHandler}>
              <i className="mdi mdi-reload me-1" />Refresh
            </Button>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Link to={route(routes.list_sys_processes)} className="btn btn-secondary mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <pre>{process.output}</pre>
      </CardBody>
      {isLoadInProgress && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewProcessInfo.propTypes = {
  process: PropTypes.object,
  refreshHandler: PropTypes.func,
  channel: PropTypes.number,
  setChannelHandler: PropTypes.func,
  lines: PropTypes.number,
  setLinesHandler: PropTypes.func,
  isLoadInProgress: PropTypes.bool,
};

export default ViewProcessInfo;
