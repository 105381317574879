/********** NORMALIZED LIST **********/

export const GET_E_SIGN_ORDER_DOC_NORM_LIST = 'GET_E_SIGN_ORDER_DOC_NORM_LIST'
export const GET_INK_SIGN_ORDER_DOC_NORM_LIST = 'GET_INK_SIGN_ORDER_DOC_NORM_LIST'
export const GET_FINANCE_ORDER_DOC_NORM_LIST = 'GET_FINANCE_ORDER_DOC_NORM_LIST'
export const GET_SUPPORTING_ORDER_DOC_NORM_LIST = 'GET_SUPPORTING_ORDER_DOC_NORM_LIST'
export const GET_ORDER_DOC_NORM_LIST_OK = 'GET_ORDER_DOC_NORM_LIST_OK'
export const GET_ORDER_DOC_NORM_LIST_ERR = 'GET_ORDER_DOC_NORM_LIST_ERR'

export const GET_ORDER_DOC_NORM_LIST_ITEM = 'GET_ORDER_DOC_NORM_LIST_ITEM'
export const GET_ORDER_DOC_NORM_LIST_ITEM_OK = 'GET_ORDER_DOC_NORM_LIST_ITEM_OK'
export const GET_ORDER_DOC_NORM_LIST_ITEM_ERR = 'GET_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const DELETE_ORDER_DOC_NORM_LIST_ITEM = 'DELETE_ORDER_DOC_NORM_LIST_ITEM'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_OK = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_OK'
export const DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR = 'DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM'
export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK'
export const REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR = 'REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR'

export const DO_ORDER_DOC_NORM_LIST_CLEANUP = 'DO_ORDER_DOC_NORM_LIST_CLEANUP'

/********** FORM **********/

export const UPLOAD_ORDER_DOC = 'UPLOAD_ORDER_DOC'
export const UPLOAD_ORDER_DOC_OK = 'UPLOAD_ORDER_DOC_OK'
export const UPLOAD_ORDER_DOC_ERR = 'UPLOAD_ORDER_DOC_ERR'

export const ADD_ORDER_DOC = 'ADD_ORDER_DOC'
export const ADD_ORDER_DOC_OK = 'ADD_ORDER_DOC_OK'
export const ADD_ORDER_DOC_ERR = 'ADD_ORDER_DOC_ERR'

export const DO_ORDER_DOC_FORM_CLEANUP = 'DO_ORDER_DOC_FORM_CLEANUP'

/*********** ACCEPT ALL **********/

export const ACCEPT_ALL_INK_SIGN_DOCS = 'ACCEPT_ALL_INK_SIGN_DOCS'
export const ACCEPT_ALL_INK_SIGN_DOCS_OK = 'ACCEPT_ALL_INK_SIGN_DOCS_OK'
export const ACCEPT_ALL_INK_SIGN_DOCS_ERR = 'ACCEPT_ALL_INK_SIGN_DOCS_ERR'
export const DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP = 'DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP'