import React from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import { nullsToEmptyStrings, phoneHasNoOfDigits, toSelectOptions } from "helpers/utilHelper";
import UsStates from "constants/usState";
import regx from "constants/regx";

const FormContactNew = props => {

  const { defaultValues, tabId, nextHandler, prevHandler } = props;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    phone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      state: Yup.string().required('Field is required'),
      phone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      phone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Back
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Address</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 2273 Bel Meadow Drive" name="address" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.address} invalid={!!formik.errors.address} />
              {!!formik.errors.address && <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">City</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. New Jersey" name="city" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.city} invalid={!!formik.errors.city} />
              {!!formik.errors.city && <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">State *</Label>
            <Col sm={8}>
              <Select
                classNamePrefix="select2-selection"
                name="state"
                options={usStates}
                onChange={selected => formik.setFieldValue('state', selected.value)}
                onFocus={e => onFieldFocused(e, 'state')}
                value={usStates.find(option => option.value === formik.values.state)}
                className={!!formik.errors.state && 'is-invalid'} />
              {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Zip</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 90017" name="zip" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.zip} invalid={!!formik.errors.zip} />
              {!!formik.errors.zip && <FormFeedback type="invalid">{formik.errors.zip}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 909-410-5017" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
              {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 909-410-5017" name="phone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone2} invalid={!!formik.errors.phone2} />
              {!!formik.errors.phone2 && <FormFeedback type="invalid">{formik.errors.phone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

const usStates = toSelectOptions(UsStates);

FormContactNew.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
};

export default FormContactNew;