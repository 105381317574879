import React, { useEffect, useState } from "react";
import Col from "components/Shared/Col";
import MessagesCard from "./MessagesCard";
import PropTypes from "prop-types";

const Messages = ({description, status}) => {
	const isVisible = () => {
		const target = document.querySelector("#sealing_card");
		const bottom = target?.getBoundingClientRect().bottom || 0;
		return bottom >= 0 && bottom <= window.innerHeight
	}

	const [toast, setToast] = useState(!isVisible());

	/********** HANDLERS **********/
	const showToast = () => {
		setToast(true);
	};

	const hideToast = () => {
		setToast(false);
	};

	/********** EFFECTS **********/
	useEffect(() => {
		document.addEventListener("scroll", scrollHandler);
		return () => document.removeEventListener("scroll", scrollHandler);
	}, []);


	const scrollHandler = () => {
		isVisible() ? hideToast() : showToast();
	};

	return <>
		<Col className="text-end">
			<MessagesCard id="sealing_card" description={description} status={status} />
		</Col>
		{toast && (<div className="seal-toast-wrapper">
			<MessagesCard id="sealing_toast" description={description} status={status} />
		</div>)}
	</>
}

Messages.propTypes = {
	description: PropTypes.string,
	status: PropTypes.string,
}

export default Messages;