import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import UserFormNew from "pages/User/Partial/Form/Info/New";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import UserRole from "model/userRole";
import { getDealerGroup } from "helpers/backendHelper";

const NewManager = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // read route parameters
  const { id } = useParams();

  /********** STATE **********/

  const [dealerGroup, setDealerGroup] = useState(null);
  const [dealerGroupError, setDealerGroupError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the group data
    refreshDealerGroup();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerGroup = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerGroup(id)
      .then(response => {
        setDealerGroup(response.dealerGroup);
      })
      .catch(ex => {
        setDealerGroupError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };


  return <React.Fragment>
    {iAmGranted(perms.create_users) && <div className="page-content">
      {dealerGroup && <React.Fragment>
        <MetaTitle>New group manager</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(dealerGroup)} />
          <Row>
            <Col xl="8" xxl="6">
              <UserFormNew
                forceValues={{
                  userRoleId: UserRole.ID_DEALER_GROUP_MANAGER,
                  dealerGroupId: parseInt(id),
                }}
                successRoute={route(routes.view_dealer_group, id)}
                cancelRoute={route(routes.view_dealer_group, id)} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {dealerGroupError && <Error error={dealerGroupError} title404="Dealer group not found" />}
    </div>}
    {iAmNotGranted(perms.create_users) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = dealerGroup => [{
  title: 'DEALER GROUPS',
  url: route(routes.list_dealer_groups),
}, {
  title: dealerGroup.name,
  url: route(routes.view_dealer_group, dealerGroup.id),
}, {
  title: 'New manager',
}];

export default NewManager;
