import { useAuth } from 'context/auth';
import React from 'react';

const ImpersonationAlert = () => {

  const { isImpersonation } = useAuth();

  return <React.Fragment>
    {isImpersonation() && <div className="d-flex align-items-center me-4">
      <span className="impersonation-alert"><i className="bx bxs-ghost text-danger" /></span>
    </div>}
  </React.Fragment>
}

export default ImpersonationAlert;