import React from "react";
import PropTypes from "prop-types";
import { getInitialsFromName } from "helpers/utilHelper";
import Message from "model/message";
import UserAvatarWithActivityStatus from "../UserAvatarWithActivityStatus";
import { useAuth } from "context/auth";

const ChatMembers = ({ members }) => {

  const { user: authUser } = useAuth();

  const getMembers = () => members.filter(member => member.id != authUser.id || member.type == Message.CHANNEL_MEMBER_TYPE_SIGNER);

  return <div className="chat-members d-inline-flex ms-3">
    {getMembers().map(member => {
      return <UserAvatarWithActivityStatus
        avatar={{
          id: member.id,
          image: member.type == Message.CHANNEL_MEMBER_TYPE_SIGNER ? "" : authUser.image,
          initials: getInitialsFromName(member.fullName),
          tooltip: member.fullName,
        }}
        activityStatus={{
          id: member.id,
          status: member.activityStatus,
          lastSeen: member.lastActiveTs,
          autoUpdate: true,
          isSigner: member.type == Message.CHANNEL_MEMBER_TYPE_SIGNER,
          showTooltip: true,
        }}
        size="sm me-1"
        className="user-avatar-group-item"
        key={member.id} />
    })}
  </div>
}

ChatMembers.propTypes = {
  members: PropTypes.array,
}

export default ChatMembers;