import React from 'react';
import { Button } from "reactstrap";

const HelpIcon = () => {

  const openHelpSidebar = () => {
    var body = document.body;
    body.classList.toggle("help-sidebar-enable");
  }

  return <React.Fragment>
    <Button color="none" className="header-item me-1" onClick={openHelpSidebar}>
      <i className="mdi mdi-help-circle-outline font-size-22" />
    </Button>
  </React.Fragment>
}

export default HelpIcon;