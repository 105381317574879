import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ViewInfo from "../View/Info";
import FormEditInfo from "../Form/Info/Edit";

const SectionInfo = props => {

  const { isLoadBusy, id, additionalFee, refreshHandler,
    qbInvoiceItems, qbInvoiceItemsError, qbBillItems, qbBillItemsError } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and until the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormEditInfo id={id} defaultValues={additionalFee} finishedHandler={toggleEditMode} qbInvoiceItems={qbInvoiceItems} qbInvoiceItemsError={qbInvoiceItemsError} qbBillItems={qbBillItems} qbBillItemsError={qbBillItemsError} />}
    {!isEditModeActive && <ViewInfo additionalFee={additionalFee} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  additionalFee: PropTypes.object,
  refreshHandler: PropTypes.func,
  qbInvoiceItems: PropTypes.array,
  qbInvoiceItemsError: PropTypes.string,
  qbBillItems: PropTypes.array,
  qbBillItemsError: PropTypes.string,
};

export default SectionInfo;
