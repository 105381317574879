import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Alert, UncontrolledAccordion } from "reactstrap";
import { doOrderDocNormListCleanup, getInkSignOrderDocNormList } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import InkSignDoc from "./Doc";

const InkSignDocs = ({ order }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { orderDocIds, orderDocsError, isLoadInProgress } = useSelector(state => state.OrderDoc.NormList);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocNormListCleanup());
    }
  }, [order.id]);

  /********** OTHER **********/

  const refreshOrderDocs = () => dispatch(getInkSignOrderDocNormList(order.id));

  return <div className="order-docs-manager">
    <div className="doc-list-header">
      <div className="doc-list-col doc-no">#</div>
      <div className="doc-list-col doc-name">Document name</div>
      <div className="doc-list-col doc-status">Status</div>
      <div className="doc-list-col doc-up-by">Uploaded by</div>
      <div className="doc-list-col doc-up-date">Upload date</div>
      <div className="doc-list-col doc-pages">No of pages</div>
      <div className="doc-list-col doc-sign-date">Signed date</div>
      <div className="doc-list-col doc-actions">Actions</div>
    </div>
    {!!orderDocIds.length && <UncontrolledAccordion stayOpen className="ink-sign-accordion">
      {orderDocIds.map((docId, index) => <InkSignDoc key={docId} id={docId} num={index + 1} order={order} refreshListHandler={refreshOrderDocs} />)}
    </UncontrolledAccordion>}
    {!isLoadInProgress && !orderDocsError && !orderDocIds.length && <div className="text-center mb-2">No contracts uploaded</div>}
    {isLoadInProgress && <SpinnerChase className="sm mb-4" />}
    {orderDocsError && <Alert color="danger" className="fade show text-center mb-0">
      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load contracts
    </Alert>}
  </div>
}

InkSignDocs.propTypes = {
  order: PropTypes.object.isRequired,
};

export default InkSignDocs;
