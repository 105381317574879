import { takeEvery, put, call } from "redux-saga/effects";

import { GET_NOTARY_STATEMENT_DT } from "./actionTypes";
import { getNotaryStatementDtErr, getNotaryStatementDtOk } from "./actions";
import { getNotaryStatementDt } from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetNotaryStatementDt({ payload: { params } }) {
  try {
    const response = yield call(getNotaryStatementDt, params);
    yield put(getNotaryStatementDtOk(response));
  } catch (error) {
    yield put(getNotaryStatementDtErr(error));
  }
}

function* statementSaga() {
  yield takeEvery(GET_NOTARY_STATEMENT_DT, onGetNotaryStatementDt);
}

export default statementSaga;
