import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import SupportCase from "model/supportCase";
import SupportCaseVid from "model/supportCaseVid";
import SupportCaseManualReview from "model/supportCaseManualReview";
import { getSupportCaseServiceWithIcon } from "helpers/jsxHelper";

const ViewInfo = ({ supportCase }) => {

  /********** STATE **********/

  const { services, vid, reporterFullName, reporterRoleName, deviceName, osName, manualReviews, reportedVia } = supportCase;

  return <>
    <Row className="striped-row gx-0">
      <Col xs="6">
        <div>Reporter Name</div>
        <div><strong>{reporterFullName || "--"}</strong></div>
      </Col>
      <Col xs="6">
        <div>Reporter Type</div>
        <div><strong>{reporterRoleName || "--"}</strong></div>
      </Col>
    </Row>
    <Row className="striped-row gx-0">
      <Col xs="6">
        <div className="mb-1">Services</div>
        <div><strong>{services.map(id => getSupportCaseServiceWithIcon(id)) || "--"}</strong></div>
      </Col>
      <Col xs="6">
        <div className="mb-1">VerifyID</div>
        <div><strong>{vid.map(id => <div key={id}>{SupportCaseVid.getVidName(id)}</div>) || "--"}</strong></div>
      </Col>
    </Row>
    <Row className="striped-row gx-0">
      <Col xs="6">
        <div>Device OS</div>
        <div><strong>{osName || "--"}</strong></div>
      </Col>
      <Col xs="6">
        <div>Device Type</div>
        <div><strong>{deviceName || "--"}</strong></div>
      </Col>
    </Row>
    <Row className="striped-row gx-0">
      <Col xs="6">
        <div>Reported via</div>
        <div><strong>{SupportCase.getReportedViaName(reportedVia) || "--"}</strong></div>
      </Col>
      <Col xs="6">
        <div>Manual Review</div>
        <div><strong>{manualReviews.map(id => SupportCaseManualReview.getManualReviewName(id)).join(", ") || "--"}</strong></div>
      </Col>
    </Row>
  </>

}

ViewInfo.propTypes = {
  supportCase: PropTypes.object
};

export default ViewInfo;