import {
  REFRESH_APP_SETTINGS,
  REFRESH_APP_SETTINGS_OK,
  REFRESH_APP_SETTINGS_ERR,
  DO_REFRESH_APP_ASETTINGS_CLEANUP,
} from "./actionTypes";

/********* REFRESH *********/

export const refreshAppSettings = () => ({
  type: REFRESH_APP_SETTINGS,
});

export const refreshAppSettingsOk = response => ({
  type: REFRESH_APP_SETTINGS_OK,
  payload: { response },
});

export const refreshAppSettingsErr = error => ({
  type: REFRESH_APP_SETTINGS_ERR,
  payload: { error },
});

export const doRefreshAppSettingsCleanup = () => ({
  type: DO_REFRESH_APP_ASETTINGS_CLEANUP,
});