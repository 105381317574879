import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, UncontrolledTooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useSelector, useDispatch } from "react-redux";
import { getAdditionalFeeList, doAdditionalFeeListCleanup } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const DataTable = () => {

  const dispatch = useDispatch();

  const { additionalFees, additionalFeesError, isLoadInProgress } = useSelector(state => state.AdditionalFee.List);

  useEffect(() => {
    refreshFees();
    return () => {
      dispatch(doAdditionalFeeListCleanup());
    }
  }, []);

  const refreshFees = () => dispatch(getAdditionalFeeList());

  return (<Card className="expand-v">
    <CardBody>
      <Row>
        <Col>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table new-uploaded-docs mb-0 bb-0">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th data-priority="1">Name</Th>
                    <Th data-priority="2">Price</Th>
                    <Th data-priority="2">QB Invoice Item</Th>
                    <Th data-priority="2">QB Bill Item</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadInProgress && <Tr>
                    <Td colSpan="8">
                      <SpinnerChase className="sm mt-2 mb-2" />
                    </Td>
                  </Tr>}
                  {!!additionalFeesError && <Tr>
                    <Td colSpan="8">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load fees
                      </Alert>
                    </Td>
                  </Tr>}
                  {!isLoadInProgress && !additionalFeesError && additionalFees.map((fee, index) => <Tr key={fee.id}>
                    <Th className="co-name">{index + 1}</Th>
                    <Td><Link to={route(routes.view_additional_fee, fee.id)}>{fee.name}</Link></Td>
                    <Td>{fee.isPercentage ? fee.price + '%' : '$' + fee.price}</Td>
                    <Td>
                      {!!fee.qbInvoiceItemId
                        ? fee.qbInvoiceItemId
                        : (!fee.qbBillItemId
                          ? <React.Fragment>
                            <i id={`invoice-item-warning-${fee.id}`} className="mdi mdi-alert-circle text-danger"></i>
                            <UncontrolledTooltip placement="top" target={`invoice-item-warning-${fee.id}`}>Please add either an invoice item or a bill item</UncontrolledTooltip>
                          </React.Fragment>
                          : '--')}
                    </Td>
                    <Td>
                      {!!fee.qbBillItemId
                        ? fee.qbBillItemId
                        : (!fee.qbInvoiceItemId
                          ? <React.Fragment>
                            <i id={`bill-item-warning-${fee.id}`} className="mdi mdi-alert-circle text-danger"></i>
                            <UncontrolledTooltip placement="top" target={`bill-item-warning-${fee.id}`}>Please add either an invoice item or a bill item</UncontrolledTooltip>
                          </React.Fragment>
                          : '--')}
                    </Td>
                  </Tr>)}
                </Tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>)
}

export default DataTable;
