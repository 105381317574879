/********** NORMALIZED LIST **********/

export const GET_VID_REQUEST_NORM_LIST = 'GET_VID_REQUEST_NORM_LIST'
export const GET_VID_REQUEST_NORM_LIST_OK = 'GET_VID_REQUEST_NORM_LIST_OK'
export const GET_VID_REQUEST_NORM_LIST_ERR = 'GET_VID_REQUEST_NORM_LIST_ERR'

export const GET_VID_REQUEST_NORM_LIST_ITEM = 'GET_VID_REQUEST_NORM_LIST_ITEM'
export const GET_VID_REQUEST_NORM_LIST_ITEM_OK = 'GET_VID_REQUEST_NORM_LIST_ITEM_OK'
export const GET_VID_REQUEST_NORM_LIST_ITEM_ERR = 'GET_VID_REQUEST_NORM_LIST_ITEM_ERR'

export const DO_VID_REQUEST_NORM_LIST_CLEANUP = 'DO_VID_REQUEST_NORM_LIST_CLEANUP'