import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Alert } from "reactstrap";
import SpinnerChase from "components/Shared/SpinnerChase";
import { getNewStoresStats } from "helpers/backendHelper";
import { getEndOfMonthString, getStartOfMonthString } from "helpers/dateHelper";
import { getTrendIcon, getTrendPercentage } from "helpers/jsxHelper";

const startOfMonth = getStartOfMonthString();
const endOfMonth = getEndOfMonthString();

const StatsAccountManager = () => {

  const [stats, setStats] = useState({});
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [statsError, setStatsError] = useState(null);

  const getStats = () => {
    setIsLoadInProgress(true);
    getNewStoresStats()
      .then(response => {
        setStats(response);
      })
      .catch(ex => {
        setStatsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  };

  // load stats data
  useEffect(() => {
    getStats();
  }, []);

  return (!isLoadInProgress && !!statsError) ? <Alert color="danger" className="fade show text-center">
    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stats
  </Alert> : <>
    <Col xs={12} md={4} className="mb-4">
      <Card className="mb-0 py-2 h-100">
        <CardBody className="d-flex align-items-center">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div>
              <div className="d-flex align-items-center mb-3">
                <div className="icon-circle avatar-xs align-self-center">
                  <span className="stats-icon primary-icon-color">
                    <i className="mdi mdi-store font-size-18" />
                  </span>
                </div>
                <div>
                  <h6 className="fw-medium mb-0">New Stores</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <h4 className="mb-0 me-2">{stats.newDealerStoresCount?.toLocaleString() ?? '--'}</h4>
                {getTrendIcon(stats.newDealerStoresPercentage)}
              </div>
              <div className="d-flex">
                {getTrendPercentage(stats.newDealerStoresPercentage)}
                <div className="trend-interval">
                  <div className="me-1">From {startOfMonth}</div>
                  <div>to {endOfMonth}</div>
                </div>
              </div>
            </div>
          }
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={4} className="mb-4">
      <Card className="mb-0 py-2 h-100">
        <CardBody className="d-flex align-items-center">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div>
              <div className="d-flex align-items-center mb-3">
                <div className="icon-circle avatar-xs align-self-center">
                  <span className="stats-icon primary-icon-color">
                    <i className="mdi mdi-tag-outline font-size-18" />
                  </span>
                </div>
                <div>
                  <h6 className="fw-medium mb-0">Total Orders from New Stores</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <h4 className="mb-0 me-2">{stats.newDealerStoresOrdersCount?.toLocaleString() ?? '--'}</h4>
                {getTrendIcon(stats.newDealerStoresOrdersPercentage)}
              </div>
              <div className="d-flex">
                {getTrendPercentage(stats.newDealerStoresOrdersPercentage)}
                <div className="trend-interval">
                  <div className="me-1">From {startOfMonth}</div>
                  <div>to {endOfMonth}</div>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
    <Col xs={12} md={4} className="mb-4">
      <Card className="mb-0 py-2 h-100">
        <CardBody className="d-flex align-items-center">
          {isLoadInProgress && <SpinnerChase className="sm" />}
          {!isLoadInProgress &&
            <div>
              <div className="d-flex align-items-center mb-3">
                <div className="icon-circle avatar-xs align-self-center">
                  <span className="stats-icon primary-icon-color">
                    <i className="mdi mdi-account-group font-size-18" />
                  </span>
                </div>
                <div>
                  <h6 className="fw-medium mb-0">New Groups</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <h4 className="mb-0 me-2">{stats.newDealerGroupsCount?.toLocaleString() ?? '--'}</h4>
                {getTrendIcon(stats.newDealerGroupsPercentage)}
              </div>
              <div className="d-flex">
                {getTrendPercentage(stats.newDealerGroupsPercentage)}
                <div className="trend-interval">
                  <div className="me-1">From {startOfMonth}</div>
                  <div>to {endOfMonth}</div>
                </div>
              </div>
            </div>}
        </CardBody>
      </Card>
    </Col>
  </>
}

export default StatsAccountManager;