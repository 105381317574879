import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import UserAvatar from './UserAvatar';
import UserActivityStatus from './UserActivityStatus';

const UserAvatarWithActivityStatus = props => {

  const { size, avatar, activityStatus, className } = props;

  return <div className={classnames('user-avatar-with-activity-status', `user-avatar-with-activity-status-${size}`, className)}>
    <UserAvatar {...avatar} size={size} />
    <UserActivityStatus {...activityStatus} size={size} hideText={true} />
  </div>
}

UserAvatarWithActivityStatus.propTypes = {
  size: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  activityStatus: PropTypes.object,
  className: PropTypes.string,
}

export default UserAvatarWithActivityStatus;