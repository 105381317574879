import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import config from "config";
import classnames from "classnames";
import { Alert, Progress } from "reactstrap";
import { bytesToSize, getBeUrl, randomStringSync, showError, showSuccess } from "helpers/utilHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import EventEmitter from 'helpers/eventsHelper';
import Confirmation from "components/Shared/Confirmation";
import { useSocketOn, useSubscribeToSupportCase } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { deleteSupportCaseFile, getSupportCaseFiles, uploadSupportCaseFile } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";

const ViewUploads = ({ supportCase }) => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [filesLoadedOnce, setFilesLoadedOnce] = useState(false);
  const [deletingFile, setDeletingFile] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);

  const refreshSupportCase = useCallback(() => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getSupportCaseFiles(supportCase.id)
      .then(response => {
        setFiles(response.files);
        setFilesLoadedOnce(true);
      })
      .catch(ex => {
        setFilesError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [supportCase.id]);


  // start receiving case updates
  useSubscribeToSupportCase(supportCase.id);

  const onSupportCaseChanged = useCallback(data => {
    if (data.id == supportCase.id) {
      refreshSupportCase();
    }
  }, [supportCase.id, refreshSupportCase]);

  // listen for changes on messages
  useSocketOn(socketEvent.supportCaseChanged, onSupportCaseChanged);

  useEffect(() => {
    refreshSupportCase();
    // listen to image upload progress
    EventEmitter.on('message.uploadProgress', uploadProgressReceived);
    return () => {
      EventEmitter.off('message.uploadProgress', uploadProgressReceived);
    }
  }, [supportCase.id, refreshSupportCase]);

  const dropFile = useCallback(file => {
    // we will be listening for file upload progress
    // however multiple files may be uploaded in the same time
    // so we need a way to identify which file the progress report belongs to
    const uid = randomStringSync(3);
    // add file to the preview list
    setFilesToUpload(files => [...files, {
      file,
      uid,
      progress: 0,
    }]);
    // upload file to backend
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uid', uid);
    uploadFile(formData);
  }, [supportCase.id]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept: 'image/jpeg, image/png, video/mp4',
    maxFiles: config.SUPPORT_CASE_MAX_FILES,
    maxSize: config.SUPPORT_CASE_MAX_FILE_SIZE,
    noClick: true,
    onDropAccepted: acceptedFiles => {
      for (const file of acceptedFiles) {
        dropFile(file);
      }
    },
    onDropRejected: rejectedFiles => {
      const firstFile = rejectedFiles[0];
      const firstError = firstFile.errors[0];
      let message;
      switch (firstError.code) {
        case 'file-invalid-type':
          message = 'Please upload only images and videos (png, jpg, jpeg, mp4)';
          break;
        case 'too-many-files':
          message = `Please select a maximum of ${config.SUPPORT_CASE_MAX_FILES} files`;
          break;
        case 'file-too-large':
          message = `Please upload files up to ${bytesToSize(config.SUPPORT_CASE_MAX_FILE_SIZE)} in size`;
          break;
        default:
          message = firstError.message;
          break;
      }
      showError(message);
    },
  });

  const uploadProgressReceived = useCallback(data => {
    // see for which file this progres report is
    const uid = data.fileUid;
    const progress = Math.round(data.ev.loaded / data.ev.total * 100);
    setFilesToUpload(files => {
      if (progress == 100) {
        // upload is finished so remove the file from the preview queue
        return files.filter(f => f.uid != uid);
      } else {
        return files.map(f => {
          if (f.uid == uid) {
            // update the progress of the file
            f.progress = progress;
          }
          return f;
        });
      }
    });
  }, []);

  const getFileDownloadUrl = useCallback(fileName => getBeUrl(`/support-case/${supportCase.id}/file/${fileName}/download`), [supportCase.id]);

  const deleteFile = useCallback(() => removeFile(), [supportCase.id, fileToDelete]);

  // delete-file event handler
  // make the remote call to delete the file
  const removeFile = () => {
    deleteSupportCaseFile(supportCase.id, fileToDelete)
      .then(response => {
        setDeletingFile(fileToDelete)
        showSuccess('File has been deleted');
      })
      .catch(ex => {
        showError('Unable to delete file');
      })
  };

  const uploadFile = (formData) => {
    let config = null;
    if (formData instanceof FormData) {
      config = {
        onUploadProgress: ev => EventEmitter.emit('message.uploadProgress', { ev, fileUid: formData.get('uid') })
      };
    }
    uploadSupportCaseFile(supportCase.id, formData, config)
      .then(response => {
        // nothing
      })
      .catch(ex => {
        showError('Unable to upload file');
      })
  };

  return <React.Fragment>
    <div {...(iAmGranted(perms.edit_support_cases) ? getRootProps({ className: 'support-case-dropzone' }) : { className: 'support-case-dropzone' })}>
      {iAmGranted(perms.edit_support_cases) && (
        <div className={classnames('dropzone', { 'is-drag-active': isDragActive })}>
          <div className="dz-message needsclick" onClick={open}>
            <input {...getInputProps()} />
            <div className="dz-message-text">
              <i className="dz-message-icon text-muted bx bxs-cloud-upload me-2 align-middle" />
              <span>Drop files here</span>
            </div>
          </div>
        </div>
      )}
      {!isLoadInProgress && !files?.length && iAmNotGranted(perms.edit_support_cases) && <div className="p-2">No files added</div>}
      {filesToUpload && <div className="support-case-uploaded-files">
        {filesToUpload.map(entry => {
          return <div className="support-case-uploaded-file mt-2 me-4 d-inline-block" key={entry.file.name}>
            <div className="font-size-13 mb-1">{entry.file.name}</div>
            <Progress value={entry.progress} color="primary" className="progress-sm"></Progress>
          </div>
        })}
      </div>}
      {isLoadInProgress && !filesLoadedOnce && <SpinnerChase className="xs mt-3 mb-n1" />}
      {files && <div className="support-case-files mt-2">
        {files.map(file => {
          return <div className="support-case-file d-inline-block mt-2 me-2" key={file}>
            <a href={getFileDownloadUrl(file)} className={classnames({ 'text-decoration-line-through': deletingFile == file })}>
              <i className="mdi mdi-file me-1"></i>
              {file}
            </a>
            {iAmGranted(perms.edit_support_cases) && <i className="mdi mdi-delete ms-1 delete-btn text-danger cursor-pointer" onClick={() => setFileToDelete(file)}></i>}
          </div>
        })}
      </div>}
      {filesError && <Alert color="danger" className="fade show text-center mb-0 mt-2">
        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load files
      </Alert>}
    </div>
    {!!fileToDelete && <Confirmation
      confirmBtnText="Delete"
      reverseButtons={false}
      onConfirm={() => {
        setFileToDelete(null);
        deleteFile();
      }}
      onCancel={() => setFileToDelete(null)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete file &quot;{fileToDelete}&quot;?</span>
    </Confirmation>}
  </React.Fragment>

}

ViewUploads.propTypes = {
  supportCase: PropTypes.object
};

export default ViewUploads;