import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, FormFeedback, Input } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { dictionaryToSelectOptions, nullsToEmptyStrings, showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { useParams } from "react-router-dom";
import SupportCase from "model/supportCase";
import SupportCaseVid from "model/supportCaseVid";
import SupportCaseService from "model/supportCaseService";
import SupportCaseManualReview from "model/supportCaseManualReview";
import { updateSupportCase, getDeviceList, getOsList } from "helpers/backendHelper";

const FormInfoEdit = ({ defaultValues, finishedHandler }) => {

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceListError, setDeviceListError] = useState(null);
  const [isDevicesLoadInProgress, setIsDevicesLoadInProgress] = useState(false);
  const [osList, setOsList] = useState([]);
  const [osListError, setOsListError] = useState(null);
  const [isOsLoadInProgress, setOsLoadInProgress] = useState(false);

  const deviceOptions = toSelectOptions(deviceList);
  const osOptions = toSelectOptions(osList);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    services: [],
    vid: [],
    manualReviews: [],
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      reportedVia: Yup.number().required("Field is required"),
      services: Yup.array().min(1, "At least one service is required"),
    }),
    onSubmit: values => saveSupportCase(values, id),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // load OS list
    refreshOsList();
    // load device list
    refreshDevicesList();
  }, [])

  // runs whenever 'deviceListError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (deviceListError) {
      // set an error on the form field
      formik.setFieldError('deviceId', 'Unable to load devices');
    }
  }, [deviceListError]);

  // runs whenever 'osListError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (osListError) {
      // set an error on the form field
      formik.setFieldError('osId', 'Unable to load operating systems');
    }
  }, [osListError]);

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError("Form has errors");
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  const handleDeviceOsChange = selected => {
    formik.setFieldValue("osId", selected.value);
    formik.setFieldValue("osName", selected.label);
  };

  const handleDeviceTypeChange = selected => {
    formik.setFieldValue("deviceId", selected.value);
    formik.setFieldValue("deviceName", selected.label);
  };

  /********** OTHER **********/

  const saveSupportCase = (values, id) => {
    setIsSaveInProgress(true);
    updateSupportCase(values, id)
      .then(response => {
        showSuccess(`Case #${values.id} has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError("Unable to save case");
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const refreshOsList = () => {
    setOsLoadInProgress(true);
    // make the initial remote call to get the user data
    getOsList()
      .then(response => {
        setOsList(response.os);
      })
      .catch(ex => {
        setOsListError(ex);
      })
      .finally(() => {
        setOsLoadInProgress(false);
      });
  };

  const refreshDevicesList = () => {
    setIsDevicesLoadInProgress(true);
    // make the initial remote call to get the user data
    getDeviceList()
      .then(response => {
        setDeviceList(response.devices);
      })
      .catch(ex => {
        setDeviceListError(ex);
      })
      .finally(() => {
        setIsDevicesLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Col xs={6}>
              <Label className="col-form-label">Reporter Name</Label>
              <Input disabled type="text" className="form-control" value={defaultValues.reporterFullName} />
            </Col>
            <Col xs={6}>
              <Label className="col-form-label">Reporter Type</Label>
              <Input disabled type="text" className="form-control" value={defaultValues.reporterRoleName} />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={6}>
              <Label className="col-form-label">Service *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="services"
                isMulti
                options={serviceOptions}
                onChange={selected => formik.setFieldValue("services", selected.map(s => s.value))}
                onFocus={e => onFieldFocused(e, "services")}
                value={serviceOptions.filter(option => formik.values.services.find(id => id == option.value))}
                className={!!formik.errors.services && "is-invalid"} />
              {!!formik.errors.services && <FormFeedback type="invalid">{formik.errors.services}</FormFeedback>}
            </Col>
            <Col xs={6}>
              <Label className="col-form-label">VerifyID</Label>
              <Select
                classNamePrefix="select2-selection"
                name="vid"
                isMulti
                options={vidOptions}
                onChange={selected => formik.setFieldValue("vid", selected.map(s => s.value))}
                onFocus={e => onFieldFocused(e, "vid")}
                value={vidOptions.filter(option => formik.values.vid.find(id => id == option.value))}
                className={!!formik.errors.vid && "is-invalid"} />
              {!!formik.errors.vid && <FormFeedback type="invalid">{formik.errors.vid}</FormFeedback>}
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={6}>
              <Label className="col-form-label">Device OS *</Label>
              <CreatableSelect
                classNamePrefix="select2-selection"
                name="osId"
                options={osOptions}
                isLoading={isOsLoadInProgress}
                onChange={handleDeviceOsChange}
                onFocus={e => onFieldFocused(e, "osId")}
                value={osOptions.find(option => option.value == formik.values.osId)}
                className={!!formik.errors.osId && "is-invalid"} />
              {!!formik.errors.osId && <FormFeedback type="invalid">{formik.errors.osId}</FormFeedback>}
            </Col>
            <Col xs={6}>
              <Label className="col-form-label">Device Type *</Label>
              <CreatableSelect
                classNamePrefix="select2-selection"
                name="deviceId"
                options={deviceOptions}
                isLoading={isDevicesLoadInProgress}
                onChange={handleDeviceTypeChange}
                onFocus={e => onFieldFocused(e, "deviceId")}
                value={deviceOptions.find(option => option.value == formik.values.deviceId)}
                className={!!formik.errors.deviceId && "is-invalid"} />
              {!!formik.errors.deviceId && <FormFeedback type="invalid">{formik.errors.deviceId}</FormFeedback>}
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={6}>
              <Label className="col-form-label">Reported via *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="reportedVia"
                options={reportedViaOptions}
                onChange={selected => formik.setFieldValue("reportedVia", selected.value)}
                onFocus={e => onFieldFocused(e, "reportedVia")}
                value={reportedViaOptions.find(option => option.value == formik.values.reportedVia)}
                className={!!formik.errors.reportedVia && "is-invalid"} />
              {!!formik.errors.reportedVia && <FormFeedback type="invalid">{formik.errors.reportedVia}</FormFeedback>}
            </Col>
            <Col xs={6}>
              <Label className="col-form-label">Manual Review</Label>
              <Select
                classNamePrefix="select2-selection"
                name="manualReviews"
                isMulti
                options={manualReviewOptions}
                onChange={selected => formik.setFieldValue("manualReviews", selected.map(s => s.value))}
                onFocus={e => onFieldFocused(e, "manualReviews")}
                value={manualReviewOptions.filter(option => formik.values.manualReviews.find(id => id == option.value))}
                className={!!formik.errors.manualReviews && "is-invalid"} />
              {!!formik.errors.manualReviews && <FormFeedback type="invalid">{formik.errors.manualReviews}</FormFeedback>}
            </Col>
          </Row>

          <Row>
            <Col xs="auto">
              <Button type="button" color="primary" className="me-2 mb-2 disabled pe-auto" onClick={finishedHandler}>
                Cancel
              </Button>
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormInfoEdit.propTypes = {
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

const serviceOptions = dictionaryToSelectOptions(SupportCaseService.getServiceMap());

const vidOptions = dictionaryToSelectOptions(SupportCaseVid.getVidMap());

const reportedViaOptions = dictionaryToSelectOptions(SupportCase.getReportedViaMap());

const manualReviewOptions = dictionaryToSelectOptions(SupportCaseManualReview.getManualReviewMap());

export default FormInfoEdit;