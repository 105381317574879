import {
  GET_VID_REQUEST_NORM_LIST,
  GET_VID_REQUEST_NORM_LIST_OK,
  GET_VID_REQUEST_NORM_LIST_ERR,
  GET_VID_REQUEST_NORM_LIST_ITEM,
  GET_VID_REQUEST_NORM_LIST_ITEM_OK,
  GET_VID_REQUEST_NORM_LIST_ITEM_ERR,
  DO_VID_REQUEST_NORM_LIST_CLEANUP,
} from './actionTypes';

/********** NORMALIZED LIST **********/

export const getVidRequestNormList = orderId => ({
  type: GET_VID_REQUEST_NORM_LIST,
  payload: { orderId },
})

export const getVidRequestNormListOk = response => ({
  type: GET_VID_REQUEST_NORM_LIST_OK,
  payload: { response },
});

export const getVidRequestNormListErr = error => ({
  type: GET_VID_REQUEST_NORM_LIST_ERR,
  payload: { error },
});


export const getVidRequestNormListItem = id => ({
  type: GET_VID_REQUEST_NORM_LIST_ITEM,
  payload: { id },
});

export const getVidRequestNormListItemOk = (id, response) => ({
  type: GET_VID_REQUEST_NORM_LIST_ITEM_OK,
  payload: { id, response },
});

export const getVidRequestNormListItemErr = (id, error) => ({
  type: GET_VID_REQUEST_NORM_LIST_ITEM_ERR,
  payload: { id, error },
});


export const doVidRequestNormListCleanup = () => ({
  type: DO_VID_REQUEST_NORM_LIST_CLEANUP,
});
