// socket events
// they need to match the back-end values
export default {
  subscribeToOrder: 'subscribeToOrder',
  unsubscribeFromOrder: 'unsubscribeFromOrder',
  orderChanged: 'orderChanged',
  subscribeToOrderDoc: 'subscribeToOrderDoc',
  unsubscribeFromOrderDoc: 'unsubscribeFromOrderDoc',
  orderDocChanged: 'orderDocChanged',
  notificationReceived: 'notificationReceived',
  subscribeToOrderMessages: 'subscribeToOrderMessages',
  unsubscribeFromOrderMessages: 'unsubscribeFromOrderMessages',
  messageReceived: 'messageReceived',
  subscribeToUser: 'subscribeToUser',
  unsubscribeFromUser: 'unsubscribeFromUser',
  subscribeToUsers: 'subscribeToUsers',
  unsubscribeFromUsers: 'unsubscribeFromUsers',
  userActivityStatusChanged: 'userActivityStatusChanged',
  subscribeToOrderSigner: 'subscribeToOrderSigner',
  unsubscribeFromOrderSigner: 'unsubscribeFromOrderSigner',
  orderSignerActivityStatusChanged: 'orderSignerActivityStatusChanged',
  subscribeToSupportCase: 'subscribeToSupportCase',
  unsubscribeFromSupportCase: 'unsubscribeFromSupportCase',
  subscribeToAllSupportCases: 'subscribeToAllSupportCases',
  unsubscribeFromAllSupportCases: 'unsubscribeFromAllSupportCases',
  supportCaseChanged: 'supportCaseChanged',
  subscribeToVidReq: 'subscribeToVidReq',
  unsubscribeFromVidReq: 'unsubscribeFromVidReq',
  vidReqChanged: 'vidReqChanged',
  vidReqCreated: 'vidReqCreated',
  notaryBiddingChanged: 'notaryBiddingChanged',
}