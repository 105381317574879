import React from "react";
import { Row, Col, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { formatTimestamp, formats } from "helpers/dateHelper";

const StepSetAppointment = ({ orderKey }) => {

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} className="mb-2">
          <Label className="col-sm-4 col-form-label">Meeting Date</Label>
          <Input
            type="text"
            className="form-control"
            value={formatTimestamp(order?.notaries[orderKey].meetingTs, formats.TRACKING_DATE)}
            readOnly
          />
        </Col>
        <Col xs={12} className="mb-2">
          <Label className="col-sm-4 col-form-label">Meeting Address</Label>
          <Input
            type="text"
            className="form-control"
            value={order?.notaries[orderKey].meetingAddress}
            readOnly
          />
        </Col>
      </Row>

      <div className="divider-faded divider-sm" />

      <h6>Action: </h6>
      <ul>
        <li>
          In order to establish the meeting, please contact the customer as soon as possible and when everything is discussed, please add the details here.
        </li>
        <li>
          Once you enter the date and time, the customer will get a notification to confirm.
        </li>
        <li>
          If, for whatever reason, the customer/notary requests to change the sigining location from the above sigining address it is required to advice Maverick ASAP.
        </li>
      </ul>
    </React.Fragment>
  )
};

StepSetAppointment.propTypes = {
  orderKey: PropTypes.number,
};

export default StepSetAppointment;