import React, { useEffect } from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Card, CardBody } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "components/Common/Breadcrumb2";
import AccessDenied from "pages/Error/AccessDenied";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { getNewDealersReport } from "store/actions";
import DealerCountReport from "./Partial/Section/DealerCount";
import NewDealersFilters from "./Partial/NewDealersFilters";

const NewDealers = () => {

  const dispatch = useDispatch();
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.NewDealersFilters);

  // range is ready if both start and end dates have been selected
  const isRangeSet = !!(filters.startTs && filters.endTs);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    if (isRangeSet) {
      dispatch(getNewDealersReport({ filters }));
    }
  }, [filters]);

  return (
    <React.Fragment>
      {iAmGranted(perms.view_new_dealers_reports) && (
        <div className="page-content">
          <MetaTitle>New Dealers Report</MetaTitle>
          <Container fluid>
            <Breadcrumbs title="NEW DEALERS REPORT" />
            <Card>
              <CardBody>
                <NewDealersFilters />
                {isRangeSet && <>
                  <DealerCountReport />
                </>}
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
      {iAmNotGranted(perms.view_new_dealers_reports) && <AccessDenied />}
    </React.Fragment>
  )
}

export default NewDealers;