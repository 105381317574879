import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { Alert, Button } from "reactstrap";
import SpinnerChase from "components/Shared/SpinnerChase";
import { showError } from "helpers/utilHelper";
import Comment from "../Comment";
import { getComments, createComment, deleteComment } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";

const ViewComments = ({ supportCase }) => {

  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [comments, setComments] = useState([]);
  const [commentsError, setCommentsError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [comment, setComment] = useState("");

  // toggle edit mode
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  /********** OTHER **********/

  const refreshComments = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the comments data
    getComments(supportCase.id)
      .then(response => {
        setComments(response);
      })
      .catch(ex => {
        setCommentsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const saveComment = () => {
    setIsSaveInProgress(true);
    createComment(supportCase.id, { content: comment })
      .then(response => {
        if (isEditModeActive) handleClearComment();
        refreshComments();
      })
      .catch(ex => {
        showError("Unable to save comment");
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  // delete-store event handler
  // make the remote call to delete the store
  const removeComment = (id) => {
    deleteComment(id)
      .then(response => {
        refreshComments();
      })
      .catch(ex => {
        showError("Unable to delete comment");
      })
  };

  /********** HANDLERS **********/

  // toggles the edit mode on and off
  const toggleEditMode = () => {
    setIsEditModeActive(prevMode => !prevMode);
  };

  const handleCommentChange = event => {
    setComment(event.target.value);
  };

  const handleSaveComment = () => {
    if (!comment) return;
    saveComment();
  };

  const handleClearComment = () => {
    setComment("");
    toggleEditMode();
  };

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get comments
    refreshComments();
  }, []);


  return <div className="p-2">
    <h6 className="mb-3">Comments</h6>
    <div>
      {iAmGranted(perms.create_support_case_comments) && (
        <div className="mb-3">
          <TextareaAutosize
            placeholder="Add a comment..."
            className="form-control"
            onChange={handleCommentChange}
            onFocus={() => { setIsEditModeActive(true) }}
            value={comment ?? ""} maxRows={15}
            minRows={isEditModeActive ? 2 : 1} />
          {isEditModeActive && <div className="pt-3 d-flex">
            <Button type="button" color="primary" className="me-2 disabled pe-auto" onClick={handleClearComment}>
              Cancel
            </Button>
            <Button type="button" color="primary" onClick={handleSaveComment} disabled={isSaveInProgress}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
          </div>}
        </div>
      )}
      {isLoadInProgress && <SpinnerChase className="sm my-2" />}
      {commentsError && <Alert color="danger" className="fade show text-center mb-4">
        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load comments
      </Alert>}
      {!isLoadInProgress && !commentsError && !comments?.length && "No comments found"}
      {!commentsError && comments.map(comment => (<Comment key={comment.id} comment={comment} removeComment={removeComment} />))}
    </div>
  </div>
}

ViewComments.propTypes = {
  supportCase: PropTypes.object
};

export default ViewComments;
