import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, UncontrolledPopover, PopoverBody, Progress, Alert } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import SpinnerChase from "components/Shared/SpinnerChase";
import { formatTimestamp, formats } from "helpers/dateHelper";
import RatingTooltip from "react-rating-tooltip";
import { sum } from "lodash";
import { route, routes } from "helpers/routeHelper";
import { getNotaryRatings, getNotaryRating } from "helpers/backendHelper";
import EditRating from "pages/Order/Partial/Form/EditRating";
import { perms, useAccess } from "context/access";

const RatingCount = ({ count }) => {
  // reverse the order of the array and convert string scores to numbers
  const reversedRatings = count.map(value => +value).reverse();
  const countSum = sum(reversedRatings);

  return <div className="rating-count-wrapper">
    {reversedRatings.map((rating, index) => (
      <div key={index} className="rating-count-container">
        <div className="score-col">{5 - index} star</div>
        <Progress className="progress-col" color="warning" value={rating / countSum * 100} />
        <div className="count-col">({rating})</div>
      </div>
    ))}
  </div>
}

RatingCount.propTypes = {
  count: PropTypes.array,
}

const SectionReviews = props => {

  const { id, notary } = props;

  const { iAmGranted } = useAccess();

  const [ratings, setRatings] = useState([]);
  const [ratingsError, setRatingsError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [resultsNotary, setResultsNotary] = useState([]);
  const [resultsError, setResultsError] = useState(null);
  const [isResultsLoadInProgress, setIsResultsLoadInProgress] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [selectedRating, setSelectedRating] = useState();

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshReviews();
  }, []);

  useEffect(() => {
    getNotaryRatingResults(id);
  }, [id]);

  /********** OTHER **********/

  /**
  * Fetches credential doc list from API
  */
  const refreshReviews = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getNotaryRatings(id)
      .then(response => {
        setRatings(response.ratings);
      })
      .catch(ex => {
        setRatingsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  /**
  * Fetches credential doc list from API
  */
  const getNotaryRatingResults = () => {
    setIsResultsLoadInProgress(true);
    // make the initial remote call to get the user data
    getNotaryRating(id)
      .then(response => {
        setResultsNotary(response);
      })
      .catch(ex => {
        setResultsError(ex);
      })
      .finally(() => {
        setIsResultsLoadInProgress(false);
      });
  };


  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>
            <Row>
              <Col xs={12} md="auto">
                Notary Review
              </Col>
              <Col xs="auto" className="ps-4">
                {!isResultsLoadInProgress && !!resultsNotary?.ratings?.general?.score && <span>{resultsNotary.ratings.general.score}</span>}
              </Col>
              <Col xs="auto" className="col-rating">
                {!isResultsLoadInProgress && !!resultsNotary?.ratings?.general?.score && <RatingTooltip
                  max={5}
                  defaultRating={parseInt(resultsNotary.ratings.general.score)}
                  disabled
                  ActiveComponent={<i className="mdi mdi-star-outline text-warning" />}
                  InActiveComponent={<i className="mdi mdi-star-outline text-muted" />}
                />}
              </Col>
            </Row>
          </CardTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isResultsLoadInProgress && !resultsError && resultsNotary && <div className="d-flex flex-wrap">
            <div className="badge rounded-pill bg-primary rating-pills" id="Timingdismiss">
              Timing <span className="score">{!!resultsNotary.ratings?.timing?.score && resultsNotary.ratings.timing.score}<i className="mdi mdi-star-outline mb-2 rating-star" /></span>
            </div>
            <UncontrolledPopover
              trigger="hover"
              target="Timingdismiss"
              placement="bottom"
            >
              <PopoverBody className="rating-count-popover">
                <RatingCount count={resultsNotary.ratings?.timing?.count} />
              </PopoverBody>
            </UncontrolledPopover>
            <div className="badge rounded-pill bg-primary rating-pills" id="Qualitydismiss">
              Quality of work <span className="score">{!!resultsNotary.ratings?.quality?.score && resultsNotary.ratings.quality.score}<i className="mdi mdi-star-outline mb-2 rating-star" /></span>
            </div>
            <UncontrolledPopover
              trigger="hover"
              target="Qualitydismiss"
              placement="bottom"
            >
              <PopoverBody className="rating-count-popover">
                <RatingCount count={resultsNotary.ratings?.quality?.count} />
              </PopoverBody>
            </UncontrolledPopover>
            <div className="badge rounded-pill bg-primary rating-pills" id="Communicationdismiss">
              Communication <span className="score">{!!resultsNotary.ratings?.communication?.score && resultsNotary.ratings.communication.score}<i className="mdi mdi-star-outline mb-2 rating-star" /></span>
            </div>
            <UncontrolledPopover
              trigger="hover"
              target="Communicationdismiss"
              placement="bottom"
            >
              <PopoverBody className="rating-count-popover">
                <RatingCount count={resultsNotary.ratings?.communication?.count} />
              </PopoverBody>
            </UncontrolledPopover>
            <div className="badge rounded-pill bg-primary rating-pills" id="Responsivenessdismiss">
              Responsiveness <span className="score">{!!resultsNotary.ratings?.responsiveness?.score && resultsNotary.ratings.responsiveness.score}<i className="mdi mdi-star-outline mb-2 rating-star" /></span>
            </div>
            <UncontrolledPopover
              trigger="hover"
              target="Responsivenessdismiss"
              placement="bottom"
            >
              <PopoverBody className="rating-count-popover">
                <RatingCount count={resultsNotary.ratings?.responsiveness?.count} />
              </PopoverBody>
            </UncontrolledPopover>
            <div className="badge rounded-pill bg-primary rating-pills" id="Availabilitydismiss">
              Availability <span className="score">{!!resultsNotary.ratings?.availability?.score && resultsNotary.ratings.availability.score}<i className="mdi mdi-star-outline mb-2 rating-star" /></span>
            </div>
            <UncontrolledPopover
              trigger="hover"
              target="Availabilitydismiss"
              placement="bottom"
            >
              <PopoverBody className="rating-count-popover">
                <RatingCount count={resultsNotary.ratings?.availability?.count} />
              </PopoverBody>
            </UncontrolledPopover>
          </div>}
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row>
        <Col>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table zebra-striped-table new-uploaded-docs mb-0 bb-0">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th data-priority="1">Order ID</Th>
                    <Th data-priority="2">Rating</Th>
                    <Th data-priority="3">Date of comment</Th>
                    <Th data-priority="4">Positive comments</Th>
                    <Th data-priority="5">Negative comments</Th>
                    {iAmGranted(perms.edit_notary_rating) && <Th data-priority="6">Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadInProgress && <Tr>
                    <Td colSpan="8">
                      <SpinnerChase className="sm mt-2 mb-2" />
                    </Td>
                  </Tr>}
                  {ratingsError && <Tr>
                    <Td colSpan="8">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load reviews
                      </Alert>
                    </Td>
                  </Tr>}
                  {!isLoadInProgress && !ratingsError && !ratings.length && <Tr>
                    <Td colSpan="8" className="text-center">No reviews</Td>
                  </Tr>}
                  {!isLoadInProgress && !ratingsError && ratings.map((rating, index) => <Tr key={index}>
                    <Th className="co-name">{index + 1}</Th>
                    <Td><Link to={route(routes.view_order, rating.orderId)}>{rating.orderId}</Link></Td>
                    <Td>{rating.general}<i className="mdi mdi-star-outline text-warning font-size-18"/></Td>
                    <Td>{formatTimestamp(rating.createdTs, formats.DATE_PT_FORMAT)}</Td>
                    <Td>{rating.positiveComments || '---'}</Td>
                    <Td>{rating.negativeComments || '---'}</Td>
                    {iAmGranted(perms.edit_notary_rating) && <Td><button type="button" className="btn btn-primary" onClick={() => { tog_center(); setSelectedRating(rating)}}>Edit</button></Td>}
                  </Tr>)}
                </Tbody>
              </Table>
              <EditRating isModalOpen={modal_center} toggleModal={() => { tog_center();}} defaultRating = {selectedRating} notary={notary} refreshReviews={refreshReviews}/>
            </div>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>)
}

SectionReviews.propTypes = {
  id: PropTypes.number,
  notary: PropTypes.object,
};

export default SectionReviews;