import { combineReducers } from "redux";
import {

  GET_ADDITIONAL_FEE_LIST,
  GET_ADDITIONAL_FEE_LIST_OK,
  GET_ADDITIONAL_FEE_LIST_ERR,
  DO_ADDITIONAL_FEE_LIST_CLEANUP,

} from './actionTypes';

/********* LIST *********/

const defaultListState = {
  additionalFees: [],
  additionalFeesError: null,
  isLoadInProgress: false,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_FEE_LIST:
      state = {
        ...state,
        additionalFeesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ADDITIONAL_FEE_LIST_OK:
      state = {
        ...state,
        additionalFees: action.payload.response.additionalFees,
        isLoadInProgress: false,
      };
      break
    case GET_ADDITIONAL_FEE_LIST_ERR:
      state = {
        ...state,
        additionalFees: [],
        additionalFeesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ADDITIONAL_FEE_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  List,
});