import { takeEvery, put, call } from "redux-saga/effects";

import {
  getPaymentPlanDtOk,
  getPaymentPlanDtErr,
  getPaymentPlanListOk,
  getPaymentPlanListErr,
} from "./actions";

import {
  getPaymentPlanDt,
  getPaymentPlans,
} from "helpers/backendHelper";

import {
  GET_PAYMENT_PLAN_DT,
  GET_PAYMENT_PLAN_LIST,
} from "./actionTypes";

/********** DATATABLE **********/

function* onGetPaymentPlanDt({ payload: { params } }) {
  try {
    const response = yield call(getPaymentPlanDt, params);
    yield put(getPaymentPlanDtOk(response));
  } catch (error) {
    yield put(getPaymentPlanDtErr(error));
  }
}

/********** LIST **********/

function* onGetPaymentPlanList() {
  try {
    const response = yield call(getPaymentPlans);
    yield put(getPaymentPlanListOk(response));
  } catch (error) {
    yield put(getPaymentPlanListErr(error));
  }
}

function* paymentPlanSaga() {
  yield takeEvery(GET_PAYMENT_PLAN_DT, onGetPaymentPlanDt);
  yield takeEvery(GET_PAYMENT_PLAN_LIST, onGetPaymentPlanList);
}

export default paymentPlanSaga;