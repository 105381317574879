import React, { useEffect, useState } from 'react';
import { useAccess } from 'context/access';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, Button } from "reactstrap";
import SpinnerChase from 'components/Shared/SpinnerChase';
import config from 'config';
import { getDealerGroupStoresDetails } from "helpers/backendHelper";
import { route, routes } from 'helpers/routeHelper';

const SectionStores = ({id}) => {

  /********** STATE **********/

  const [stores, setStores] = useState([]);
  const [storesError, setStoresError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getStoresList();
  }, []);

  /********** EVENT HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(expanded => !expanded);

  /********** OTHER **********/

  const getTableRows = () => isExpanded ? stores : stores?.slice(0, config.COLLAPSED_TBL_ROWS);

  const canExpand = () => !isExpanded && stores.length > config.COLLAPSED_TBL_ROWS;

  const canCollapse = () => isExpanded;

  const getStoresList = () => {
    setIsLoadInProgress(true);
    getDealerGroupStoresDetails(id)
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Stores Included</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Store</th>
              <th>Store manager</th>
              <th>Number of orders</th>
            </tr>
          </thead>
          <tbody>
            {getTableRows().length > 0 && getTableRows().map((entry, index) => (
              <tr key={index}>
                <td><Link to={route(routes.view_dealer_store, entry.id)}>{entry.name}</Link></td>
                <td>{entry.managerId ? <Link to={route(routes.view_user, entry.managerId)}>{entry.managerFullName}</Link> : '---'}</td>
                <td>{entry.orderCount}</td>
              </tr>
            ))}
            {getTableRows().length == 0 && !storesError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">No stores found</td>
            </tr>}
            {!!storesError && <tr>
              <td className="table-empty" colSpan="3">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load managers
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="text-center">
          {canExpand() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1"></i>View more</Button>}
          {canCollapse() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-up me-1"></i>View less</Button>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionStores.propTypes = {
  id: PropTypes.number,
}

export default SectionStores;