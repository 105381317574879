import {
  APPLY_DEALER_STATEMENT_DT_FILTERS,
  CLEAR_DEALER_STATEMENT_DT_FILTERS,
  DO_DEALER_STATEMENT_DT_CLEANUP,
  GET_DEALER_STATEMENT_DT,
  GET_DEALER_STATEMENT_DT_ERR,
  GET_DEALER_STATEMENT_DT_OK,
} from "./actionTypes";

/********** DATATABLE **********/

export const getDealerStatementDt = params => ({
  type: GET_DEALER_STATEMENT_DT,
  payload: { params },
});

export const getDealerStatementDtOk = response => ({
  type: GET_DEALER_STATEMENT_DT_OK,
  payload: { response },
});

export const getDealerStatementDtErr = error => ({
  type: GET_DEALER_STATEMENT_DT_ERR,
  payload: { error },
});

export const doDealerStatementDtCleanup = () => ({
  type: DO_DEALER_STATEMENT_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyDealerStatementDtFilters = filters => ({
  type: APPLY_DEALER_STATEMENT_DT_FILTERS,
  payload: { filters },
});

export const clearDealerStatementDtFilters = () => ({
  type: CLEAR_DEALER_STATEMENT_DT_FILTERS,
});