import React from "react"
import PropTypes from 'prop-types';

const UsageReportTableHeader = ({ title }) => {

  return <React.Fragment>
    <div className="usage-report-header-parent">
      <div className="usage-report-header-main">{title}</div>
      <div className="usage-report-header">
        <div className="header-qty">Qty</div>
        <div className="header-amt">Amt</div>
      </div>
    </div>
  </React.Fragment>
}

UsageReportTableHeader.propTypes = {
  title: PropTypes.string,
}

export default UsageReportTableHeader;
