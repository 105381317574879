import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { doOrderActivityCleanup, doOrderSingleCleanup, getOrderActivity, getOrderWithCustomerSigners } from "store/actions";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import ActivityEntry from "components/Shared/ActivityEntry";

const ViewActivity = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { logs, logsError, isLoadInProgress: isLogLoadInProgress } = useSelector(state => state.Order.Activity);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  useEffect(() => {
    if (!order) {
      return;
    }
    dispatch(getOrderActivity(order.id));
    return () => {
      dispatch(doOrderActivityCleanup());
    }
  }, [order]);

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getOrderWithCustomerSigners(id));

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <div className="card-title mt-2 mb-3">Activity Log</div>
                </CardHeader>
                <CardBody className="pb-5">
                  {!!logs.length && <ul className="verti-timeline list-unstyled">
                    {logs.map(log => <ActivityEntry key={log._id} log={log} />)}
                  </ul>}
                  {isLogLoadInProgress && <SpinnerChase />}
                  {logsError && <Error error={logsError} title404="Order not found" />}
                  {!logs.length && !isLogLoadInProgress && !logsError && <p>No entries found</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: 'Activity',
}];

export default ViewActivity;