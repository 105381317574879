import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button, Label, FormFeedback, Input } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { doOrderDocFormCleanup, uploadOrderDoc, addOrderDoc } from "store/actions";
import { capitalize, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import {
  ServerErrorException,
  UnprocessableEntityException,
  ValidationException,
  UNABLE_SEND_UPLOADED_DOCS_NOTIF,
  UNABLE_START_ORDER_DOC_PROCESSING,
  UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY,
  UPLOADED_FILE_IS_ENCRYPTED,
  UNABLE_TO_VERIFY_UPLOADED_FILE,
} from "helpers/errorHelper";
import Order from "model/order";
import OrderDoc from "model/orderDoc";
import EventEmitter from 'helpers/eventsHelper';
import TextareaAutosize from "react-textarea-autosize";

const FormNewDocumentsFile = forwardRef((props, ref) => {

  const { id, finishedHandler, cancelHandler, eSignEnabled, inkSignEnabled, hasNotary, docDeliveryOption, forceDocType } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const { isUploadInProgress, uploaded, uploadError } = useSelector(state => state.OrderDoc.Form);

  const [uploadProgress, setUploadProgress] = useState(0);

  const progressReceived = ev => setUploadProgress(Math.round(ev.loaded / ev.total * 100));

  /********** FORM CONFIG **********/

  const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];

  useImperativeHandle(ref, () => {
    return {
      formikValues: formik.values,
      formikInstance: formik,
    };
  })

  const formInitialValues = {
    docType: forceDocType || '',
    docName: '',
    file: '',
    numOfSignatures: '',
    numOfInitials: '',
    numOfPages: '',
    isNotarizationRequired: '',
    signingInstructions: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      docType: Yup.number().required('Field is required'),
      docName: Yup.string().required('Field is required').max(50, "Document name should be 50 characters or less."),
      numOfPages: Yup.number()
        .when('docType', {
          is: val => !shouldUploadFile(val),
          then: Yup.number().required('Field is required'),
        }),
      file: Yup.mixed()
        .when('docType', {
          is: val => shouldUploadFile(val),
          then: Yup.mixed().required('Field is required'),
        })
        .test('fileType', 'Only pdf and images are accepted', selectedFiles => {
          if (!selectedFiles) {
            // Yup runs validators in parallel which means that test() is performed even if the required() validator fails
            return true;
          }
          return [...selectedFiles].every(file => !!file && allowedFileTypes.includes(file.type));
        })
        .test('fileNum', 'All selected files must be of the same type, either pdf or images', selectedFiles => {
          if (!selectedFiles) {
            return true;
          }
          const fileTypes = new Set([...selectedFiles].map(file => {
            // for the purpose of this validation, we only need to distinguish between pdf and images
            // therefore we consider all image extensions to be the same file type 'image'
            return file.type.startsWith('image/') ? 'image' : file.type;
          }));
          return fileTypes.size == 1;
        })
        .test('pdfNum', 'When choosing pdf, only 1 file can be selected', selectedFiles => {
          if (!selectedFiles) {
            return true;
          }
          return [...selectedFiles].filter(file => file.type == 'application/pdf').length <= 1;
        }),
    }),
    onSubmit: values => {
      const formData = new FormData();
      formData.append('docType', values.docType);
      formData.append('docName', values.docName);
      formData.append('numOfPages', values.numOfPages);
      formData.append('numOfSignatures', values.numOfSignatures);
      formData.append('numOfInitials', values.numOfInitials);
      formData.append('signingInstructions', values.signingInstructions);
      if (values.isNotarizationRequired) {
        formData.append('isNotarizationRequired', '1');
      } else {
        formData.append('isNotarizationRequired', '0');
      }
      if (!!values.file) {
        for (const file of [...values.file]) {
          formData.append('file', file);
        }
      }
      if (shouldUploadFile(values.docType)) {
        dispatch(uploadOrderDoc(formData, id));
      } else {
        dispatch(addOrderDoc(formData, id));
      }
    },
  });

  const isInkDocSelected = formik.values.docType == OrderDoc.TYPE_INK_SIGNED;
  const instructionsLabel = (hasNotary && isInkDocSelected) ? 'Special Instructions to the Notary' : 'Special Instructions';

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    EventEmitter.on('orderDoc.uploadProgress', progressReceived);
    return () => {
      EventEmitter.off('orderDoc.uploadProgress', progressReceived);
      // state cleanup on component unmount
      dispatch(doOrderDocFormCleanup());
    }
  }, []);

  // runs whenever the 'uploaded' flag changes
  // which happens after a upload-doc attempt
  useEffect(() => {
    if (uploaded === true) {
      showSuccess('Document has been uploaded');
      // finishedHandler will re-render the form
      // before the unmnount cleanup has a chance to complete
      // so we run cleanup here manually
      dispatch(doOrderDocFormCleanup());
      // notify parent component of the upload
      finishedHandler();
    } else if (uploaded === false) {
      // enable the save button
      formik.setSubmitting(false);
      if (uploadError instanceof ServerErrorException) {
        if (uploadError.code == UNABLE_START_ORDER_DOC_PROCESSING) {
          // doc has been uploaded but the processing could not be started
          // if we fail here then the new doc will not appear in the list of uploaded docs
          // so we want to treat it as a successfull upload
          // and let it have the 'processing error' status
          showSuccess('Document has been uploaded');
          // clean up the form
          dispatch(doOrderDocFormCleanup());
          // perform finish tasks
          finishedHandler();
          return;
        } else if (uploadError.code == UNABLE_SEND_UPLOADED_DOCS_NOTIF) {
          // doc has been uploaded but the notifications could not be sent (at least some of them)
          // we want to make this distinction because the owner might want to resend notifications manually
          showError('Unable to send notifications');
          // clean up the form
          dispatch(doOrderDocFormCleanup());
          // perform finish tasks
          finishedHandler();
          return;
        } else if (uploadError.code == UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY) {
          // this document name already exist
          showError(`Document ${formik.values?.docName} already exists`);
          return;
        }
      } else if (uploadError instanceof UnprocessableEntityException) {
        if (uploadError.code == UPLOADED_FILE_IS_ENCRYPTED) {
          showError('The file is encrypted which prevents editing including signing');
          return;
        } else if (uploadError.code == UNABLE_TO_VERIFY_UPLOADED_FILE) {
          showError('The file cannot be verified');
          return;
        }
      } else if (uploadError instanceof ValidationException) {
        // the save failed due to validation
        // so show an error on each invalid field
        for (const [name, message] of Object.entries(uploadError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      showError('Unable to upload document');
    }
  }, [uploaded]);

  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    if (name == 'docType') {
      // when changing the document type, we should also clear the errors on the dependent fields
      delete errors.numOfPages;
      delete errors.file;
    }
    formik.setStatus(errors);
  }

  const handleNotarizationRequired = event => {
    const isChecked = event.target.checked;
    formik.setFieldValue('isNotarizationRequired', !!isChecked);

    if (!isChecked) {
      formik.setFieldValue('isNotarizationRequired', 0);
    }
  };

  const handleCancel = () => {
    // reset form fieldss
    formik.setValues({
      ...formik.initialValues,
      file: '',
    });
    formik.setTouched({});
    formik.setErrors({});
    const fileInput = document.getElementById("file");
    if (fileInput) {
      fileInput.value = "";
    }
    if (!!cancelHandler) {
      cancelHandler();
    }
  };

  /********** OTHER **********/

  const getDocTypeOptions = () => toSelectOptions(Object.entries(OrderDoc.getTypes()), 0, 1).filter(option => {
    switch (parseInt(option.value)) {
      case OrderDoc.TYPE_E_SIGNED:
        // e-sign option should not be available if e-sign is not required
        return eSignEnabled;
      case OrderDoc.TYPE_INK_SIGNED:
        // ink-sign option should not be available if ink-sign is not required
        return inkSignEnabled;
      default:
        return true;
    }
  });

  /**
   * Returns TRUE when the form should contain a file upload
   * This depends on the selected delivery option and document type
   * @param {number} docType
   * @returns {boolean}
   */
  const shouldUploadFile = docType => docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD || docType != OrderDoc.TYPE_INK_SIGNED;

  const colSize1 = shouldUploadFile(formik.values.docType) ? 5 : 8;

  return <React.Fragment>
    <Row className="g-0">
      <div className="font-size-11 mb-3 order-docs-info d-flex flex-direction-row">
        <i className="mdi mdi-information-outline me-1" />
        <div className="d-flex align-items-center">Please list all document titles as they are shown on each form. Please do not use acronyms or shortened title references/terms.</div>
      </div>
    </Row>
    <Form>
      <Row>
        <Col xl="4">
          <Row className="mb-4">
            <Col className="mt-0">
              <Label>Document name *</Label>
              <Input
                type="text"
                className="form-control"
                name="docName"
                onChange={capitalizeTextOnChange}
                onFocus={onFieldFocused}
                value={formik.values.docName}
                invalid={!!formik.errors.docName}
              />
              {!!formik.errors.docName && <FormFeedback type="invalid">{formik.errors.docName}</FormFeedback>}
            </Col>
          </Row>
        </Col>
        <Col xl={colSize1}>
          <Row className="mb-4">
            {!forceDocType && <Col>
              <Label>Document type *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="docType"
                id="docType"
                options={getDocTypeOptions()}
                onChange={selected => formik.setFieldValue('docType', selected.value)}
                onFocus={e => onFieldFocused(e, 'docType')}
                value={getDocTypeOptions().find(option => option.value === formik.values.docType)}
                className={!!formik.errors.docType && 'is-invalid'} />
              {!!formik.errors.docType && <FormFeedback type="invalid">{formik.errors.docType}</FormFeedback>}
            </Col>}
            {!shouldUploadFile(formik.values.docType) && <Col>
              <Label>No of pages *</Label>
              <Input
                type="number"
                min="1"
                name="numOfPages"
                className="form-control"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.numOfPages}
                invalid={!!formik.errors.numOfPages}
              />
              {!!formik.errors.numOfPages && <FormFeedback type="invalid">{formik.errors.numOfPages}</FormFeedback>}
            </Col>}
            <Col>
              <Label>No of signatures</Label>
              <Input
                type="number"
                min="1"
                name="numOfSignatures"
                className="form-control"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.numOfSignatures}
                invalid={!!formik.errors.numOfSignatures}
              />
              {!!formik.errors.numOfSignatures && <FormFeedback type="invalid">{formik.errors.numOfSignatures}</FormFeedback>}
            </Col>
            <Col>
              <Label>No of initials</Label>
              <Input
                type="number"
                min="1"
                name="numOfInitials"
                className="form-control"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.numOfInitials}
                invalid={!!formik.errors.numOfInitials}
              />
              {!!formik.errors.numOfInitials && <FormFeedback type="invalid">{formik.errors.numOfInitials}</FormFeedback>}
            </Col>
          </Row>
        </Col>
        {shouldUploadFile(formik.values.docType) && <Col xl="3">
          <Row className="mb-4">
            <Label>Upload file *</Label>
            <Col>
              <Input
                type="file"
                multiple
                accept="image/*, application/pdf"
                className="form-control"
                name="file"
                id="file"
                onChange={e => formik.setFieldValue('file', e.currentTarget.files)}
                onFocus={onFieldFocused}
                invalid={!!formik.errors.file} />
              {!formik.errors.file && <small className="form-text text-muted">Select either a single PDF or multiple images</small>}
              {!!formik.errors.file && <FormFeedback type="invalid">{formik.errors.file}</FormFeedback>}
            </Col>
          </Row>
        </Col>}
      </Row>
      <Row>
        <Row className="mb-3">
          <Col xl="4">
            <Label>{instructionsLabel}:</Label>
            <TextareaAutosize
              minRows={4}
              maxRows={10}
              className={'form-control'}
              name="signingInstructions"
              onChange={capitalizeTextOnChange}
              onFocus={onFieldFocused}
              value={formik.values.signingInstructions} />
          </Col>
        </Row>
        {(hasNotary && isInkDocSelected) && (<Row className="mb-4">
          <Col>
            <Input
              type="checkbox"
              className="form-check-input"
              id="isNotarizationRequired"
              name="isNotarizationRequired"
              onChange={handleNotarizationRequired}
              defaultChecked={formik.values.isNotarizationRequired}
            />
            <Label htmlFor="isNotarizationRequired" className='ms-2'>Notarize this document</Label>
          </Col>
        </Row>)}
      </Row>
      <Row className="d-flex text-end mb-4">
        <Col>
          <Button type="button" color="secondary" className="mx-2" onClick={() => handleCancel()} >
            <span>Cancel</span>
          </Button>
          <Button type="button" color="primary" className="progress-button" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
            <div className="btn-progress-bar" style={{ width: `${uploadProgress}%` }}></div>
            <span>{isUploadInProgress && <i className="mdi mdi-spin mdi-loading me-1" />} + Add Document</span>
          </Button>
        </Col>
      </Row>
    </Form>
  </React.Fragment >
});

FormNewDocumentsFile.propTypes = {
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  eSignEnabled: PropTypes.bool,
  inkSignEnabled: PropTypes.bool,
  forceDocType: PropTypes.number,
  hasNotary: PropTypes.bool,
  docDeliveryOption: PropTypes.number,
};

export default FormNewDocumentsFile;