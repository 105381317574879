import { takeEvery, put, call } from "redux-saga/effects";

import {
  DELETE_CREDENTIAL_LIST_ITEM,
  GET_CREDENTIAL_LIST,
  UPLOAD_CREDENTIAL,
  EDIT_CREDENTIAL
} from "./actionTypes";

import {
  getCredentialListOk,
  getCredentialListErr,

  deleteCredentialListItemOk,
  deleteCredentialListItemErr,

  uploadCredentialOk,
  uploadCredentialErr,

  editCredentialOk,
  editCredentialErr,
} from "./actions";

import {
  getNotaryCredentials,
  deleteNotaryCredential,
  uploadNotaryCredential,
  editNotaryCredential
} from "helpers/backendHelper";

/********** LIST **********/

function* onGetCredentials({ payload: { id } }) {
  try {
    const response = yield call(getNotaryCredentials, id);
    yield put(getCredentialListOk(response));
  } catch (error) {
    yield put(getCredentialListErr(error));
  }
}

function* onDeleteCredentialListItem({ payload: { id } }) {
  try {
    const response = yield call(deleteNotaryCredential, id);
    yield put(deleteCredentialListItemOk(id, response));
  } catch (error) {
    yield put(deleteCredentialListItemErr(id, error));
  }
}

/********** FORM **********/

function* onUploadCredential({ payload: { data, notaryId } }) {
  try {
    const response = yield call(uploadNotaryCredential, data, notaryId);
    yield put(uploadCredentialOk(response));
  } catch (error) {
    yield put(uploadCredentialErr(error));
  }
}

function* onEditCredential({ payload: { id, data, type } }) {
  try {
    const response = yield call(editNotaryCredential, id, data, type);
    yield put(editCredentialOk(response));
  } catch (error) {
    yield put(editCredentialErr(error));
  }
}

function* credentialSaga() {
  yield takeEvery(GET_CREDENTIAL_LIST, onGetCredentials);
  yield takeEvery(DELETE_CREDENTIAL_LIST_ITEM, onDeleteCredentialListItem);
  yield takeEvery(UPLOAD_CREDENTIAL, onUploadCredential);
  yield takeEvery(EDIT_CREDENTIAL, onEditCredential);
}

export default credentialSaga;