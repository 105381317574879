import { combineReducers } from "redux";

import {
  APPLY_SCHEDULER_REPORT_FILTERS,
  CLEAR_SCHEDULER_REPORT_FILTERS,
  DO_SCHEDULER_REPORT_CLEANUP,
  DO_SCHEDULER_REPORT_FILTERS_CLEANUP,
  GET_SCHEDULER_REPORT,
  GET_SCHEDULER_REPORT_ERR,
  GET_SCHEDULER_REPORT_OK,

  APPLY_GROUP_USAGE_REPORT_FILTERS,
  DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,
  CLEAR_GROUP_USAGE_REPORT_FILTERS,
  GET_GROUP_USAGE_REPORT,
  GET_GROUP_USAGE_REPORT_OK,
  GET_GROUP_USAGE_REPORT_ERR,
  DO_GROUP_USAGE_REPORT_CLEANUP,

  GET_DEALER_SALES_REPORT,
  GET_DEALER_SALES_REPORT_OK,
  GET_DEALER_SALES_REPORT_ERR,
  DO_DEALER_SALES_REPORT_CLEANUP,
  APPLY_DEALER_SALES_REPORT_FILTERS,
  CLEAR_DEALER_SALES_REPORT_FILTERS,
  DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
  GET_NEW_DEALERS_REPORT,
  GET_NEW_DEALERS_REPORT_OK,
  GET_NEW_DEALERS_REPORT_ERR,
  DO_NEW_DEALERS_REPORT_CLEANUP,
  APPLY_NEW_DEALERS_REPORT_FILTERS,
  CLEAR_NEW_DEALERS_REPORT_FILTERS,
  DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP,
} from "./actionTypes";

/********** SCHEDULER REPORT **********/

const defaultSchedulerReportState = {
  users: [],
  stats: {},
  reportError: null,
  listParams: {
    sortBy: null,
    sortDir: 'asc',
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const Scheduler = (state = defaultSchedulerReportState, action) => {
  switch (action.type) {
    case GET_SCHEDULER_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_SCHEDULER_REPORT_OK:
      state = {
        ...state,
        users: action.payload.response.users,
        stats: action.payload.response.stats,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_SCHEDULER_REPORT_ERR:
      state = {
        ...state,
        users: [],
        stats: {},
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_SCHEDULER_REPORT_CLEANUP:
      state = {
        ...state,
        users: [],
        stats: {},
        reportError: null,
      };
      break
  }
  return state;
}

/********** SCHEDULER REPORT FILTERS **********/

const defaultSchedulerFiltersState = {
  schedulerId: '',
  startTs: '',
  endTs: '',
};

const SchedulerFilters = (state = defaultSchedulerFiltersState, action) => {
  switch (action.type) {
    case APPLY_SCHEDULER_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_SCHEDULER_REPORT_FILTERS:
      state = {
        ...state,
        schedulerId: "",
      };
      break
    case DO_SCHEDULER_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultSchedulerFiltersState,
      };
      break
  }
  return state;
}

/********** GROUP USAGE REPORT **********/

const defaultGroupUsageReportState = {
  stores: [],
  stats: {},
  reportError: null,
  listParams: {
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const GroupUsage = (state = defaultGroupUsageReportState, action) => {
  switch (action.type) {
    case GET_GROUP_USAGE_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_GROUP_USAGE_REPORT_OK:
      state = {
        ...state,
        stores: action.payload.response.stores,
        stats: action.payload.response.stats,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_GROUP_USAGE_REPORT_ERR:
      state = {
        ...state,
        stores: [],
        stats: {},
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_GROUP_USAGE_REPORT_CLEANUP:
      state = {
        ...state,
        stores: [],
        stats: {},
        reportError: null,
      };
      break
  }
  return state;
}

/********** GROUP USAGE REPORT FILTERS **********/

const defaultGroupUsageFiltersState = {
  startTs: '',
  endTs: '',
  group: '',
  region: '',
  store: ''
};

const GroupUsageFilters = (state = defaultGroupUsageFiltersState, action) => {
  switch (action.type) {
    case APPLY_GROUP_USAGE_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_GROUP_USAGE_REPORT_FILTERS:
      state = {
        ...state,
        group: '',
        region: '',
        store: ''
      };
      break
    case DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultGroupUsageFiltersState,
      };
      break
  }
  return state;
}

/********** DEALER SALES REPORT **********/

const defaultDealerSalesReportState = {
  stores: [],
  reportError: null,
  listParams: {
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const DealerSales = (state = defaultDealerSalesReportState, action) => {
  switch (action.type) {
    case GET_DEALER_SALES_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_SALES_REPORT_OK:
      state = {
        ...state,
        stores: action.payload.response.stores,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_SALES_REPORT_ERR:
      state = {
        ...state,
        stores: [],
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_SALES_REPORT_CLEANUP:
      state = {
        ...state,
        stores: [],
        reportError: null,
      };
      break
  }
  return state;
}

/********** DEALER SALES REPORT FILTERS **********/

const defaultDealerSalesFiltersState = {
  startTs: '',
  endTs: '',
  group: '',
  region: '',
  store: ''
};

const DealerSalesFilters = (state = defaultDealerSalesFiltersState, action) => {
  switch (action.type) {
    case APPLY_DEALER_SALES_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_DEALER_SALES_REPORT_FILTERS:
      state = {
        ...state,
        group: '',
        region: '',
        store: ''
      };
      break
    case DO_DEALER_SALES_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultDealerSalesFiltersState,
      };
      break
  }
  return state;
}

/********** NEW DEALERS REPORT **********/

const defaultNewDealersReportState = {
  dealers: [],
  reportError: null,
  listParams: {
    filters: null,
    pageSize: 10,
    page: 1,
  },
  totalCount: 0,
  isLoadInProgress: false,
};

const NewDealers = (state = defaultNewDealersReportState, action) => {
  switch (action.type) {
    case GET_NEW_DEALERS_REPORT:
      state = {
        ...state,
        listParams: action.payload.params,
        reportError: null,
        isLoadInProgress: true,
      };
      break
    case GET_NEW_DEALERS_REPORT_OK:
      state = {
        ...state,
        dealers: action.payload.response.dealers,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_NEW_DEALERS_REPORT_ERR:
      state = {
        ...state,
        dealers: [],
        totalCount: 0,
        reportError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_NEW_DEALERS_REPORT_CLEANUP:
      state = {
        ...defaultNewDealersReportState
      };
      break
  }
  return state;
}

/********** NEW DEALERS REPORT FILTERS **********/

const defaultNewDealersFiltersState = {
  startTs: '',
  endTs: '',
};

const NewDealersFilters = (state = defaultNewDealersFiltersState, action) => {
  switch (action.type) {
    case APPLY_NEW_DEALERS_REPORT_FILTERS:
      state = {
        ...action.payload.filters,
      };
      break
    case CLEAR_NEW_DEALERS_REPORT_FILTERS:
      state = {
        ...state,
      };
      break
    case DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP:
      state = {
        ...defaultNewDealersFiltersState,
      };
      break
  }
  return state;
}

export default combineReducers({
  Scheduler,
  SchedulerFilters,
  GroupUsage,
  GroupUsageFilters,
  DealerSales,
  DealerSalesFilters,
  NewDealers,
  NewDealersFilters
});