import {
 GET_ORDER_FEES,
 GET_ORDER_FEES_OK,
 GET_ORDER_FEES_ERR,
 DO_ORDER_FEES_CLEANUP,

 GET_ORDER_AVAILABLE_FEES,
 GET_ORDER_AVAILABLE_FEES_OK,
 GET_ORDER_AVAILABLE_FEES_ERR,
 DO_ORDER_AVAILABLE_FEES_CLEANUP,

 UPDATE_DEALER_ACCOUNTING,
 UPDATE_NOTARY_ACCOUNTING,
 UPDATE_QUICKBOOK_ACCOUNTING,

 SAVE_DEALER_OK,
 SAVE_DEALER_ERR,
 DO_DEALER_FORM_CLEANUP,

 SAVE_NOTARY_OK,
 SAVE_NOTARY_ERR,
 DO_NOTARY_FORM_CLEANUP,

} from './actionTypes';

/********* SINGLE *********/

export const getOrderFees = id => ({
 type: GET_ORDER_FEES,
 payload: { id }
});

export const getOrderFeesOk = response => ({
 type: GET_ORDER_FEES_OK,
 payload: { response },
});

export const getOrderFeesErr = error => ({
 type: GET_ORDER_FEES_ERR,
 payload: { error },
});

export const doOrderFeesCleanup = () => ({
 type: DO_ORDER_FEES_CLEANUP,
});

/********* LIST *********/

export const getOrderAvailableFees = id => ({
 type: GET_ORDER_AVAILABLE_FEES,
 payload: { id }
});

export const getOrderAvailableFeesOk = response => ({
 type: GET_ORDER_AVAILABLE_FEES_OK,
 payload: { response },
});

export const getOrderAvailableFeesErr = error => ({
 type: GET_ORDER_AVAILABLE_FEES_ERR,
 payload: { error },
});

export const doOrderAvailableFeesCleanup = () => ({
 type: DO_ORDER_AVAILABLE_FEES_CLEANUP,
});

/********** FORM **********/

export const updateDealerAccounting = (data, id) => ({
 type: UPDATE_DEALER_ACCOUNTING,
 payload: { id, data },
});

export const updateNotaryAccounting = (data, id) => ({
 type: UPDATE_NOTARY_ACCOUNTING,
 payload: { id, data },
});

export const updateQuickbookAccounting = (data, id) => ({
 type: UPDATE_QUICKBOOK_ACCOUNTING,
 payload: { id, data },
});

export const saveDealerOk = response => ({
 type: SAVE_DEALER_OK,
 payload: { response },
});

export const saveDealerErr = error => ({
 type: SAVE_DEALER_ERR,
 payload: { error },
});

export const doDealerFormCleanup = () => ({
 type: DO_DEALER_FORM_CLEANUP,
});

export const saveNotaryOk = response => ({
 type: SAVE_NOTARY_OK,
 payload: { response },
});

export const saveNotaryErr = error => ({
 type: SAVE_NOTARY_ERR,
 payload: { error },
});

export const doNotaryFormCleanup = () => ({
 type: DO_NOTARY_FORM_CLEANUP,
});