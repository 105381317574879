import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const hours = Math.floor(countDown / (1000 * 60 * 60));
	const minutes = Math.floor((countDown / 1000 / 60) % 60);
	const seconds = Math.floor((countDown / 1000) % 60);

	return [hours, minutes, seconds];
};

export { useCountdown };
