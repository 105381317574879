import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "photoswipe/dist/photoswipe.css";
import { formats, formatTimestamp } from "helpers/dateHelper";
import pdfIcon from "assets/images/pdf-file.svg";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import { deleteOrderDocNormListItem, getOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { perms, useAccess } from "context/access";
import OrderDoc from "model/orderDoc";
import Confirmation from "components/Shared/Confirmation";

const FinanceDoc = ({ id, num, refreshListHandler, orderIsLocked }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isDeleteConfVisible, setIsDeleteConfVisible] = useState(false);

  /********** EFFECTS **********/

  // runs whenever an order doc is deleted
  useEffect(() => {
    if (orderDoc.isDeleted === true) {
      showSuccess(`Document "${orderDoc.name}" has been deleted`);
      refreshListHandler();
    } else if (orderDoc.isDeleted === false) {
      showError('Unable to delete document');
    }
  }, [orderDoc.isDeleted]);

  /********** SOCKET **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the right document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** EVENT HANDLERS **********/

  const removeOrderDoc = () => dispatch(deleteOrderDocNormListItem(orderDoc.id));

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  /********** OTHER **********/

  const isBusy = () => orderDoc.isDeleteInProgress;

  const canBeDeleted = () => iAmGranted(perms.edit_orders) && !orderIsLocked;

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const isProcessingFailed = () => orderDoc.status == OrderDoc.STATUS_INITIAL_PROCESSING_ERROR;

  return <>
    {orderDoc && <tr>
      <td title={'Id: ' + orderDoc.id}>{num}</td>
      <td>
        <img src={pdfIcon} className="ms-n1 me-1" />
        {orderDoc.name}
        {!isReady() && !isProcessingFailed() && <span className="badge badge-lg rounded-pill bg-warning ms-2">Processing</span>}
        {isProcessingFailed() && <span className="badge badge-lg rounded-pill bg-danger ms-2">Processing error</span>}
      </td>
      <td>{orderDoc.creatorFullName}</td>
      <td>{formatTimestamp(orderDoc.createdTs, formats.US_DATE)}</td>
      <td>{orderDoc.numOfPages}</td>
      <td className="doc-actions">
        <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)} className="btn-group">
          <DropdownToggle color="default" className="dropdown-toggle">
            {isBusy() && <i className="bx bx-loader-alt bx-spin" />}
            {!isBusy() && <i className="bx bx-dots-horizontal-rounded" />}
          </DropdownToggle>
          <DropdownMenu end>
            {orderDoc.isWithdrawn ? <React.Fragment>
              {/* Preview links of order documents become unavailable if isWithdrawn is true */}
              {isReady() && <><DropdownItem tag="span" id='view-doc'>View</DropdownItem><UncontrolledTooltip placement="top" target="view-doc">Document no longer available.</UncontrolledTooltip></>}
            </React.Fragment> :
              <React.Fragment>
                {isReady() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank">View</DropdownItem>}
              </React.Fragment>}
            {canBeDeleted() && <DropdownItem onClick={() => setIsDeleteConfVisible(true)} disabled={orderDoc.isDeleteInProgress}>Delete</DropdownItem>}
            {isProcessingFailed() && <DropdownItem onClick={restartProcessing}>Reprocess</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
        {isDeleteConfVisible && <Confirmation
          confirmBtnText="Delete"
          reverseButtons={false}
          onConfirm={() => {
            setIsDeleteConfVisible(false);
            removeOrderDoc();
          }}
          onCancel={() => setIsDeleteConfVisible(false)}>
          <span style={{ color: '#556EE6' }}>Are you sure you want to delete document &quot;{orderDoc.name}&quot;?</span>
        </Confirmation>}
      </td>
    </tr>}
  </ >
}

FinanceDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
  orderIsLocked: PropTypes.bool,
};

export default FinanceDoc;