import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InfoWindow, Map, Marker } from "google-maps-react";
import { useGoogleApi } from "context/google";
import { getMiles } from "helpers/utilHelper";
import { getOrderSigners } from "helpers/backendHelper";
import OrderSigner from "model/orderSigner";
import { useParams } from "react-router-dom";
import notaryMapIcon from 'assets/images/notary-map-icon.svg';
import customerMapIcon from 'assets/images/user-map-icon.svg';

// set a default center, otherwise the map is centered in the ocean
const defaultCenter = {
  lat: 33.59773919487583,
  lng: -117.6563644,
}

// component that shows multiple pins on the map
const GoogleMapsLocations = props => {

  let { id } = useParams();
  id = parseInt(id);

  const { locations, center } = props;

  const { google } = useGoogleApi();

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showInfoWindowCustomer, setShowInfoWindowCustomer] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeMarkerCustomer, setActiveMarkerCustomer] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const [customersLocations, setCustomersLocations] = useState([]);

  let bounds = new google.maps.LatLngBounds();

  // we need to extend the bounds in order to "zoom out" and cover all markers
  // this effect is obtain by calling the `fitBounds` method when map is ready
  for (var i = 0; i < locations.length; i++) {
    bounds.extend({ lat: locations[i].latitude, lng: locations[i].longitude });
  }

  useEffect(() => {
    getOrderSigners(id, OrderSigner.TYPE_CUSTOMER).then((data) => {
      if (data?.signers) {
        const result = [];
        data.signers.map((signer) => result.push({
          latitude: signer.latitude,
          longitude: signer.longitude,
          customerName: signer.fullName
        }));
        setCustomersLocations(result);
      }
    });
  }, []);


  const onMarkerClicked = (props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  }

  const onMarkerClickedCustomer = (props, marker) => {
    setActiveMarkerCustomer(marker);
    setShowInfoWindowCustomer(true);
  }

  const onMapClicked = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    if (showInfoWindowCustomer) {
      setShowInfoWindowCustomer(false);
      setActiveMarkerCustomer({});
    }
  }
  // when map is ready, zoom out to center bounds
  const onMapReady = (_mapProps, map) => {
    // don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
      var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    map.fitBounds(bounds);
  };

  return (
    <div
      id="gmaps-markers"
      className="gmaps"
      style={{ position: "relative" }}
    >
      <Map
        google={google}
        style={{ width: "100%", height: "100%" }}
        zoom={14}
        onClick={onMapClicked}
        onReady={onMapReady}
        initialCenter={center ? { lat: center.latitude, lng: center.longitude } : defaultCenter}
      >
        {locations.map((location, index) => (
          <Marker
            key={index}
            notaryName={location.notaryName}
            notaryRating={location.notaryRating}
            distance={location.distance}
            onClick={onMarkerClicked}
            position={{ lat: location.latitude, lng: location.longitude }}
            icon={{
              url: notaryMapIcon, //custom icon for notary
              scaledSize: new google.maps.Size(55, 65), // icon size
            }}
          />))}
        {customersLocations.map((customer, index) => (
          <Marker
            key={index}
            customerName={customer.customerName}
            icon={{ url: customerMapIcon, scaledSize: new google.maps.Size(55, 65) }}
            onClick={onMarkerClickedCustomer}
            position={{ lat: customer.latitude, lng: customer.longitude }}
          />
        ))}
        <InfoWindow visible={showInfoWindow} marker={activeMarker}>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mt-2 me-2">{selectedPlace.notaryName}</h5>
              {!!selectedPlace.notaryRating && (
                <div>
                  {selectedPlace.notaryRating}
                  <i className="mdi mdi-star-outline text-warning ms-1" />
                </div>
              )}
            </div>
            <h6>{`${getMiles(selectedPlace.distance)} miles away`}</h6>
          </div>
        </InfoWindow>
        <InfoWindow visible={showInfoWindowCustomer} marker={activeMarkerCustomer}>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mt-2 me-2">{activeMarkerCustomer.customerName}</h5>
            </div>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

GoogleMapsLocations.propTypes = {
  center: PropTypes.object,
  locations: PropTypes.array,
}

export default GoogleMapsLocations;