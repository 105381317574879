import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Row, Col, Alert, CardHeader, CardTitle } from "reactstrap";
import Select from "react-select";
import SpinnerChase from "components/Shared/SpinnerChase";
import { smallSelectStyles } from "helpers/utilHelper";
import { getCurrentMonthInteger, getMonthFromTs, getMonthOptions } from "helpers/dateHelper";
import { getAccountManagerNewStores } from "helpers/backendHelper";

const Stores = () => {

  /********** STATE **********/

  const [dealerStores, setDealerStores] = useState({});
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [dealerStoresError, setDealerStoresError] = useState(null);
  // calculating the month options can be a costly operation if we do it at every re-render
  // we can optimize it by memoizing the result of the function
  const monthOptions = useMemo(getMonthOptions, []);
  // default createdBetween filter covers the current month range
  const [createdBetween, setCreatedBetween] = useState(monthOptions[monthOptions.length - 1].interval.join("-"));
  // we need to convert the createdBetween string to the month integer it represents in order to easily manipulate it
  const selectedMonth = getMonthFromTs(createdBetween.split("-")[0]);

  /********** EFFECTS **********/

  // runs anytime month filter changes
  // including at component mount
  useEffect(() => {
    // fetch new dealer stores
    getDealerStores();
  }, [selectedMonth]);

  /********** HANDLERS **********/

  const handleMonthChange = selected => {
    setCreatedBetween(selected.interval.join("-"));
  };

  /********** OTHER **********/

  const getDealerStores = () => {
    setIsLoadInProgress(true);
    getAccountManagerNewStores({ filters: { createdBetween } })
      .then(response => {
        setDealerStores(response.dealerStores);
      })
      .catch(ex => {
        setDealerStoresError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  };

  return <>
    <Card className="py-2">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col xs={10}>
            <CardTitle>New Stores</CardTitle>
          </Col>
          <Col xs={2} className="mb-1">
            <Select
              classNamePrefix="select2-selection"
              styles={smallSelectStyles}
              options={monthOptions}
              onChange={handleMonthChange}
              value={monthOptions.find(option => option.value === selectedMonth)}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead className="vertical-align-middle">
            <tr>
              <th>Store Name</th>
              <th>Store Manager</th>
              <th>Store Address</th>
              <th>Store Group</th>
              <th>Store Region</th>
              <th>No. of Orders</th>
            </tr>
          </thead>
          <tbody>
            {dealerStores.length > 0 && dealerStores.map((entry, index) => (
              <tr key={index}>
                <td>{entry.name}</td>
                <td>{entry.managerFullName || "--"}</td>
                <td>{entry.address || "--"}</td>
                <td>{entry.dealerGroupName || "--"}</td>
                <td>{entry.dealerRegionName || "--"}</td>
                <td>{entry.numOfOrders}</td>
              </tr>
            ))}
            {dealerStores.length === 0 && <tr>
              <td className="table-empty" colSpan="6">No stores found</td>
            </tr>
            }
            {!!dealerStoresError && <tr>
              <td className="table-empty" colSpan="6">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stores
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="6">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </>
}

export default Stores;