import React, { useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";
import OrderDoc from "model/orderDoc";
import { getBeUrl } from "helpers/utilHelper";
import { useSocketOn, useSubscribeToOrderDoc } from "hooks/socket";
import socketEvent from 'constants/socketEvent';
import { getOrderDocNormListItem, reprocessOrderDocNormListItem } from "store/actions";
import 'photoswipe/dist/photoswipe.css';
import { formats, formatTimestamp } from "helpers/dateHelper";
import pdfIcon from 'assets/images/pdf-file.svg';
import { perms, useAccess } from "context/access";

const ESignDoc = ({ id, num, order }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const orderDoc = useSelector(state => state.OrderDoc.NormList.orderDocs[id]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  /********** OTHER **********/

  const refreshOrderDoc = useCallback(() => dispatch(getOrderDocNormListItem(orderDoc.id)), [orderDoc.id]);

  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrderDoc(orderDoc.id);

  const onOrderDocChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple documents
    // therefore we need to check whether this update refers to the current document
    if (data.id == orderDoc.id) {
      refreshOrderDoc();
    }
  }, [orderDoc.id, refreshOrderDoc]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderDocChanged, onOrderDocChanged);

  /********** EVENT HANDLERS **********/

  const restartProcessing = () => dispatch(reprocessOrderDocNormListItem(orderDoc.id));

  /********** OTHER **********/

  const isReady = () => orderDoc.status > OrderDoc.STATUS_PENDING_INITIAL_PROCESSING;

  const isProcessingFailed = () => orderDoc.isProcessingFailed;

  const canBeReprocessed = () => iAmGranted(perms.edit_orders) && isProcessingFailed();

  return <>
    {orderDoc && <tr>
      <td title={'Id: ' + orderDoc.id}>{num}</td>
      <td>
        <img src={pdfIcon} className="ms-n1 me-1" />
        {orderDoc.name}
      </td>
      <td>
        <span className={`badge badge-lg rounded-pill ps-3 pe-3 bg-${OrderDoc.getStatusColor(orderDoc.status)}`}>{orderDoc.statusName}</span>
      </td>
      <td>{orderDoc.creatorFullName}</td>
      <td>{formatTimestamp(orderDoc.createdTs, formats.US_DATE)}</td>
      <td>{orderDoc.numOfPages}</td>
      <td>{orderDoc.signedTs ? formatTimestamp(orderDoc.signedTs, formats.US_DATE) : '--'}</td>
      <td className="doc-actions">
        <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)} className="btn-group">
          <DropdownToggle color="default" className="dropdown-toggle">
            <i className="bx bx-dots-horizontal-rounded" />
          </DropdownToggle>
          <DropdownMenu end>
            {orderDoc.isWithdrawn ? <React.Fragment>
              {/* Preview/download links of order documents become unavailable if isWithdrawn is true */}
              {isReady() && <><DropdownItem tag="span" id='download-doc'>Download</DropdownItem><UncontrolledTooltip placement="top" target="download-doc">Document no longer available.</UncontrolledTooltip></>}
              {isReady() && <><DropdownItem tag="span" id="view-doc">View</DropdownItem><UncontrolledTooltip placement="top" target="view-doc">Document no longer available.</UncontrolledTooltip></>}
            </React.Fragment> :
              <React.Fragment>
                {isReady() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf`)} target="_blank">View</DropdownItem>}
                {isReady() && <DropdownItem tag="a" href={getBeUrl(`order-doc/${orderDoc.id}/pdf/download`)} target="_blank">Download</DropdownItem>}
              </React.Fragment>
            }
            {canBeReprocessed() && <DropdownItem onClick={() => restartProcessing()} disabled={orderDoc.isReprocessInProgress}>Reprocess</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
      </td>
    </tr>}
  </ >
}

ESignDoc.propTypes = {
  id: PropTypes.number.isRequired,
  num: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  refreshListHandler: PropTypes.func.isRequired,
};

export default ESignDoc;
