import React, { useState } from "react";
import { CardBody, Table, Button, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { route, routes } from "helpers/routeHelper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { perms, useAccess } from "context/access";
import PropTypes from "prop-types";
import TotalCosts from "../Components/TotalCosts";
import AccountingNotes from "../Components/AccountingNotes";
import { getDealerFeeTotalCost, showError, showSuccess } from "helpers/utilHelper";
import AdditionalFee from "model/additionalFee";
import Order from "model/order";
import verifyIdIcon from "assets/images/order/supervised-user-circle.svg";
import additionalVehicleIcon from "assets/images/order/directions-car.svg";
import Confirmation from "components/Shared/Confirmation";
import { createOrderDealerStatement } from "helpers/backendHelper";
import Payment from "model/payment";

const DealerTabContent = ({ fees, ignoreStatement, refreshOrderHandler, isRefreshing }) => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // get redux state from the store
  const { order } = useSelector(state => state.Order.Single);

  const [isCreateStatementConfVisible, setIsCreateStatementConfVisible] = useState(false);
  const [isCreateStatementInProgress, setIsCreateStatementInProgress] = useState(false);

  // check if ignore button should be visible
  const ignoreStatementVisible = order.status == Order.STATUS_CANCELLED && ![Order.ACCOUNTING_STATUS_SETTLED, Order.ACCOUNTING_STATUS_IGNORED].includes(order.accountingStatus);

  const createStatement = () => {
    setIsCreateStatementInProgress(true);
    createOrderDealerStatement(order.id)
      .then(response => {
        showSuccess(`Dealer statement #${response.id} has been created`);
        refreshOrderHandler();
      })
      .catch(ex => {
        showError('Unable to create dealer statement');
      })
      .finally(() => {
        setIsCreateStatementInProgress(false);
      });
  }

  const getOrderPaymentBadge = payment => {
    switch (payment.status) {
      case Payment.STATUS_REQUESTED:
        return <Button color="default" className="btn btn-soft-warning pe-none" title="Processing">
          Payment #{order.paymentId} <i className="bx bx-hourglass ms-1"></i>
        </Button>
      case Payment.STATUS_COMPLETED:
        return <Button color="default" className="btn btn-soft-success pe-none" title="Complete">
          Payment #{order.paymentId} <i className="mdi mdi-check-circle ms-1"></i>
        </Button>
      case Payment.STATUS_FAILED:
        return <Button color="default" className="btn btn-soft-danger cursor-default" title="Failed">
          Payment #{order.paymentId} <i className="mdi mdi-information-outline ms-1" id="payment-failed-info"></i>
          <UncontrolledTooltip placement="top" target="payment-failed-info">{payment.failReason}</UncontrolledTooltip>
        </Button>
    }
  }

  return (
    <CardBody className="px-0">
      <Table className="section-rows table bt-1 view-accounting">
        <tbody>
          {fees?.map((item, index) => {
            if (item) {
              return (
                <tr key={index}>
                  <td className="section-row-label">
                    <div className="d-flex align-items-center">
                      {item.additionalFeeId === AdditionalFee.ID_TRANSACTION_FEE ? 'Transaction Fee' : item.additionalFeeName}
                      {(item.count === 2 && item.additionalFeeId === AdditionalFee.ID_VERIFY_ID) && <div className='multiple-times-label d-flex ms-3'><img className="me-1" src={verifyIdIcon} />{item.count}x Signers</div>}
                      {(item.count > 1 && item.additionalFeeId === AdditionalFee.ID_ADDITIONAL_VEHICLE) && <div className='multiple-times-label d-flex ms-3'><img className="me-1" src={additionalVehicleIcon} />{item.count}x Vehicles</div>}
                    </div>
                  </td>
                  <td className={classnames("section-row-value text-right ps-4")}>{`${Number(item.price) * Number(item.count ?? 1)}$` || '--'}</td>
                </tr>)
            }
          })}

          {!!order.additionalServicesAmount && (
            <tr>
              <td className="section-row-label">Additional Service</td>
              <td className={classnames("section-row-value text-right ps-4")}>{`${order.additionalServicesAmount}$` || '--'}</td>
            </tr>
          )}

          {!!order.compensationAmount && (
            <tr>
              <td className="section-row-label">Comp</td>
              <td className={classnames("section-row-value text-right ps-4")}>{`${order.compensationAmount}$` || '--'}</td>
            </tr>
          )}
        </tbody>
      </Table>

      {(fees?.length === 0 || fees?.every(fee => fee === null)) && (<p className="mb-4">There are no fees for this order.</p>)}
      {isRefreshing &&
        <TotalCosts label="Dealer Total Cost" value={getDealerFeeTotalCost(fees, order?.compensationAmount, order?.additionalServicesAmount)} />
      }
      {order?.dealerAccountingNotes && <AccountingNotes title={"Dealer notes to Accounting"} message={order.dealerAccountingNotes} />}
      {order?.accountingReview && <AccountingNotes title={"Accounting Review"} message={order.accountingReviewReason} />}

      <div className="d-flex mt-4">
        {!!order.paymentId && <div>{getOrderPaymentBadge(order.payment)}</div>}
        <div className="flex-fill text-end">
          {iAmGranted(perms.view_statements) && !!order.dealerStatementId && <Link to={route(routes.view_dealer_statement, { id: order.dealerStatementId })} className="btn btn-info mb-2 me-2">View Statement</Link>}
          {iAmGranted(perms.mark_orders_as_ready_for_billing) && order.dealerStatementMissing && <div id="generate-statement-btn" style={{ display: 'inline-block' }}>
            <Button color="danger" onClick={() => setIsCreateStatementConfVisible(true)} disabled={isCreateStatementInProgress} className="mb-2 me-2" id="create-dealer-statement-btn">
              Create Statement
              {isCreateStatementInProgress && <i className="mdi mdi-spin mdi-loading ms-1" />}
              {!isCreateStatementInProgress && <i className="mdi mdi-information-outline ms-1" />}
            </Button>
            <UncontrolledTooltip placement="top" target="create-dealer-statement-btn">A dealer statement is expected but missing. Click here to generate it</UncontrolledTooltip>
          </div>}
          {iAmGranted(perms.edit_order_accounting_status) && ignoreStatementVisible && <React.Fragment>
            <div id="ignore-statement-btn" style={{ display: 'inline-block' }}>
              <Button color="danger" onClick={ignoreStatement} className="mb-2 me-2" id="ignore-statement-btn">
                Ignore <i className="mdi mdi-close ms-1" />
              </Button>
            </div>
          </React.Fragment>}
          {iAmGranted(perms.edit_order_fees) && order.canAmendDealerAmount && <Link to={route(routes.view_accounting_edit, { id: order?.id, customValue: 1 })} className="btn btn-primary mb-2">
            Edit <i className="mdi mdi-arrow-right ms-1" />
          </Link>}
        </div>
      </div>

      {isCreateStatementConfVisible && <Confirmation
        confirmBtnText="Create Statement"
        onConfirm={() => {
          createStatement();
          setIsCreateStatementConfVisible(false);
        }}
        reverseButtons={false}
        onCancel={() => setIsCreateStatementConfVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to create the dealer statement?</span>
      </Confirmation>}
    </CardBody>
  )
}

DealerTabContent.propTypes = {
  fees: PropTypes.array,
  ignoreStatement: PropTypes.func,
  refreshOrderHandler: PropTypes.func,
  isRefreshing: PropTypes.bool,
};

export default DealerTabContent;