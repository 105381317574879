/********** DATATABLE **********/

export const GET_NOTARY_DT = "GET_NOTARY_DT"
export const GET_NOTARY_DT_OK = "GET_NOTARY_DT_OK"
export const GET_NOTARY_DT_ERR = "GET_NOTARY_DT_ERR"
export const DO_NOTARY_DT_CLEANUP = "DO_NOTARY_DT_CLEANUP"

/********** DATATABLE FILTERS **********/

export const APPLY_NOTARY_DT_FILTERS = "APPLY_NOTARY_DT_FILTERS"
export const PATCH_NOTARY_DT_FILTERS = "PATCH_NOTARY_DT_FILTERS"
export const CLEAR_NOTARY_DT_FILTERS = "CLEAR_NOTARY_DT_FILTERS"
export const UNSET_NOTARY_DT_FILTERS = "UNSET_NOTARY_DT_FILTERS"
export const DO_NOTARY_DT_FILTERS_CLEANUP = "DO_NOTARY_DT_FILTERS_CLEANUP"


/********** RATING **********/

export const ADD_NOTARY_RATING = "ADD_NOTARY_RATING"
export const ADD_NOTARY_RATING_OK = "ADD_NOTARY_RATING_OK"
export const ADD_NOTARY_RATING_ERR = "ADD_NOTARY_RATING_ERR"
export const DO_NOTARY_RATING_CLEANUP = "DO_NOTARY_RATING_CLEANUP"

/********** RATING RESULTS **********/

export const GET_NOTARY_RATING_RESULTS = "GET_NOTARY_RATING_RESULTS"
export const GET_SECOND_NOTARY_RATING_RESULTS = "GET_SECOND_NOTARY_RATING_RESULTS"
export const GET_NOTARY_RATING_RESULTS_OK = "GET_NOTARY_RATING_RESULTS_OK"
export const GET_SECOND_NOTARY_RATING_RESULTS_OK = "GET_SECOND_NOTARY_RATING_RESULTS_OK"
export const GET_NOTARY_RATING_RESULTS_ERR = "GET_NOTARY_RATING_RESULTS_ERR"
export const DO_NOTARY_RATING_RESULTS_CLEANUP = "DO_NOTARY_RATING_RESULTS_CLEANUP"

/********** MY NOTARY RATING **********/

export const GET_MY_NOTARY_RATING = "GET_MY_NOTARY_RATING"
export const GET_MY_SECOND_NOTARY_RATING = "GET_MY_SECOND_NOTARY_RATING"
export const GET_MY_NOTARY_RATING_OK = "GET_MY_NOTARY_RATING_OK"
export const GET_MY_SECOND_NOTARY_RATING_OK = "GET_MY_SECOND_NOTARY_RATING_OK"
export const GET_MY_NOTARY_RATING_ERR = "GET_MY_NOTARY_RATING_ERR"
export const DO_MY_NOTARY_RATING_CLEANUP = "DO_MY_NOTARY_RATING_CLEANUP"
