const ART_HOW_TO_SEAL_AN_ORDER = "1610055840";
const ART_HOW_TO_ADD_SIGNATURE_PLACEMENT = "1610842113";
const ART_HOW_TO_SUBMIT_REMOTE_ORDER_WET_SIGNED = "1368424565";
const ART_HOW_TO_SUBMIT_REMOTE_ORDER_E_SIGNED = "1368424463";
const ART_HOW_TO_SUBMIT_REMOTE_NOTARY = "1368424626";
const ART_DEALER_DEMO = "1447100440";
const ART_HOW_TO_SUBMIT_REMOTE_ORDER_VID = "1368195133";
const ART_HOW_TO_SUBMIT_RUSH_ORDER = "1368784945";
const ART_HOW_TO_ADD_CO_SIGNER = "1368621132";
const ART_NOTARY_DEMO = "1446936637";

export const articlesUrlMap = {
  [ART_HOW_TO_SEAL_AN_ORDER]: ['https://www.youtube-nocookie.com/embed/Z8rcXu7g6EQ?si=GY5PU720XCZYVVmL'],
  [ART_HOW_TO_ADD_SIGNATURE_PLACEMENT]: ['https://www.youtube-nocookie.com/embed/kmH8bWrqmtg?si=G5Zzj0EruB7rC2yk'],
  [ART_HOW_TO_SUBMIT_REMOTE_ORDER_WET_SIGNED]: ['https://www.youtube-nocookie.com/embed/i9Vk0o-pACE?si=PVEi7Su4cxDxBPFL'],
  [ART_HOW_TO_SUBMIT_REMOTE_ORDER_E_SIGNED]: ['https://www.youtube.com/embed/8eQ9PnoVhmk?si=VEJWnQJ_HGN4nLL1'],
  [ART_HOW_TO_SUBMIT_REMOTE_NOTARY]: ['https://www.youtube-nocookie.com/embed/YkyXiaRvZ5o?si=NVDFn8sBLnVkk20e', 'https://www.youtube-nocookie.com/embed/wFb6x84_fGM?si=zo9N-02S7zF81bid'],
  [ART_DEALER_DEMO]: ['https://www.youtube-nocookie.com/embed/xDbwjTT22n8?si=AnPLQ0xH3ywgpn7b'],
  [ART_HOW_TO_SUBMIT_REMOTE_ORDER_VID]: ['https://www.youtube-nocookie.com/embed/xfUokqd7iuc?si=ypMf50i2-S-YDex7'],
  [ART_HOW_TO_SUBMIT_RUSH_ORDER]: ['https://www.youtube-nocookie.com/embed/IgylmPVOsqs?si=T1nvV0IYZ09EWr2N'],
  [ART_HOW_TO_ADD_CO_SIGNER]: ['https://www.youtube-nocookie.com/embed/TEa2w07PENE?si=Fqwz7A6ccpHGCmqJ'],
  [ART_NOTARY_DEMO]: ['https://www.youtube-nocookie.com/embed/PbHmV0z6I64?si=KzB6pJr6gbNW7s3A'],
}
