import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Button, Alert, Input, InputGroup } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import helpIcon from 'assets/images/help-icon.svg';
import { getServicedeskArticles } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDebounce } from "hooks/debounce";
import HelpItem from "./Partial/HelpItem";

const List = () => {

  // Holds the input text entered by the user
  const [searchTerm, setSearchTerm] = useState("");
  // Holds the articles
  const [articles, setArticles] = useState([]);
  // whenever articles has errors
  const [articlesError, setArticlesError] = useState(false);
  // Holds the selected article
  const [selectedArticle, setSelectedArticle] = useState(false);
  // whenever articles are loading
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  // Debounce the value of the search
  const debouncedAddress = useDebounce(searchTerm, 500);

  // Fetches the Articles
  useEffect(() => {
    setIsLoadInProgress(true)
    getServicedeskArticles(searchTerm)
      .then(res => {
        setArticles(res.articles?.values)
        setArticlesError(false)
        setSelectedArticle(null);
      })
      .catch(err => {
        setArticlesError(true)
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [debouncedAddress]);

  // Resets selected article then user presses back from HelpItem
  const resetHelpContent = () => {
    setSelectedArticle(null)
  }

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>Help Centre</MetaTitle>
      <Container fluid>
        <Breadcrumbs title="Help Centre" />
        <Card className="height-100">
          <CardBody>
            {
              !selectedArticle &&
              <Row>
                <Col>
                  <div className="d-flex flex-column align-items-center">
                    <div className="mt-3">
                      <img src={helpIcon} />
                    </div>
                    <h2 className="mt-3">Help section</h2>
                    <h5 className="text-muted help-description mt-3">Find step-by-step articles to help you make the most out of every feature and get answers to common questions quickly.</h5>
                  </div>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className="d-flex flex-column align-items-center mb-5">
                  <div className="article-list-container d-flex justify-content-center flex-column align-items-center">
                    {
                      !selectedArticle &&
                      <div className="w-75">
                        <h4>Articles</h4>
                        <div className="article-list">
                          <InputGroup className="mb-3">
                            <Button color="primary" className="help-search-btn">
                              <i className="mdi mdi-magnify" />
                            </Button>
                            <Input
                              type="search"
                              className="form-control help-search-input"
                              placeholder="Search ..."
                              aria-label="Search in chat"
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                            />
                          </InputGroup>
                          {!articlesError && !selectedArticle && !isLoadInProgress &&
                            <>{!!articles && !!articles.length ? <>
                              {
                                !debouncedAddress.length &&
                                <div className="help-title  mt-1"><strong>Recommended for you</strong></div>
                              }
                              {articles.map((article, index) => {
                                return <div key={index} className="help-article-item" onClick={() => setSelectedArticle(article)}>
                                  <div className="article-title-button">{article.title}</div>
                                  <i className="mdi mdi-play font-size-16" />
                                </div>
                              })}
                            </>
                              : <div className="d-flex justify-content-center mt-4 font-size-16">No articles found</div>
                            }</>
                          }
                        </div>
                      </div>
                    }
                    {selectedArticle &&
                      <HelpItem
                        article={selectedArticle}
                        resetHelpContent={resetHelpContent}
                      />
                    }
                    {isLoadInProgress &&
                      <SpinnerChase className="sm help-spinner mt-5 mb-5" />
                    }
                    {!!articlesError && <Alert color="danger" className="fade show text-center">
                      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load articles
                    </Alert>}
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  </React.Fragment>
}

export default List;
