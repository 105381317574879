import React from "react"
import PropTypes from 'prop-types';
import { Container, Row, Col } from "reactstrap"
import MetaTitle from "components/Shared/MetaTitle";
import errorImg from "assets/images/error-img.png"

const NetworkError = props => {
  return <React.Fragment>
    <div className="account-pages my-5 pt-5">
      <MetaTitle>Network Error</MetaTitle>
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">Oops</h1>
              <h4 className="text-uppercase">{props.title || 'There is something wrong with the network'}</h4>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={errorImg} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

NetworkError.propTypes = {
  title: PropTypes.string,
  btnLink: PropTypes.string,
  btnText: PropTypes.string,
}

export default NetworkError
