import Message from 'model/message';
import VidRequest from 'model/vidRequest';
import { combineReducers } from 'redux';
import {
  GET_ORDER_DT,
  GET_ORDER_DT_OK,
  GET_ORDER_DT_ERR,
  DO_ORDER_DT_CLEANUP,

  APPLY_ORDER_DT_FILTERS,
  CLEAR_ORDER_DT_FILTERS,
  PATCH_ORDER_DT_FILTERS,
  UNSET_ORDER_DT_FILTERS,
  DO_ORDER_DT_FILTERS_CLEANUP,

  APPLY_IN_STORE_ORDER_DT_FILTERS,
  CLEAR_IN_STORE_ORDER_DT_FILTERS,
  PATCH_IN_STORE_ORDER_DT_FILTERS,
  UNSET_IN_STORE_ORDER_DT_FILTERS,
  DO_IN_STORE_ORDER_DT_FILTERS_CLEANUP,

  APPLY_PENDING_REVIEW_ORDER_DT_FILTERS,
  PATCH_PENDING_REVIEW_ORDER_DT_FILTERS,
  CLEAR_PENDING_REVIEW_ORDER_DT_FILTERS,
  UNSET_PENDING_REVIEW_ORDER_DT_FILTERS,
  DO_PENDING_REVIEW_ORDER_DT_FILTERS_CLEANUP,

  GET_INACTIVE_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,

  GET_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_FULL_ORDER,
  GET_ORDER_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  GET_FULL_ORDER_OK,
  GET_ORDER_ERR,
  DELETE_ORDER,
  DELETE_ORDER_OK,
  DELETE_ORDER_ERR,
  DO_ORDER_SINGLE_CLEANUP,
  RESET_ORDER_ACTION_FLAG,

  RESEND_CUSTOMER_NOTIFICATIONS,
  RESEND_CUSTOMER_NOTIFICATIONS_OK,
  RESEND_CUSTOMER_NOTIFICATIONS_ERR,

  START_CUSTOMER_MEETING,
  START_CUSTOMER_MEETING_OK,
  START_CUSTOMER_MEETING_ERR,
  START_NOTARY_MEETING,
  START_NOTARY_MEETING_OK,
  START_NOTARY_MEETING_ERR,

  GET_ORDER_VID,
  GET_ORDER_VID_OK,
  GET_ORDER_VID_ERR,
  DO_ORDER_VID_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  DO_ORDER_MESSAGES_CLEANUP,
  GET_ORDER_MESSAGES_ERR,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,

  GET_ORDER_ACTIVITY,
  GET_ORDER_ACTIVITY_OK,
  GET_ORDER_ACTIVITY_ERR,
  DO_ORDER_ACTIVITY_CLEANUP,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,

  GET_ORDER_E_SIGN_DOCS,
  GET_ORDER_E_SIGN_DOCS_OK,
  GET_ORDER_E_SIGN_DOCS_ERR,
  DO_ORDER_E_SIGN_DOCS_CLEANUP,

  ASSIGN_ORDER_SCHEDULER,
  ASSIGN_ORDER_SCHEDULER_OK,
  ASSIGN_ORDER_SCHEDULER_ERR,
  DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP,

  AWARD_NOTARY_BID,
  AWARD_NOTARY_BID_OK,
  AWARD_NOTARY_BID_ERR,
  DECLINE_NOTARY_BID,
  DECLINE_NOTARY_BID_OK,
  DECLINE_NOTARY_BID_ERR,
  START_ORDER_NOTARY_BID,
  START_ORDER_NOTARY_BID_OK,
  START_ORDER_NOTARY_BID_ERR,
  ADD_ORDER_NOTARY_BID,
  ADD_ORDER_NOTARY_BID_OK,
  ADD_ORDER_NOTARY_BID_ERR,
  DO_NOTARY_BID_CLEANUP,

  GET_ORDER_NOTARY_DT,
  GET_ORDER_NOTARY_DT_OK,
  GET_ORDER_NOTARY_DT_ERR,
  DO_ORDER_NOTARY_DT_CLEANUP,

  APPLY_ORDER_NOTARY_DT_FILTERS,
  CLEAR_ORDER_NOTARY_DT_FILTERS,

  PATCH_ORDER_NOTARY_DT_FILTERS,
  UNSET_ORDER_NOTARY_DT_FILTERS,
  DO_ORDER_NOTARY_DT_FILTERS_CLEANUP,

  GET_ORDER_NOTARY_BID_DT,
  GET_ORDER_NOTARY_BID_DT_OK,
  GET_ORDER_NOTARY_BID_DT_ERR,
  DO_ORDER_NOTARY_BID_DT_CLEANUP,

  START_NOTARY_REQUEUE,
  START_NOTARY_REQUEUE_OK,
  START_NOTARY_REQUEUE_ERROR,

  NOTIFY_NOTARY_BID,
  NOTIFY_NOTARY_BID_OK,
  NOTIFY_NOTARY_BID_ERR,

  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,
  SAVE_ORDER_OK,
  SAVE_ORDER_ERR,
  DO_ORDER_FORM_CLEANUP,

  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_SUPPORTING_DOCS_OK,
  GET_ORDER_SUPPORTING_DOCS_ERR,
  DO_ORDER_SUPPORTING_DOCS_CLEANUP,
  GET_ORDER_PREVIEW_DOCS,
  GET_ORDER_PREVIEW_DOCS_OK,
  GET_ORDER_PREVIEW_DOCS_ERR,
  DO_ORDER_PREVIEW_DOCS_CLEANUP,
  SET_ORDER_IS_RURAL,

  GET_NOTARY_ORDER_DT,
  GET_NOTARY_ORDER_DT_OK,
  GET_NOTARY_ORDER_DT_ERR,
  DO_NOTARY_ORDER_DT_CLEANUP,
  RESET_CHANNEL_UNREAD_MESSAGES,

} from './actionTypes';
import Order from 'model/order';

/********** DATATABLE **********/

const defaultDtState = {
  orders: [],
  ordersError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_ORDER_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        ordersError: null,
        isLoadInProgress: !!action.payload.silentLoad ? false : true,
      };
      break
    case GET_ORDER_DT_OK:
      state = {
        ...state,
        orders: action.payload.response.orders,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DT_ERR:
      state = {
        ...state,
        orders: [],
        totalCount: 0,
        ordersError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_DT_CLEANUP:
      state = {
        ...state,
        orders: [],
        ordersError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  id: '',
  schedulerId: '',
  notaryId: '',
  dealerGroup: '',
  dealerStore: '',
  customerLocation: '',
  includeStatus: [],
  includeService: [],
  incAlert: [],
  vidStatuses: [],
  underReviewStatus: [],
  duplicateReviewStatus: [],
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_ORDER_DT_FILTERS:
      state = {
        ...defaultDtFiltersState, // we need to initialize `includeStatus` and `includeService` with empty arrays
        ...action.payload.filters,
        _set: true,
      };
      break
    case PATCH_ORDER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_ORDER_DT_FILTERS:
      state = {
        ...defaultDtFiltersState,
        _set: true,
      };
      break
    case UNSET_ORDER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_ORDER_DT_FILTERS_CLEANUP:
      state = {
        ...defaultDtFiltersState,
      };
      break
  }
  return state;
}

/********** REMOTE ORDER DATATABLE FILTERS **********/

const defaultRemoteOrderState = {
  id: '',
  schedulerId: '',
  notaryId: '',
  dealerGroup: '',
  dealerStore: '',
  additionalSignerName: '',
  customerLocation: Order.CUSTOMER_LOCATION_REMOTE,
  includeStatus: [],
  includeService: [],
  incAlert: [],
  vidStatuses: [],
  underReviewStatus: [],
  duplicateReviewStatus: [],
  _set: false,
};

const RemoteOrdersDtFilters = (state = defaultRemoteOrderState, action) => {
  switch (action.type) {
    case APPLY_ORDER_DT_FILTERS:
      state = {
        ...defaultRemoteOrderState,
        ...action.payload.filters,
        customerLocation: Order.CUSTOMER_LOCATION_REMOTE,
        _set: true,
      };
      break
    case PATCH_ORDER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        customerLocation: Order.CUSTOMER_LOCATION_REMOTE,
        _set: true,
      };
      break
    case CLEAR_ORDER_DT_FILTERS:
      state = {
        ...defaultRemoteOrderState,
        _set: true,
      };
      break
    case UNSET_ORDER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_ORDER_DT_FILTERS_CLEANUP:
      state = {
        ...defaultRemoteOrderState,
      };
      break
  }
  return state;
}

/********** INSTORE ORDER DATATABLE FILTERS **********/
const defaultInStoreOrderState = {
  id: '',
  dealerGroup: '',
  dealerStore: '',
  customerLocation: Order.CUSTOMER_LOCATION_IN_STORE,
  includeStatus: [],
  includeService: [],
  incAlert: [],
  vidStatuses: [],
  underReviewStatus: [],
  duplicateReviewStatus: [],
  _set: false,
};

const InStoreOrdersDtFilters = (state = defaultInStoreOrderState, action) => {
  switch (action.type) {
    case APPLY_IN_STORE_ORDER_DT_FILTERS:
      state = {
        ...defaultInStoreOrderState,
        ...action.payload.filters,
        customerLocation: Order.CUSTOMER_LOCATION_IN_STORE,
        _set: true,
      };
      break
    case PATCH_IN_STORE_ORDER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        customerLocation: Order.CUSTOMER_LOCATION_IN_STORE,
        _set: true,
      };
      break
    case CLEAR_IN_STORE_ORDER_DT_FILTERS:
      state = {
        ...defaultInStoreOrderState,
        _set: true,
      };
      break
    case UNSET_IN_STORE_ORDER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_IN_STORE_ORDER_DT_FILTERS_CLEANUP:
      state = {
        ...defaultInStoreOrderState,
      };
      break
  }
  return state;
}

/********** PENDING REVIEW DATATABLE FILTERS **********/

const defaultPendingReviewDtFiltersState = {
  id: '',
  schedulerId: '',
  notaryId: '',
  dealerGroup: '',
  dealerStore: '',
  customerLocation: '',
  includeStatus: [],
  includeService: [],
  vidStatuses: [],
  incAlert: [],
  underReviewStatus: [],
  duplicateReviewStatus: [],
  pendingReview: true,
  _set: false,
};

const PendingReviewDtFilters = (state = defaultPendingReviewDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_PENDING_REVIEW_ORDER_DT_FILTERS:
      state = {
        ...defaultPendingReviewDtFiltersState,
        ...action.payload.filters,
        // if user applied filter by review, use the selected filters
        // otherwise, show only pending review orders
        pendingReview: action.payload.filters.accountingReview ||
          action.payload.filters.onHoldReview ||
          action.payload.filters.underReview ||
          action.payload.filters.duplicateReview ? null : true,
        _set: true,
      };
      break
    case PATCH_PENDING_REVIEW_ORDER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        // if user applied filter by review, use the selected filters
        // otherwise, show only pending review orders
        pendingReview: action.payload.filters.accountingReview ||
          action.payload.filters.onHoldReview ||
          action.payload.filters.underReview ||
          action.payload.filters.duplicateReview ? null : true,
        _set: true,
      };
      break
    case CLEAR_PENDING_REVIEW_ORDER_DT_FILTERS:
      state = {
        ...defaultPendingReviewDtFiltersState,
        _set: true,
      };
      break
    case UNSET_PENDING_REVIEW_ORDER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_PENDING_REVIEW_ORDER_DT_FILTERS_CLEANUP:
      state = {
        ...defaultPendingReviewDtFiltersState,
      };
      break
  }
  return state;
}

/********** LIST **********/

const defaultListState = {
  orders: [],
  isLoadInProgress: false,
  ordersError: null,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_INACTIVE_ORDER_LIST:
      state = { ...state, isLoadInProgress: true, ordersError: null };
      break;
    case GET_ORDER_LIST_OK:
      state = { ...state, isLoadInProgress: false, orders: action.payload.response.orders };
      break;
    case GET_ORDER_LIST_ERR:
      state = { ...state, isLoadInProgress: false, ordersError: action.payload.error };
      break;
    case DO_ORDER_LIST_CLEANUP:
      state = { ...defaultListState };
      break;
  }
  return state;
}

/********** SINGLE **********/

const defaultSingleState = {
  order: null,
  orderError: null,
  isLoadInProgress: false,
  deleted: null,
  deleteError: null,
  deleteInProgress: false,
  resentCustomerNotif: null,
  resendCustomerNotifError: null,
  isResendCustomerNotifInProgress: false,
  reloaded: null,
  reloadError: null,
  isReloadInProgress: false,
  customerMeetingStarted: null,
  customerMeetingStartError: false,
  isCustomerMeetingStartInProgress: false,
  customerMeetingLink: null,
  notaryMeetingStarted: null,
  notaryMeetingStartError: false,
  isNotaryMeetingStartInProgress: false,
  notaryMeetingLink: null,
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER:
    case GET_ORDER_WITH_CUSTOMER_SIGNERS:
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES:
    case GET_FULL_ORDER:
      state = {
        ...state,
        orderError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_OK:
      state = {
        ...state,
        order: action.payload.response.order,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
          notaries: action.payload.notariesResponse.notaries,
        },
        isLoadInProgress: false,
      };
      break
    case GET_FULL_ORDER_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
          vehicles: action.payload.vehiclesResponse.vehicles,
          notaries: action.payload.notariesResponse.notaries,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_ERR:
      state = {
        ...state,
        order: null,
        orderError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    //delete
    case DELETE_ORDER:
      state = {
        ...state,
        deleted: null,
        deleteError: null,
        deleteInProgress: true,
      };
      break
    case DELETE_ORDER_OK:
      state = {
        ...state,
        deleted: true,
        deleteInProgress: false,
      };
      break
    case DELETE_ORDER_ERR:
      state = {
        ...state,
        deleted: false,
        deleteError: action.payload.error,
        deleteInProgress: false,
      };
      break
    // resend customer notifications
    case RESEND_CUSTOMER_NOTIFICATIONS:
      state = {
        ...state,
        resentCustomerNotif: null,
        resendCustomerNotifError: null,
        isResendCustomerNotifInProgress: true,
      };
      break
    case RESEND_CUSTOMER_NOTIFICATIONS_OK:
      state = {
        ...state,
        resentCustomerNotif: true,
        isResendCustomerNotifInProgress: false,
      };
      break
    case RESEND_CUSTOMER_NOTIFICATIONS_ERR:
      state = {
        ...state,
        resentCustomerNotif: false,
        resendCustomerNotifError: action.payload.error,
        isResendCustomerNotifInProgress: false,
      };
      break
    // customer meeting
    case START_CUSTOMER_MEETING:
      state = {
        ...state,
        customerMeetingStarted: null,
        customerMeetingStartError: null,
        isCustomerMeetingStartInProgress: true,
        customerMeetingLink: null,
      };
      break
    case START_CUSTOMER_MEETING_OK:
      state = {
        ...state,
        customerMeetingStarted: true,
        isCustomerMeetingStartInProgress: false,
        customerMeetingLink: action.payload.response.link,
      };
      break
    case START_CUSTOMER_MEETING_ERR:
      state = {
        ...state,
        customerMeetingStarted: false,
        customerMeetingStartError: action.payload.error,
        isCustomerMeetingStartInProgress: false,
      };
      break
    // notary meeting
    case START_NOTARY_MEETING:
      state = {
        ...state,
        notaryMeetingStarted: null,
        notaryMeetingStartError: null,
        isNotaryMeetingStartInProgress: true,
        notaryMeetingLink: null,
      };
      break
    case START_NOTARY_MEETING_OK:
      state = {
        ...state,
        notaryMeetingStarted: true,
        isNotaryMeetingStartInProgress: false,
        notaryMeetingLink: action.payload.response.link,
      };
      break
    case START_NOTARY_MEETING_ERR:
      state = {
        ...state,
        notaryMeetingStarted: false,
        notaryMeetingStartError: action.payload.error,
        isNotaryMeetingStartInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
    case RESET_ORDER_ACTION_FLAG:
      if (!state.hasOwnProperty(action.payload.flag)) {
        break;
      }
      state = {
        ...state,
        [action.payload.flag]: null,
      };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  order: null,
  saved: null,
  saveError: null,
  isSaveInProgress: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_CUSTOMER:
    case UPDATE_ORDER_VEHICLE:
    case UPDATE_ORDER_CONTRACT:
      state = {
        ...state,
        order: action.payload.data,
        saved: null,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SET_ORDER_IS_RURAL:
      state = {
        ...state,
        isRural: action.payload.isRural,
        saved: null,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_ORDER_OK:
      state = {
        ...state,
        order: {
          ...state.order,
          id: action.payload.response.id,
        },
        saved: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_ORDER_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_ORDER_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** VID **********/

const defaultVidState = {
  [VidRequest.SIGNER_FIRST]: {
    vidRequest: null,
    vidRequestError: null,
    isLoadInProgress: false,
  },
  [VidRequest.SIGNER_SECOND]: {
    vidRequest: null,
    vidRequestError: null,
    isLoadInProgress: false,
  },
};

const Vid = (state = defaultVidState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_VID:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequestError: null,
          isLoadInProgress: true,
        },
      };
      break
    case GET_ORDER_VID_OK:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequest: action.payload.response.vidRequest,
          isLoadInProgress: false,
        },
      };
      break
    case GET_ORDER_VID_ERR:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequest: null,
          vidRequestError: action.payload.error,
          isLoadInProgress: false,
        },
      };
      break
    // cleanup
    case DO_ORDER_VID_CLEANUP:
      state = {
        ...state,
        [action.payload.signer]: defaultVidState[action.payload.signer],
      };
      break
  }
  return state;
}

/********** INK-SIGN DOCS **********/

const defaultInkSignDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const InkSignDocs = (state = defaultInkSignDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_INK_SIGN_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_INK_SIGN_DOCS_CLEANUP:
      state = { ...defaultInkSignDocsState };
      break
  }
  return state;
}

/********** E-SIGN DOCS **********/

const defaultESignDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const ESignDocs = (state = defaultESignDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_E_SIGN_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_E_SIGN_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_E_SIGN_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_E_SIGN_DOCS_CLEANUP:
      state = { ...defaultESignDocsState };
      break
  }
  return state;
}

/********** SUPPORTING DOCS **********/

const defaultSupportingDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const SupportingDocs = (state = defaultSupportingDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_SUPPORTING_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_SUPPORTING_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_SUPPORTING_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_SUPPORTING_DOCS_CLEANUP:
      state = { ...defaultSupportingDocsState };
      break
  }
  return state;
}

const defaultPreviewDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const PreviewDocs = (state = defaultPreviewDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_PREVIEW_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_PREVIEW_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_PREVIEW_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_PREVIEW_DOCS_CLEANUP:
      state = { ...defaultPreviewDocsState };
      break
  }
  return state;
}

/********** ACTIVITY **********/

const defaultActivityState = {
  logs: [],
  logsError: null,
  isLoadInProgress: false,
}

const Activity = (state = defaultActivityState, action) => {
  switch (action.type) {
    case GET_ORDER_ACTIVITY:
      state = {
        ...state,
        logsError: null,
        isLoadInProgress: true,
      };
      break;
    case GET_ORDER_ACTIVITY_OK:
      state = {
        ...state,
        logs: action.payload.response.activityLogs,
        isLoadInProgress: false,
      };
      break;
    case GET_ORDER_ACTIVITY_ERR:
      state = {
        ...state,
        logsError: action.payload.error,
        isLoadInProgress: false,
      };
      break;
    case DO_ORDER_ACTIVITY_CLEANUP:
      state = { ...defaultActivityState };
      break;
  }
  return state;
}

/********** MESSAGES **********/

const channels = Object.keys(Message.getChannelMap());
// build a map that contains all the channels as keys and empty arrays as values
// these represent the initial values for each channel messages list and will be populated with data once the backend request returns
const channelsMap = channels.reduce((accum, val) => {
  accum[val] = [];
  return accum;
}, {});

const defaultMessagesState = {
  messages: {
    ...channelsMap
  },
  unreadMessagesCount: {
    ...channelsMap
  },
  messagesError: null,
  isLoadInProgress: false,
  lastMessage: null,
  isLoadMessageInProgress: false,
  lastMessageError: null,
  members: {
    ...channelsMap
  },
  membersError: null,
  isLoadMembersInProgress: false,
}

const Messages = (state = defaultMessagesState, action) => {
  switch (action.type) {
    case GET_ORDER_MESSAGES:
      state = {
        ...state,
        isLoadInProgress: true,
        messagesError: null,
      };
      break
    case GET_ORDER_MESSAGES_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        messages: {
          ...state.messages,
          [action.payload.channelId]: action.payload.response.messages
        },
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: action.payload.response.unreadMessagesCount
        }
      }
      break
    case GET_ORDER_MESSAGES_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        messagesError: action.payload.error
      }
      break
    case DO_ORDER_MESSAGES_CLEANUP:
      state = { ...defaultMessagesState }
      break
    case GET_CHAT_MEMBERS:
      state = {
        ...state,
        isLoadMembersInProgress: true,
        membersError: null,
      };
      break
    case GET_CHAT_MEMBERS_OK:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        members: {
          ...state.members,
          [action.payload.channelId]: action.payload.response.participants,
        }
      }
      break
    case GET_CHAT_MEMBERS_ERR:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        membersError: action.payload.error
      }
      break
    case RESET_CHANNEL_UNREAD_MESSAGES:
      state = {
        ...state,
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: 0
        }
      }
      break
  }
  return state;
}

/********** SCHEDULER **********/

const defaultSchedulerState = {
  assigned: null,
  assignError: null,
  isAssignInProgress: false,
}

const Scheduler = (state = defaultSchedulerState, action) => {
  switch (action.type) {
    case ASSIGN_ORDER_SCHEDULER:
      state = {
        ...state,
        assignError: null,
        isAssignInProgress: true,
      };
      break;
    case ASSIGN_ORDER_SCHEDULER_OK:
      state = {
        ...state,
        assigned: true,
        isAssignInProgress: false,
      };
      break;
    case ASSIGN_ORDER_SCHEDULER_ERR:
      state = {
        ...state,
        assigned: false,
        assignError: action.payload.error,
        isAssignInProgress: false,
      };
      break;
    case DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP:
      state = { ...defaultSchedulerState };
      break;
  }
  return state;
}

/********** NOTARY **********/

const defaultNotaryBidState = {
  awarded: null,
  awardError: null,
  isAwardInProgress: false,
  declined: null,
  declineError: null,
  isDeclineInProgress: false,
  notified: null,
  notifyError: null,
  isNotifyInProgress: false,
  started: null,
  startError: null,
  isStartInProgress: false,
}

const NotaryBid = (state = defaultNotaryBidState, action) => {
  switch (action.type) {
    case AWARD_NOTARY_BID:
      state = {
        ...state,
        awardError: null,
        isAwardInProgress: true,
      };
      break;
    case AWARD_NOTARY_BID_OK:
      state = {
        ...state,
        awarded: true,
        isAwardInProgress: false,
      };
      break;
    case AWARD_NOTARY_BID_ERR:
      state = {
        ...state,
        awarded: false,
        awardError: action.payload.error,
        isAwardInProgress: false,
      };
      break;
    case DECLINE_NOTARY_BID:
      state = {
        ...state,
        declineError: null,
        isDeclineInProgress: true,
      };
      break;
    case DECLINE_NOTARY_BID_OK:
      state = {
        ...state,
        declined: true,
        isDeclineInProgress: false,
      };
      break;
    case DECLINE_NOTARY_BID_ERR:
      state = {
        ...state,
        declined: false,
        declineError: action.payload.error,
        isDeclineInProgress: false,
      };
      break;
    case NOTIFY_NOTARY_BID:
      state = {
        ...state,
        notifyError: null,
        isNotifyInProgress: true,
      };
      break;
    case NOTIFY_NOTARY_BID_OK:
      state = {
        ...state,
        notified: true,
        isNotifyInProgress: false,
      };
      break;
    case NOTIFY_NOTARY_BID_ERR:
      state = {
        ...state,
        notified: false,
        notifyError: action.payload.error,
        isNotifyInProgress: false,
      };
      break;
    case START_ORDER_NOTARY_BID:
      state = {
        ...state,
        startError: null,
        isStartInProgress: true,
      };
      break;
    case START_ORDER_NOTARY_BID_OK:
      state = {
        ...state,
        started: true,
        isStartInProgress: false,
      };
      break;
    case START_ORDER_NOTARY_BID_ERR:
      state = {
        ...state,
        started: false,
        startError: action.payload.error,
        isStartInProgress: false,
      };
      break;
    case ADD_ORDER_NOTARY_BID:
      state = {
        ...state,
        addError: null,
        isAddInProgress: true
      };
      break
    case ADD_ORDER_NOTARY_BID_OK:
      state = {
        ...state,
        added: true,
        isAddInProgress: false
      };
      break
    case ADD_ORDER_NOTARY_BID_ERR:
      state = {
        ...state,
        added: false,
        isAddInProgress: false
      };
      break
    case DO_NOTARY_BID_CLEANUP:
      state = {
        ...defaultNotaryBidState
      };
      break
  }
  return state;
}

/********** NOTARY BID DATATABLE **********/

const defaultNotaryBidDtState = {
  notaryBids: [],
  requeue: null,
  notaryBidsError: null,
  isLoadInProgress: false,
  notaryRequeueError: null,
  isLoadRequeueInProgress: false,
};

const NotaryBidDt = (state = defaultNotaryBidDtState, action) => {
  switch (action.type) {
    case GET_ORDER_NOTARY_BID_DT:
      state = {
        ...state,
        notaryBidsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_NOTARY_BID_DT_OK:
      state = {
        ...state,
        notaryBids: action.payload.response.notaryBids,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_NOTARY_BID_DT_ERR:
      state = {
        ...state,
        notaryBids: [],
        notaryBidsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case START_NOTARY_REQUEUE:
      state = {
        ...state,
        notaryRequeueError: null,
        isLoadRequeueInProgress: true,
      };
      break
    case START_NOTARY_REQUEUE_OK:
      state = {
        ...state,
        requeue: true,
        isLoadRequeueInProgress: false,
      };
      break
    case START_NOTARY_REQUEUE_ERROR:
      state = {
        ...state,
        requeue: false,
        notaryRequeueError: action.payload.error,
        isLoadRequeueInProgress: false,
      };
      break

    // cleanup
    case DO_ORDER_NOTARY_BID_DT_CLEANUP:
      state = { ...defaultNotaryBidDtState };
      break
  }
  return state;
}

/********** DATATABLE **********/

const defaultNotaryDtState = {
  notaries: [],
  notariesError: null,
  totalCount: 0,
  listParams: {
    sortBy: "distance",
    sortDir: "asc",
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const NotaryDt = (state = defaultNotaryDtState, action) => {
  switch (action.type) {
    case GET_ORDER_NOTARY_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        notariesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_NOTARY_DT_OK:
      state = {
        ...state,
        notaries: action.payload.response.notaries,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_NOTARY_DT_ERR:
      state = {
        ...state,
        notaries: [],
        totalCount: 0,
        notariesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_NOTARY_DT_CLEANUP:
      state = {
        ...state,
        notaries: [],
        notariesError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultNotaryDtFiltersState = {
  name: '',
  state: '',
  city: '',
  _set: false,
};

const NotaryDtFilters = (state = defaultNotaryDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_ORDER_NOTARY_DT_FILTERS:
      state = {
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_ORDER_NOTARY_DT_FILTERS:
      state = {
        ...defaultNotaryDtFiltersState,
        _set: true,
        latitude: state.latitude,
        longitude: state.longitude,
        type: state.type
      };
      break
    case PATCH_ORDER_NOTARY_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case UNSET_ORDER_NOTARY_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_ORDER_NOTARY_DT_FILTERS_CLEANUP:
      state = {
        ...defaultNotaryDtFiltersState,
      };
      break
  }
  return state;
}

/********** NOTARY ORDERS DATATABLE **********/

const defaultNotaryOrderDtState = {
  orders: [],
  ordersError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 10,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const NotaryOrderDt = (state = defaultNotaryOrderDtState, action) => {
  switch (action.type) {
    case GET_NOTARY_ORDER_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        ordersError: null,
        isLoadInProgress: true,
      };
      break
    case GET_NOTARY_ORDER_DT_OK:
      state = {
        ...state,
        orders: action.payload.response.orders,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_NOTARY_ORDER_DT_ERR:
      state = {
        ...state,
        orders: [],
        totalCount: 0,
        ordersError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_NOTARY_ORDER_DT_CLEANUP:
      state = {
        ...state,
        orders: [],
        ordersError: null,
      };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
  List,
  Single,
  Form,
  Vid,
  InkSignDocs,
  ESignDocs,
  Activity,
  Messages,
  Scheduler,
  NotaryBid,
  NotaryBidDt,
  NotaryDt,
  NotaryDtFilters,
  SupportingDocs,
  PreviewDocs,
  PendingReviewDtFilters,
  RemoteOrdersDtFilters,
  InStoreOrdersDtFilters,
  NotaryOrderDt
});