import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, Alert, CardHeader, CardTitle } from "reactstrap";
import { formats, formatTimestamp } from "helpers/dateHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDispatch, useSelector } from "react-redux";
import { doSubscriptionDtCleanup, getSubscriptionDt } from "store/actions";

const Subscription = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { subscriptions, listParams, subscriptionsError, isLoadInProgress } = useSelector(state => state.DealerStore.SubscriptionDt);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get subscriptions
    dispatch(getSubscriptionDt({ ...listParams, pageSize: 5, page: 1 }));
    return () => {
      // state cleanup on component unmount
      dispatch(doSubscriptionDtCleanup());
    }
  }, []);

  return <>
    <Card className="subscriptions-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Latest subscriptions</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Group name</th>
              <th>Store name</th>
              <th>Subscription</th>
              <th>Subscription Value</th>
              <th>Subscription Date</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.length > 0 && subscriptions.map((entry, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry.dealerGroupName}</td>
                <td>{entry.dealerStoreName}</td>
                <td>{entry.paymentPlanName}</td>
                <td>${entry.subscriptionPrice}</td>
                <td>{formatTimestamp(entry.paymentPlanStartDate, formats.DATETIME)}</td>
              </tr>
            ))}
            {subscriptions.length === 0 && <tr>
              <td className="table-empty" colSpan="7">No subscriptions found</td>
            </tr>
            }
            {!!subscriptionsError && <tr>
              <td className="table-empty" colSpan="7">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load subscriptions
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </>
}

export default Subscription;