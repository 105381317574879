/********* SINGLE *********/

export const GET_ORDER_FEES = 'GET_ORDER_FEES'
export const GET_ORDER_FEES_OK = 'GET_ORDER_FEES_OK'
export const GET_ORDER_FEES_ERR = 'GET_ORDER_FEES_ERR'
export const DO_ORDER_FEES_CLEANUP = 'DO_ORDER_FEES_CLEANUP'

/********* LIST *********/

export const GET_ORDER_AVAILABLE_FEES = 'GET_ORDER_AVAILABLE_FEES'
export const GET_ORDER_AVAILABLE_FEES_OK = 'GET_ORDER_AVAILABLE_FEES_OK'
export const GET_ORDER_AVAILABLE_FEES_ERR = 'GET_ORDER_AVAILABLE_FEES_ERR'
export const DO_ORDER_AVAILABLE_FEES_CLEANUP = 'DO_ORDER_AVAILABLE_FEES_CLEANUP'

/********** FORM **********/

export const UPDATE_DEALER_ACCOUNTING = 'UPDATE_DEALER_ACCOUNTING'
export const UPDATE_NOTARY_ACCOUNTING = 'UPDATE_NOTARY_ACCOUNTING'
export const UPDATE_QUICKBOOK_ACCOUNTING = 'UPDATE_QUICKBOOK_ACCOUNTING'

export const SAVE_DEALER_OK = 'SAVE_DEALER_OK'
export const SAVE_DEALER_ERR = 'SAVE_DEALER_ERR'
export const DO_DEALER_FORM_CLEANUP = 'DO_DEALER_FORM_CLEANUP'

export const SAVE_NOTARY_OK = 'SAVE_NOTARY_OK'
export const SAVE_NOTARY_ERR = 'SAVE_NOTARY_ERR'
export const DO_NOTARY_FORM_CLEANUP = 'DO_NOTARY_FORM_CLEANUP'