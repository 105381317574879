import React from "react";
import {Container, Row} from "reactstrap";
import MetaTitle from "../../../../../components/Shared/MetaTitle";
import Col from "../../../../../components/Shared/Col";
import FormCustomFee from "./FormCustomFee";
import {useParams} from "react-router-dom";


const CustomFeeNew = () => {

	// read route parameters
	const { id } = useParams();

	return <React.Fragment>
		<div className="page-content">
				<MetaTitle>New signer</MetaTitle>
				<Container fluid>
					<Row>
						<Col xl="8" xxl="6">
							<FormCustomFee id={parseInt(id)} />
						</Col>
					</Row>
				</Container>
			</div>
	</React.Fragment>
}

export default CustomFeeNew;