import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { doOrderMessagesCleanup, getChatMembers, getOrderMessages } from "store/actions";
import SectionInfo from "./Partial/Section/Info";
import ChatUserHeader from "components/Shared/Chat/UserHeader";
import ChatConversation from "components/Shared/Chat/Conversation";
import { useSocketOn, useSubscribeToOrderMessages, useSubscribeToSupportCase } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { getSupportCase } from "helpers/backendHelper";
import useAllowedChannels from "hooks/allowedChannels";
import SectionSupportCases from "./Partial/Section/SupportCases";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [supportCase, setSupportCase] = useState(null);
  const [supportCaseError, setSupportCaseError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const { messages, isLoadInProgress: isMessagesLoadInProgress, messagesError, members } = useSelector(state => state.Order.Messages);

  // the only channels that will be displayed in support cases are the support channels
  // therefore, we do not need to send the real value for order.isNotaryRequired
  // we will consider that the order is without notary, since we don't need the notary-specific channels here anyway (e.g. Scheduler - Customer, Scheduler - Notary)
  // otherwise, we would need to make a separate call to the BE and get the order
  const channels = useAllowedChannels(false);

  /********** OTHER **********/

  const refreshSupportCase = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getSupportCase(id)
      .then(response => {
        setSupportCase(response.supportCase);
      })
      .catch(ex => {
        setSupportCaseError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const refreshMessages = () => dispatch(getOrderMessages(supportCase.orderId, supportCase.channel));

  const refreshChatMembers = () => dispatch(getChatMembers(supportCase.orderId, supportCase.channel));

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => {
    refreshSupportCase();
    refreshMessages();
  }, [refreshMessages, refreshSupportCase]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  // start receiving case updates
  useSubscribeToSupportCase(id);

  const onSupportCaseChanged = useCallback(refreshSupportCase, [refreshSupportCase]);

  // listen for changes on messages
  useSocketOn(socketEvent.supportCaseChanged, onSupportCaseChanged);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the support case data
    refreshSupportCase();
  }, [id]);

  // load messages
  useEffect(() => {
    if (supportCase) {
      refreshMessages();
      refreshChatMembers();
    }
    return () => {
      dispatch(doOrderMessagesCleanup());
    }
  }, [supportCase?.orderId, supportCase?.channel]);

  return <React.Fragment>
    {iAmGranted(perms.view_support_cases) && <div className="page-content">
      {supportCase && <React.Fragment>
        <MetaTitle>Support Case #{supportCase.id} | Support Cases</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(supportCase)} />
          <Row>
            <Col xs={12} lg={6}>
              <ChatUserHeader />
              <ChatConversation
                id={supportCase.orderId}
                channelId={supportCase.channel}
                messages={messages[supportCase.channel]}
                isLoadInProgress={isMessagesLoadInProgress}
                members={members[supportCase.channel]}
                messagesError={messagesError}
                channels={channels}
              />
            </Col>
            <Col xs={12} lg={6}>
              <SectionInfo refreshHandler={refreshSupportCase} supportCase={supportCase} />
            </Col>
            <Col>
              <SectionSupportCases signerId={supportCase.orderSignerId} supportCaseId={supportCase.id} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !supportCase && <Preloader className="inner" />}
      {supportCaseError && <Error error={supportCaseError} title404="Support case not found" />}
    </div>}
    {iAmNotGranted(perms.view_support_cases) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = supportCase => ([{
  title: "SUPPORT CASES",
  url: route(routes.list_support_cases),
}, {
  title: `#${supportCase.id}`,
}]);

export default View;
