import {
  GET_DEALER_GROUP_REGION_LIST,
  GET_DEALER_REGION_LIST_OK,
  GET_DEALER_REGION_LIST_ERR,
  DO_DEALER_REGION_LIST_CLEANUP,
} from "./actionTypes";

/********* LIST *********/

export const getDealerGroupRegionList = groupId => ({
  type: GET_DEALER_GROUP_REGION_LIST,
  payload: { groupId }
});

export const getDealerRegionListOk = response => ({
  type: GET_DEALER_REGION_LIST_OK,
  payload: { response },
});

export const getDealerRegionListErr = error => ({
  type: GET_DEALER_REGION_LIST_ERR,
  payload: { error },
});

export const doDealerRegionListCleanup = () => ({
  type: DO_DEALER_REGION_LIST_CLEANUP,
});