import { getDealerStatementDt } from "helpers/backendHelper";
import { getDealerStatementDtErr, getDealerStatementDtOk } from "./actions";
import { GET_DEALER_STATEMENT_DT } from "./actionTypes";
import { takeEvery, put, call } from "redux-saga/effects";

/********** DATATABLE **********/

function* onGetDealerStatementDt({ payload: { params } }) {
  try {
    const response = yield call(getDealerStatementDt, params);
    yield put(getDealerStatementDtOk(response));
  } catch (error) {
    yield put(getDealerStatementDtErr(error));
  }
}

function* statementSaga() {
  yield takeEvery(GET_DEALER_STATEMENT_DT, onGetDealerStatementDt);
}

export default statementSaga;