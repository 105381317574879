import React, { useEffect, useState } from "react";
import { Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Confirmation from "components/Shared/Confirmation";
import { route, routes } from "helpers/routeHelper";
import classnames from "classnames";
import FormDealerEdit from "./Tabs/EditDealer";
import FormNotaryEdit from "./Tabs/EditNotary";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  doOrderAvailableFeesCleanup,
  doOrderFeesCleanup,
  getOrderAvailableFees,
  getOrderFees
} from "store/orderFees/actions";
import { doOrderSingleCleanup, getFullOrder } from "store/order/actions";
import FormQuickbooksEdit from "./Tabs/EditQuickbooks";
import OrderFee from "model/orderFee";
import readyForBillingIcon from "assets/images/ready-for-billing-icon.svg";
import { perms, useAccess } from "context/access";
import { markAsReadyForBilling } from "helpers/backendHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import OrderNotary from "model/orderNotary";
import Order from "model/order";
import { ORDER_FEES_NOT_ALL_SET } from "helpers/errorHelper";

const ViewAccountingEdit = () => {

  let { id, customValue } = useParams();
  id = parseInt(id);
  customValue = customValue.toString();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const { iAmGranted } = useAccess();

  // get redux state from the store
  const { fees } = useSelector(state => state.OrderFees.Single);
  const { order } = useSelector(state => state.Order.Single);

  // find active notary, and check status
  const isOrderCompletedByNotary = !!order?.notaries && order.notaries.filter(n => !n.isInactive)[0]?.status === OrderNotary.STATUS_ORDER_COMPLETE;
  const isOrderNotSettled = order?.accountingStatus === Order.ACCOUNTING_STATUS_NOT_SETTLED;
  const orderHasNotary = order?.isNotaryRequired;
  const isOrderComplete = order?.status == Order.STATUS_COMPLETED;
  const orderIsReviewedByAccounting = order?.accountingReviewStatus;

  /* 1. one can mark order as ready for billing if the order has been completed by the notary or cancelled
        and the accounting status is NOT SETLED
     2. the order can be marked as ready for billing if it's a non-notary order, it has been completed but
        the fees have not been setled, and no accounting review is required
  */
  const canMarkAsReadyForBilling =
    (orderHasNotary && isOrderNotSettled && (isOrderCompletedByNotary || order?.status === Order.STATUS_CANCELLED)) ||
    (!orderHasNotary && isOrderNotSettled && isOrderComplete && !orderIsReviewedByAccounting)

  // is the confirmation dialog visible or not
  // used to show/hide the confirm fee settlement warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isMarkInProgress, setIsMarkInProgress] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState(customValue);

  /********** EVENT HANDLERS **********/

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const generateNavItem = item => {
    return (
      <NavItem key={item.id} className={item.id.toString()}>
        <NavLink
          className={classnames({
            active: customActiveTab === item.id.toString(),
          })}
          onClick={() => {
            toggleCustom(item.id.toString());
          }}
        >
          {item.name}
        </NavLink>
      </NavItem>
    )
  };

  const generateNavContent = item => {
    return (
      <TabPane key={item.id} tabId={item.id.toString()}>
        {item.component}
      </TabPane>
    )
  };

  const confirmFeeSettlement = () => {
    setIsMarkInProgress(true);
    markAsReadyForBilling(id)
      .then(() => {
        setIsMarkInProgress(false);
        showSuccess("Fee settlement confirmed");
        refreshOrder();
      })
      .catch(err => {
        setIsMarkInProgress(false);
        if (err.code == ORDER_FEES_NOT_ALL_SET) {
          showError("Some of the fees are not set! All fields should have a value!");
          return;
        }
        showError("Unable to confirm fee settlement");
      })
  };

  /********** EFFECTS **********/

  // fetch data at component mount
  useEffect(() => {
    refreshOrder();
    // load list of fees from the master plan
    dispatch(getOrderAvailableFees(id));
    refreshFees();
    return () => {
      dispatch(doOrderSingleCleanup());
      dispatch(doOrderAvailableFeesCleanup());
      dispatch(doOrderFeesCleanup());
    }
  }, [id]);

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getFullOrder(id));

  const refreshFees = () => dispatch(getOrderFees(id));

  const navInfo = [
    {
      name: "Dealer",
      id: 1,
      component:
        <FormDealerEdit order={order} orderFees={fees?.filter(fee => fee.appliesTo === OrderFee.APPLIES_TO_DEALER)} refreshHandler={refreshFees} />
    },
    {
      name: "Notary",
      id: 2,
      // hide tab if notary is not required
      component: (
        order?.isNotaryRequired
          ? (
            <FormNotaryEdit order={order} orderFees={fees?.filter(fee => fee.appliesTo === OrderFee.APPLIES_TO_NOTARY)} />
          )
          : null
      )
    },
    {
      name: "Quickbooks",
      id: 3,
      component: <FormQuickbooksEdit order={order} refreshOrder={refreshOrder} />
    }
  ];

  // filter out null components
  const navTabs = navInfo.filter(item => item.component !== null);

  return (
    <div className="page-content">
      {!!order && <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbs(id)} />
        <Row className="vertical-tabs-page">
          <h1 className="page-title">Accounting</h1>
          <div className="nav-container mb-5">
            <Nav vertical>
              {navTabs.map(navItem => generateNavItem(navItem))}
            </Nav>
            <TabContent activeTab={customActiveTab}>
              <TabContent activeTab={customActiveTab}>
                {navTabs.map(tabItem => generateNavContent(tabItem))}
              </TabContent>
            </TabContent>
          </div>
          <div className="text-end">
            {iAmGranted(perms.mark_orders_as_ready_for_billing) && canMarkAsReadyForBilling && (
              <Button color="primary" onClick={() => setIsConfirmationVisible(true)} disabled={isMarkInProgress} >
                {isMarkInProgress ? <i className="mdi mdi-spin mdi-loading me-2" /> : <img alt="confirm fee settlement" src={readyForBillingIcon} />} Confirm Fee Settlement
              </Button>
            )}
          </div>
        </Row>
      </Container>}
      {isConfirmationVisible && <Confirmation
        title={<div className="fw-600 text-primary mb-2">Thank you for confirming the fee settlement!</div>}
        confirmBtnText="Yes"
        reverseButtons={false}
        cancelBtnText="No"
        onConfirm={() => {
          confirmFeeSettlement();
          setIsConfirmationVisible(false)
        }}
        onCancel={() => setIsConfirmationVisible(false)}>
        <span className="text-primary">{`You are about to initiate the payment processing for both the dealership and the notary. You have reviewed all the fees associated with this order and no other changes are necessary after this. Are you sure you want to proceed?`}</span>
      </Confirmation>}
    </div>
  )
}

const breadcrumbs = id => [{
  title: '< Back',
  url: route(routes.view_order, id),
}];

export default ViewAccountingEdit;