import { takeEvery, put, call } from "redux-saga/effects";
import {

  GET_ADDITIONAL_FEE_LIST,

} from './actionTypes';

import {

  getAdditionalFeeListOk,
  getAdditionalFeeListErr,

} from './actions';

import {

  getAdditionalFees,

} from 'helpers/backendHelper'


/********** LIST **********/

function* onGetAdditionalFeeList() {
  try {
    const response = yield call(getAdditionalFees);
    yield put(getAdditionalFeeListOk(response));
  } catch (error) {
    yield put(getAdditionalFeeListErr(error));
  }
}

function* additionalFeeSaga() {
  yield takeEvery(GET_ADDITIONAL_FEE_LIST, onGetAdditionalFeeList);
}

export default additionalFeeSaga;