import React from "react";
import { Row, Col, Button, Form, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { dictionaryToSelectOptions } from "helpers/utilHelper";
import Notary from "model/notary";
import NotaryBid from "model/notaryBid";
import DataTableNotaries from "../../DataTable/Notaries";
import { applyOrderNotaryDtFilters } from "store/actions";
import { route, routes } from "../../../../../helpers/routeHelper";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";

const FormEditNotaryBids = () => {

  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { order } = useSelector(state => state.Order.Single);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    notaryType: "",
    distanceFrom: NotaryBid.DISTANCE_FROM_CUSTOMER_SIGNER,
    address: order.signers[0].address,
    latitude: order.signers[0].latitude,
    longitude: order.signers[0].longitude,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => dispatch(applyOrderNotaryDtFilters({
      type: values.notaryType,
      latitude: values.distanceFrom === NotaryBid.DISTANCE_FROM_CUSTOMER_SIGNER ? values.latitude : "",
      longitude: values.distanceFrom === NotaryBid.DISTANCE_FROM_CUSTOMER_SIGNER ? values.longitude : "",
    })),
  });

  const onAddressTextChanged = (value) => {
    formik.setFieldValue(`address`, value);
    formik.setFieldValue(`latitude`, '');
    formik.setFieldValue(`longitude`, '');
  }

  const onPlaceSelected = () => (place, value) => {
    formik.setFieldValue(`address`, value);
    formik.setFieldValue(`latitude`, place.geometry.location.lat());
    formik.setFieldValue(`longitude`, place.geometry.location.lng());
  }

  return <Form>
    <Row className="mb-4">
      <Col xs={12} lg={6}>
        <Label className="col-sm-4 col-form-label">Signing Address</Label>
        <PlacesAutocomplete
          type="text"
          name="address"
          onChange={e => onAddressTextChanged(e.target.value)}
          onPlaceChanged={onPlaceSelected()}
          value={formik.values.address}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Label className="col-sm-4 col-form-label">Notary Type</Label>
        <Select
          isClearable
          classNamePrefix="select2-selection"
          name="notaryType"
          options={notaryTypeOptions}
          onChange={selected => formik.setFieldValue("notaryType", selected ? (+selected.value || "") : null)}
          value={notaryTypeOptions.find(option => option.value == formik.values.notaryType)}
          className={!!formik.errors.notaryType && "is-invalid"}
        />
        {!!formik.errors.notaryType && <FormFeedback type="invalid">{formik.errors.notaryType}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Col xs={12} lg={6}>
        <Label className="col-sm-4 col-form-label">Distance from</Label>
        <Select
          classNamePrefix="select2-selection"
          name="distanceFrom"
          options={distanceFromOptions}
          onChange={selected => formik.setFieldValue("distanceFrom", +selected.value)}
          value={distanceFromOptions.find(option => option.value == formik.values.distanceFrom)}
          className={!!formik.errors.distanceFrom && "is-invalid"}
        />
        {!!formik.errors.distanceFrom && <FormFeedback type="invalid">{formik.errors.distanceFrom}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Col>
        <div className="text-end">
          <Button type="button" color="primary" onClick={() => navigate(route(routes.view_order_notary, order.id))}>
            See notary bidding <i className="mdi mdi-autorenew me-1"></i>
          </Button>
          <Button type="button" color="primary" onClick={formik.handleSubmit} className='ms-3'>
            Search for Notaries <i className="bx bx-search-alt search-icon ms-1" />
          </Button>
        </div>
      </Col>
    </Row>
    {formik.submitCount > 0 &&
      <Row>
        <Col>
          <p className="font-size-15 fw-bold">
            The following notaries are available for this signing:
          </p>
          <DataTableNotaries />
        </Col>
      </Row>
    }
  </Form>
}

const notaryTypeOptions = [{ label: "All", value: "" }, ...dictionaryToSelectOptions(Notary.getTypeMap()).filter(option => Notary.isApprovedType(+option.value))];

const distanceFromOptions = dictionaryToSelectOptions(NotaryBid.getDistanceFromSignerMap());

export default FormEditNotaryBids;