export default class Message {

  static CHANNEL_DEALER_CUSTOMER = 1;
  static CHANNEL_SUPPORT_DEALER = 2;
  static CHANNEL_SUPPORT_DEALER_CUSTOMER = 3;
  static CHANNEL_SCHEDULER_CUSTOMER = 4;
  static CHANNEL_SCHEDULER_NOTARY = 5;
  static CHANNEL_NOTARY_CUSTOMER = 6;
  static CHANNEL_MY_NOTES = 7;
  static CHANNEL_INTERNAL_NOTES = 8;
  static CHANNEL_SCHEDULER_DEALER = 9;

  static CHANNEL_MEMBER_TYPE_USER = 1;
  static CHANNEL_MEMBER_TYPE_SIGNER = 2;

  static CONTENT_TYPE_TEXT = 1
  static CONTENT_TYPE_IMAGE = 2

  // in order to extend the current channels with a new channel
  // we need to add it to the getChannelMap method
  // and edit the permissions for it in the src/context/access.js file
  static getChannelMap() {
    return {
      [this.CHANNEL_DEALER_CUSTOMER]: 'Dealer - Customer',
      [this.CHANNEL_SUPPORT_DEALER]: 'Tech Support - Dealer',
      [this.CHANNEL_SUPPORT_DEALER_CUSTOMER]: 'Tech Support - Dealer - Customer',
      [this.CHANNEL_SCHEDULER_CUSTOMER]: 'Scheduler - Customer',
      [this.CHANNEL_SCHEDULER_NOTARY]: 'Scheduler - Notary',
      [this.CHANNEL_NOTARY_CUSTOMER]: 'Notary - Customer',
      [this.CHANNEL_MY_NOTES]: 'My Notes',
      [this.CHANNEL_INTERNAL_NOTES]: 'Internal Notes',
      [this.CHANNEL_SCHEDULER_DEALER]: 'Scheduler - Dealer',
    };
  }

  static getChannelName(id) {
    return this.getChannelMap()[id];
  }

  // Template Messages
  static SCHEDULER_DEALER_TEMPLATES = [
    "Hi Dealer Reps, Please provide the tracking number for the docs, thank you!",
    "The notary has not received the docs, please advise.",
    "The notary has attempted to contact the customer to set a signing appointment, we have not received a response. Please have the dealer rep assigned to the deal contact the customer and ask the customer to call the notary to set an appointment. Thank you!",
    "A return label was not included in the docs. Please upload a return label, thank you!",
    "The notary will be in contact within 24 hours after document delivery. The order was placed as a Standard order by the dealership. A Standard order has a 3-5 day turnaround time. We will advise when a signing appointment is set. Thank you!",
    "Thank you for the tracking number!",
    "The customer has informed the notary that the deal is cancelled. Please advise if we need to cancel the order.",
    "The customer has questions on the docs, please have the dealer rep assigned to the deal contact the customer to discuss their questions and concerns.",
    "Hi All, We have found 1 available notary willing to travel to the customer's location. The notary is about <mileage or time> one way from the customer's location. The notary is willing to travel round trip to meet with the customer, they have asked for a $<fee amount> travel fee for gas and time. Can I get your approval for this fee? As soon as I hear from you I will send you the confirmation email with the notary's address so the docs can go out ASAP. Thank you!",
    "The contact number for the customer is incorrect, please provide a valid number.",
    "The customer has informed the notary that the documents are incorrect. Please advise if a new set of docs will be going out.",
    "The notary arrived at the scheduled signing appointment and the customer was a no-show. Please be advised a trip fee will be applied. Please have the dealer rep assigned to the deal contact the customer and ask the customer to call the notary to set a 2nd appointment.",
    "Please be advised the customer has cancelled the scheduled appointment. Do we need to cancel the order?",
    "The customer is in a rural order. We will continue our search for an available notary in the morning.",
    "Hi All, Since we have not received a response from the dealership we will cancel the order today. Please submit a new order when the dealership and customer are ready. Thank you!",
    "Hi All, The customer has informed Maverick that they will be refusing to provide their thumbprint on the Maverick Affidavit of Identity. As you know, it is mandatory to collect a thumbprint from each off-site customer. A refusal will require written permission from the GM to proceed. Since the signing has not taken place yet, please contact the customer and explain the thumbprint requirement. Thank you!"
  ]

  static SCHEDULER_NOTARY_TEMPLATES = [
    "Hi! FedEx tracking number for the docs:",
    "It looks like docs were delivered, please advise when a signing appointment is set, thank you!",
    "Thank you for attempting to reach the customer. Since you have not received a response we will ask the dealership to contact the customer, they will ask the customer to contact you to set an appointment.",
    "Please advise when a signing appointment has been set, thank you!",
    "Hi! Was the signing completed?",
    "Hi! Can you please confirm where the signing took place?",
    "As a reminder, Maverick and the dealership do not allow location changes.The signing must take place at the address on the order. Please reply ASAP if the customer requests to change the signing location. Please do not proceed with the signing if the customer requests a location change.",
    "The AOI is missing the customer's signature. Please meet with the customer again to collect the signature and upload the updated AOI.",
    "The DL/AOI provided is unclear. Please take a picture of the DL/AOI and upload a clear copy. Scanned copies tend to be unclear. Unfortunately, we are unable to close out the order until we have clear documentation.",
    "Hi! Were the docs dropped?",
    "The dealership has advised that a signature was missed on a required document. I will be calling you shortly!"
  ]

  static INTERNAL_TEMPLATES = [
    "Docs in transit",
    "LM for NT for appt",
    "Marked UR: Change of location - Dealer Approved - POR Uploaded",
    "Marked UR: Red Flags - Management Review",
    "LM for Dealer",
    "Marked UR: Thumbprint Refusal - MavSign Management notified - GM approval received",
    "Notary MIA - MavSign Management contacted"
  ]

}