import React, { useEffect } from "react";
import { Row, Label, Input, FormFeedback } from "reactstrap";
import Col from "components/Shared/Col";
import DatePicker from "components/Shared/DatePicker";
import { useFormikContext } from "formik";

const UploadDriverLicense = () => {

  // consume formik instance from context
  const formik = useFormikContext();

  /********** EFFECTS **********/

  // remove the expiration date whenever `expiry` field switches to `no`
  useEffect(() => {
    if (formik.values.expiry === 0) delete formik.values.expirationTs;
  }, [formik.values.expiry]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  return <Row className="mb-4">
    <Col xs={12} md={6}>
      <Label className="col-form-label">Driver License Number*</Label>
      <Input type="text" className="form-control" name="driverLicenseNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.driverLicenseNo} invalid={!!formik.errors.driverLicenseNo} />
      {!!formik.errors.driverLicenseNo && <FormFeedback type="invalid">{formik.errors.driverLicenseNo}</FormFeedback>}
    </Col>
    <Col xs={12} md={6} />
    <Col xs={12} md={6}>
      <Label className="col-form-label">Upload file (pdf)*</Label>
      <Input type="file" className="form-control" name="file" onChange={e => formik.setFieldValue("file", e.currentTarget.files[0])} onFocus={onFieldFocused} invalid={!!formik.errors.file} />
      {!!formik.errors.file && <FormFeedback type="invalid">{formik.errors.file}</FormFeedback>}
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Expiration Date*</Label>
      <DatePicker
        name="expirationTs"
        value={formik.values.expirationTs}
        onChange={selected => formik.setFieldValue("expirationTs", selected)}
        onFocus={onFieldFocused}
        minDate={new Date().setHours(0, 0, 0, 0)}
        invalid={!!formik.errors.expirationTs}
      />
      {!!formik.errors.expirationTs && <FormFeedback type="invalid" className="d-block">{formik.errors.expirationTs}</FormFeedback>}
    </Col>
  </Row>
}

export default UploadDriverLicense;