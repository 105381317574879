import React, { useState, useEffect } from "react";
import { route, routes } from "helpers/routeHelper";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionInfo from "./Partial/Section/Info";
import { getDealerStoreSigner } from "helpers/backendHelper";


const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [signer, setSigner] = useState(null);
  const [signerError, setSignerError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store signer data
    refreshDealerStoreSigner();
  }, [id]);

  /********** OTHER **********/

  const refreshDealerStoreSigner = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getDealerStoreSigner(id)
      .then(response => {
        setSigner(response.signer);
      })
      .catch(ex => {
        setSignerError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_dealer_stores) && <div className="page-content">
      {signer && <React.Fragment>
        <MetaTitle>{signer.fullName} | Signers</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(signer, iAmGranted)} />
          <Row>
            <Col xxl="8">
              <SectionInfo id={id} signer={signer} refreshHandler={refreshDealerStoreSigner} isLoadBusy={isLoadInProgress} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !signer && <Preloader className="inner" />}
      {signerError && <Error error={signerError} title404="Signer not found" />}
    </div>}
    {iAmNotGranted(perms.view_dealer_stores) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = signer => [{
  title: 'STORES',
  url: route(routes.list_dealer_stores),
}, {
  title: signer.dealerStoreName,
  url: route(routes.view_dealer_store, signer.dealerStoreId),
}, {
  title: `${signer.fullName}`,
}];

export default View;
