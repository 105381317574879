import React from 'react';
import PropTypes from 'prop-types';
import Order from 'model/order';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderInternalStatusChanged = props => {

  const { log } = props;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {!!log.author && <React.Fragment>
          <AuthorName log={log} /> changed internal order status to <b>{Order.getInternalStatusName(log.newValues.internalStatus ? log.newValues.internalStatus : log.oldValues.internalStatus)}</b>.
        </React.Fragment>}
        {!log.author && <span>Order internal status changed to <b>{Order.getInternalStatusName(log.newValues.internalStatus ? log.newValues.internalStatus : log.old.internalStatus)}</b></span>}
        <p className="mb-1"><EventTime log={log} /></p>
        <p className="mb-0"><b>{log.newValues.escalationReason ? log.newValues.escalationReason : log.oldValues.escalationReason}</b></p>
      </div>
    </div>
  </React.Fragment>
}

OrderInternalStatusChanged.propTypes = {
  log: PropTypes.object,
}

export default OrderInternalStatusChanged;