import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import Confirmation from "../../../../../components/Shared/Confirmation";
import { showError, showSuccess } from "../../../../../helpers/utilHelper";
import { deletePaymentPlanFee } from "../../../../../helpers/backendHelper";

const ViewInfo = ({ isRefreshing, id, customFee, toggleEditMode }) => {
  // hooks that check permissions
  const { iAmGranted } = useAccess();
  // router hook that helps redirect
  const navigate = useNavigate();

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const removeFeeFromPaymentPlan = () => {
    setIsDeleteInProgress(true);
    deletePaymentPlanFee(id)
      .then(() => {
        showSuccess(`"${customFee.additionalFeeName}" has been deleted`);
        navigate(route(routes.view_payment_plan, customFee.paymentPlanId));
      })
      .catch(err => {
        showError('Unable to delete signer');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      })
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{customFee.additionalFeeName}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.create_payment_plans) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit fee
              </Button>}
              {iAmGranted(perms.create_payment_plans) && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete fee
              </Button>}
              <Link to={route(routes.view_payment_plan, customFee.paymentPlanId)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <Col>
            <label className="mb-0">Name</label>
            <div>{customFee.additionalFeeName}</div>
          </Col>
          <Col>
            <label className="mb-0">Price</label>
            <div>{customFee.isPercentage ? customFee.price + '%' : '$' + customFee.price}</div>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Delete"
      reverseButtons={false}
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeFeeFromPaymentPlan();
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete &quot;{customFee.additionalFeeName} &quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  customFee: PropTypes.object,
  toggleEditMode: PropTypes.func,
  id: PropTypes.number,
};

export default ViewInfo;
