import React from 'react';
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";

const QuickbooksHeader = ({isConnected}) => {

	const connectionStatus = isConnected ? {
		className: "badge rounded-pill bg-success ms-2 font-size-10",
		text: "Connected"
	} : {
		className: "badge rounded-pill p-1 bg-warning ms-2 font-size-10",
		text: "Disconnected"
	};

	return(<Row>
			<Col md={8}>
					<h6 className='font-size-15 mb-1 font-weight-semibold'>Quickbooks connection</h6>
					<p className='header-description font-size-13'>Seamlessly Integrate with QuickBooks for Effortless Financial Management</p>
			</Col>
			<Col md={4} className='p-0'>
				<div className='d-flex align-items-center'>
					<h5 className='font-size-10 mb-0 status-label'>Connection Status</h5>
					<div className={connectionStatus.className}>{connectionStatus.text}</div>
				</div>
			</Col>
		</Row>
	)
}

QuickbooksHeader.propTypes = {
	isConnected: PropTypes.bool,
};

export default QuickbooksHeader;