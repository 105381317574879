export default class OrderNotary {

  static STATUS_ORDER_INCOMPLETE = -1;
  static STATUS_ASSIGNED = 0;
  static STATUS_DOCS_RECEIVED = 1;
  static STATUS_APPOINTMENT_SET = 2;
  static STATUS_APPOINTMENT_DECLINED = 3;
  static STATUS_APPOINTMENT_ACCEPTED = 4;
  static STATUS_DOCS_PRINTED = 5;
  static STATUS_DOCS_UPLOADED = 6;
  static STATUS_ORDER_COMPLETE = 7;

  static VIRTUAL_STATUS_PROCESSING = 0;
  static VIRTUAL_STATUS_COMPLETE = 1;

  static getStatusMap() {
    return {
      [this.STATUS_ASSIGNED]: 'Assigned to Notary',
      [this.STATUS_DOCS_RECEIVED]: 'Docs Received',
      [this.STATUS_APPOINTMENT_SET]: 'Appointment Sent',
      [this.STATUS_APPOINTMENT_DECLINED]: 'Customer Declined Appointment',
      [this.STATUS_APPOINTMENT_ACCEPTED]: 'Customer Accepted Appointment',
      [this.STATUS_DOCS_PRINTED]: 'Docs Printed',
      [this.STATUS_DOCS_UPLOADED]: 'Docs Uploaded',
      [this.STATUS_ORDER_COMPLETE]: 'Completed by Notary',
    };
  }

  static getStatusMapColor() {
    return {
      [this.STATUS_ASSIGNED]: 'assigned-notary',
      [this.STATUS_DOCS_RECEIVED]: 'docs-received',
      [this.STATUS_APPOINTMENT_SET]: 'appointment-sent',
      [this.STATUS_APPOINTMENT_DECLINED]: 'appointment-declined',
      [this.STATUS_APPOINTMENT_ACCEPTED]: 'appointment-accepted',
      [this.STATUS_DOCS_PRINTED]: 'docs-printed',
      [this.STATUS_DOCS_UPLOADED]: 'docs-uploaded',
      [this.STATUS_ORDER_COMPLETE]: 'completed-notary',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getGranularStatusColor(id) {
    return this.getStatusMapColor()[id];
  }

  static getVirtualStatusMap() {
    return {
      [this.VIRTUAL_STATUS_PROCESSING]: 'Processing',
      [this.VIRTUAL_STATUS_COMPLETE]: 'Completed',
    };
  }

  static getVirtualStatusName(id) {
    return this.getVirtualStatusMap()[id];
  }

  static getVirtualStatus(id) {
    if (id < this.STATUS_ORDER_COMPLETE)
      return this.VIRTUAL_STATUS_PROCESSING;
    else return this.VIRTUAL_STATUS_COMPLETE;
  }

  static getVirtualStatusColor(id) {
    switch (id) {
      case this.VIRTUAL_STATUS_PROCESSING:
        return 'warning';
      case this.VIRTUAL_STATUS_COMPLETE:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getNotaryInfoStatusColor(status) {
    switch (status) {
      case this.STATUS_ASSIGNED:
        return 'bg-warning';
      case this.STATUS_DOCS_RECEIVED:
        return 'bg-orange';
      case this.STATUS_APPOINTMENT_SET:
      case this.STATUS_APPOINTMENT_ACCEPTED:
        return 'bg-primary';
      case this.STATUS_APPOINTMENT_DECLINED:
        return 'bg-danger';
      case this.STATUS_DOCS_PRINTED:
      case this.STATUS_DOCS_UPLOADED:
        return 'bg-info';
      case this.STATUS_ORDER_COMPLETE:
        return 'bg-success';
      default:
        return '';
    }
  }

}