import {
  GET_PAYMENT_DT,
  GET_PAYMENT_DT_ERR,
  GET_PAYMENT_DT_OK,
  DO_PAYMENT_DT_CLEANUP,

  APPLY_PAYMENT_DT_FILTERS,
  CLEAR_PAYMENT_DT_FILTERS,
  PATCH_PAYMENT_DT_FILTERS,
  UNSET_PAYMENT_DT_FILTERS,

  DO_PAYMENT_DT_FILTERS_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getPaymentDt = params => ({
  type: GET_PAYMENT_DT,
  payload: { params },
});

export const getPaymentDtOk = response => ({
  type: GET_PAYMENT_DT_OK,
  payload: { response },
});

export const getPaymentDtErr = error => ({
  type: GET_PAYMENT_DT_ERR,
  payload: { error },
});

export const doPaymentDtCleanup = () => ({
  type: DO_PAYMENT_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyPaymentDtFilters = filters => ({
  type: APPLY_PAYMENT_DT_FILTERS,
  payload: { filters },
});

export const patchPaymentDtFilters = filters => ({
  type: PATCH_PAYMENT_DT_FILTERS,
  payload: { filters },
});

export const clearPaymentDtFilters = () => ({
  type: CLEAR_PAYMENT_DT_FILTERS,
});

export const unsetPaymentDtFilters = () => ({
  type: UNSET_PAYMENT_DT_FILTERS,
});

export const doPaymentDtFiltersCleanup = () => ({
  type: DO_PAYMENT_DT_FILTERS_CLEANUP,
});

