import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { Row, Form, Label, FormFeedback, Button, Card, CardHeader, CardBody } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { updateNotaryMeetingDate } from "helpers/backendHelper";
import { nullsToEmptyStrings, showError, showSuccess } from "helpers/utilHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import DatePicker from "components/Shared/DatePicker";

const FormEditNotaryAppointment = props => {

  const { defaultValues, finishedHandler, refreshHandler } = props;

  /********** STATE **********/
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const { order } = useSelector(state => state.Order.Single);

  /********** FORM CONFIG **********/

  const formValidationSchema = {
    meetingTs: Yup.string().trim().nullable(),
  };

  /********** FORM CONFIG **********/
  const formInitialValues = {
    meetingTs: order.orderNotary.meetingTs,
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => saveAppointmentDate(),
  })

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveAppointmentDate = () => {
    setIsSaveInProgress(true);
    updateNotaryMeetingDate(order.orderNotary.id, { meetingTs: formik.values.meetingTs, meetingAddress: order.orderNotary.meetingAddress })
      .then(response => {
        showSuccess('Meeting date has been saved');
        finishedHandler(true);
        refreshHandler();
      })
      .catch(ex => {
        showError('Unable to save meeting date');
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Notary</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form className="pt-1">
          <Row className="mb-4">
            <Col xl="12">
              <Label>Meeting Date</Label>
              <DatePicker
                enableTime
                name="meetingTs"
                onChange={selected => formik.setFieldValue("meetingTs", selected)}
                value={formik.values.meetingTs}
                minDate={new Date().setHours(0, 0, 0, 0)}
                invalid={!!formik.errors.meetingTs}
                placeholder={formatTimestamp(order.orderNotary.meetingTs, formats.SIGNATURE_DATE)}
                onFocus={onFieldFocused}
                format="d M, Y | H:i"
              />
              {!!formik.errors.meetingTs && <FormFeedback type="invalid">{formik.errors.meetingTs}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormEditNotaryAppointment.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
  refreshHandler: PropTypes.func,
};

export default FormEditNotaryAppointment;