import React from "react"
import { Row, Col } from "reactstrap";
import MyProfile from "./Section/MyProfile";
import InactiveOrders from "./Section/InactiveOrders";
import Messages from "./Section/Messages";
import Stats from "./Section/StatsCustomerSupport";

const CustomerSupport = () => {

  return (
    <>
      <Row className="d-flex align-items-stretch mb-4">
        <Col xs={12} xl={4} className="mb-4 mb-xl-0"><MyProfile /></Col>
        <Col xs={12} xl={8} className="mb-4 mb-xl-0"><Messages /></Col>
      </Row>
      <Row className="d-flex align-items-stretch">
        <Stats />
      </Row>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={12} className="mb-4 mb-xl-0"><InactiveOrders /></Col>
      </Row>
    </>
  )
}

export default CustomerSupport;
