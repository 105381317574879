/********** DATATABLE **********/

export const GET_DEALER_STORE_DT = 'GET_DEALER_STORE_DT'
export const GET_DEALER_STORE_DT_OK = 'GET_DEALER_STORE_DT_OK'
export const GET_DEALER_STORE_DT_ERR = 'GET_DEALER_STORE_DT_ERR'
export const DO_DEALER_STORE_DT_CLEANUP = 'DO_DEALER_STORE_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_DEALER_STORE_DT_FILTERS = 'APPLY_DEALER_STORE_DT_FILTERS'
export const CLEAR_DEALER_STORE_DT_FILTERS = 'CLEAR_DEALER_STORE_DT_FILTERS'

/********* LIST *********/

export const GET_DEALER_STORE_LIST = 'GET_DEALER_STORE_LIST'
export const GET_DEALER_STORE_LIST_OK = 'GET_DEALER_STORE_LIST_OK'
export const GET_DEALER_STORE_LIST_ERR = 'GET_DEALER_STORE_LIST_ERR'
export const DO_DEALER_STORE_LIST_CLEANUP = 'DO_DEALER_STORE_LIST_CLEANUP'
export const GET_DEALER_GROUP_STORE_LIST = 'GET_DEALER_GROUP_STORE_LIST'
export const GET_DEALER_REGION_STORE_LIST = 'GET_DEALER_REGION_STORE_LIST'

/********* SUBSCRIPTIONS DATATABLE *********/

export const GET_SUBSCRIPTION_DT = 'GET_SUBSCRIPTION_DT'
export const GET_SUBSCRIPTION_DT_OK = 'GET_SUBSCRIPTION_DT_OK'
export const GET_SUBSCRIPTION_DT_ERR = 'GET_SUBSCRIPTION_DT_ERR'
export const DO_SUBSCRIPTION_DT_CLEANUP = 'DO_SUBSCRIPTION_DT_CLEANUP'