import InactiveIcon from "../../../../assets/images/inactive-icon.svg";
import React from "react";
import {Alert} from "reactstrap";
import PropTypes from "prop-types";

const InactiveMessage = ({time}) => {
	return(
		<Alert color="primary" className='p-4 pt-2 pb-2'>
			<img src={InactiveIcon} alt="inactive-icon" className='me-2' />
			<span className="font-size-13">Your order is now inactive, it has been more than {time} hours since the last change on this order. To reactivate it, please perform an action</span>
		</Alert>)
}

InactiveMessage.propTypes = {
	time: PropTypes.number,
};

export default InactiveMessage;