import config from "config";
import React, {useState, useEffect} from "react";
import {Card, CardBody, Button, Alert} from "reactstrap";
import { getOrderSignerSupportCases } from 'helpers/backendHelper';
import PropTypes from 'prop-types';
import { getSupportCaseServiceWithIcon } from "helpers/jsxHelper";
import { formatTimestamp, formats, subtractHoursFromNow } from "helpers/dateHelper";
import SupportCase from "model/supportCase";
import { Link, } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const SectionSupportCases = ({signerId, supportCaseId}) => {

  /********** STATE **********/
  const [supportCases, setSupportCases] = useState([]);
  const [supportCasesError, setSupportCasesError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  /********** EVENT HANDLERS **********/
  const toggleExpanded = () => setIsExpanded(expanded => !expanded);

  /********** OTHER **********/
  const getTableRows = () => isExpanded ? supportCases : supportCases?.slice(0, config.COLLAPSED_TBL_ROWS);
  const canExpand = () => !isExpanded && supportCases.length > config.COLLAPSED_TBL_ROWS;
  const canCollapse = () => isExpanded;

  const isFlagged = row => (subtractHoursFromNow(config.SUPPORT_CASE_FLAG_THRESHOLD / 3600) > row.updatedTs) && row.status < SupportCase.STATUS_RESOLVED;

  useEffect(() => {
    getSupportCasesList();
  }, [supportCaseId]);

  const getSupportCasesList = () => {
    getOrderSignerSupportCases(signerId)

    .then(response => {
      const suportCaseList = response.supportCases.filter(item => item.id !== supportCaseId);
      setSupportCases(suportCaseList);
    })
    .catch(err => setSupportCasesError(err))
  }

  return <React.Fragment>
    <Card>
      <CardBody className="pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Case ID</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Ticket Creation Time</th>
              <th>Service</th>
              <th>Assigned </th>
            </tr>
          </thead>
          <tbody>
            {getTableRows().length > 0 && getTableRows().map((entry, index) => (
              <tr className={`${entry.createdTs && isFlagged(entry) ? "bg-danger bg-soft" : undefined}`} key={index}>
                <td><Link to={route(routes.view_support_case, entry.id)}>{entry.id}</Link></td>
                <td>
                  <span className={`badge badge-lg rounded-pill bg-${SupportCase.getStatusColor(entry.status)}`}>
                    {entry.statusName}
                    </span>
                  </td>
                <td><span className={`priority-circle bg-${SupportCase.getPriorityColor(entry.priority)}`} />
                  {SupportCase.getPriorityName(entry.priority)}
                </td>
                <td>{formatTimestamp(entry.createdTs, formats.DATETIME)}</td>
                <td>{entry.services.length ? entry.services.map(id => getSupportCaseServiceWithIcon(id)) : '--'}</td>
                <td>{entry.assigneeFullName || '--'}</td>
              </tr>
            ))}
            {getTableRows().length == 0 && !supportCasesError && <tr>
              <td>No support cases found</td>
            </tr>}
            {!!supportCasesError && <tr>
              <td className="table-empty" colSpan="3">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load support cases
                </Alert>
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="text-center">
          {canExpand() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1"></i>View more</Button>}
          {canCollapse() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-up me-1"></i>View less</Button>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionSupportCases.propTypes = {
  signerId: PropTypes.number,
  supportCaseId: PropTypes.number
}

export default SectionSupportCases;
