export default class NotaryBid {

  static STATUS_SELECTED = 0;
  static STATUS_NOTIFIED = 1;
  static STATUS_DECLINED_BY_NOTARY = 2;
  static STATUS_ACCEPTED_BY_NOTARY = 3;
  static STATUS_REJECTED_BY_SCHEDULER = 4;
  static STATUS_AWARDED_BY_SCHEDULER = 5;
  static STATUS_NOT_AWARDED = 6;
  static STATUS_CLOSED_BY_AWARD = 7;

  static DISTANCE_FROM_CUSTOMER_SIGNER = 0;
  static DISTANCE_FROM_DEALER_SIGNER = 1;

  static AVAILABILITY_TODAY = 1
  static AVAILABILITY_TOMORROW = 2
  static AVAILABILITY_WEEKEND = 3

  static getDistanceFromSignerMap() {
    return {
      [this.DISTANCE_FROM_CUSTOMER_SIGNER]: 'Customer',
      [this.DISTANCE_FROM_DEALER_SIGNER]: 'Dealer',
    };
  }

  static getDistanceFromSignerName(id) {
    return this.getDistanceFromSignerMap()[id];
  }

  static getAvailabilityMap() {
    return {
      [this.AVAILABILITY_TODAY]: '24 hours',
      [this.AVAILABILITY_TOMORROW]: '48 hours',
      [this.AVAILABILITY_WEEKEND]: 'Over the weekend',
    };
  }

  static getAvailabilityName(id) {
    return this.getAvailabilityMap()[id];
  }
}