import React, { useEffect, useState } from "react";
import SealingCard from "./SealingCard";
import Col from "components/Shared/Col";

const Sealing = () => {

  const isSealingVisible = () => {
    const target = document.querySelector("#sealing_card");
    const bottom = target?.getBoundingClientRect().bottom || 0;
    return bottom >= 0 && bottom <= window.innerHeight
  }

  const [toast, setToast] = useState(!isSealingVisible());

  /********** EFFECTS **********/

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  }, []);

  /********** HANDLERS **********/

  const showToast = () => {
    setToast(true);
  };

  const hideToast = () => {
    setToast(false);
  };

  const scrollHandler = () => {
    isSealingVisible() ? hideToast() : showToast();
  };

  return <>
    <Col className="text-end">
      <SealingCard id="sealing_card" />
    </Col>
    {toast && (<div className="seal-toast-wrapper">
      <SealingCard id="sealing_toast" />
    </div>)}
  </>
}

export default Sealing;