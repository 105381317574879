import React from "react";
import PropTypes from 'prop-types';
import ViewProcessInfo from "../View/ProcessInfo";

const SectionProcessInfo = props => {

  return <React.Fragment>
    <ViewProcessInfo {...props} />
  </React.Fragment>
}

SectionProcessInfo.propTypes = {
  process: PropTypes.object,
  refreshHandler: PropTypes.func,
  channel: PropTypes.number,
  setChannelHandler: PropTypes.func,
  lines: PropTypes.number,
  setLinesHandler: PropTypes.func,
  isLoadInProgress: PropTypes.bool,
};

export default SectionProcessInfo;
