import React from "react"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import FormInfoNew from "./Partial/Form/Info/New";

const New = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  return <React.Fragment>
    {iAmGranted(perms.create_payment_plans) && <div className="page-content">
      <MetaTitle>New payment plan</MetaTitle>
      <Container fluid>
        <Breadcrumbs breadcrumbItems={breadcrumbs()} />
        <Row>
          <Col xl="8" xxl="6">
            <FormInfoNew
              successRoute={route(routes.list_payment_plans)}
              cancelRoute={route(routes.list_payment_plans)} />
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.create_payment_plans) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: "PAYMENT PLANS",
  url: route(routes.list_payment_plans),
}, {
  title: "New payment plan",
}];

export default New;
