import { combineReducers } from 'redux';
import {
  GET_VID_REQUEST_NORM_LIST,
  GET_VID_REQUEST_NORM_LIST_OK,
  GET_VID_REQUEST_NORM_LIST_ERR,
  GET_VID_REQUEST_NORM_LIST_ITEM,
  GET_VID_REQUEST_NORM_LIST_ITEM_OK,
  GET_VID_REQUEST_NORM_LIST_ITEM_ERR,
  DO_VID_REQUEST_NORM_LIST_CLEANUP,
} from './actionTypes';

/********** NORMALIZED LIST **********/

const defaultNormListState = {
  vidRequestIds: [],
  vidRequests: [],
  vidRequestsError: null,
  isLoadInProgress: false,
};

const NormList = (state = defaultNormListState, action) => {
  switch (action.type) {
    // get list
    case GET_VID_REQUEST_NORM_LIST:
      state = {
        ...state,
        vidRequestsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_VID_REQUEST_NORM_LIST_OK:
      const ids = [];
      const requests = action.payload.response.vidRequests.reduce((prev, curr) => {
        ids.push(curr.id);
        return { ...prev, [curr.id]: curr };
      }, {});
      state = {
        ...state,
        vidRequestIds: ids,
        vidRequests: requests,
        isLoadInProgress: false,
      };
      break
    case GET_VID_REQUEST_NORM_LIST_ERR:
      state = {
        ...state,
        vidRequests: [],
        vidRequestsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // get row
    case GET_VID_REQUEST_NORM_LIST_ITEM: {
      const requestId = action.payload.id;
      const request = state.vidRequests[requestId];
      request.isFetchInProgress = true;
      request.fetchError = null;
      state = {
        ...state,
        vidRequests: {
          ...state.vidRequests,
          [requestId]: { ...request },
        },
      };
      break;
    }
    case GET_VID_REQUEST_NORM_LIST_ITEM_OK: {
      const requestId = action.payload.id;
      state = {
        ...state,
        vidRequests: {
          ...state.vidRequests,
          [requestId]: action.payload.response.vidRequest,
        },
      };
      break;
    }
    case GET_VID_REQUEST_NORM_LIST_ITEM_ERR: {
      const requestId = action.payload.id;
      const request = state.vidRequests[requestId];
      request.isFetchInProgress = false;
      request.fetchError = action.payload.error;
      state = {
        ...state,
        vidRequests: {
          ...state.vidRequests,
          [requestId]: { ...request },
        },
      };
      break;
    }
    // cleanup
    case DO_VID_REQUEST_NORM_LIST_CLEANUP:
      state = { ...defaultNormListState };
      break;
  }
  return state;
}

export default combineReducers({
  NormList,
})