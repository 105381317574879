import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";
import { showSuccess, showError } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { formats, formatTimestamp } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import { deleteDealerGroup } from "helpers/backendHelper";

const ViewInfo = props => {

  const { isRefreshing, id, dealerGroup, toggleEditMode } = props;

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // is the confirmation dialog visible or not
  // used to show/hide the delete warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  // when deleting the group dealer is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /********** EVENT HANDLERS **********/

  // delete-group event handler
  // make the remote call to delete the group
  const removeDealerGroup = () => {
    setIsDeleteInProgress(true);
    deleteDealerGroup(id)
      .then(response => {
        const name = dealerGroup.name;
        showSuccess(`Dealer group "${name}" has been deleted`);
        navigate(route(routes.list_dealer_groups));
      })
      .catch(ex => {
        showError('Unable to delete dealer group');
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  };

  const getFullAddress = group => {
    const parts = [];
    const fields = ['address', 'city', 'state', 'zip'];
    for (const field of fields) {
      if (!!group[field]) {
        parts.push(group[field]);
      }
    }
    if (!!parts.length) {
      return parts.join(', ');
    }
    return '--';
  }

  const getContactInfo = group => {
    const parts = [];
    const fields = ['email', 'phone'];
    for (const field of fields) {
      if (!!group[field]) {
        parts.push(group[field]);
      }
    }
    if (!!parts.length) {
      return <React.Fragment>
        {parts
          .map((part, index) => <React.Fragment key={index}>{part}</React.Fragment>)
          .reduce((result, part, index) => [result, <span className="value-sep" key={`sep${index}`}>|</span>, part])}
      </React.Fragment>
    }
    return '--';
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{dealerGroup.name}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_groups) && <Button type="button" color="primary" className="ms-2 mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit group
              </Button>}
              {iAmGranted(perms.delete_dealer_groups) && <Button type="button" color="danger" className="ms-2 mb-2" onClick={() => { setIsConfirmationVisible(true) }} disabled={isDeleteInProgress}>
                {isDeleteInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isDeleteInProgress && <i className="mdi mdi-delete me-1" />}
                Delete group
              </Button>}
              <Link to={route(routes.list_dealer_groups)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          <Col xs={12} xl="4">
            <label className="mb-0">Address</label>
            <div>{getFullAddress(dealerGroup)}</div>
          </Col>
          <Col xs={12} xl="4">
            <label className="mb-0">Contact</label>
            <div>{getContactInfo(dealerGroup)}</div>
          </Col>
          <Col xs="6" xl="4" className="mt-2">
            <h5 className="font-size-14">Check Payments</h5>
            <p className="text-muted mb-0">{dealerGroup.canPayWithCheck ? "Enabled" : "Disabled"}</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerGroup.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerGroup.updatedTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4" className="mt-2">
            <h5 className="font-size-14">Uses Coupa</h5>
            <p className="text-muted mb-0">{dealerGroup.usesCoupa ? "Yes" : "No"}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      reverseButtons={false}
      confirmBtnText="Delete"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        removeDealerGroup();
      }}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to delete dealer group &quot;{dealerGroup.name}&quot;?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  dealerGroup: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;
