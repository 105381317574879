/********** DATATABLE **********/

export const GET_DEALER_GROUP_DT = 'GET_DEALER_GROUP_DT'
export const GET_DEALER_GROUP_DT_OK = 'GET_DEALER_GROUP_DT_OK'
export const GET_DEALER_GROUP_DT_ERR = 'GET_DEALER_GROUP_DT_ERR'
export const DO_DEALER_GROUP_DT_CLEANUP = 'DO_DEALER_GROUP_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_DEALER_GROUP_DT_FILTERS = 'APPLY_DEALER_GROUP_DT_FILTERS'
export const CLEAR_DEALER_GROUP_DT_FILTERS = 'CLEAR_DEALER_GROUP_DT_FILTERS'

/********* LIST *********/

export const GET_DEALER_GROUP_LIST = 'GET_DEALER_GROUP_LIST'
export const GET_DEALER_GROUP_LIST_OK = 'GET_DEALER_GROUP_LIST_OK'
export const GET_DEALER_GROUP_LIST_ERR = 'GET_DEALER_GROUP_LIST_ERR'
export const DO_DEALER_GROUP_LIST_CLEANUP = 'DO_DEALER_GROUP_LIST_CLEANUP'