import { useEffect } from 'react';

const ExternalRedirect = props => {

  useEffect(() => {
    window.location.replace(props.to);
  }, [props.to]);

  return null;
}

export default ExternalRedirect;