import React from "react"
import { Row, Col } from "reactstrap";
import MyProfile from "./Section/MyProfile";
import CustomerPerformance from "./Section/CustomerPerformance";
import Leaderboard from "./Section/Leaderboard";
import InactiveOrders from "./Section/InactiveOrders";
import Subscriptions from "./Section/Subscriptions";
import Appointments from "./Section/Appointments";

const Admin = () => {

  return (
    <>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={4} className="mb-4"><MyProfile /></Col>
        <Col xs={12} xl={8} className="mb-4 mb-xl-0"><CustomerPerformance /></Col>
      </Row>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={4} className="mb-4 mb-xl-0"><Leaderboard /></Col>
        <Col xs={12} xl={8} className="mb-4 mb-xl-0"><Appointments /></Col>
      </Row>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={12} className="mb-4 mb-xl-0"><Subscriptions /></Col>
        <Col xs={12} xl={12} className="mb-4 mb-xl-0"><InactiveOrders /></Col>
      </Row>
    </>
  )
}

export default Admin;
