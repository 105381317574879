import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

const SpinnerChase = props => {

  return <div className={classnames('spinner-chase', props.className)}>
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
    <div className="chase-dot" />
  </div>
}

SpinnerChase.propTypes = {
  className: PropTypes.string,
}

export default SpinnerChase;