import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_USER_ROLE_LIST,
} from "./actionTypes";

import {
  getUserRoleListOk,
  getUserRoleListErr,
} from "./actions";

import {
  getUserRoles,
} from "helpers/backendHelper";

/********** LIST **********/

function* onGetUserRoleList() {
  try {
    const response = yield call(getUserRoles);
    yield put(getUserRoleListOk(response));
  } catch (error) {
    yield put(getUserRoleListErr(error));
  }
}

function* userRoleSaga() {
  yield takeEvery(GET_USER_ROLE_LIST, onGetUserRoleList);
}

export default userRoleSaga;
