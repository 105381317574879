import React from "react"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import DataTable from "./Partial/DataTable";

const List = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  return <React.Fragment>
    {iAmGranted(perms.view_users) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>Users</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="USERS" />
          <Row>
            <Col>
              <DataTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>}
    {iAmNotGranted(perms.view_users) && <AccessDenied />}
  </React.Fragment>
}

export default List;
