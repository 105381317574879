import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Alert } from "reactstrap";
import Col from "components/Shared/Col";
import PropTypes from "prop-types";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import ActivityEntry from "components/Shared/ActivityEntry";
import { getOrderActivity } from "helpers/backendHelper";

const SectionActivity = props => {

  const { id } = props;

  /********** STATE **********/

  const [logs, setLogs] = useState([]);
  const [logsError, setLogsError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);


  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    refreshLogs();
  }, [id]);

  /********** OTHER **********/

  const refreshLogs = () => {
    setIsLoadInProgress(true);
    getOrderActivity(id, 2)
      .then(response => {
        setLogs(response.activityLogs);
      })
      .catch(ex => {
        setLogsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Activity</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        {!!logs.length && <ul className="verti-timeline list-unstyled">
          {logs.map(log => <ActivityEntry key={log._id} log={log} noDetails={true} />)}
        </ul>}
        {!logs.length && !logsError && <p>No activity</p>}
        {!!logsError && <div>
          <Alert color="danger" className="fade show text-center">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load activity
          </Alert>
        </div>}
        {isLoadInProgress && <SpinnerChase className="sm mt-2 mb-2" />}
        <div className="text-end mt-4">
          <Link to={route(routes.view_order_activity, id)} className='btn btn-primary'>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionActivity.propTypes = {
  id: PropTypes.number,
};

export default SectionActivity;