import React from "react";
import { Button } from "reactstrap";
import UserAvatar from "components/Shared/UserAvatar";
import { useAuth } from "context/auth";
import { getInitialsFromName, getSsoAppUrl } from "helpers/utilHelper";

const MyProfile = () => {
  // hook that gets the user from context
  const { user } = useAuth();

  return <div className="my-profile">
    <div className="my-profile-header">
      <h5>Welcome Back!</h5>
    </div>
    <UserAvatar id={user.id} image={user.image} initials={getInitialsFromName(user.fullName)} size="xl" className="my-profile-avatar" />
    <div className="my-profile-content">
      <div className="d-flex justify-content-between">
        <div className="d-inline">
          <h5 className="font-size-15">{user.getFullName()}</h5>
          <p className="text-muted font-size-13">{user.userRoleName}</p>
        </div>
        <a href={getSsoAppUrl('me')}>
          <Button color="primary">
            View Profile <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </div>
    </div>
  </div>
}

export default MyProfile