import { combineReducers } from "redux";
import {
  GET_ORDER_FEES,
  GET_ORDER_FEES_OK,
  GET_ORDER_FEES_ERR,
  DO_ORDER_FEES_CLEANUP,

  GET_ORDER_AVAILABLE_FEES,
  GET_ORDER_AVAILABLE_FEES_OK,
  GET_ORDER_AVAILABLE_FEES_ERR,
  DO_ORDER_AVAILABLE_FEES_CLEANUP,

  UPDATE_DEALER_ACCOUNTING,
  UPDATE_NOTARY_ACCOUNTING,
  UPDATE_QUICKBOOK_ACCOUNTING,

  SAVE_DEALER_OK,
  SAVE_DEALER_ERR,
  DO_DEALER_FORM_CLEANUP,

  DO_NOTARY_FORM_CLEANUP,
  SAVE_NOTARY_ERR,
  SAVE_NOTARY_OK,

} from './actionTypes';

/********* SINGLE *********/
const defaultSingleState = {
  fees: null,
  feesError: null,
  isLoadInProgress: false,
}

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    case GET_ORDER_FEES:
      state = {
        ...state,
        feesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_FEES_OK:
      state = {
        ...state,
        fees: action.payload.response.fees,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_FEES_ERR:
      state = {
        ...state,
        fees: null,
        feesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_FEES_CLEANUP:
      state = { ...defaultSingleState };
      break
  }

  return state;
}

/********* LIST *********/

const defaultListState = {
  availableFees: null,
  availableFeesError: null,
  isLoadInProgress: false,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_ORDER_AVAILABLE_FEES:
      state = {
        ...state,
        availableFeesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_AVAILABLE_FEES_OK:
      state = {
        ...state,
        availableFees: action.payload.response.fees,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_AVAILABLE_FEES_ERR:
      state = {
        ...state,
        availableFees: null,
        availableFeesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_AVAILABLE_FEES_CLEANUP:
      state = { ...defaultListState };
      break
  }

  return state;
}

/********** FORM **********/

const defaultFormState = {
  accounting: null,
  savedDealer: null,
  saveDealerError: null,
  savedNotary: null,
  saveNotaryError: null,
  isSaveInProgress: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case UPDATE_DEALER_ACCOUNTING:
    case UPDATE_NOTARY_ACCOUNTING:
    case UPDATE_QUICKBOOK_ACCOUNTING:
      state = {
        ...state,
        accounting: action.payload.data,
        savedDealer: null,
        savedNotary: null,
        saveDealerError: null,
        saveNotaryError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_DEALER_OK:
      state = {
        ...state,
        savedDealer: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_DEALER_ERR:
      state = {
        ...state,
        savedDealer: false,
        savedDealerError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_DEALER_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
    case SAVE_NOTARY_OK:
      state = {
        ...state,
        savedNotary: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_NOTARY_ERR:
      state = {
        ...state,
        savedNotary: false,
        saveNotaryError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_NOTARY_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}


export default combineReducers({
  Single,
  List,
  Form,
})