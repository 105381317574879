import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ViewInfo from "../View/Info";
import FormInfoEdit from "../Form/Info/Edit";
import { Card, CardBody, Row, Col, Dropdown, DropdownToggle } from "reactstrap";
import { formats, formatTimestamp, timeSinceShort } from "helpers/dateHelper";
import Order from "model/order";
import { route, routes } from "helpers/routeHelper";
import ViewQuickActions from "../View/QuickActions";
import ViewTags from "../View/Tags";
import ViewComments from "../View/Comments";
import SupportCase from "model/supportCase";
import ViewUploads from "../View/Uploads";
import { getGranularStatusName } from "helpers/utilHelper";
import { perms, useAccess } from "context/access";

const SectionInfo = ({ refreshHandler, supportCase }) => {

  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const { id, status, orderId, orderGranularStatus, customerFullName, createdTs, orderSubmittedTs, firstResponseTs } = supportCase;

  const isResolved = SupportCase.isResolved(status);

  // toggle edit mode
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  /********** HANDLERS **********/

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      refreshHandler();
    }
  };

  return <React.Fragment>
    <Card>
      <CardBody>
        <Row>
          <Col xs="6">
            <div>Case: <strong>#{id}</strong></div>
            <div>Signing ID: <Link to={route(routes.view_order, orderId)} target="_blank" className="text-inherit"><strong>#{orderId}</strong></Link></div>
            <div>Customer Name: <Link to={route(routes.view_order, orderId)} target="_blank" className="text-inherit"><strong>{customerFullName || "--"}</strong></Link></div>
            <div>Order Status: <span className={`text-${Order.getGranularStatusColor(orderGranularStatus)}`}><strong>{getGranularStatusName(orderGranularStatus) || "--"}</strong></span></div>
          </Col>
          <Col xs="6" className="d-flex justify-content-end">
            <div className="text-end me-3">
              <div>Ticket Creation Time: <strong>{formatTimestamp(createdTs, formats.DATETIME)}</strong></div>
              <div>Submitted Time: <strong>{formatTimestamp(orderSubmittedTs, formats.DATETIME)}</strong></div>
              <div>DCS Response Time: <strong>{firstResponseTs ? timeSinceShort(createdTs, firstResponseTs) : "--"}</strong></div>
            </div>
          </Col>
        </Row>

        <div className="divider-faded" />

        <ViewQuickActions supportCase={supportCase} />

        <div className="divider-faded" />

        <Row>
          <Col className="d-flex justify-content-between align-items-end">
            <h6 className="mb-3">Ticket information</h6>
            {!isResolved && iAmGranted(perms.edit_support_cases) && (<Dropdown toggle={toggleEditMode}>
              <DropdownToggle className="btn nav-btn" tag="i">
                {isEditModeActive ? <i className="mdi mdi-close font-size-18" /> : <i className="mdi mdi-lead-pencil font-size-16" />}
              </DropdownToggle>
            </Dropdown>)}
          </Col>
        </Row>

        {isEditModeActive && <FormInfoEdit defaultValues={supportCase} finishedHandler={toggleEditMode} />}
        {!isEditModeActive && <ViewInfo supportCase={supportCase} />}

        <div className="divider-faded" />

        <ViewUploads supportCase={supportCase} />

        <div className="divider-faded" />

        <ViewTags supportCase={supportCase} />

        <div className="divider-faded" />

        <ViewComments supportCase={supportCase} />
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionInfo.propTypes = {
  refreshHandler: PropTypes.func.isRequired,
  supportCase: PropTypes.object
};

export default SectionInfo;
