import React, { useEffect, useRef, useState } from 'react';
import { closeSocket, openSocket } from 'helpers/socketHelper';
import { ACC_TOKEN_JWT_EXPIRED, ACC_TOKEN_MISSING } from 'helpers/errorHelper';
import { refreshAccessToken } from 'helpers/apiHelper';
import Preloader from 'components/Shared/Preloader';

export const SocketContext = React.createContext({ socket: null });

const SocketProvider = props => {

  // we use a ref to store the socket client as it won't be updated frequently
  // when setting the initial value of the ref, mind the following issue
  // https://beta.reactjs.org/reference/react/useRef#avoiding-recreating-the-ref-contents
  const socket = useRef(null);
  if (socket.current === null) {
    socket.current = openSocket();
  }

  // store the socket id in state
  // this will be updated every time the socket connects
  // we do not actually need the value
  // but we need a way to re-render the descendant components when the socket disconnects and reconnects
  // allowing them to re-subscribe to events with the new socket connection
  const [socketId, setSocketId] = useState(socket.current.id);
  // store the connection error in state
  // we do not actually need the value
  // but we need a way to wait untill the socket either connects or fails to connect
  const [connErr, setConnErr] = useState();

  // handler that helps emit an event
  const socketEmit = (event, data) => socket.current.emit(event, data);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('connect', () => {
        console.log('Socket connected. Id', socket.current.id);
        setSocketId(socket.current.id);
      });
      socket.current.on('connect_error', err => {
        console.error('Socket connection error:', err.data || err);
        // if this is the first connection attempt then, because it failed, there is no socketId
        // so we need another way to let the execution continue
        if (!socketId) {
          setConnErr(true);
        }
        // check if the client is unable to connect because the access token has expired
        // maybe because the app has been idle for a while
        if (shouldRefreshToken(err)) {
          // refresh the access token
          refreshAccessToken()
            .then(() => {
              console.log('Socket access token refreshed');
              // in this case the socket will not reconnect automatically
              socket.current.connect();
            })
            .catch(ex => console.error('Socket refresh access token failed', ex));
        }
      });
      socket.current.on('error', err => {
        console.error('Socket error:', err);
      });
      socket.current.on('disconnect', reason => {
        console.error('Socket disconnected:', reason);
      });
      socket.current.io.on('reconnect_attempt', () => {
        console.log('Socket attempting to reconnect');
      });
      socket.current.io.on('reconnect', () => {
        console.log('Socket reconnected');
      });
    }
    // Remove all the listeners and close the socket upon unmount
    return () => closeSocket(socket.current);
  }, []);

  // wait untill either the socket is connected or the connection fails
  // we want to fail silently if the socket connection fails
  // in the same time we want to (ideally) connect first before continuing execution
  if (!!socketId || connErr) {
    return <SocketContext.Provider value={{
      socket: socket.current,
      socketEmit: socketEmit,
    }} {...props} />
  }
  return <Preloader />
}

const shouldRefreshToken = error => !!error.data && [ACC_TOKEN_MISSING, ACC_TOKEN_JWT_EXPIRED].includes(error.data.code);

export const useSocket = () => React.useContext(SocketContext);

export default SocketProvider;