import Statement from "../model/statement";

export default [
  {
    id: Statement.TYPE_DEALER_SINGLE_ORDER,
    name: 'Notary'
  },
  {
    id: Statement.TYPE_DEALER_COLLECTIVE_REMOTE,
    name: 'Weekly Remote'
  },
  {
    id: Statement.TYPE_DEALER_COLLECTIVE_INSTORE,
    name: 'Monthly Instore'
  },
  {
    id: Statement.TYPE_DEALER_SUBSCRIPTION,
    name: 'Subscription'
  },
];