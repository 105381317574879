import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, FormFeedback, CardTitle } from "reactstrap";
import classnames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import { nullsToEmptyStrings, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { updateDealerStoreBilling } from "helpers/backendHelper";

/* This is the billing form with only the comments field visible */
const FormCommentsEdit = props => {

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  // billing email is an array of email addresses or null
  // however BE expects a string of comma separated values
  let defaultBillingEmail = defaultValues.billingEmail;
  if (!!defaultBillingEmail && Array.isArray(defaultBillingEmail)) {
    defaultBillingEmail = defaultBillingEmail.join(', ');
  }

  const formInitialValues = {
    billingName: '',
    billingPhone: '',
    billingPhone2: '',
    billingAccountNo: '',
    comments: '',
    ...nullsToEmptyStrings(defaultValues),
    billingEmail: defaultBillingEmail || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({}),
    onSubmit: values => saveDealerStoreComments(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  /********** OTHER **********/

  const saveDealerStoreComments = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreBilling(values, id)
      .then(response => {
        showSuccess(`Dealer store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save dealer store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Comments</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Col>
              <TextareaAutosize maxRows={15} className={classnames('form-control', { 'is-invalid': !!formik.errors.comments })} name="comments" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.comments} />
              {!!formik.errors.comments && <FormFeedback type="invalid">{formik.errors.comments}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormCommentsEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormCommentsEdit;