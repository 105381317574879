import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import FormEditTrackingAndShipping from "../Form/Edit/TrackingAndShipping";
import ViewTrackingAndShipping from "../View/TrackingAndShipping";

const SectionTrackingAndShipping = ({ isLoadBusy, id, order, refreshHandler, isLocked }) => {

  /********** STATE **********/
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  /********** EFFECTS **********/
  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  /********** EVENT HANDLERS **********/
    // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormEditTrackingAndShipping defaultValues={order} id={id} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewTrackingAndShipping id={id} order={order} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} isLocked={isLocked} />}
  </React.Fragment>
}

SectionTrackingAndShipping.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  order: PropTypes.object,
  refreshHandler: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default SectionTrackingAndShipping;