import React from "react";
import PropTypes from "prop-types";
import { Row, Card, CardBody } from "reactstrap";
import Col from "components/Shared/Col";

const SealingCard = ({ id }) => {

  return <Card id={id} className="text-start d-inline-block">
    <CardBody>
      <Row>
        <Col>
          <div className="card-title mb-0">Sealing order</div>
          Waiting for the order to be sealed
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <i className="fas fa-spinner fa-spin seal-spinner-icon"></i>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

SealingCard.propTypes = {
  id: PropTypes.string
}

export default SealingCard;