import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Row, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import Order from 'model/order';
import OrderDoc from 'model/orderDoc';
import VidRequest from 'model/vidRequest';
import { useDispatch } from 'react-redux';
import { getGranularStatusName, getVIDStatusImage } from 'helpers/utilHelper';
import { doOrderDocNormListCleanup } from 'store/actions';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import multipleSignersIcon from 'assets/images/multiple-signers.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import noThumbprintIcon from 'assets/images/no-thumbprint-icon.svg';
import {
  getOrderInkSignStatus,
  getOrderESignStatus,
  getOrderVidStatus,
  getESignOrderDocs,
} from "helpers/backendHelper";
import Col from "components/Shared/Col";
import inactiveIcon from "assets/images/inactive-order.svg";

const SectionOrderHeader = ({ order, id }) => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const [inkSignStatus, setInkSignStatus] = useState(null);
  const [inkSignStatusError, setInkSignStatusError] = useState(null);

  const [eSignStatus, setESignStatus] = useState(null);
  const [eSignStatusError, setESignStatusError] = useState(null);

  const [vidStatus, setVidStatus] = useState(null);
  const [vidStatusError, setVidStatusError] = useState(null);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  /********** CONSTANTS ********/

  const isOrderCancelled = () => order.status === Order.STATUS_CANCELLED;

  const isOrderDraft = () => order.status === Order.STATUS_DRAFT;

  const orderHasAccountingReview = () => !!order.accountingReviewStatus;

  const orderHasOnHoldReview = () => !!order.onHoldReviewStatus;

  const orderHasDuplicateReview = () => !!order.duplicateReviewStatus;

  const orderHasUnderReview = () => !!order.underReviewStatus;

  const color = Order.getGranularStatusColor(order.granularStatus);

  // Calculate the time difference in hours
  const timePassedInHours = Math.floor((Date.now() - order.updatedTs * 1000) / 3600000);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    if (order.isInkSignRequired)
      refreshOrderInkSignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isEsignRequired)
      refreshOrderESignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isVidRequired);
    refreshOrderVidStatus();
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    // make the initial remote call to get the data
    refreshESignOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocNormListCleanup());
    }
  }, [order.id]);

  /********** OTHER **********/

  const refreshOrderInkSignStatus = () => {
    getOrderInkSignStatus(id)
      .then(response => {
        setInkSignStatus(response.inkSign);
      })
      .catch(ex => {
        setInkSignStatusError(ex);
      })
  };

  const refreshOrderESignStatus = () => {
    getOrderESignStatus(id)
      .then(response => {
        setESignStatus(response.eSign);
      })
      .catch(ex => {
        setESignStatusError(ex);
      })
  };

  const refreshOrderVidStatus = () => {
    // make the initial remote call to get the data
    getOrderVidStatus(id)
      .then(response => {
        setVidStatus(response.vid);
      })
      .catch(ex => {
        setVidStatusError(ex);
      })
  };

  const refreshESignOrderDocs = () => {
    getESignOrderDocs(id)
      .then(response => {
        setESignOrderDocs(response.orderDocs);
      })
      .catch(err => {
      })
  };

  // Update screen width state variable
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const renderDesktopView = () => {
    return (<>
      <Card className="mt-1">
        <CardBody className="px-0 pb-0">
          <div className={classnames('card-section pb-3')}>
            <div className='order-actions'>
              <div className='d-flex align-items-center mb-3'>
                <span className='header-title font-weight-semibold'>Order ID {order.id}</span>
                {order.isInactive && order.status != Order.STATUS_CANCELLED && (<span className='ms-5'>
                  <img src={inactiveIcon} />
                  <span className="ms-2">Inactive({timePassedInHours}h)</span>
                </span>)}
              </div>
              <div className='row'>
                <div className="col-2 pe-0">
                  <div className='mb-2'>
                    <p className='d-inline font-weight-normal header-stats'>Status:</p>
                    <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
                  </div>
                  <div>
                    {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
                    {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
                    {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
                      <><img id={`doc-delivery-shipping`} src={docDeliveryShippingIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping`}>Documents shipping</UncontrolledTooltip></>
                    }
                    {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
                      <><img id={`doc-delivery-upload`} src={docDeliveryUploadIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload`}>Documents upload</UncontrolledTooltip></>
                    }
                    {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
                    {order.notaryMustUploadDocs && <><img id='must-upload-docs' src={mustUploadDocsIcon} className='me-2 must-upload-icon' /><UncontrolledTooltip placement="top" target='must-upload-docs'>Notary must upload docs</UncontrolledTooltip></>}
                    {!order.isAoiThumbprintRequired && order.isNotaryRequired && <><img id='no-thumbprint-aoi' src={noThumbprintIcon} className='me-2' /><UncontrolledTooltip placement="top" target='no-thumbprint-aoi'>No Thumbprint AOI</UncontrolledTooltip></>}
                  </div>
                </div>
                {!isOrderDraft() && <div className="col-5 d-flex display-status">
                  {order.isVidRequired && !!vidStatus && <>
                    <div className="divider me-2"></div>
                    <div className='d-flex align-items-start'>
                      {getVIDStatusImage(order.vidStatus)}
                      <div className='d-flex flex-column'>
                        <span className='d-inline header-stats'>Identity Validation Overview</span>
                        {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                          <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
                        }
                      </div>
                    </div>
                  </>}
                  {order.isEsignRequired && !!eSignStatus && <>
                    <div className="divider me-1 ms-1"></div>
                    <div className='d-flex align-items-start'>
                      <img id='e-sign' src={eSignServiceIcon} className="me-2" />
                      <div className='d-flex flex-column'>
                        <span className='d-inline header-stats'>e-Signed Contract</span>
                        {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                          <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
                      </div>
                    </div>
                  </>}
                  {order.isInkSignRequired && !!inkSignStatus && <>
                    <div className="divider me-1 ms-1"></div>
                    <div className='d-flex align-items-start'>
                      <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
                      <div className='d-flex flex-column'>
                        <span className='d-inline header-stats'>Ink Signed Contract</span>
                        {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                          <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
                      </div>
                    </div>
                  </>}
                </div>}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>)
  }

  const renderTabletView = () => {
    return (<div className='order-actions'>
      <Row className='mb-4'>
        <Col sm={12} xl={6}>
          <span>
            <div className="row mb-2">
              <div className='col-6 header-title font-weight-semibold'>Order ID {order.id}</div>
              {order.isInactive && order.status != Order.STATUS_CANCELLED && (<div className='col-6 d-flex align-items-center justify-content-end'>
                <img src={inactiveIcon} />
                <span className="ms-2">Inactive({timePassedInHours}h)</span>
              </div>)}
            </div>
            <div>
              <p className='d-inline font-weight-normal header-stats'>Status:</p>
              <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
              <span>
                {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
                {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
                {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
              </span>
            </div>
          </span>
        </Col>
      </Row>
      <Row>
        {!isOrderDraft() && <div className="d-flex display-status">
          {order.isVidRequired && !!vidStatus && <>
            <div className='d-flex align-items-start'>
              {getVIDStatusImage(order.vidStatus)}
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Identity Validation Overview:</span>
                {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
                }
              </div>
            </div>
            <div className="divider me-1 ms-1"></div>
          </>}
          {order.isEsignRequired && !!eSignStatus && <>
            <div className='d-flex align-items-start'>
              <img id='e-sign' src={eSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>e-Signed Contract</span>
                {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
              </div>
            </div>
            <div className="divider me-1 ms-1"></div>
          </>}
          {order.isInkSignRequired && !!inkSignStatus && <>
            <div className='d-flex align-items-start'>
              <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Ink Signed Contract</span>
                {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
              </div>
            </div>
          </>}
        </div>}
      </Row>
    </div>)
  }

  const renderView = () => {
    if (screenWidth >= 1200) {
      return renderDesktopView();
    } else {
      return renderTabletView();
    }
  };

  return <React.Fragment>
    {isOrderCancelled() && <Alert color="danger">
      <i className="bx bx-block label-icon me-2"></i>{Order.getCancellationReasonsName(order.cancellationReason)}
    </Alert>}

    {orderHasAccountingReview() && <Alert color="danger">
      <i className="bx bx-calculator ms-2"></i> Further analysis needed by Accounting: {order?.accountingReviewReason}
    </Alert>}

    {orderHasDuplicateReview() && <Alert color="danger">
      <i className='bx bxs-user-account bx-xs ms-2' /> Duplicate order: {order?.duplicateReviewReason}
    </Alert>}

    {orderHasUnderReview() && <Alert color="danger">
      <i className='bx bx-search-alt-2 bx-xs ms-2' /> Under review order: {order?.underReviewReason}
    </Alert>}

    {orderHasOnHoldReview() && <Alert color="danger">
      <i className="fas fa-hand-paper ms-2" /> On Hold: {order?.onHoldReviewReason}
    </Alert>}

    <div>
      {renderView()}
    </div>

  </React.Fragment>
}

SectionOrderHeader.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default SectionOrderHeader;
