import React from "react";
import PropTypes from 'prop-types';
import ViewInfo from "../View/Info";

const SectionInfo = props => {

  const { cronJob, refreshHandler, isLoadInProgress } = props;

  return <React.Fragment>
    <ViewInfo cronJob={cronJob} refreshHandler={refreshHandler} isLoadInProgress={isLoadInProgress} />
  </React.Fragment>
}

SectionInfo.propTypes = {
  cronJob: PropTypes.object,
  refreshHandler: PropTypes.func,
  isLoadInProgress: PropTypes.bool,
};

export default SectionInfo;
