import { useRef } from 'react'

const useFirstRender = () => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return {
      isFirstRender: true,
      isNotFirstRender: false,
    };
  }

  return {
    isFirstRender: isFirst.current,
    isNotFirstRender: !isFirst.current,
  };
}

export default useFirstRender;