import {
  GET_DEALER_GROUP_DT,
  GET_DEALER_GROUP_DT_OK,
  GET_DEALER_GROUP_DT_ERR,
  DO_DEALER_GROUP_DT_CLEANUP,
  APPLY_DEALER_GROUP_DT_FILTERS,
  CLEAR_DEALER_GROUP_DT_FILTERS,
  GET_DEALER_GROUP_LIST,
  GET_DEALER_GROUP_LIST_OK,
  GET_DEALER_GROUP_LIST_ERR,
  DO_DEALER_GROUP_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getDealerGroupDt = params => ({
  type: GET_DEALER_GROUP_DT,
  payload: { params },
});

export const getDealerGroupDtOk = response => ({
  type: GET_DEALER_GROUP_DT_OK,
  payload: { response },
});

export const getDealerGroupDtErr = error => ({
  type: GET_DEALER_GROUP_DT_ERR,
  payload: { error },
});

export const doDealerGroupDtCleanup = () => ({
  type: DO_DEALER_GROUP_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyDealerGroupDtFilters = filters => ({
  type: APPLY_DEALER_GROUP_DT_FILTERS,
  payload: { filters },
});

export const clearDealerGroupDtFilters = () => ({
  type: CLEAR_DEALER_GROUP_DT_FILTERS,
});

/********* LIST *********/

export const getDealerGroupList = () => ({
  type: GET_DEALER_GROUP_LIST,
});

export const getDealerGroupListOk = response => ({
  type: GET_DEALER_GROUP_LIST_OK,
  payload: { response },
});

export const getDealerGroupListErr = error => ({
  type: GET_DEALER_GROUP_LIST_ERR,
  payload: { error },
});

export const doDealerGroupListCleanup = () => ({
  type: DO_DEALER_GROUP_LIST_CLEANUP,
});