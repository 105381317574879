
import { combineReducers } from "redux";
import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_ERR, DELETE_NOTIFICATION_OK, DO_NOTIFICATION_SINGLE_CLEANUP, GET_NOTIFICATION, GET_NOTIFICATION_ERR, GET_NOTIFICATION_OK, MARK_NOTIFICATION_ERR, MARK_NOTIFICATION_READ, MARK_NOTIFICATION_READ_OK, MARK_NOTIFICATION_UNREAD, MARK_NOTIFICATION_UNREAD_OK, GET_NOTIFICATION_DT, GET_NOTIFICATION_DT_OK, GET_NOTIFICATION_DT_ERR, DO_NOTIFICATION_DT_CLEANUP, CHANGE_NOTIFICATIONS_PAGE, MARK_NOTIFICATIONS_READ_OK, MARK_NOTIFICATIONS_UNREAD_OK, MARK_NOTIFICATIONS_ERR, MARK_NOTIFICATIONS_UNREAD, MARK_NOTIFICATIONS_READ, GET_NOTIFICATION_LIST, GET_NOTIFICATION_LIST_OK, GET_NOTIFICATION_LIST_ERR, DO_NOTIFICATION_LIST_CLEANUP, DELETE_NOTIFICATIONS_ERR, DELETE_NOTIFICATIONS_OK, DELETE_NOTIFICATIONS } from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  notifications: [],
  notificationsError: null,
  totalCount: 0,
  isLoadInProgress: false,
  listParams: {
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDir: 'desc',
  },
  isMarkInProgress: false,
  markError: null,
  marked: null,
  isDeleteInProgress: false,
  deleteError: null,
  deleted: null,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_DT:
      state = {
        ...state,
        notificationsError: null,
        isLoadInProgress: true,
        listParams: action.payload.params,
      };
      break
    case GET_NOTIFICATION_DT_OK:
      state = {
        ...state,
        notifications: action.payload.response.notifications,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_NOTIFICATION_DT_ERR:
      state = {
        ...state,
        notifications: [],
        totalCount: 0,
        notificationsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_NOTIFICATION_DT_CLEANUP:
      state = { ...defaultDtState };
      break
    case CHANGE_NOTIFICATIONS_PAGE:
      state = { ...state, listParams: { ...state.listParams, page: action.payload.page } };
      break;
    case MARK_NOTIFICATIONS_READ:
    case MARK_NOTIFICATIONS_UNREAD:
      state = { ...state, isMarkInProgress: true, markError: null, marked: null };
      break
    case MARK_NOTIFICATIONS_READ_OK:
      state = { ...state, isMarkInProgress: false, notifications: state.notifications.map(notif => action.payload.response.ids.includes(notif.id) ? { ...notif, isRead: true } : notif), marked: true };
      break
    case MARK_NOTIFICATIONS_UNREAD_OK:
      state = { ...state, isMarkInProgress: false, notifications: state.notifications.map(notif => action.payload.response.ids.includes(notif.id) ? { ...notif, isRead: false } : notif), marked: true };
      break
    case MARK_NOTIFICATIONS_ERR:
      state = { ...state, isMarkInProgress: false, markError: action.payload.error, marked: false };
      break
    case DELETE_NOTIFICATIONS:
      state = { ...state, isDeleteInProgress: true, deleteError: null, deleted: null };
      break
    case DELETE_NOTIFICATIONS_OK:
      state = { ...state, isDeleteInProgress: false, deleted: true };
      break
    case DELETE_NOTIFICATIONS_ERR:
      state = { ...state, isDeleteInProgress: false, deleted: false, deleteError: action.payload.error };
      break
  }
  return state;
}

/********** LIST **********/

const defaultListState = {
  notifications: [],
  notificationsError: null,
  isLoadInProgress: false,
  unreadCount: 0,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      state = { ...state, isLoadInProgress: true, notificationsError: null };
      break
    case GET_NOTIFICATION_LIST_OK:
      state = { ...state, isLoadInProgress: false, notifications: action.payload.response.notifications, unreadCount: action.payload.response.unreadCount };
      break
    case GET_NOTIFICATION_LIST_ERR:
      state = { ...state, isLoadInProgress: false, notifications: [], unreadCount: 0, notificationsError: action.payload.error };
      break
    case DO_NOTIFICATION_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

/********** SINGLE **********/

const defaultSingleState = {
  notification: null,
  isLoadInProgress: false,
  notificationError: null,
  isMarkInProgress: false,
  markError: null,
  marked: null,
  isDeleteInProgress: false,
  deleted: null,
  deleteError: null,
}

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      state = { ...state, isLoadInProgress: true, notificationError: null };
      break
    case GET_NOTIFICATION_OK:
      state = { ...state, isLoadInProgress: false, notification: action.payload.response.notification };
      break
    case GET_NOTIFICATION_ERR:
      state = { ...state, isLoadInProgress: false, notificationError: action.payload.error };
      break
    case DELETE_NOTIFICATION:
      state = { ...state, isDeleteInProgress: true };
      break
    case DELETE_NOTIFICATION_OK:
      state = { ...state, isDeleteInProgress: false, deleted: true };
      break
    case DELETE_NOTIFICATION_ERR:
      state = { ...state, isDeleteInProgress: false, deleted: false, deleteError: action.payload.error };
      break
    case MARK_NOTIFICATION_READ:
    case MARK_NOTIFICATION_UNREAD:
      state = { ...state, isMarkInProgress: true, markError: null, marked: null };
      break
    case MARK_NOTIFICATION_READ_OK:
      state = { ...state, isMarkInProgress: false, marked: true };
      break
    case MARK_NOTIFICATION_UNREAD_OK:
      state = { ...state, isMarkInProgress: false, marked: true };
      break
    case MARK_NOTIFICATION_ERR:
      state = { ...state, isMarkInProgress: false, markError: action.payload.error, marked: false };
      break
    case DO_NOTIFICATION_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
  }
  return state;
}

export default combineReducers({
  Single,
  Dt,
  List,
});