import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap"
import Select from "react-select";
import { canPayWithCheckOptionsForGroups, getYesNoOptions, phoneHasNoOfDigits, showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import regx from "constants/regx";
import { route, routes } from "helpers/routeHelper";
import UsStates from "constants/usState";
import { createDealerGroup } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";

const FormInfoNew = () => {

  // router hook that helps redirect
  const navigate = useNavigate();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    canPayWithCheck: false,
    usesCoupa: false,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Field is required'),
      address: Yup.string().trim().required('Field is required'),
      city: Yup.string().trim().required('Field is required'),
      state: Yup.string().trim().required('Field is required'),
      zip: Yup.string().trim().required('Field is required'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address').nullable(),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      canPayWithCheck: Yup.boolean(),
      usesCoupa: Yup.boolean()
    }),
    onSubmit: values => saveDealerGroup(values),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerGroup = values => {
    setIsSaveInProgress(true);
    createDealerGroup(values)
      .then(response => {
        showSuccess(`Dealer group "${formik.values.name}" has been saved`);
        navigate(route(routes.list_dealer_groups));
      })
      .catch(ex => {
        showError('Unable to save dealer group');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }


  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save group
              </Button>
              <Link to={route(routes.list_dealer_groups)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Autonation" name="name" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.name} invalid={!!formik.errors.name} />
                  {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">HQ Address *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 2273 Bel Meadow Drive" name="address" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.address} invalid={!!formik.errors.address} />
                  {!!formik.errors.address && <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">City *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. New Jersey" name="city" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.city} invalid={!!formik.errors.city} />
                  {!!formik.errors.city && <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">State *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="state"
                    options={usStates}
                    onChange={selected => formik.setFieldValue('state', selected.value)}
                    onFocus={e => onFieldFocused(e, 'state')}
                    value={usStates.find(option => option.value === formik.values.state)}
                    className={!!formik.errors.state && 'is-invalid'} />
                  {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Zip *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 90017" name="zip" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.zip} invalid={!!formik.errors.zip} />
                  {!!formik.errors.zip && <FormFeedback type="invalid">{formik.errors.zip}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Email *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
                  {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Phone *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 909-410-5017" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
                  {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
                </Col>
              </Row>
              {iAmGranted(perms.edit_can_pay_with_check) && (
                <Row className="mb-4">
                  <Label className="col-sm-3 col-form-label">Enable Check Payments</Label>
                  <Col sm={9}>
                    <Select
                      classNamePrefix="select2-selection"
                      name="canPayWithCheck"
                      options={canPayWithCheckOptionsForGroups}
                      onChange={selected => formik.setFieldValue("canPayWithCheck", selected.value)}
                      value={canPayWithCheckOptionsForGroups.find(option => option.value === formik.values.canPayWithCheck)}
                      className={!!formik.errors.canPayWithCheck && "is-invalid"}
                    />
                    {!!formik.errors.canPayWithCheck && <FormFeedback type="invalid">{formik.errors.canPayWithCheck}</FormFeedback>}
                  </Col>
                </Row>
              )}
              {iAmGranted(perms.edit_uses_coupa) && (
                <Row className="mb-4">
                  <Label className="col-sm-3 col-form-label">Uses Coupa</Label>
                  <Col sm={9}>
                    <Select
                      classNamePrefix="select2-selection"
                      name="usesCoupa"
                      options={getYesNoOptions()}
                      onChange={selected => formik.setFieldValue("usesCoupa", selected.value)}
                      value={getYesNoOptions().find(option => option.value === formik.values.usesCoupa)}
                      className={!!formik.errors.usesCoupa && "is-invalid"}
                    />
                    {!!formik.errors.usesCoupa && <FormFeedback type="invalid">{formik.errors.usesCoupa}</FormFeedback>}
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

const usStates = toSelectOptions(UsStates);

export default FormInfoNew;