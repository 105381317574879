import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, CardHeader, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Col from "components/Shared/Col";
import classnames from "classnames";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import shield from "assets/images/shield-blue.svg";

const ViewVehicle = props => {

  const { isRefreshing, order, toggleEditMode, isLocked } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return <React.Fragment>
    <Card className="vehicle-information expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Vehicle Information</div>
          </Col>
          {iAmGranted(perms.edit_orders) && !isLocked && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="px-0 pb-0">
        <div className={classnames("card-section")}>
          <Table className={classnames("section-rows table")}>
            <tbody>
              <tr>
                <td className="section-row-label">Stock Number</td>
                <td className="section-row-value">{(order.vehicles && order.vehicles[0]?.stockNum) || "--"}</td>
              </tr>
              <tr>
                <td className="section-row-label">V.I.N. #</td>
                <td className="section-row-value">{(order.vehicles && order.vehicles[0]?.vin) || "--"}</td>
              </tr>
              <tr>
                <td className="section-row-label">Vehicle year, make, model</td>
                <td className="section-row-value">{(order.vehicles && order.vehicles[0]?.yearMakeModel) || "--"}</td>
              </tr>
            </tbody>
          </Table>
          {order.vehicles?.length > 1 && <Col><div className="card-title mt-4 mb-4">Additional Vehicle Information</div></Col>}
          <Nav pills className="navtab-bg nav-justified">
            {order.vehicles?.length > 1 && order.vehicles.slice(1).map((vehicle, idx) => {
              return <NavItem key={idx + 1}>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === idx }, "nav-tab")} onClick={() => { toggle(idx); }}>
                  {order.vehicles?.length > 2 && <> Vehicle <span className="number nav-number">{idx + 1}</span> </>}
                </NavLink>
              </NavItem>
            })}
          </Nav>
          {order.vehicles?.length > 1 && <TabContent activeTab={activeTab} className={`p-3 mb-3 active-content ${order.vehicles?.length < 3 && "pt-0"}`}>
            {order.vehicles.slice(1).map((vehicle, idx) => {
              return <TabPane tabId={idx} key={idx}>
                <Table className={classnames("section-rows table active-content", "active.nav-link")}>
                  <tbody>
                    <tr>
                      <td className="section-row-label">Stock Number</td>
                      <td className="section-row-value">{vehicle.stockNum}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">V.I.N. #</td>
                      <td className="section-row-value">{vehicle.vin}</td>
                    </tr>
                    <tr>
                      <td className="section-row-label">Vehicle year, make, model</td>
                      <td className="section-row-value">{vehicle.yearMakeModel || "--"}</td>
                    </tr>
                  </tbody>
                </Table>
              </TabPane>
            })}
          </TabContent>
          }
        </div>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewVehicle.propTypes = {
  isRefreshing: PropTypes.bool,
  order: PropTypes.object,
  toggleEditMode: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default ViewVehicle;
