import React from 'react';
import MetaTitle from 'components/Shared/MetaTitle';
import { Container } from 'reactstrap';
import Admin from './Partial/Admin';
import CustomerSupport from './Partial/CustomerSupport';
import Scheduler from './Partial/Scheduler';
import AccountManager from './Partial/AccountManager';
import { useAuth } from 'context/auth';
import { useAccess } from 'context/access';
import BounceEmailAlert from './Partial/BounceEmailAlert';

const Dashboard = () => {

  const { user } = useAuth();

  const {
    iAmMasterAdmin,
    iAmAdmin,
    iAmAccountManager,
    iAmDsc,
    iAmScheduler,
    iAmLeadOps
  } = useAccess();

  let UserRoleDashboard;

  if (iAmAdmin() || iAmMasterAdmin()) {
    UserRoleDashboard = Admin;
  } else if (iAmDsc()) {
    UserRoleDashboard = CustomerSupport;
  } else if (iAmScheduler() || iAmLeadOps()) {
    UserRoleDashboard = Scheduler;
  } else if (iAmAccountManager()) {
    UserRoleDashboard = AccountManager;
  }

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>Dashboard</MetaTitle>
      <Container fluid>
        {!!user.hasEmailAlert && <BounceEmailAlert user={user} />}
        {UserRoleDashboard && <UserRoleDashboard />}
        {!UserRoleDashboard && <div className="text-center pt-5">
          <h1 className="display-6">Welcome <strong className="font-weight-semibold">{user.fullName}</strong></h1>
        </div>}
      </Container>
    </div>
  </React.Fragment>
}

export default Dashboard;
