import { combineReducers } from "redux";
import {
  GET_PAYMENT_PLAN_DT,
  GET_PAYMENT_PLAN_DT_OK,
  GET_PAYMENT_PLAN_DT_ERR,
  DO_PAYMENT_PLAN_DT_CLEANUP,
  APPLY_PAYMENT_PLAN_DT_FILTERS,
  CLEAR_PAYMENT_PLAN_DT_FILTERS,
  GET_PAYMENT_PLAN_LIST,
  GET_PAYMENT_PLAN_LIST_OK,
  GET_PAYMENT_PLAN_LIST_ERR,
  DO_PAYMENT_PLAN_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  paymentPlans: [],
  paymentPlansError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_PAYMENT_PLAN_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        paymentPlansError: null,
        isLoadInProgress: true,
      };
      break
    case GET_PAYMENT_PLAN_DT_OK:
      state = {
        ...state,
        paymentPlans: action.payload.response.paymentPlans,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_PAYMENT_PLAN_DT_ERR:
      state = {
        ...state,
        paymentPlans: [],
        totalCount: 0,
        paymentPlansError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_PAYMENT_PLAN_DT_CLEANUP:
      state = {
        ...state,
        paymentPlans: [],
        paymentPlansError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  name: '',
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_PAYMENT_PLAN_DT_FILTERS:
      state = action.payload.filters;
      break
    case CLEAR_PAYMENT_PLAN_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
  }
  return state;
}

/********* LIST *********/

const defaultListState = {
  paymentPlans: [],
  paymentPlansError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_PAYMENT_PLAN_LIST:
      state = {
        ...state,
        paymentPlansError: null,
        isLoadInProgress: true,
      };
      break
    case GET_PAYMENT_PLAN_LIST_OK:
      state = {
        ...state,
        paymentPlans: action.payload.response.paymentPlans,
        isLoadInProgress: false,
      };
      break
    case GET_PAYMENT_PLAN_LIST_ERR:
      state = {
        ...state,
        paymentPlans: [],
        paymentPlansError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_PAYMENT_PLAN_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
  List,
});