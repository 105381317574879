import React from "react"
import { Row, Col } from "reactstrap";
import MyProfile from "./Section/MyProfile";
import CustomerPerformance from "./Section/CustomerPerformance";
import Leaderboard from "./Section/Leaderboard";
import Groups from "./Section/Groups";
import Stores from "./Section/Stores";
import StatsAccountManager from "./Section/StatsAccountManager";
import Appointments from "./Section/Appointments";

const AccountManager = () => {

  return (
    <Row id="account-manager-dashboard">
      <Col xs={12} xl={4}>
        <Row className="mb-4 g-0">
          <MyProfile />
        </Row>
        <Row className="mb-4 g-0">
          <Leaderboard />
        </Row>
      </Col>
      <Col xs={12} xl={8}>
        <Row className="g-0">
          <CustomerPerformance />
        </Row>
        <Row className="align-items-stretch">
          <StatsAccountManager />
        </Row>
        <Row className="g-0">
          <Groups />
        </Row>
        <Row className="g-0">
          <Stores />
        </Row>
        <Row className="g-0">
          <Appointments />
        </Row>
      </Col>
    </Row>
  )
}

export default AccountManager;
