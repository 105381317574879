import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
// import SectionBilling from "../Partial/Section/Billing";
import { getPayment } from "helpers/backendHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [notary, setNotary] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshPayment();
  }, [id]);

  /********** OTHER **********/

  const refreshPayment = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getPayment(id)
      .then(response => {
        setNotary(response.notary);
      })
      .catch(ex => {
        setPaymentError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_payments) && <div className="page-content">
      {notary && <React.Fragment>
        <MetaTitle>{notary.userFullName} | Payments</MetaTitle>
        <Container fluid className='notary-profile'>
          <Breadcrumbs breadcrumbItems={breadcrumbs(payment)} />
          <Row>
            <Col xs={12} md={6}>
              {/* <SectionBilling id={id} notary={notary} isLoadInProgress={isLoadInProgress} /> */}
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !payment && <Preloader className="inner" />}
      {paymentError && <Error error={paymentError} title404="Payment not found" />}
    </div>}
    {iAmNotGranted(perms.view_payments) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = notary => [{
  title: "PAYMENTS",
  url: route(routes.list_payments),
}, {
  title: payment.id,
}];

export default View;
