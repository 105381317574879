import React from 'react';
import SupportCaseService from 'model/supportCaseService';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import vidServiceIcon from 'assets/images/verify-id-service-icon.svg';
import sealServiceIcon from 'assets/images/seal-service-icon.svg';
import billingServiceIcon from 'assets/images/billing-service-icon.svg';
import platformServiceIcon from 'assets/images/platform-service-icon.svg';
import { getTrendColor } from './utilHelper';
import { isNil } from 'lodash';

export const formatPaymentPlanDescription = value => {
  if (!value) {
    return '--';
  }
  const lines = value.split("\n").map((line, index) => {
    const icon = line.toLowerCase().startsWith('x ') ? 'bx bx-x' : 'bx bx-check';
    const text = line.replace(/^x /, '');
    return <p key={index}><i className={icon} />{text}</p>
  });
  return lines;
}

export const formatPaymentPlanPrice = plan => {
  const fees = [];
  // depending on how the payment plan was fetched from the backend
  // prices might be properties of the plan object
  // or properties of the products
  if (plan.products) {
    const baseFeeProduct = plan.products.find(p => p.type == PaymentPlanProduct.TYPE_BASE_FEE);
    if (!!baseFeeProduct) {
      plan.baseFee = baseFeeProduct.price;
    }
    const orderFeeProduct = plan.products.find(p => p.type == PaymentPlanProduct.TYPE_ORDER_FEE);
    if (!!orderFeeProduct) {
      plan.orderFee = orderFeeProduct.price;
    }
  }
  if (parseInt(plan.baseFee)) {
    fees.push(<div className="payment-plan-selection-item-price-fee" key="baseFee">
      ${Number(plan.baseFee)}<span>/Month</span>
    </div>);
  }
  if (parseInt(plan.orderFee)) {
    fees.push(<div className="payment-plan-selection-item-price-fee" key="orderFee">
      ${Number(plan.orderFee)}<span>/Signing</span>
    </div>);
  }
  if (!fees.length) {
    return '$ Free';
  }
  return fees;
}

export const getSupportCaseServiceWithIcon = serviceId => {
  let imgSrc;

  switch (serviceId) {
    case SupportCaseService.VID:
      imgSrc = vidServiceIcon;
      break
    case SupportCaseService.INK_SIGN:
      imgSrc = inkSignServiceIcon;
      break
    case SupportCaseService.E_SIGN:
      imgSrc = eSignServiceIcon;
      break
    case SupportCaseService.SEAL:
      imgSrc = sealServiceIcon;
      break
    case SupportCaseService.BILLING:
      imgSrc = billingServiceIcon;
      break
    case SupportCaseService.PLATFORM:
      imgSrc = platformServiceIcon;
      break
  }

  return <div key={serviceId} className="support-case-service">
    <div className="support-case-service-icon">
      <img key={serviceId} src={imgSrc} />
    </div>
    <div>
      {SupportCaseService.getServiceName(serviceId)}
    </div>
  </div>
}

export const getTrendIcon = percentage => {
  if (isNil(percentage)) return;
  if (percentage > 0) return <i className='fas fa-chevron-up text-success font-size-16' />;
  if (percentage < 0) return <i className='fas fa-chevron-down text-danger font-size-26' />;
  if (percentage === 0) return <i className='fas fa-equals text-warning font-size-16' />;
};

export const getTrendPercentage = (percentage = 0) => {
  let sign = "";
  if (percentage > 0) {
    sign = "+";
  }
  const color = getTrendColor(percentage);
  return <div className={`bg-${color} bg-soft text-${color} trend-percentage`}>{`${sign} ${percentage} %`}</div>
};

