import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import classnames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import { canPayWithCheckOptionsForStores, isObject, nullsToEmptyStrings, phoneHasNoOfDigits, toSelectOptions } from "helpers/utilHelper";
import regx from "constants/regx";
import Select from "react-select";
import { perms, useAccess } from "context/access";
import { getDealerGroup, getPaymentPlans } from "helpers/backendHelper";
import { ValidationException } from "helpers/errorHelper";

const FormBillingNew = props => {

  const { defaultValues, tabId, nextHandler, prevHandler, saveError } = props;

  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [paymentPlans, setPaymentPlans] = useState([]);
  const [paymentPlansError, setPaymentPlansError] = useState(null);
  const [dealerGroup, setDealerGroup] = useState();
  const [dealerGroupError, setDealerGroupError] = useState(null);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    paymentPlanId: '',
    billingName: '',
    billingEmail: '',
    billingPhone: '',
    billingPhone2: '',
    billingAccountNo: '',
    maxPaymentPlanDays: '',
    maxPaymentPlanOrders: '',
    comments: '',
    canPayWithCheck: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      paymentPlanId: Yup.number().required('Field is required'),
      // user may submit one or multiple comma-separated email addresses
      // so we leave the complex validation to the backend
      billingEmail: Yup.string().trim(),
      billingPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      billingPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, { ...values, canPayWithCheck: Boolean(values.canPayWithCheck) });
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the payment plan field options
    getPaymentPlansList();
  }, []);

  // runs whenever 'paymentPlansError' changes
  useEffect(() => {
    if (paymentPlansError) {
      // set an error on the form field
      formik.setFieldError('paymentPlanId', 'Unable to load payment plans');
    }
  }, [paymentPlansError]);

  // if dealer store belongs to a group, fetch the group data so we can pre-fill the canPayWithCheck field
  useEffect(() => {
    if (defaultValues.dealerGroupId) {
      getDealerGroup(defaultValues.dealerGroupId)
        .then(response => {
          setDealerGroup(response.dealerGroup);
        })
        .catch(ex => {
          setDealerGroupError(ex);
        })
    }
  }, []);

  useEffect(() => {
    if (!saveError) {
      return;
    }
    // see if the save failed due to validation
    if (saveError instanceof ValidationException) {
      // show an error on each invalid field
      for (const [name, message] of Object.entries(saveError.fields)) {
        formik.setFieldError(name, message);
      }
    }
  }, [saveError]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  // prepares the list of groups to be used as select options
  const getPaymentPlanOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(paymentPlans)];

  const getPaymentPlansList = () => {
    // make the initial remote call to get the user data
    getPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(ex => {
        setPaymentPlansError(ex);
      })
  };

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Back
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2 added-store" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Finish
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Payment plan *</Label>
            <Col sm={8}>
              <Select
                classNamePrefix="select2-selection"
                name="paymentPlanId"
                options={getPaymentPlanOptions()}
                onChange={selected => formik.setFieldValue('paymentPlanId', selected.value)}
                onFocus={e => onFieldFocused(e, 'paymentPlanId')}
                value={getPaymentPlanOptions().find(option => option.value === formik.values.paymentPlanId)}
                className={!!formik.errors.paymentPlanId && 'is-invalid'} />
              {!!formik.errors.paymentPlanId && <FormFeedback type="invalid">{formik.errors.paymentPlanId}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Billing contact</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="billingName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingName} invalid={!!formik.errors.billingName} />
              {!!formik.errors.billingName && <FormFeedback type="invalid">{formik.errors.billingName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <TextareaAutosize className={classnames('form-control', { 'is-invalid': !!formik.errors.billingEmail })} placeholder="ex. john@domain.com" name="billingEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingEmail} />
              {!!formik.errors.billingEmail && <FormFeedback type="invalid">{isObject(formik.errors.billingEmail) ? Object.values(formik.errors.billingEmail)[0] : formik.errors.billingEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone} invalid={!!formik.errors.billingPhone} />
              {!!formik.errors.billingPhone && <FormFeedback type="invalid">{formik.errors.billingPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 9094105017" name="billingPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingPhone2} invalid={!!formik.errors.billingPhone2} />
              {!!formik.errors.billingPhone2 && <FormFeedback type="invalid">{formik.errors.billingPhone2}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Account number</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 94205617" name="billingAccountNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.billingAccountNo} invalid={!!formik.errors.billingAccountNo} />
              {!!formik.errors.billingAccountNo && <FormFeedback type="invalid">{formik.errors.billingAccountNo}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Payment plan days</Label>
            <Col sm={8}>
              <Input
                type="text"
                className="form-control"
                placeholder={iAmGranted(perms.edit_payment_plans) ? "ex. 25" : "--"}
                name="maxPaymentPlanDays"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.maxPaymentPlanDays}
                invalid={!!formik.errors.maxPaymentPlanDays}
                readOnly={!iAmGranted(perms.edit_payment_plans)}
              />
              {!!formik.errors.maxPaymentPlanDays && <FormFeedback type="invalid">{formik.errors.maxPaymentPlanDays}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Payment plan orders</Label>
            <Col sm={8}>
              <Input
                type="text"
                className="form-control"
                placeholder={iAmGranted(perms.edit_payment_plans) ? "ex. 25" : "--"}
                name="maxPaymentPlanOrders"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.maxPaymentPlanOrders}
                invalid={!!formik.errors.maxPaymentPlanOrders}
                readOnly={!iAmGranted(perms.edit_payment_plans)}
              />
              {!!formik.errors.maxPaymentPlanOrders && <FormFeedback type="invalid">{formik.errors.maxPaymentPlanOrders}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Comments</Label>
            <Col sm={8}>
              <TextareaAutosize maxRows={15} className={classnames('form-control', { 'is-invalid': !!formik.errors.comments })} name="comments" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.comments} />
              {!!formik.errors.comments && <FormFeedback type="invalid">{formik.errors.comments}</FormFeedback>}
            </Col>
          </Row>
          {iAmGranted(perms.edit_can_pay_with_check) && (
            <Row className="mb-4">
              <Label className="col-sm-4 col-form-label">Enable Check Payments</Label>
              <Col sm={8}>
                {!!formik.values.dealerGroupId
                  ? (
                    <Input disabled value={canPayWithCheckOptionsForStores.find(option => option.value == dealerGroup?.canPayWithCheck)?.label} />
                  )
                  : (
                    <Select
                      classNamePrefix="select2-selection"
                      name="canPayWithCheck"
                      options={canPayWithCheckOptionsForStores}
                      onChange={selected => formik.setFieldValue("canPayWithCheck", selected.value)}
                      value={canPayWithCheckOptionsForStores.find(option => option.value === formik.values.canPayWithCheck)}
                      className={!!formik.errors.canPayWithCheck && "is-invalid"}
                    />
                  )}
                {!!formik.errors.canPayWithCheck && <FormFeedback type="invalid">{formik.errors.canPayWithCheck}</FormFeedback>}
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormBillingNew.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  saveError: PropTypes.object,
};

export default FormBillingNew;