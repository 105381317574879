import { takeEvery, put, call } from "redux-saga/effects"

import { GET_AUTH_USER, IMPERSONATE_USER } from "./actionTypes"
import { getAuthUserOk, getAuthUserErr, impersonateUserOk, impersonateUserErr } from "./actions"

import {
  getAuthUser,
  impersonateUser,
} from "helpers/backendHelper";

/********** LOGIN **********/

function* onGetAuthUser() {
  try {
    const response = yield call(getAuthUser);
    yield put(getAuthUserOk(response));
  } catch (error) {
    yield put(getAuthUserErr(error));
  }
}

/********** IMPERSONATION **********/

function* onImpersonateUser({ payload: { id } }) {
  try {
    const response = yield call(impersonateUser, id);
    yield put(impersonateUserOk(response));
  } catch (error) {
    yield put(impersonateUserErr(error));
  }
}

function* authSaga() {
  yield takeEvery(GET_AUTH_USER, onGetAuthUser);
  yield takeEvery(IMPERSONATE_USER, onImpersonateUser);
}

export default authSaga;
