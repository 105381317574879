import React, { useState, useEffect } from "react";
import MetaTitle from "components/Shared/MetaTitle";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import { route, routes } from "helpers/routeHelper";
import { useParams } from "react-router-dom";
import { getOrder, getOrderBiddingLogs, getOrderSigners } from "helpers/backendHelper";
import OrderSigner from "model/orderSigner";
import { showError } from "helpers/utilHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { formatTimestamp, formats } from "helpers/dateHelper";

const BiddingLogs = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  const [order, setOrder] = useState(null);
  const [orderError, setOrderError] = useState(null);
  const [isOrderLoadInProgress, setIsOrderLoadInProgress] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isLogsLoadInProgress, setIsLogsLoadInProgress] = useState(false);

  useEffect(() => {
    refreshOrder();
  }, []);

  useEffect(() => {
    refreshLogs();
  }, [order?.id]);

  const refreshOrder = () => {
    setIsOrderLoadInProgress(true);
    Promise.all([
      getOrder(id),
      getOrderSigners(id, OrderSigner.TYPE_CUSTOMER),
    ])
      .then(responses => {
        setOrder(({
          ...responses[0].order,
          signers: responses[1].signers,
        }));
      })
      .catch(ex => {
        setOrderError(ex);
      })
      .finally(() => {
        setIsOrderLoadInProgress(false);
      });
  };

  const refreshLogs = () => {
    setIsLogsLoadInProgress(true);
    getOrderBiddingLogs(id)
      .then(response => {
        setLogs(response.notaryBiddingLogs);
      })
      .catch(ex => {
        showError('Unable to load bidding logs');
      })
      .finally(() => {
        setIsLogsLoadInProgress(false);
      });
  }

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col><Breadcrumbs breadcrumbItems={breadcrumbs(order)} /></Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title mt-2 mb-0">Bidding Logs</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      {logs.map(log => {
                        return <div className="notary-bidding-log mb-2" key={log._id}>
                          <span className="badge-soft-dark me-1 badge bg-light me-3">{formatTimestamp(log.timestamp, formats.DATETIME)}</span>
                          {log.message}
                        </div>
                      })}
                      {isLogsLoadInProgress && <SpinnerChase className="sm dtable" />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isOrderLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: `${order.signers[0].fullName}`,
  url: route(routes.view_order, order.id),
}, {
  title: "Edit order",
  url: route(routes.view_order, order.id),
}, {
  title: "Notary",
  url: route(routes.view_order_notary, order.id),
}, {
  title: "Logs",
}];

export default BiddingLogs;