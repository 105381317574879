import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody, Input } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { hasNonEmpty, toSelectOptions } from "helpers/utilHelper";
import {
  applyDealerStatementDtFilters,
  clearDealerStatementDtFilters,
  doDealerStoreListCleanup,
  getDealerStoreList
} from "store/actions";
import statementType from "constants/statementType";
import paymentMethod from "constants/paymentMethod";

const statementTypeOptions = toSelectOptions(statementType);
const paymentMethodOptions = toSelectOptions(paymentMethod);

const DataTableFilters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.DealerStatement.DtFilters);
  const { stores, storesError } = useSelector(state => state.DealerStore.List);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store filter options
    dispatch(getDealerStoreList());
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerStoreListCleanup());
    }
  }, []);

  // runs whenever 'storesError' changes
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError("store", "Unable to load stores");
    }
  }, [storesError]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => {
    dispatch(applyDealerStatementDtFilters(values));
  }

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearDealerStatementDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offCanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offCanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(filters);

  // adds 'All' to the list of stores
  // prepares the list to be used as select options
  const getStoreOptions = () => {
    return [{ label: "All", value: "" }, ...toSelectOptions(stores)];
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Store Name</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerStoreId"
              options={getStoreOptions()}
              onChange={selected => formik.setFieldValue("dealerStoreId", selected.value)}
              value={getStoreOptions().find(option => option.value === formik.values.dealerStoreId)}
              className={!!formik.errors.dealerStoreId && "is-invalid"} />
            {!!formik.errors.dealerStoreId && <FormFeedback type="invalid">{formik.errors.dealerStoreId}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Type</Label>
            <Select
              classNamePrefix="select2-selection"
              name="type"
              options={statementTypeOptions}
              onChange={selected => formik.setFieldValue("type", selected.value)}
              value={statementTypeOptions.find(option => option.value === formik.values.type)}
            />
            {!!formik.errors.type && <FormFeedback type="invalid">{formik.errors.type}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Payment method</Label>
            <Select
              classNamePrefix="select2-selection"
              name="paymentMethod"
              options={paymentMethodOptions}
              onChange={selected => formik.setFieldValue("paymentMethod", selected.value)}
              value={paymentMethodOptions.find(option => option.value === formik.values.paymentMethod)}
            />
            {!!formik.errors.paymentMethod && <FormFeedback type="invalid">{formik.errors.paymentMethod}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Order ID</Label>
            <Input type="text" className="form-control" name="orderId" onChange={formik.handleChange} value={formik.values.orderId} invalid={!!formik.errors.orderId} />
            {!!formik.errors.orderId && <FormFeedback type="invalid">{formik.errors.orderId}</FormFeedback>}
          </div>

          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default DataTableFilters;