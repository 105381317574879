import React from 'react';
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import popUpIcon from "assets/images/icon-pop-up.png";

const Confirmation = props => {

  return <SweetAlert
    title=""
    custom
    customIcon={popUpIcon}
    showCancel
    confirmBtnBsStyle="primary"
    confirmBtnCssClass="me-2 button-alert"
    cancelBtnBsStyle="secondary"
    cancelBtnCssClass="ms-2 button-alert"
    style={{ backgroundColor: '#CED4F2' }}
    btnSize="default"
    disabled={false}
    reverseButtons={true}
    {...props}>
    {props.children}
  </SweetAlert>
}

Confirmation.propTypes = {
  children: PropTypes.any,
}

export default Confirmation;