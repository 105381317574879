import React from "react";
import { Col, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { getVIDStatusImage } from "helpers/utilHelper";
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import coSigners from 'assets/images/co-signer-icon.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import ruralIcon from 'assets/images/rural-order.svg';
import Order from "model/order";

const OrderInfoAndServices = ({ order }) => {
  return <Col className="mb-3">
    <div className="chat-breadcrumbs">
      {order.customerFirstName && <span>Customer Name: <strong>{order.customerFirstName} {order.customerLastName},</strong> </span>}
      {order.id && <span className="ms-2">Order ID:<strong> # {order.id}, </strong></span>}
      {order.id && <span className="ms-2">Services Included: </span>}
      {order.isVidRequired && getVIDStatusImage(order.vidStatus, order)}
      {order.isEsignRequired && <><img id={`e-sign${order.id}`} src={eSignServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`e-sign${order.id}`}>E sign</UncontrolledTooltip></>}
      {order.isInkSignRequired && <><img id={`ink-sign${order.id}`} src={inkSignServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`ink-sign${order.id}`}>Ink sign</UncontrolledTooltip></>}
      {order.isNotaryRequired && <><img id={`notary-service${order.id}`} src={notaryServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`notary-service${order.id}`}>Notary service</UncontrolledTooltip></>}
      {order.hasAdditionalSigner && <><img id={`co-signers${order.id}`} src={coSigners} className="me-1" /><UncontrolledTooltip placement="bottom" target={`co-signers${order.id}`}>Co-signers</UncontrolledTooltip></>}
      {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
        <><img id={`doc-delivery-shipping${order.id}`} src={docDeliveryShippingIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`doc-delivery-shipping${order.id}`}>Documents shipping</UncontrolledTooltip></>
      }{order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
        <><img id={`doc-delivery-upload${order.id}`} src={docDeliveryUploadIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`doc-delivery-upload${order.id}`}>Documents upload</UncontrolledTooltip></>
      }
      {order.isRushRequired && <><img id={`rush-order${order.id}`} src={rushOrderServiceIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`rush-order${order.id}`}>Rush order</UncontrolledTooltip></>}
      {order.notaryMustUploadDocs && <><img id={`must-upload-docs${order.id}`} src={mustUploadDocsIcon} className="me-1 must-upload-icon" /><UncontrolledTooltip placement="bottom" target={`must-upload-docs${order.id}`}>Notary must upload docs</UncontrolledTooltip></>}
      {order.isRural && <><img id={`rural-order${order.id}`} src={ruralIcon} className="me-1" /><UncontrolledTooltip placement="bottom" target={`rural-order${order.id}`}>Rural</UncontrolledTooltip></>}
    </div>
  </Col>
}

OrderInfoAndServices.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object,
}

export default OrderInfoAndServices;