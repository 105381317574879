import { takeEvery, put, call } from "redux-saga/effects";

import { getSchedulerReport, getGroupUsageReport, getDealerSalesReport, getNewDealersReport } from "helpers/backendHelper";

import { GET_SCHEDULER_REPORT, GET_GROUP_USAGE_REPORT, GET_DEALER_SALES_REPORT, GET_NEW_DEALERS_REPORT } from "./actionTypes";

import {
  getDealerSalesReportErr,
  getDealerSalesReportOk,
  getGroupUsageReportErr,
  getGroupUsageReportOk,
  getNewDealersReportErr,
  getNewDealersReportOk,
  getSchedulerReportErr,
  getSchedulerReportOk
} from "./actions";

function* onGetSchedulerReport({ payload: { params } }) {
  try {
    const response = yield call(getSchedulerReport, params);
    yield put(getSchedulerReportOk(response));
  } catch (error) {
    yield put(getSchedulerReportErr(error));
  }
};

function* onGetGroupUsageReport({ payload: { params } }) {
  try {
    const response = yield call(getGroupUsageReport, params);
    yield put(getGroupUsageReportOk(response));
  } catch (error) {
    yield put(getGroupUsageReportErr(error));
  }
};

function* onGetDealerSalesReport({ payload: { params } }) {
  try {
    const response = yield call(getDealerSalesReport, params);
    yield put(getDealerSalesReportOk(response));
  } catch (error) {
    yield put(getDealerSalesReportErr(error));
  }
};

function* onGetNewDealersReport({ payload: { params: { filters } } }) {
  try {
    const response = yield call(getNewDealersReport, filters);
    yield put(getNewDealersReportOk(response));
  } catch (error) {
    yield put(getNewDealersReportErr(error));
  }
};

function* reportSaga() {
  yield takeEvery(GET_SCHEDULER_REPORT, onGetSchedulerReport);
  yield takeEvery(GET_GROUP_USAGE_REPORT, onGetGroupUsageReport);
  yield takeEvery(GET_DEALER_SALES_REPORT, onGetDealerSalesReport);
  yield takeEvery(GET_NEW_DEALERS_REPORT, onGetNewDealersReport);
};

export default reportSaga;