import React, { useState } from "react"
import { Row, Col, Button } from "reactstrap"
import SpinnerChase from "components/Shared/SpinnerChase";
import PropTypes from 'prop-types'
import { articlesUrlMap } from "constants/help";

const HelpItem = ({ article, resetHelpContent }) => {

  // whenever article are loading
  const [isLoadInProgress, setIsLoadInProgress] = useState(true);

  return <React.Fragment>
    <Row>
      <Col>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <Button color="none" className="ps-0 mt-1" onClick={resetHelpContent}>
            <i className="mdi mdi-chevron-left font-size-24 me-2" />
          </Button>
          <h2 className="mt-3">{article.title}</h2>
        </div>
      </Col>
    </Row>
    <Row className={!!articlesUrlMap[article.source.pageId] ? "w-100" : "w-75"}>
      <Col>
        <div className="d-flex flex-column align-items-center">
          <div className="article-item-container">
            {isLoadInProgress &&
              <SpinnerChase className="sm help-spinner mt-5" />
            }
            <div className="article-container">
              {/* Article Iframe */}
              <iframe
                className="article-iframe"
                src={article.content?.iframeSrc}
                onLoad={() => setIsLoadInProgress(false)}
              />
              {
                !!articlesUrlMap[article.source.pageId] && !isLoadInProgress &&
                <div className="d-flex flex-column video-container">
                  {
                    articlesUrlMap[article.source.pageId].map((video, idx) => {
                      return (
                        <div className="d-flex align-items-center flex-column pb-4" key={idx}>
                          {
                            idx === 0 &&
                            <>
                              <h3>Still Unsure?</h3>
                              <h6 className="text-muted mb-3">Watch the Step-by-Step Guide!</h6>
                            </>
                          }
                          {/* Video Iframe (if needed) */}
                          <iframe
                            src={`${video}&autoplay=0&showinfo=0&modestbranding=1&rel=0`}
                            title="preview"
                            allowFullScreen
                            allow="autoplay; encrypted-media"
                            width="100%"
                            height="300px"
                            style={{ border: 0 }}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </React.Fragment>
}

HelpItem.propTypes = {
  article: PropTypes.any,
  resetHelpContent: PropTypes.func
}

export default HelpItem;
