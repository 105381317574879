export default class Payment {

  static STATUS_FAILED = -1;
  static STATUS_REQUESTED = 0;
  static STATUS_COMPLETED = 1;

  static TYPE_SINGLE_ORDER = 1;
  static TYPE_MULTIPLE_ORDERS = 2;
  static TYPE_SUBSCRIPTION = 3;

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_FAILED:
        return 'danger';
      case this.STATUS_REQUESTED:
        return 'warning';
      case this.STATUS_COMPLETED:
        return 'success';
      default:
        return 'dark';
    }
  }

  static getStatusMap() {
    return {
      [this.STATUS_FAILED]: 'Failed',
      [this.STATUS_REQUESTED]: 'Processing',
      [this.STATUS_COMPLETED]: 'Completed',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }
}