/********** DATATABLE **********/

export const GET_SUPPORT_CASE_DT = 'GET_SUPPORT_CASE_DT'
export const GET_SUPPORT_CASE_DT_OK = 'GET_SUPPORT_CASE_DT_OK'
export const GET_SUPPORT_CASE_DT_ERR = 'GET_SUPPORT_CASE_DT_ERR'
export const DO_SUPPORT_CASE_DT_CLEANUP = 'DO_SUPPORT_CASE_DT_CLEANUP'
export const SET_SUPPORT_CASE_DT_ACTIVE_TAB = 'SET_SUPPORT_CASE_DT_ACTIVE_TAB'

/********** DATATABLE FILTERS **********/

export const APPLY_SUPPORT_CASE_DT_FILTERS = 'APPLY_SUPPORT_CASE_DT_FILTERS'
export const CLEAR_SUPPORT_CASE_DT_FILTERS = 'CLEAR_SUPPORT_CASE_DT_FILTERS'
export const PATCH_SUPPORT_CASE_DT_FILTERS = 'PATCH_SUPPORT_CASE_DT_FILTERS'
export const UNSET_SUPPORT_CASE_DT_FILTERS = 'UNSET_SUPPORT_CASE_DT_FILTERS'