import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { timestamp } from 'helpers/dateHelper';
import { getBeUrl } from 'helpers/utilHelper';
import OrderDoc from 'model/orderDoc';

export const InkSignPageContext = React.createContext();

const InkSignPageProvider = props => {

  const { docId, pageNum, refreshDocHandler } = props;

  const page = useSelector(state => state.OrderDoc.NormList.orderDocs[docId].pages?.[pageNum] || {});

  page.status = page.status !== undefined ? page.status : OrderDoc.PAGE_STATUS_NOT_SUBMITTED;
  // we use timestamp() to avoid caching
  page.image = page.status >= OrderDoc.PAGE_STATUS_PENDING_REVIEW ? getBeUrl(`order-doc/${docId}/ink-sign/page/${pageNum}.png?${timestamp()}`) : null;

  return <InkSignPageContext.Provider value={{ docId, pageNum, refreshDocHandler, page }} {...props} />
}

InkSignPageProvider.propTypes = {
  docId: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  refreshDocHandler: PropTypes.func.isRequired,
};

export const useInkSignPage = () => React.useContext(InkSignPageContext);

export default InkSignPageProvider;