import React from "react";
import WarningIcon from "assets/images/red-warning.svg";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

const InternalStatus = ({ text }) => {
  return (
    <Alert color="danger" className="p-4 pt-2 pb-2">
      <img src={WarningIcon} alt="warning-icon" className="me-2" />
      <span className="font-size-13">{text}</span>
    </Alert>
  )
}

InternalStatus.propTypes = {
  text: PropTypes.string.isRequired,
}

export default InternalStatus;