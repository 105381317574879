import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { hasNonEmpty, extractDtFiltersFromUrl, toSelectOptions, dictionaryToSelectOptions } from "helpers/utilHelper";
import { applyPaymentDtFilters, clearPaymentDtFilters, patchPaymentDtFilters, unsetPaymentDtFilters } from 'store/actions';
import { isEmpty, omit } from 'lodash';
import Payment from 'model/payment';
import { getDealerStores } from 'helpers/backendHelper';

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.Payment.DtFilters);
  const [stores, setStores] = useState([]);
  const [storesError, setStoresError] = useState(null);
  // const { stores, storesError } = useSelector(state => state.Payment);
  // // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: omit(filters, "createdBetween"),
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // load dealer stores
    getDealerStoreList();
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchPaymentDtFilters(filters));
    }
    return () => {
      // switch back the '_set' flag
      dispatch(unsetPaymentDtFilters());
    }
  }, []);

  // runs whenever 'storesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError('store', 'Unable to load stores');
    }
  }, [storesError]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyPaymentDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearPaymentDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const getDealerStoreList = () => {
    getDealerStores()
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      });
  }

  const hasFilters = () => hasNonEmpty(omit(filters, ["_set", "createdBetween"]));

  // adds 'All' to the list of stores
  // prepares the list to be used as select options
  const getStoreOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(stores)];
  }

  const getStatusOptions = () => {
    return [{ label: 'All', value: '' }, ...dictionaryToSelectOptions(Payment.getStatusMap())];
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="status"
              options={getStatusOptions()}
              onChange={selected => formik.setFieldValue("status", selected.value)}
              value={getStatusOptions().find(option => option.value === formik.values.status)}
              className={!!formik.errors.status && "is-invalid"} />
            {!!formik.errors.status && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Store</Label>
            <Select
              classNamePrefix="select2-selection"
              name="dealerStoreId"
              options={getStoreOptions()}
              onChange={selected => formik.setFieldValue('dealerStoreId', selected.value)}
              value={getStoreOptions().find(option => option.value === formik.values.dealerStoreId)}
              className={!!formik.errors.dealerStoreId && 'is-invalid'} />
            {!!formik.errors.dealerStoreId && <FormFeedback type="invalid">{formik.errors.dealerStoreId}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;