import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardSubtitle } from "reactstrap";
import classnames from "classnames";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import { route, routes } from 'helpers/routeHelper';
import { formats, formatTimestamp } from "helpers/dateHelper";
import CardPreloader from 'components/Shared/CardPreloader';

const ViewInfo = props => {

  const { isRefreshing, id, dealerStore, toggleEditMode } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className={classnames(dealerStore.isActive ? 'bg-transparent' : 'bg-warning bg-soft', 'pt-3')}>
        <Row>
          <Col>
            <h3>{dealerStore.name}</h3>
            <CardSubtitle className="text-muted">{dealerStore.isActive ? 'Active' : 'Inactive'}</CardSubtitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
              <Link to={route(routes.list_dealer_stores)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Group</label>
              <div>{dealerStore.dealerGroupName ? <Link to={route(routes.view_dealer_group, dealerStore.dealerGroupId)}>{dealerStore.dealerGroupName}</Link> : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">General manager</label>
              <div>{dealerStore.generalManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">General manager email</label>
              <div>{dealerStore.generalManagerEmail || '--'}</div>
            </Row>
          </Col>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Region</label>
              <div>{dealerStore.dealerRegionName ? <Link to={route(routes.view_dealer_region, dealerStore.dealerRegionId)}>{dealerStore.dealerRegionName}</Link> : '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Sales manager</label>
              <div>{dealerStore.salesManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Sales manager email</label>
              <div>{dealerStore.salesManagerEmail || '--'}</div>
            </Row>
          </Col>
          <Col xs="6" xl="4">
            <Row className="mb-3">
              <label className="mb-0">Pre-owned manager</label>
              <div>{dealerStore.preoManagerName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Pre-owned manager email</label>
              <div>{dealerStore.preoManagerEmail || '--'}</div>
            </Row>
            {iAmGranted(perms.view_dealer_store_account_manager) && <Row className="mb-3">
              <label className="mb-0">Account Manager</label>
              <div>{dealerStore.accountManagerFullName ? <Link to={route(routes.view_user, dealerStore.accountManagerId)}>{dealerStore.accountManagerFullName}</Link> : '--'}</div>
            </Row>}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Created</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerStore.createdTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary"></i> Updated</h5>
            <p className="text-muted mb-0">{formatTimestamp(dealerStore.updatedTs, formats.DATETIME)}</p>
          </Col>
          <Col xs="6" xl="4">
            <h5 className="font-size-14"><i className={classnames("mdi me-1 text-primary", { 'mdi-credit-card-check': dealerStore.isPaymentSetupComplete, 'mdi-credit-card-clock': !dealerStore.isPaymentSetupComplete })}></i> Payment Setup</h5>
            <p className="text-muted mb-0">{dealerStore.isPaymentSetupComplete ? 'Complete' : 'Pending'}</p>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewInfo;