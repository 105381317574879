import React from "react";
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import DataTableCustomFee from "../DataTableCustomFee";
import { route, routes } from 'helpers/routeHelper';
import PropTypes from "prop-types";
import {perms, useAccess} from "../../../../context/access";

const SectionCustomFee = ({ id }) => {

	// hooks that check permissions
	const { iAmGranted } = useAccess();

	return <React.Fragment>
		<Card>
			<CardHeader className="bg-transparent pt-3 pb-0">
				<Row>
					<Col>
						<h3>Custom Fee</h3>
					</Col>
					<Col md="auto">
						<div className="text-end">
							{iAmGranted(perms.edit_payment_plans) && 	<Link to={route(routes.new_payment_plan_fee, id)} className="btn btn-outline-primary">
								<i className="mdi mdi-plus me-1" />Add fee
							</Link>}
						</div>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
					<DataTableCustomFee />
			</CardBody>
		</Card>
	</React.Fragment>
}

SectionCustomFee.propTypes = {
	id: PropTypes.number,
};

export default SectionCustomFee;