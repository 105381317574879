import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNotaryCredentials } from "helpers/backendHelper";
import { Row, Label, Input, FormFeedback } from "reactstrap";
import Col from "components/Shared/Col";
import DatePicker from "components/Shared/DatePicker";
import { useFormikContext } from "formik";
import Credential from "model/notaryCredential";
import { getYesNoOptionsAsNumbers, getBeUrl } from "helpers/utilHelper";

const UploadDriverLicense = () => {

  let { id } = useParams();
  id = parseInt(id);

  const [credentials, setCredentials] = useState([]);
  const [credentialsError, setCredentialsError] = useState();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    refreshCredentials();
  }, []);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  /**
  * Fetches credential doc list from API
  */
  const refreshCredentials = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getNotaryCredentials(id)
      .then(response => {
        setCredentials(response.credentials);
      })
      .catch(ex => {
        setCredentialsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  // consume formik instance from context
  const formik = useFormikContext();

  /********** EFFECTS **********/

  // remove the expiration date whenever `expiry` field switches to `no`
  useEffect(() => {
    if (formik.values.expiry === 0) delete formik.values.expirationTs;
  }, [formik.values.expiry]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  };

  const credentialToUpload = credentials.find(credential => credential.type === formik.values.type);

  return <Row className="mb-4">
    <Col xs={12} md={6}>
      <Label className="col-form-label">Driver License Number</Label>
      <Input type="text" className="form-control" name="driverLicenseNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.driverLicenseNo} invalid={!!formik.errors.driverLicenseNo} />
      {!!formik.errors.driverLicenseNo && <FormFeedback type="invalid">{formik.errors.driverLicenseNo}</FormFeedback>}
    </Col>
    <Col xs={12} md={6} />
    <Col xs={12} md={6}>
      <Label className="col-form-label">Upload file (pdf)</Label>
      <Input type="file" className="form-control" name="file" onChange={e => formik.setFieldValue("file", e.currentTarget.files[0])} onFocus={onFieldFocused} invalid={!!formik.errors.file} />
      {!!formik.errors.file && <FormFeedback type="invalid">{formik.errors.file}</FormFeedback>}
      <div className="mt-1">
        <a href={getBeUrl(`notary-credential/${credentialToUpload?.id}/render`)} target="_blank" rel="noreferrer">
          {Credential.getFileName(credentialToUpload?.type) + "_" + credentialToUpload?.userFullName}
        </a>
      </div>
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Expiration Date</Label>
      <DatePicker
        name="expirationTs"
        value={formik.values.expirationTs}
        onChange={selected => formik.setFieldValue("expirationTs", selected)}
        onFocus={onFieldFocused}
        minDate={new Date().setHours(0, 0, 0, 0)}
        invalid={!!formik.errors.expirationTs}
      />
      {!!formik.errors.expirationTs && <FormFeedback type="invalid" className="d-block">{formik.errors.expirationTs}</FormFeedback>}
    </Col>
  </Row>
}

export default UploadDriverLicense;