import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Card, CardBody, Alert, CardHeader, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { getProcessList } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { bytesToSize } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { timeSinceShort } from "helpers/dateHelper";
import classnames from "classnames";

const ListProcesses = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  const [processes, setProcesses] = useState([]);
  const [processesError, setProcessesError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  useEffect(() => {
    refreshProcesses();
  }, []);

  const refreshProcesses = () => {
    setIsLoadInProgress(true);
    getProcessList()
      .then(response => {
        setProcesses(response.processes);
      })
      .catch(ex => {
        setProcessesError('Unable to load processes');
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  const getStatusClassName = status => {
    switch (status) {
      case 'online':
        return 'bg-success';
      case 'stopped':
      case 'errored':
        return 'bg-danger';
      default:
        return 'bg-secondary';
    }
  }

  return <React.Fragment>
    {iAmGranted(perms.view_system_processes) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>Processes</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="PROCESSES" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="pt-3 bg-transparent">
                  <Row>
                    <Col className="text-end">
                      <Button type="button" color="primary" onClick={refreshProcesses}>
                        <i className="mdi mdi-reload me-1" />Refresh
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>CPU</th>
                        <th>Memory</th>
                        <th>Uptime</th>
                        <th>Restarts</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!processes.length && !isLoadInProgress && processes.map(process => <tr key={process.name}>
                        <td>
                          <Link to={route(routes.view_sys_process, process.name)}>{process.name}</Link>
                        </td>
                        <td>
                          <span className={classnames('badge badge-lg', getStatusClassName(process.status))}>{process.status}</span>
                        </td>
                        <td>{process.cpu * 100}%</td>
                        <td>{bytesToSize(process.memory)}</td>
                        <td>{timeSinceShort(process.uptime)}</td>
                        <td>{process.restarts}</td>
                      </tr>)}
                      {!processes.length && !isLoadInProgress && !processesError && <tr>
                        <td colSpan="6" className="text-center">No processes found</td>
                      </tr>}
                      {isLoadInProgress && <tr>
                        <td colSpan="6">
                          <SpinnerChase className="sm mt-2 mb-2" />
                        </td>
                      </tr>}
                      {!!processesError && !isLoadInProgress && <tr>
                        <td colSpan="6">
                          <Alert color="danger" className="fade show text-center">
                            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load processes
                          </Alert>
                        </td>
                      </tr>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    }
    {iAmNotGranted(perms.view_system_processes) && <AccessDenied />}
  </React.Fragment >
}

export default ListProcesses;
