import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import ExpanderToggle from '../ExpanderToggle';
import ExpanderDetails from '../ExpanderDetails';

const OrderSignerVerificationChecked = props => {

  const { log, noDetails } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const hasDetails = !noDetails && !log.verified;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        <AuthorName log={log} /> submitted security code [<strong>{log.subject.code}</strong>] for verification.
        {log.verified && <> Verification <strong className="text-success">passed</strong>.</>}
        {!log.verified && <> Verification <strong className="text-danger">failed</strong>.</>}
        <p className="mb-0">
          <EventTime log={log} showFull={true} />
          <ExpanderToggle {...props} {...{ isExpanded, setIsExpanded }} noDetails={!hasDetails} />
        </p>
      </div>
    </div>
    <ExpanderDetails {...props} {...{ isExpanded }} noDetails={!hasDetails}>
      <Table className="table-borderless mb-0 w-auto">
        <tbody>
          <tr>
            <th className="pt-2 pb-0">Error Code</th>
            <td className="pt-2 pb-0">{log.errCode}</td>
          </tr>
        </tbody>
      </Table>
    </ExpanderDetails>
  </React.Fragment>
}

OrderSignerVerificationChecked.propTypes = {
  log: PropTypes.object,
  noDetails: PropTypes.bool,
}

export default OrderSignerVerificationChecked;