/********** DATATABLE **********/

export const GET_NOTARY_STATEMENT_DT = "GET_NOTARY_STATEMENT_DT"
export const GET_SCHEDULER_DT = "GET_SCHEDULER_DT"
export const GET_NOTARY_STATEMENT_DT_OK = "GET_NOTARY_STATEMENT_DT_OK"
export const GET_NOTARY_STATEMENT_DT_ERR = "GET_NOTARY_STATEMENT_DT_ERR"
export const DO_NOTARY_STATEMENT_DT_CLEANUP = "DO_NOTARY_STATEMENT_DT_CLEANUP"

/********** DATATABLE FILTERS **********/

export const APPLY_NOTARY_STATEMENT_DT_FILTERS = "APPLY_NOTARY_STATEMENT_DT_FILTERS"
export const PATCH_NOTARY_STATEMENT_DT_FILTERS = "PATCH_NOTARY_STATEMENT_DT_FILTERS"
export const CLEAR_NOTARY_STATEMENT_DT_FILTERS = "CLEAR_NOTARY_STATEMENT_DT_FILTERS"
export const UNSET_NOTARY_STATEMENT_DT_FILTERS = "UNSET_NOTARY_STATEMENT_DT_FILTERS"
