import React, {useEffect, useState} from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, CardFooter, Alert, Button } from "reactstrap"
import { Link } from "react-router-dom";
import { getSchedulerInProgressOrders } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { showError } from "helpers/utilHelper";

const InProgressOrders = () => {

  /********** STATE **********/
  const [progressOrders, setProgressOrders] = useState([]);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [progressOrdersError, setProgressOrdersError] = useState(null);

  /********** OTHETRS **********/

  const onGetSchedulerInProgressOrders = () => {
    setIsLoadInProgress(true);
    getSchedulerInProgressOrders({ limit: 10 })
    .then(resp => setProgressOrders(resp.orders))
    .catch(err => {
      setProgressOrdersError(err);
      showError("Unable to load scheduler progress orders data.")
    })
    .finally(() => setIsLoadInProgress(false))
  }

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get orders in progress
    onGetSchedulerInProgressOrders();
  }, []);

  return <>
    <Card className="inactive-orders-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>In Progress Orders</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Signing ID</th>
              <th>Customer Name</th>
              <th>Dealer</th>
              <th>Notary</th>
              <th>Notary status</th>
              <th>Meeting time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {progressOrders.length > 0 && progressOrders.map((entry, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry.id}</td>
                <td>{entry.customerName}</td>
                <td>{entry.dealerName}</td>
                <td>{entry.notaryName}</td>
                <td>{!!entry.statusName ? entry.statusName : '--'}</td>
                <td>{!!entry.meetingTs ? formatTimestamp(entry.meetingTs, formats.DATETIME) : 'Not set'}</td>
                <td>
                  <Link to={route(routes.view_order, entry.id)}><span className="badge badge-lg rounded-pill bg-primary">View Details</span></Link>
                </td>
              </tr>
            ))}
            {progressOrders.length === 0 && !progressOrdersError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="8">No orders found</td>
            </tr>
            }
            {!!progressOrdersError && <tr>
              <td className="table-empty" colSpan="8">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a href={route(routes.list_orders)}>
          <Button color="light" className="text-primary">
            View All <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </CardFooter>
    </Card>
  </>
}

export default InProgressOrders;