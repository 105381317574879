import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../UserAvatar';
import { getInitialsFromName, getLogAuthorFullName } from 'helpers/utilHelper';

const AuthorAvatar = props => {

  const { log } = props;

  const getInitials = author => getInitialsFromName(getLogAuthorFullName(author));

  const getIcon = author => {
    if (!author) {
      return 'bx bx-calendar-event';
    }
    return null;
  }

  return <div className="flex-shrink-0 me-3">
    <UserAvatar id={log.author?.id} image={log.author?.image} initials={getInitials(log.author)} icon={getIcon(log.author)} size="sm" />
  </div>
}

AuthorAvatar.propTypes = {
  log: PropTypes.object,
}

export default AuthorAvatar;