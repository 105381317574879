import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import OrderDoc from 'model/orderDoc';

const InkSignPreviewModal = ({ page }) => {

  return <div className={'ink-sign-gallery-item status-' + page.status}>
    <div className="ink-sign-gallery-item-preview">
      <img src={page.image} className="ink-sign-gallery-item-img" />
    </div>
    <div className="ink-sign-gallery-item-btns">
      {page.status == OrderDoc.PAGE_STATUS_ACCEPTED && <Button type="button" color="primary" className="cursor-default">
        Accepted page
        <i className="bx bx-check-circle ms-2 align-middle"></i>
      </Button>}
      {page.status == OrderDoc.PAGE_STATUS_REJECTED && <Button type="button" color="danger" className="cursor-default">
        Rejected page
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
    </div>
  </div>
}

InkSignPreviewModal.propTypes = {
  page: PropTypes.object.isRequired,
};

export default InkSignPreviewModal;