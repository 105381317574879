export default {
  AL: "https://www.sos.alabama.gov/government-records/notary-public",
  AK: "https://aws.state.ak.us/NotaryDirectory/Commissions/Search",
  AZ: "https://apps.azsos.gov/apps/notary/search/Default.aspx",
  AR: "https://bcs.sos.arkansas.gov/NotarySearch",
  CA: "https://bcs.sos.arkansas.gov/search/notary",
  CO: "https://www.sos.state.co.us/notary/pages/public/verifyNotary.xhtml",
  CT: "https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx",
  DC: "https://opendata.dc.gov/datasets/notary-public-locations-2/data",
  FL: "http://notaries.dos.state.fl.us/not001.html",
  GA: "https://search.gsccca.org/notary/search.asp",
  HI: "https://notary.ehawaii.gov/notary/public/publicsearch.html",
  ID: "https://sosbiz.idaho.gov/search/notary",
  IL: "https://apps.ilsos.gov/notarysearch/",
  IN: "https://mylicense.in.gov/EVerification/Search.aspx",
  IA: "https://sos.iowa.gov/search/notary/search.aspx",
  KS: "https://sos.ks.gov/general-services/notary_public/notary-search.aspx",
  KY: "https://web.sos.ky.gov/notaries/(S(4yzyhe0vk1vopskms5ty3tgl))/SearchNotaries.aspx",
  LA: "https://www.sos.la.gov/NotaryAndCertifications/SearchForLouisianaNotaries/Pages/default.aspx",
  ME: "https://www1.maine.gov/cgi-bin/online/notary/search/search_index.pl",
  MD: "https://mdsos2.my.site.com/s/search-notary-database",
  MI: "https://dsvsesvc.sos.state.mi.us/TAP/_/#1",
  MN: "https://notary.sos.state.mn.us/search/searchfornotary",
  MS: "https://www.sos.ms.gov/index.php/notary-public-search",
  MO: "https://s1.sos.mo.gov/business/notary/Search/NotarySearch.aspx",
  MT: "https://biz.sosmt.gov/search/notary",
  NV: "https://esos.nv.gov/NotarySearchOnline/NotarySearchExternal",
  NJ: "https://state.nj.us/treasury/revenue/notary.shtml",
  NM: "https://enterprise.sos.nm.gov/search/notary",
  NY: "https://appext20.dos.ny.gov/lcns_public/chk_load",
  NC: "https://www.sosnc.gov/online_services/notary/find_a_notary",
  ND: "https://firststop.sos.nd.gov/search/notary",
  OH: "https://notarysearch.ohiosos.gov/ords/f?p=ENSEARCH:SEARCH:3376926223589:::1::",
  OK: "https://www.sos.ok.gov/notary/search.aspx",
  OR: "https://data.oregon.gov/Business/Active-Notaries/j2pk-zk6z",
  PA: "https://www.notaries.pa.gov/Pages/NotarySearch.aspx",
  RI: "http://business.sos.ri.gov/notarypublicsearch/",
  SC: "https://search.scsos.com/notaries",
  SD: "https://sosenterprise.sd.gov/BusinessServices/Notary/NotarySearch.aspx",
  TN: "https://tnbear.tn.gov/notary/notary",
  TX: "https://direct.sos.state.tx.us/notaries/notarysearch.asp",
  UT: "https://secure.utah.gov/notary/search.html",
  VT: "https://secure.professionals.vermont.gov/prweb/PRServletCustom/V9csDxL3sXkkjMC_FR2HrA%5B%5B*/!STANDARD?UserIdentifier=LicenseLookupGuestUser",
  VA: "https://solutions.virginia.gov/notary/search/search",
  WA: "https://professions.dol.wa.gov/s/license-lookup",
  WV: "https://apps.sos.wv.gov/business/notary/",
  WI: "https://www.wdfi.org/apps/NotarySearch/SearchCriteria.aspx"
};