export default class DealerStore {

  static PAYMENT_METHOD_TYPE_CARD = 1;
  static PAYMENT_METHOD_TYPE_BANK_ACCOUNT = 2;
  static PAYMENT_METHOD_TYPE_CHECK = 3;

  static PAYMENT_SETUP_STATUS_NOT_STARTED = 0;
  static PAYMENT_SETUP_STATUS_IN_PROGRESS = 1;
  static PAYMENT_SETUP_STATUS_NOT_COMPLETE = 2;
  static PAYMENT_SETUP_STATUS_COMPLETE = 3;

  static getPaymentMethodMap() {
    return {
      [this.PAYMENT_METHOD_TYPE_CARD]: 'Card',
      [this.PAYMENT_METHOD_TYPE_BANK_ACCOUNT]: 'Bank Account',
      [this.PAYMENT_METHOD_TYPE_CHECK]: 'Check',
    };
  }

  static getPaymentMethodName(id) {
    return this.getPaymentMethodMap()[id];
  }

  static getPaymentSetupStatusMap() {
    return {
      [this.PAYMENT_SETUP_STATUS_NOT_STARTED]: 'Not Started',
      [this.PAYMENT_SETUP_STATUS_IN_PROGRESS]: 'In Progress',
      [this.PAYMENT_SETUP_STATUS_NOT_COMPLETE]: 'Not Complete',
      [this.PAYMENT_SETUP_STATUS_COMPLETE]: 'Complete',
    };
  }

  static isStripePaymentMethod(paymentMethodType) {
    return [this.PAYMENT_METHOD_TYPE_CARD, this.PAYMENT_METHOD_TYPE_BANK_ACCOUNT].includes(paymentMethodType);
  }

  static isOfflinePaymentMethod(paymentMethodType) {
    return [this.PAYMENT_METHOD_TYPE_CHECK].includes(paymentMethodType);
  }
}