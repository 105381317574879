import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import DateRangePicker from "components/Shared/DateRangePicker";
import { useFormik } from "formik";
import {
  applyNewDealersReportFilters,
} from "store/actions";
import { getEndOfPrevMonthTs, getStartOfPrevMonthTs } from "helpers/dateHelper";

const NewDealersFilters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.NewDealersFilters);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // default date range to previous month
  useEffect(() => {
    if (!filters.startTs && !filters.endTs) {
      applyFilters({
        startTs: getStartOfPrevMonthTs(),
        endTs: getEndOfPrevMonthTs(),
      })
    }
  }, []);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    if (selected[0]) {
      formik.setFieldValue("startTs", selected[0]);
    } else {
      formik.setFieldValue("startTs", null);
    }

    if (selected[1]) {
      formik.setFieldValue("endTs", selected[1]);
    } else {
      formik.setFieldValue("endTs", null);
    }

    // if the range is complete, apply the changes
    if (selected?.length === 2) {
      applyFilters({ ...filters, startTs: selected[0], endTs: selected[1] });
    }
  };

  const handleClearRange = () => {
    formik.setFieldValue("startTs", null);
    formik.setFieldValue("endTs", null);
    applyFilters({ ...filters, startTs: null, endTs: null });
  };

  // event handler for the "apply-filters" button
  const applyFilters = values => dispatch(applyNewDealersReportFilters(values));

  return (
    <Row className="justify-content-between mb-4">
      <Col xs={6} sm={"auto"}>
        <DateRangePicker
          onChange={handleChangeRange}
          value={[formik.values.startTs, formik.values.endTs].filter(ts => !!ts)}
          onClear={handleClearRange}
        />
      </Col>
    </Row>
  )
}

export default NewDealersFilters;