import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SectionInfo from "../../PaymentPlan/CustomFees/Partial/Section/Info";
import { getPaymentPlanFee } from "../../../helpers/backendHelper";


const View = () => {

	let { id } = useParams();
	id = parseInt(id);

	// hooks that check permissions
	const { iAmGranted, iAmNotGranted } = useAccess();

	/********** STATE **********/

	const [customFee, setCustomFee] = useState(null);
	const [customFeeError, setCustomFeeError] = useState(null);
	const [isLoadInProgress, setIsLoadInProgress] = useState(false);

	/********** EFFECTS **********/

	// runs once on component mount
	useEffect(() => {
		// make the initial remote call to get the user data
		refreshCustomFee();
	}, [id]);

	/********** OTHER **********/

	const refreshCustomFee = () => {
		setIsLoadInProgress(true);
		// make the initial remote call to get the user data
		getPaymentPlanFee(id)
			.then(response => {
				setCustomFee(...response.paymentPlanFee);
			})
			.catch(err => {
				setCustomFeeError(err);
			})
			.finally(() => {
				setIsLoadInProgress(false);
			});
	};

	return <React.Fragment>
		{iAmGranted(perms.edit_payment_plans) && <div className="page-content">
			{customFee && <React.Fragment>
				<MetaTitle>{customFee.name} | Additional Fees</MetaTitle>
				<Container fluid>
					<Row>
						<Col xl="8" xxl="7">
							<SectionInfo id={id} customFee={customFee} refreshHandler={refreshCustomFee} isLoadBusy={isLoadInProgress} />
						</Col>
					</Row>
				</Container>
			</React.Fragment>}

			{isLoadInProgress && !customFee && <Preloader className="inner" />}
			{customFeeError && <Error error={customFeeError} title404="Additional fee not found" />}
		</div>}
		{iAmNotGranted(perms.edit_payment_plans) && <AccessDenied />}
	</React.Fragment>
}

export default View;
