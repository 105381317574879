/********** LOGIN **********/

export const GET_AUTH_USER = "GET_AUTH_USER"
export const GET_AUTH_USER_OK = "GET_AUTH_USER_OK"
export const GET_AUTH_USER_ERR = "GET_AUTH_USER_ERR"

export const LOGOUT_USER_OK = "LOGOUT_USER_OK"

/********** IMPERSONATION **********/

export const IMPERSONATE_USER = "IMPERSONATE_USER"
export const IMPERSONATE_USER_OK = "IMPERSONATE_USER_OK"
export const IMPERSONATE_USER_ERR = "IMPERSONATE_USER_ERR"