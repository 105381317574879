/********** DATATABLE **********/

export const GET_USER_DT = 'GET_USER_DT'
export const GET_SCHEDULER_DT = 'GET_SCHEDULER_DT'
export const GET_USER_DT_OK = 'GET_USER_DT_OK'
export const GET_USER_DT_ERR = 'GET_USER_DT_ERR'
export const DO_USER_DT_CLEANUP = 'DO_USER_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_USER_DT_FILTERS = 'APPLY_USER_DT_FILTERS'
export const PATCH_USER_DT_FILTERS = 'PATCH_USER_DT_FILTERS'
export const CLEAR_USER_DT_FILTERS = 'CLEAR_USER_DT_FILTERS'
export const UNSET_USER_DT_FILTERS = 'UNSET_USER_DT_FILTERS'

/********* LIST *********/

export const GET_DEALER_GROUP_MANAGER_LIST = 'GET_DEALER_GROUP_MANAGER_LIST'
export const GET_DEALER_REGION_MANAGER_LIST = 'GET_DEALER_REGION_MANAGER_LIST'
export const GET_DEALER_STORE_USER_LIST = 'GET_DEALER_STORE_USER_LIST'
export const GET_USER_LIST_OK = 'GET_USER_LIST_OK'
export const GET_USER_LIST_ERR = 'GET_USER_LIST_ERR'
export const DO_USER_LIST_CLEANUP = 'DO_USER_LIST_CLEANUP'