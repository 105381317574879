import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { toSelectOptions, hasNonEmpty } from "helpers/utilHelper";
import UsStates from "constants/usState";
import { applyDealerGroupDtFilters, clearDealerGroupDtFilters } from 'store/actions';

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.DealerGroup.DtFilters);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyDealerGroupDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearDealerGroupDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas 
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(filters);

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Name</Label>
            <Input type="text" className="form-control" name="name" onChange={formik.handleChange} value={formik.values.name} invalid={!!formik.errors.name} />
            {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>State</Label>
            <Select
              classNamePrefix="select2-selection"
              name="state"
              options={stateOptions}
              onChange={selected => formik.setFieldValue('state', selected.value)}
              value={stateOptions.find(option => option.value === formik.values.state)}
              className={!!formik.errors.state && 'is-invalid'} />
            {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Email</Label>
            <Input type="text" className="form-control" name="email" onChange={formik.handleChange} value={formik.values.email} invalid={!!formik.errors.email} />
            {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Phone</Label>
            <Input type="text" className="form-control" name="phone" onChange={formik.handleChange} value={formik.values.phone} invalid={!!formik.errors.phone} />
            {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

const stateOptions = [
  { label: 'All', value: '' },
  ...toSelectOptions(UsStates),
];

export default Filters;