import React from "react";
import { useCountdown } from "hooks/useCountdown";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";

const RequeueButton = ({ targetDate, handleRequeue }) => {
	const [hours, minutes, seconds] = useCountdown(targetDate);

	if ( hours + minutes + seconds <= 0) {
		return (
			<div className="expired-notice" onClick={handleRequeue}>
				<i id="requeue-icon" className="bx bx-revision text-secondary font-size-20"></i>
				<UncontrolledTooltip placement="top" target="requeue-icon">Add the notary back into the bidding process.</UncontrolledTooltip>
			</div>
		);
	}
};

RequeueButton.propTypes = {
	targetDate: PropTypes.any,
	handleRequeue: PropTypes.any,
}

export default RequeueButton;

