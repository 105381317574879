import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import FormInfoEdit from "../Form/Info/Edit";
import ViewInfo from "../View/Info";

const SectionInfo = props => {

  const { isLoadBusy, id, dealerGroup, refreshHandler } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormInfoEdit id={id} defaultValues={dealerGroup} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewInfo id={id} dealerGroup={dealerGroup} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerGroup: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionInfo;
