import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Button, FormFeedback } from "reactstrap";
import SelectButton from "components/Shared/SelectButton";
import SupportCase from "model/supportCase";
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName, showError, toSelectOptions } from "helpers/utilHelper";
import { perms, useAccess } from "context/access";
import { assignSupportCase, getSupportCaseHandlers, setSupportCaseInternalStatus, setSupportCasePriority, setSupportCaseStatus } from "helpers/backendHelper";
import Order from "model/order";

const ViewQuickActions = ({ supportCase }) => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isPriorityOpen, setIsPriorityOpen] = useState(false);
  const [isInternalStatusOpen, setIsInternalStatusOpen] = useState(false);
  const [isStatusChangeInProgress, setIsStatusChangeInProgress] = useState(false);
  const [isPriorityChangeInProgress, setIsPriorityChangeInProgress] = useState(false);
  const [isAssignInProgress, setIsAssignInProgress] = useState(false);
  const [isInternalStatusChangeInProgress, setIsInternalStatusChangeInProgress] = useState(false);

  const [handlers, setHandlers] = useState([]);
  const [handlersError, setHandlersError] = useState();

  const { status, statusName, assigneeId, assigneeFullName, priority, internalStatus, internalStatusName, customerLocation } = supportCase;

  const isResolved = SupportCase.isResolved(status);

    /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the assignee field options
    getHandlerList();
  }, []);

  /********** EVENT HANDLERS **********/

  const setStatus = (status) => {
    setIsStatusChangeInProgress(true);
    setSupportCaseStatus(supportCase.id, { status })
      .then(response => {
        // nothing
      })
      .catch(ex => {
        showError("Unable to change the status");
      })
      .finally(() => {
        setIsStatusChangeInProgress(false);
      })
  }

  const setPriority = (priority) => {
    setIsPriorityChangeInProgress(true);
    setSupportCasePriority(supportCase.id, { priority })
      .then(response => {
        // nothing
      })
      .catch(ex => {
        showError("Unable to change the priority");
      })
      .finally(() => {
        setIsPriorityChangeInProgress(false);
      })
  }

  const assignCase = (userId) => {
    setIsAssignInProgress(true);
    assignSupportCase(supportCase.id, { userId })
      .catch(ex => {
        showError("Unable to change the assignee");
      })
      .finally(() => {
        setIsAssignInProgress(false);
      })
  }

  const setInternalStatus = (status) => {
    setIsInternalStatusChangeInProgress(true);
    setSupportCaseInternalStatus(supportCase.id, { status })
      .then(response => {
        // nothing
      })
      .catch(ex => {
        showError("Unable to change the internal status");
      })
      .finally(() => {
        setIsInternalStatusChangeInProgress(false);
      })
  }

  /********** OTHER **********/

  // omit the current status
  // also omit the open status since one cannot chang the status back to open
  const getStatuses = () => Object.entries(SupportCase.getStatusMap()).filter(entry => entry[0] != status && entry[0] != SupportCase.STATUS_OPEN);
  const getPriorities = () => Object.entries(SupportCase.getPriorityMap()).filter(entry => entry[0] != priority);
  const getInternalStatuses = () => Object.entries(SupportCase.getInternalStatusMap()).filter(entry => entry[0] != internalStatus);

  const getHandlerList = () => {
    getSupportCaseHandlers()
      .then(response => {
        setHandlers(response.users);
      })
      .catch(ex => {
        setHandlersError(ex);
      });
  };

  const getAssigneeName = () => assigneeFullName || "Unassigned";

  const assigneeOptions = toSelectOptions(handlers, "id", "fullName");

  return <div className="support-case-quick-actions">
    <Row className="justify-content-between">
      <Col xs="6">
        <div className="mb-2">
          <span className="me-2">Status</span>
          {(isResolved || iAmNotGranted(perms.edit_support_cases)) && <Button color={SupportCase.getStatusColor(status)} size="sm" className="action-btn">{statusName}</Button>}
          {!isResolved && iAmGranted(perms.edit_support_cases) && <ButtonDropdown isOpen={isStatusOpen} toggle={() => setIsStatusOpen(!isStatusOpen)} className="action-dropdown" disabled={isStatusChangeInProgress}>
            <DropdownToggle color={SupportCase.getStatusColor(status)} className="btn btn-sm">
              {statusName}
              {isStatusChangeInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
              {!isStatusChangeInProgress && <i className="bx bxs-down-arrow ms-2" />}
            </DropdownToggle>
            <DropdownMenu>
              {getStatuses().map(entry => {
                return <DropdownItem key={entry[0]} onClick={() => setStatus(entry[0])}>{entry[1]}</DropdownItem>
              })}
            </DropdownMenu>
          </ButtonDropdown>}
        </div>

        <div className="me-2">
          <span>Order Type: </span>
          <strong>{Order.getCustomerLocationName(customerLocation)}</strong>
        </div>

        <div>
          <span className="me-2">Priority</span>
          {(isResolved || iAmNotGranted(perms.edit_support_cases)) && <React.Fragment>
            <i className={'mdi mdi-circle align-middle me-2 text-' + SupportCase.getPriorityColor(parseInt(priority))}></i>
            <strong>{SupportCase.getPriorityName(priority)}</strong>
          </React.Fragment>}
          {!isResolved && iAmGranted(perms.edit_support_cases) && <ButtonDropdown isOpen={isPriorityOpen} toggle={() => setIsPriorityOpen(!isPriorityOpen)} className="action-dropdown" disabled={isPriorityChangeInProgress}>
            <DropdownToggle color="default" className="btn btn-sm">
              <i className={'mdi mdi-circle align-middle me-2 text-' + SupportCase.getPriorityColor(parseInt(priority))}></i>
              <strong>{SupportCase.getPriorityName(priority)}</strong>
              {isPriorityChangeInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
              {!isPriorityChangeInProgress && <i className="bx bxs-down-arrow ms-2" />}
            </DropdownToggle>
            <DropdownMenu>
              {getPriorities().map(entry => {
                return <DropdownItem key={entry[0]} onClick={() => setPriority(entry[0])}><i className={'mdi mdi-circle align-middle me-2 text-' + SupportCase.getPriorityColor(parseInt(entry[0]))}></i>{entry[1]}</DropdownItem>
              })}
            </DropdownMenu>
          </ButtonDropdown>}
        </div>
      </Col>
      <Col xs="auto">
        <div>
          <span className="me-2">Assignee</span>
          {isResolved || iAmNotGranted(perms.assign_support_cases) && <React.Fragment>
            {!!assigneeId && <UserAvatar id={assigneeId} initials={getInitialsFromName(getAssigneeName())} size="sm me-2" />}
            <strong>{getAssigneeName()}</strong>
          </React.Fragment>}
          {!isResolved && iAmGranted(perms.assign_support_cases) && (
            <SelectButton
              name="assigneeId"
              options={assigneeOptions}
              onChange={selected => assignCase(selected.value)}
              value={assigneeOptions.find(option => option.value === assigneeId)}
              isLoading={isAssignInProgress}
              hasError={!!handlersError}
              showAvatar
            />
          )}
        </div>

        <div>
          <span className="me-2">Internal status</span>
          {(isResolved || iAmNotGranted(perms.edit_support_cases)) && <strong>{internalStatusName}</strong>}
          {!isResolved && iAmGranted(perms.edit_support_cases) && <ButtonDropdown isOpen={isInternalStatusOpen} toggle={() => setIsInternalStatusOpen(!isInternalStatusOpen)} className="action-dropdown" disabled={isInternalStatusChangeInProgress}>
            <DropdownToggle color="default" className="btn btn-sm">
              <strong>{internalStatusName}</strong>
              {isInternalStatusChangeInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
              {!isInternalStatusChangeInProgress && <i className="bx bxs-down-arrow ms-2" />}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {getInternalStatuses().map(entry => {
                return <DropdownItem key={entry[0]} onClick={() => setInternalStatus(entry[0])}>{entry[1]}</DropdownItem>
              })}
            </DropdownMenu>
          </ButtonDropdown>}
        </div>
      </Col>
    </Row>
  </div>

}

ViewQuickActions.propTypes = {
  supportCase: PropTypes.object
};

export default ViewQuickActions;