import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UserAvatar from 'components/Shared/UserAvatar';
import { ListGroup, ListGroupItem, Modal } from "reactstrap";
import Downshift from "downshift";
import { getInitialsFromName } from "helpers/utilHelper";

const CustomInput = ({ value, onInput, onKeyDown, placeholder, inputRef, tagData, taggingActive }) => {

  /********** STATE **********/

  // Whether the popover autocomplete is visible
  const [popoverVisible, setPopoverVisible] = useState(false);
  // Holds the position of the popover based on caret (typing cursor) position
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  // Holds the last char that the user pressed
  const [lastChar, setLastChar] = useState("");
  // Holds the users that can be tagged
  const [userList, setUserList] = useState();
  // Holds the filtered users list
  const [filteredUserList, setFilteredUserList] = useState([]);
  // Holds the charactes added for autocomplete list
  const [autocompleteValue, setAutocompleteValue] = useState("");
  // Holds the child node where the caret is located
  const [curretChildNode, setCurrentChildNode] = useState(0);
  // Holds the child node for p
  const [pNodeIndex, setPNodeIndex] = useState(0);
  // Holds the caret position in a node
  const [caretPosition, setCaretPosition] = useState();

  /********** REGEX EXPRESSIONS **********/

  // regex to check if the trigger has something behind it (eg. test@ where the trigger is @)
  const triggerRegex = /(?<!\S|&nbsp;)@/g;

  /********** HANDLERS **********/

  // Function that gets the caret coordinates on the viewport (we need this to know where should we show the popover)
  const getCaretCoordinates = () => {
    // Getting the selection
    const selection = window.getSelection();
    // If we have a selection
    if (selection.rangeCount > 0) {
      // Get the range
      const range = selection.getRangeAt(0);
      // Get caret position in px relative to the viewport
      const rect = range.getBoundingClientRect();
      // Update popover position (left and top) in pixels
      setPopoverPosition({
        left: rect.left,
        top: rect.top,
      });
    }
  };

  // Function that doesn't allow to delete the first paragraph
  const preventFirstParagraphDeletion = (event) => {
    const editableDiv = inputRef.current;
    if (!editableDiv) return;

    // Get all <p> elements inside the contenteditable
    const paragraphs = editableDiv.querySelectorAll('p');

    if (paragraphs.length > 0 && window.getSelection) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Get the first <p> element inside the contenteditable
      const firstP = document.querySelector('.chat-input p');
      // Getting the anchor node
      let anchorNode = selection.focusNode;

      // If the anchor node is a text node, get its parent element
      if (anchorNode.nodeType === Node.TEXT_NODE) {
        anchorNode = anchorNode.parentNode;
      }

      // Check when users selects all text using mouse or ctrl+a
      if (range.commonAncestorContainer === editableDiv && range.startOffset === 0 && (firstP.contains(anchorNode) || anchorNode === firstP || anchorNode.contains(firstP))) {
        // If Backspace or Delete is pressed and the cursor is at the beginning of the first <p>
        if (event.key === 'Backspace' || event.key === 'Delete') {
          event.preventDefault(); // Prevent deleting the first <p>
          paragraphs.forEach((p) => {
            if (editableDiv.firstChild === p) {
              p.textContent = "";
              p.innerHTML = "";
            } else {
              p.remove();
            }
          })

          range.setStart(editableDiv.childNodes[0], 0)
          range.setEnd(editableDiv.childNodes[0], 0)
          selection.removeAllRanges();
          range.collapse(false);
          selection.addRange(range);

          onInput(inputRef.current.innerHTML);
          return;
        }
      }
      // Check when user selects all content on the first paragraph
      if (range.commonAncestorContainer === paragraphs[0] && range.startOffset === 0) {
        // If Backspace or Delete is pressed and the cursor is at the beginning of the first <p>
        if (event.key === 'Backspace' || event.key === 'Delete') {
          event.preventDefault(); // Prevent deleting the first <p>
          paragraphs[0].textContent = "";
          paragraphs[0].innerHTML = "";
          onInput(inputRef.current.innerHTML);
          return;
        }
      }

      // Check if the selection has only empty spaces
      if (range.startContainer.textContent.trim().length === 0 && pNodeIndex === 0 && curretChildNode === 0) {
        // If Backspace or Delete is pressed and the cursor is at the beginning of the first <p>
        if (event.key === 'Backspace') {
          event.preventDefault(); // Prevent deleting the first <p>
          return;
        }
      }

      // Check when user presses backspace/delete at the start of the first p
      if ((range.startContainer === paragraphs[0] || range.startContainer.parentElement === paragraphs[0]) && range.startOffset === 0 && pNodeIndex === 0) {
        // If Backspace or Delete is pressed and the cursor is at the beginning of the first <p>
        if (event.key === 'Backspace' || event.key === 'Delete') {
          event.preventDefault(); // Prevent deleting the first <p>
          return;
        }
      }

      // If we have a BR at the end keep it and don't delete it
      if (range.startContainer.childNodes.length === 2) {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          if (range.startContainer.childNodes[1].nodeName === "BR") {
            event.preventDefault();
            return;
          }
        }
      }

    }
  }

  // Function to check if the input is blank (doesnt have content so we can show the placeholder)
  const checkInputBlank = () => {
    if (!!inputRef.current) {
      const pElements = inputRef.current.querySelectorAll("p");
      if (pElements.length === 1) {
        if (pElements[0].innerHTML.length === 0 || pElements[0].textContent.length === 0) {
          return true;
        }
      }
    }
    return false;
  }

  // Removing current line when the user is at the start of the line ( we need to do this manually because the browser creates a random span around the remaining content)
  const removeCurrentLine = (e) => {

    // Get the current selection
    let selection = window.getSelection();
    // Create the range of that selection
    let rangeCreated = selection.getRangeAt(0)

    const inputElement = inputRef.current;
    let lineContent;
    lineContent = inputElement.childNodes[curretChildNode]?.innerHTML || "";
    if ((caretPosition === 0 && pNodeIndex === 0 && curretChildNode > 0 && rangeCreated.startOffset === rangeCreated.endOffset && lineContent !== "<br>") || (caretPosition === 0 && pNodeIndex === 0 && curretChildNode > 0 && rangeCreated.startOffset === rangeCreated.endOffset && /\s/.test(JSON.stringify(lineContent)) && lineContent.length === 1)) {

      e.preventDefault();

      inputElement.childNodes[curretChildNode]?.remove();
      inputElement.childNodes[curretChildNode - 1].innerHTML = inputElement.childNodes[curretChildNode - 1].innerHTML + lineContent
      // Remove remaining brs if it's the case
      inputElement.childNodes[curretChildNode - 1].innerHTML = inputElement.childNodes[curretChildNode - 1].innerHTML.replace(/<br>/g, "");

      // Focus on the previous line
      inputElement.childNodes[curretChildNode - 1].focus();

      // Set the cursor on the previous line
      if (!!inputElement.childNodes[curretChildNode - 1].lastChild) {
        rangeCreated.setStart(inputElement.childNodes[curretChildNode - 1].lastChild, inputElement.childNodes[curretChildNode - 1].lastChild.textContent.length)
        rangeCreated.setEnd(inputElement.childNodes[curretChildNode - 1].lastChild, inputElement.childNodes[curretChildNode - 1].lastChild.textContent.length)
      } else {
        rangeCreated.setStart(inputElement.childNodes[curretChildNode - 1], inputElement.childNodes[curretChildNode - 1].textContent.length)
        rangeCreated.setEnd(inputElement.childNodes[curretChildNode - 1], inputElement.childNodes[curretChildNode - 1].textContent.length)
      }

      selection.removeAllRanges();
      rangeCreated.collapse(false);
      selection.addRange(rangeCreated);

      onInput(inputElement.innerHTML)
    }
  }

  // Function that decides if the popover should be shown
  const handlePopoverTag = () => {
    // Div Node - the div parent
    const divNode = inputRef.current;
    // Extracting the text content with spaces between the p
    const textNode = Array.from(divNode.querySelectorAll('p'))
      .map(p => p.textContent.trim()) // Get text content and trim whitespace
      .join(' '); // Join with a space

    // If we have innerHTML (user wrote something in the div)
    if (!!textNode) {
      // Check if we need to show the tagging options
      if (triggerRegex.test(textNode.replace("&nbsp;", " ")) && lastChar === "@" && taggingActive) {
        // Check caret coordinates and save them into popover coordinates
        getCaretCoordinates();
        // Show the popup
        setPopoverVisible(true);
        // setting the @ on the autocomplete value
        setAutocompleteValue("@");
      }
      // Check if we need to close the tagging options
      handleCloseTagging(textNode);
    } else {
      setPopoverVisible(false);
    }
  };

  // Function that handles the closing of the tagging popover
  const handleCloseTagging = (text) => {
    if (!!text && !text?.length) {
      setPopoverVisible(false);
    }
    if (lastChar === "Backspace" && !autocompleteValue.length) {
      setPopoverVisible(false);
    }
    if (lastChar === "ArrowRight" || lastChar === "ArrowLeft") {
      setPopoverVisible(false);
    }
  };

  // Function that handles tagging the person (when user click on a list item)
  const handleTagUser = (user) => {
    // Template User String
    const taggedUserString = `@[${user.fullName}](${user.id}-${user.userRoleId})`
    // Focusing on the div input
    inputRef.current.focus();
    // Get the current selection
    let selection = window.getSelection();
    // Create the range of that selection
    let rangeCreated = selection.getRangeAt(0)
    // Get the anchor node (where the cursor or selection starts)
    let anchorNode = selection.anchorNode;
    // Check if anchorNode is inside a parent element
    if (anchorNode) {
      let childTextContent;
      // Setting the input element
      const inputElement = inputRef.current;
      // Extracting the child node text content (where we will add the tag)
      // If we have child nodes we take the inner html of the current P index
      if (!!inputElement.childNodes[curretChildNode].childNodes.length) {
        childTextContent = inputElement.childNodes[curretChildNode].childNodes[pNodeIndex].textContent;
        // No child nodes available, it means it's empty so we take the empty innerhtml
      } else {
        childTextContent = inputElement.childNodes[curretChildNode].innerHTML
      }

      // Removing the @ and the autocomplete text
      const autocompleteRegex = autocompleteValue.replace(/\s+/g, "\\s+");
      // Using regex for double space or other characters
      const regex = new RegExp(autocompleteRegex, 'g')
      // Replacing the autocomplete with nothing
      childTextContent = childTextContent.replace(regex, "")

      // Splitting the text node so we can add the span
      const remainingTextNode = childTextContent.slice(0, caretPosition - autocompleteValue.length)
      const tagNode = document.createElement('span');
      const nextTextNode = childTextContent.slice(caretPosition - autocompleteValue.length) + " "

      // Set attributes and styles of the span
      tagNode.setAttribute('contentEditable', 'false');
      tagNode.setAttribute('spellcheck', 'false');
      tagNode.setAttribute('id', taggedUserString);
      tagNode.textContent = `@${user.fullName}`;

      // Set the inline styles of the span
      tagNode.style.display = 'inline-block';
      tagNode.style.marginTop = '1px';
      tagNode.style.color = '#556EE6';
      tagNode.style.fontWeight = '600';
      tagNode.style.backgroundColor = '#dfdfdf';
      tagNode.style.borderRadius = '20px';
      tagNode.style.padding = '0px 5px';
      tagNode.style.fontSize = '12px';

      // Setting the nodes accordingly
      inputElement.childNodes[curretChildNode].childNodes[pNodeIndex].textContent = remainingTextNode;
      inputElement.childNodes[curretChildNode].childNodes[pNodeIndex].after(tagNode);
      inputElement.childNodes[curretChildNode].childNodes[pNodeIndex + 1].after(nextTextNode);

      // Set cursor after the tag
      if (!!inputElement.childNodes[curretChildNode].childNodes.length) {
        // Set the cursor just after the inserted newline
        rangeCreated.setStart(inputElement.childNodes[curretChildNode].childNodes[pNodeIndex + 1].nextSibling, 0)
      } else {
        rangeCreated.setStart(inputElement.childNodes[curretChildNode].nextSibling, 0)
      }

      rangeCreated.collapse(false);
      selection.addRange(rangeCreated);

      // Set the state value with the current value
      onInput(inputRef.current.innerHTML);
    }
    // We added the tag so we need to clear the last character entered from the keyboard
    setLastChar(undefined);
    // We close the popup
    setPopoverVisible(false);
  }

  // Function that sets the current node and caret position
  const getCurrentNodeAndCaretPosition = () => {
    // Set timeout to wait a bit before getting the current node/position because
    // the caret is moving but the render happens usually after
    setTimeout(() => {
      // Get the current selection
      let selection = window.getSelection();
      // Get the anchor node (where the cursor or selection starts)
      let anchorNode = selection.anchorNode;
      // Check if anchorNode is inside a parent element
      if (anchorNode && !!inputRef.current) {
        // Get the parent element of the anchorNode
        let parentElement = anchorNode.nodeType === Node.TEXT_NODE ? anchorNode.parentElement : anchorNode;
        // Find the index of the anchorNode within its parent
        let childNodes = Array.prototype.slice.call(inputRef.current.childNodes);
        let index = childNodes.indexOf(parentElement);
        setCurrentChildNode(index)

        // Find the index in the p element
        let childPNodes = Array.prototype.slice.call(parentElement.childNodes);
        setPNodeIndex(0);

        if (!!parentElement.childNodes.length && anchorNode.nodeType === Node.TEXT_NODE) {
          setPNodeIndex(childPNodes.indexOf(anchorNode));
        }
      }

      // Setting the care position
      if (!!selection.rangeCount) {
        let rangeCreated = selection.getRangeAt(0)
        // This gives you the position of the cursor
        const position = rangeCreated.startOffset;
        // Setting the caret position
        setCaretPosition(position);
      }
    }, 10)
  }

  // Handler for onKeyDown
  const handleInputOnKeyDown = e => {
    // Don't let the first paragraph be deleted
    preventFirstParagraphDeletion(e);
    // On key down prop function
    onKeyDown(e);
    // Setting the last characted pressed by the user
    setLastChar(e.key);
    // Check if the user presses something else and we should close the tagging modal
    handleCloseTagging();
    // Check caret position
    getCurrentNodeAndCaretPosition();
    // Remove any other spans that doesn't have content editable
    inputRef.current.querySelectorAll('span:not([contentEditable])').forEach(span => span.remove());
    // Check if the user wants to delete the line
    if (e.key === "Backspace" || e.key === "Delete") {
      removeCurrentLine(e)
    }
    // Check if there are any brs added randomly and delete them
    // deleteBrsCreated();
    // Setting autocomplete value based on what keys the user pressed
    if (popoverVisible) {
      // Letters and numbers
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
        setAutocompleteValue(autocompleteValue + e.key)
      }
      // Space
      if (e.keyCode === 32) {
        setAutocompleteValue(autocompleteValue + " ")
      }
      if (e.key == "Backspace" || e.key == "Delete") {
        setAutocompleteValue(autocompleteValue.slice(0, -1))
      }
    } else {
      setAutocompleteValue("")
    }
  }

  /********** EFFECTS **********/

  // Runs once on component mounting to set the user list
  useEffect(() => {
    setUserList(tagData)
  }, [tagData])

  // Check tagging each time the user writes something in the input
  useEffect(() => {
    handlePopoverTag();
    // deleteBrsCreated();
  }, [value])

  // Get the current node and the current caret position
  useEffect(() => {
    getCurrentNodeAndCaretPosition();
  }, [value, inputRef.current])

  // Filtering by autocomplete value
  useEffect(() => {
    if (!!userList) {
      const filteredUsers = userList.filter(item => item.fullName.toLowerCase().includes(autocompleteValue.replace("@", "").toLowerCase()))
      setFilteredUserList(filteredUsers);
    }
  }, [autocompleteValue, userList])

  // UseEffect for handling the paste into the input (we need to treat the paste as a normal input)
  useEffect(() => {
    const handlePaste = (e) => {
      e.preventDefault();
      // Get the plain text from the clipboard
      let text = (e.clipboardData || window.clipboardData).getData('text');
      // Insert the text at the cursor's position
      const sel = window.getSelection();
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        range.deleteContents(); // Remove selected content, if any
        const textNode = document.createTextNode(text);
        range.insertNode(textNode);

        // Move the caret to the end of the inserted text
        range.setStartAfter(textNode);
        range.setEndAfter(textNode);
        sel.removeAllRanges();
        sel.addRange(range);

        // Set the state value with the current value
        onInput(inputRef.current.innerHTML);
      };
    }
    const inputDiv = inputRef.current;
    // Attach the paste event listener
    if (inputDiv) {
      inputDiv.addEventListener('paste', handlePaste);
    }

    // Clean up the event listener on unmount
    return () => {
      if (inputDiv) {
        inputDiv.removeEventListener('paste', handlePaste);
      }
    };
  }, [inputRef.current])

  // Stop scrolling while modal is open
  useEffect(() => {
    if (popoverVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [popoverVisible])

  return (
    <>
      <div
        ref={inputRef}
        contentEditable
        className={`form-control chat-input ${checkInputBlank() ? "chat-input-blank" : ""}`}
        onKeyDown={handleInputOnKeyDown}
        onClick={() => getCurrentNodeAndCaretPosition()}
        onInput={(e) => onInput(e.target.innerHTML)}
        // Hide React Warning
        suppressContentEditableWarning={true}
        // Placeholder coming from props
        placeholder={placeholder}
        id="div-input-editable"
        aria-multiline
      >
        <p
          className="chat-p"
        ></p>
      </div>
      <Downshift
        inputValue={value}
        isOpen={popoverVisible}
      >
        {({
          isOpen
        }) => (
          <div>
            <Modal
              isOpen={popoverVisible}
              backdrop={true}
              style={{
                position: "fixed",
                top: filteredUserList.length * 65 > 300 ? popoverPosition.top - 340 : popoverPosition.top - filteredUserList.length * 65 - 40,
                left: popoverPosition.left,
                width: 300,
                maxHeight: "300px",
                overflow: "auto",
                margin: "1.75rem auto",
                visibility: filteredUserList.length === 0 ? "hidden" : ""
              }}
              toggle={() => {
                setPopoverVisible(false);
                // Remove focus from input, we need to wait for react to render
                setTimeout(() => {
                  window.getSelection().removeAllRanges();
                }, 10)
              }}
              autoFocus={false}
              fade={false}
              backdropClassName="bg-transparent"
              scrollable={false}
            >
              <ListGroup>
                {isOpen
                  ? filteredUserList.map((item, index) => (
                    <ListGroupItem key={index} className="border-0 cursor-pointer list-group-item" action onClick={() => handleTagUser(item)}  >
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <UserAvatar
                            id={item.id}
                            initials={getInitialsFromName(item.fullName)}
                            image={item.image}
                            size="sm"
                          />
                        </div>
                        <div className="flex-grow-1 overflow-hidden my-auto">
                          <h5 className="text-truncate font-size-14 mb-1">
                            {item.fullName}
                          </h5>
                          <div className="text-muted">
                            {item.userRoleName}
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))
                  : null}
              </ListGroup>
            </Modal>
          </div>
        )}
      </Downshift>
    </>
  )
}

CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  inputRef: PropTypes.any,
  tagData: PropTypes.array,
  taggingActive: PropTypes.bool
}

export default CustomInput;