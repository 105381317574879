import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, Button } from "reactstrap";
import { Alert } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import SpinnerChase from "components/Shared/SpinnerChase";
import Confirmation from "components/Shared/Confirmation";
import { formatTimestamp, formats, timestamp } from "helpers/dateHelper";
import { getBeUrl, showError, showSuccess } from "helpers/utilHelper";
import Credential from "model/notaryCredential";
import pdfIcon from 'assets/images/pdf-icon.svg';
import { perms, useAccess } from "context/access";
import { getNotaryCredentials, deleteNotaryCredential } from "helpers/backendHelper";
import FormCredential from "../Form/Credential";
import FormContentCredential from "../Form/Content/Credential";
import FormCredentialEdit from "../Form/Edit/Credential";
import FormEditCredential from "../Form/Edit/Content/Credential";

const SectionCredentials = props => {

  const { id } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [credentials, setCredentials] = useState([]);
  const [credentialsError, setCredentialsError] = useState();
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [credentialToDelete, setCredentialToDelete] = useState(null);
  const [addFormIsOpen, setAddFormIsOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [editFormIsOpen, setEditFormIsOpen] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshCredentials();
  }, []);

  useEffect(() => {
    if (!isLoadInProgress) {
      setIsRefreshing(false);
    }
  }, [isLoadInProgress]);

  // toggles the add form on and off
  const toggleAddForm = () => {
    setAddFormIsOpen(prevMode => !prevMode);
  }

  const toggleEditForm = (id) => {
    if (editingRowId === id) {
      setEditingRowId(null);
      // Close the form if it's already open for this credential
    } else {
      setEditingRowId(id); // Open the form for the new credential
    };
  }

  /********** OTHER **********/

  /**
   * Fetches credential doc list from API
   */
  const refreshCredentials = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getNotaryCredentials(id)
      .then(response => {
        setCredentials(response.credentials);
      })
      .catch(ex => {
        setCredentialsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const refreshEditMode = () => {
    refreshCredentials()
    setEditingRowId(null);
  }

  const refreshAddMode = () => {
    refreshCredentials()
    setAddFormIsOpen(prevMode => !prevMode);
  }


  const handleDelete = (credential) => {
    setCredentialToDelete(credential);
    setIsConfirmationVisible(true);
  };

  const removeCredential = () => {
    deleteNotaryCredential(credentialToDelete.id)
      .then(() => {
        showSuccess(`Credential has been deleted`);
        refreshCredentials();
      })
      .catch(() => {
        showError('Unable to delete credential');
      })
      .finally(() => {
        setIsConfirmationVisible(false);
      });
  };

  const credentialToEdit = credentials.find(cred => cred.id === editingRowId);

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Credentials</CardTitle>
        </Col>
        {iAmGranted(perms.delete_notary_credentials) && <Col xs="auto">
          <div className="text-end">
            {!!addFormIsOpen && <Button type="button" color="secondary" className="me-2" onClick={toggleAddForm} disabled={isRefreshing}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>}
            {!addFormIsOpen && <Button type="button" color="primary" className="mb-2" onClick={toggleAddForm} disabled={isRefreshing}>
              <i className="mdi mdi-plus me-1" />Add
            </Button>}
          </div>
        </Col>}
      </Row>
    </CardHeader>
    <CardBody>
      {addFormIsOpen && <FormCredential>
        <FormContentCredential refreshListHandler={refreshAddMode} />
      </FormCredential>}
      <Row>
        <Col>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table zebra-striped-table new-uploaded-docs mb-0 bb-0">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th data-priority="1">Credential Type</Th>
                    <Th data-priority="2">Document name</Th>
                    <Th data-priority="3">Status</Th>
                    <Th data-priority="4">Expiry Date</Th>
                    <Th data-priority="5">Uploaded Date</Th>
                    {iAmGranted(perms.delete_notary_credentials) &&
                      <Th data-priority="6">Action</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadInProgress && <Tr>
                    <Td colSpan="8">
                      <SpinnerChase className="sm mt-2 mb-2" />
                    </Td>
                  </Tr>}
                  {credentialsError && <Tr>
                    <Td colSpan="8">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load documents
                      </Alert>
                    </Td>
                  </Tr>}
                  {!isLoadInProgress && !credentialsError && !credentials.length && <Tr>
                    <Td colSpan="8" className="text-center">No documents uploaded</Td>
                  </Tr>}
                  {!isLoadInProgress && !credentialsError && credentials.map((credential, index) => <Tr key={index}>
                    <Th className="co-name">{index + 1}</Th>
                    <Td>{credential.typeName}</Td>
                    <Td>
                      <a href={getBeUrl(`notary-credential/${credential.id}/render`)} target="_blank" rel="noreferrer">
                        <img src={pdfIcon} /> {Credential.getFileName(credential.type)}
                      </a>
                    </Td>
                    <Td>{getCredentialStatus(credential.expirationTs)}</Td>
                    <Td>{credential.expirationTs ? formatTimestamp(credential.expirationTs, formats.DATE_PT_FORMAT) : '---'}</Td>
                    <Td>{formatTimestamp(credential.createdTs, formats.DATE_PT_FORMAT)}</Td>
                    <Td>
                      {iAmGranted(perms.delete_notary_credentials) &&
                        <div className="d-flex justify-content">
                          {!!editFormIsOpen && <>
                            <Button type="button" color="default" className="text-danger mt-n2 mb-n2" onClick={() => handleDelete(credential)} disabled={credential.isDeleteInProgress}>
                              <i className="bx bxs-trash-alt" />
                            </Button>
                          </>
                          }
                          {editingRowId !== credential.id &&
                            < Button
                              type="button"
                              color="default"
                              className="text-danger mt-n2 mb-n2"
                              onClick={() => handleDelete(credential)}
                              disabled={credential.isDeleteInProgress}
                            >
                              <i className="bx bxs-trash-alt" />
                            </Button>}
                          <Button
                            type="button"
                            color="default"
                            size="md"
                            className="mt-n2 mb-n2"
                            onClick={() => toggleEditForm(credential.id)}
                            disabled={isRefreshing}
                          >
                            {editingRowId === credential.id ?
                              <Button type="button">
                                <i className="mdi mdi-chevron-left me-1" />Cancel
                              </Button>
                              :
                              <i className="mdi mdi-pencil me-1" />
                            }
                          </Button>
                        </div>
                      }
                    </Td>
                  </Tr>)}
                  {editingRowId &&
                    <Tr>
                      <Td colSpan={7}>
                        <FormCredentialEdit credential={credentialToEdit} >
                          <FormEditCredential credential={credentialToEdit} refreshHandler={refreshEditMode} />
                        </FormCredentialEdit>
                      </Td>
                    </Tr>
                  }
                </Tbody>
              </Table>
            </div>
            {isConfirmationVisible && <Confirmation
              confirmBtnText="Delete"
              reverseButtons={false}
              onConfirm={() => {
                removeCredential();
              }}
              onCancel={() => setIsConfirmationVisible(false)}>
              <span style={{ color: '#556EE6' }}>Are you sure you want to delete the document</span>
            </Confirmation>}
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card >)
}

SectionCredentials.propTypes = {
  id: PropTypes.number,
};

const getCredentialStatus = expirationTs => {
  if (!expirationTs) return "Active";
  return expirationTs < timestamp() ? "Expired" : "Active";
}

export default SectionCredentials;