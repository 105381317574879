import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import SpinnerChase from "components/Shared/SpinnerChase";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import CronJob from "model/cronJob";
import { formatTimestamp, formats, timeSince } from "helpers/dateHelper";
import classnames from "classnames";
import { getCronJobs } from "helpers/backendHelper";

const DataTable = () => {

  /********** STATE **********/
  const [cronJobs, setCronJobs] = useState([]);
  const [cronJobsError, setCronJobsError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshCronJobs();
  }, []);

  /********** OTHER **********/
  const refreshCronJobs = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getCronJobs()
      .then(response => {
        setCronJobs(response.cronJobs);
      })
      .catch(ex => {
        setCronJobsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const getStatusClassName = status => {
    switch (status) {
      case CronJob.STATUS_ERROR:
        return 'bg-danger';
      case CronJob.STATUS_RUNNING:
        return 'bg-primary';
      case CronJob.STATUS_SUCCESS:
        return 'bg-success';
      default:
        return 'bg-secondary';
    }
  }

  return (<Card className="expand-v">
    <CardBody>
      <Row>
        <Col>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table className="table new-uploaded-docs mb-0 bb-0">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th data-priority="1">Name</Th>
                    <Th data-priority="2">Frequency</Th>
                    <Th data-priority="2">Last Status</Th>
                    <Th data-priority="2">Last Run</Th>
                    <Th data-priority="2">Last Duration</Th>
                    <Th data-priority="2">Next Scheduled Run</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadInProgress && <Tr>
                    <Td colSpan="7">
                      <SpinnerChase className="sm mt-2 mb-2" />
                    </Td>
                  </Tr>}
                  {!!cronJobsError && <Tr>
                    <Td colSpan="7">
                      <Alert color="danger" className="fade show text-center mb-0">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load cron jobs
                      </Alert>
                    </Td>
                  </Tr>}
                  {!isLoadInProgress && !cronJobsError && cronJobs.map((cronJob, index) => <Tr key={cronJob.id}>
                    <Th className="co-name">{index + 1}</Th>
                    <Td><Link to={route(routes.view_cron_job, cronJob.id)}>{cronJob.name}</Link></Td>
                    <Td>{cronJob.frequency}</Td>
                    <Td>
                      <span className={classnames('badge badge-lg', getStatusClassName(cronJob.status))}>{CronJob.getStatusName(cronJob.status)}</span>
                    </Td>
                    <Td>
                      {!!cronJob.startedTs ? formatTimestamp(cronJob.startedTs, formats.DATETIME) : '--'}
                      {cronJob.isLate && <span className="badge bg-danger ms-1">Late</span>}
                    </Td>
                    <Td>{!!cronJob.endedTs ? timeSince(cronJob.startedTs, cronJob.endedTs, '< 1 second') : '--'}</Td>
                    <Td>{!!cronJob.nextRunTs ? formatTimestamp(cronJob.nextRunTs, formats.DATETIME) : '--'}</Td>
                  </Tr>)}
                </Tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>)
}

export default DataTable;
