import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import User from 'model/user';
import { useSocketOn, useSubscribeToOrderSigner, useSubscribeToUser } from 'hooks/socket';
import socketEvent from 'constants/socketEvent';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import { randomStringSync } from 'helpers/utilHelper';
import { UncontrolledTooltip } from "reactstrap";

const UserActivityStatus = props => {

  const { id, status, lastSeen, hideText, size, autoUpdate, skipSubscription, className, isSigner, showTooltip } = props;

  /********** STATE **********/

  const [activityInfo, setActivityInfo] = useState({ status, lastSeen });

  /********** SOCKET **********/

  const shouldSubscribe = useCallback(() => autoUpdate && !skipSubscription && !!id, [autoUpdate, skipSubscription, id]);
  const shouldSubscribeToUser = useCallback(() => !isSigner && shouldSubscribe(), [isSigner, shouldSubscribe]);
  const shouldSubscribeToOrderSigner = useCallback(() => isSigner && shouldSubscribe(), [isSigner, shouldSubscribe]);

  // start receiving updates about this particular user
  // but only if auto-update has been requested
  // and if we should not skip the subscription part
  // and if an id has been provided
  useSubscribeToUser(id, shouldSubscribeToUser);
  useSubscribeToOrderSigner(id, shouldSubscribeToOrderSigner);

  // since this function does not depend on external variables
  // we can cache it between renders for performance reasons
  // also to prevent adding the event to the socket on every render
  const onUserActivityStatusChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple users
    // therefore we need to check whether this update refers to the current user
    if (data.id == id) {
      setActivityInfo({
        status: data.status,
        lastSeen: data.lastActive,
      });
    }
  }, []);

  const eventName = isSigner ? socketEvent.orderSignerActivityStatusChanged : socketEvent.userActivityStatusChanged;
  const shouldListen = useCallback(() => autoUpdate && !!id, [autoUpdate, id]);

  // listen for changes on user activity status
  // but only if auto-update has been requested and an id has been provided
  useSocketOn(eventName, onUserActivityStatusChanged, shouldListen);

  /********** OTHER **********/

  const getStatusColor = () => {
    switch (activityInfo.status) {
      case User.ACTIVITY_STATUS_ACTIVE:
        return 'bg-success';
      case User.ACTIVITY_STATUS_IDLE:
        return 'bg-warning';
      default:
        return 'bg-secondary';
    }
  }

  const uid = randomStringSync(2);

  const shouldShowTimeSince = () => !!activityInfo.lastSeen && activityInfo.status != User.ACTIVITY_STATUS_ACTIVE;

  return <React.Fragment>
    {activityInfo.status !== undefined && <div className={classnames('user-activity-status', `user-activity-status-${activityInfo.status}`, `user-activity-status-${size}`, className)} id={'usractstat' + uid}>
      <i className={classnames('user-activity-status-circle', getStatusColor())}></i>
      {!hideText && <span className="user-activity-status-text ms-1">
        <span className="user-activity-status-name">{User.getActivityStatusName(activityInfo.status)}</span>
        {shouldShowTimeSince() && <span className="user-activity-status-last-seen ms-2 badge bg-light text-secondary align-middle">since {formatTimestamp(activityInfo.lastSeen, formats.SHORT_US_DATETIME)}</span>}
      </span>}
      {showTooltip && shouldShowTimeSince() && <UncontrolledTooltip placement="top" target={'usractstat' + uid}>since {formatTimestamp(activityInfo.lastSeen, formats.SHORT_US_DATETIME)}</UncontrolledTooltip>}
    </div>}
  </React.Fragment>
}

UserActivityStatus.propTypes = {
  // the id of the user whose status we are showing
  // only relevant if 'autoUpdate' is TRUE
  // because we need to subscribe and listen to changes of this user
  id: PropTypes.number,
  // the activity status value
  status: PropTypes.number,
  // the last activity timestamp
  lastSeen: PropTypes.number,
  // whether to hide the status text and only show the icon
  hideText: PropTypes.bool,
  // the display size of the widget (sm, md, lg, etc)
  size: PropTypes.string.isRequired,
  // whether to subscribe and listen to user changes
  // and update the status in real time
  autoUpdate: PropTypes.bool,
  // whether to skip the subscription part when auto-updating
  // and only listen for changes
  // set this to TRUE when the subscription is handled by a parent component
  // only relevant when 'autoUpdate' is TRUE
  skipSubscription: PropTypes.bool,
  // the css class value
  className: PropTypes.string,
  // whether in fact this user is an order signer
  isSigner: PropTypes.bool,
  // whether to show a tooltip with time since last activity
  showTooltip: PropTypes.bool,
}

export default UserActivityStatus;