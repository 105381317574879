import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SectionInfo from "./Partial/Section/Info";
import { getAdditionalFee, getQbBillItems, getQbInvoiceItems } from "helpers/backendHelper";
import { QB_UNABLE_REFRESH_TOKEN } from "helpers/errorHelper";
import { showError } from "helpers/utilHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [additionalFee, setAdditionalFee] = useState(null);
  const [additionalFeeError, setAdditionalFeeError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const [qbInvoiceItems, setQbInvoiceItems] = useState([]);
  const [qbInvoiceItemsError, setQbInvoiceItemsError] = useState(null);
  const [qbBillItems, setQbBillItems] = useState([]);
  const [qbBillItemsError, setQbBillItemsError] = useState(null);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshAdditionalFee();
  }, [id]);

  // runs once at component mount
  useEffect(() => {
    // load quickbooks service options
    getQbInvoiceItemList();
    getQbBillItemList();
  }, [])

  /********** OTHER **********/

  const refreshAdditionalFee = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getAdditionalFee(id)
      .then(response => {
        setAdditionalFee(response.additionalFee);
      })
      .catch(ex => {
        setAdditionalFeeError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  // getting a response from the quickbooks API takes a while, so we are loading the options eagerly, before the edit form is open
  const getQbInvoiceItemList = () => {
    getQbInvoiceItems()
      .then(response => {
        setQbInvoiceItems(response.QueryResponse.Item);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        setQbInvoiceItemsError(ex);
      });
  };

  const getQbBillItemList = () => {
    getQbBillItems()
      .then(response => {
        setQbBillItems(response.QueryResponse.Account);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        setQbBillItemsError(ex);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_additional_fees) && <div className="page-content">
      {additionalFee && <React.Fragment>
        <MetaTitle>{additionalFee.name} | Additional Fees</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(additionalFee)} />
          <Row>
            <Col xl="8" xxl="7">
              <SectionInfo
                additionalFee={additionalFee}
                id={id}
                refreshHandler={refreshAdditionalFee}
                isLoadBusy={isLoadInProgress}
                qbInvoiceItems={qbInvoiceItems}
                qbInvoiceItemsError={qbInvoiceItemsError}
                qbBillItems={qbBillItems}
                qbBillItemsError={qbBillItemsError}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !additionalFee && <Preloader className="inner" />}
      {additionalFeeError && <Error error={additionalFeeError} title404="Additional fee not found" />}
    </div>}
    {iAmNotGranted(perms.view_additional_fees) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = additionalFee => [{
  title: 'ADDITIONAL FEES',
  url: route(routes.list_additional_fees),
}, {
  title: additionalFee.name,
}];

export default View;
