import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { distanceServedOptions } from 'helpers/utilHelper';

const SectionShipping = (props) => {

  const { notary, isLoadInProgress } = props;

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Delivery Information</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Document Delivery Address</td>
            <td className="section-row-value ps-4">{notary.shippingAddress || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Delivery State</td>
            <td className="section-row-value ps-4">{notary.shippingState || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Delivery City</td>
            <td className="section-row-value ps-4">{notary.shippingCity || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Delivery ZIP</td>
            <td className="section-row-value ps-4 no-border">{notary.shippingZip || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label no-border">Distance willing to traver</td>
            <td className="section-row-value ps-4 no-border">{distanceServedOptions.find(o => o.value === notary.distanceServed)?.label || "---"}</td>
          </tr>
          <tr>
          <td className="section-row-label no-border">Cities/Counties Served</td>
          <td className="section-row-value ps-4 no-border">{notary.citiesCountiesServed || "---"}</td>
        </tr>
        </tbody>
      </Table>
    </CardBody>
    {isLoadInProgress && <CardPreloader />}
  </Card>)
}

SectionShipping.propTypes = {
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool
};

export default SectionShipping;