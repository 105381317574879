import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ViewSectionSpecialInstructions from "../View/SpecialInstructions";
import FormSpecialInstructionsEdit from "../Form/SpecialInstructions/Edit";

const SectionSpecialInstructions = ({ isLoadBusy, id, dealerGroup, refreshHandler }) => {

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and until the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfully save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormSpecialInstructionsEdit id={id} defaultValues={dealerGroup} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewSectionSpecialInstructions id={id} dealerGroup={dealerGroup} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionSpecialInstructions.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerGroup: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionSpecialInstructions;
