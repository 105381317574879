import React, { useState } from "react";
import PropTypes from "prop-types";
import FormEditBilling from "../Form/Edit/Billing";
import ViewBilling from "../View/Billing";

const SectionBilling = props => {

  const { id, refreshHandler, notary, isLoadInProgress, qbNotaries, qbNotariesError,
    qbVendor, qbVendorError, isQbVendorLoadInProgress } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormEditBilling id={id} finishedHandler={toggleEditMode} notary={notary} qbNotaries={qbNotaries} qbNotariesError={qbNotariesError} />}
    {!isEditModeActive && <ViewBilling
      id={id}
      toggleEditMode={toggleEditMode}
      notary={notary}
      isLoadInProgress={isLoadInProgress}
      qbVendor={qbVendor}
      qbVendorError={qbVendorError}
      isQbVendorLoadInProgress={isQbVendorLoadInProgress}
    />}
  </React.Fragment>
}

SectionBilling.propTypes = {
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
  qbNotaries: PropTypes.array,
  qbNotariesError: PropTypes.string,
  qbVendor: PropTypes.object,
  qbVendorError: PropTypes.string,
  isQbVendorLoadInProgress: PropTypes.bool,
};

export default SectionBilling;
