import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Button, UncontrolledTooltip, Alert, CardFooter } from "reactstrap";
import PropTypes from "prop-types";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, useNavigate } from "react-router-dom";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import Payment from "model/payment";
import { getPaymentDt, retryPayment } from "helpers/backendHelper";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Confirmation from "components/Shared/Confirmation";
import DealerStore from "model/dealerStore";
import { perms, useAccess } from "context/access";
import { showError, showSuccess } from "helpers/utilHelper";
import { applyPaymentDtFilters } from 'store/actions';
import { useDispatch } from "react-redux";

const SectionPayments = ({ id }) => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();
  const navigate = useNavigate();

  /********** STATE **********/

  const [payments, setPayments] = useState([]);
  const [paymentsError, setPaymentsError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [paymentToRetry, setPaymentToRetry] = useState();

  const listParams = {
    sortBy: "id", // the default datatable sort column
    sortDir: "desc", // the default datatable sort payment
    pageSize: 10, // the default datatable page size
    page: 1, // the default datatable page
    search: null, // the default datatable search phrase
    filters: {
      status: "",
      dealerStoreId: id, // setting the current dealer store
      createdBetween: ""
    }, // the default datatable filters
  }

  /********* EFFECTS *********/

  // runs once on component mount
  useEffect(() => {
    getDealerStorePaymentList(listParams);
  }, [id]);

  /********** EVENT HANDLERS **********/

  const getDealerStorePaymentList = (listParams) => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the signers data
    getPaymentDt(listParams)
      .then(response => {
        setPayments(response.payments);
      })
      .catch(ex => {
        setPaymentsError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const handleRetryPayment = id => () => {
    setIsConfirmationVisible(true);
    setPaymentToRetry(id);
  };

  const retry = () => {
    retryPayment(paymentToRetry)
      .then(() => {
        showSuccess("Payment has been retried");
        refreshPayments();
      })
      .catch(() => {
        showError("Cannot retry payment");
      })
  };

  const refreshPayments = () => {
    getDealerStorePaymentList(listParams);
  };

  const goToPaymentDt = () => {
    // Set the filters for the selected dealer store
    dispatch(applyPaymentDtFilters({
      status: "",
      dealerStoreId: id,
      createdBetween: ""
    }));
    // Navigate to it
    navigate(route(routes.list_payments))
  }

  // Function that returns the payment type column
  const getPaymentTypeColumn = (entry) => {
    switch (entry.type) {
      case Payment.TYPE_MULTIPLE_ORDERS:
        return "Collective";
      case Payment.TYPE_SINGLE_ORDER:
        return !!entry.orderId ? <Link to={route(routes.view_order, entry.orderId)}>Order {entry.orderId}</Link> : "--"
      case Payment.TYPE_SUBSCRIPTION:
        return entry.paymentPlanName;
      default:
        return "--"
    }
  }

  // Function that returns the status column
  const getStatusColumn = (entry) => {
    const color = Payment.getStatusColor(entry.status);
    const canRetryPayment = iAmGranted(perms.retry_order_payments) && entry.status === Payment.STATUS_FAILED && DealerStore.isStripePaymentMethod(entry.paymentMethod) && entry.type === Payment.TYPE_SINGLE_ORDER;
    return <>
      <p className={`badge badge-lg rounded-pill bg-${color}`}>
        {Payment.getStatusName(entry.status)}
        {!!entry.failReason && <React.Fragment>
          <a href={`https://docs.stripe.com/error-codes#${entry.failReason.replaceAll('_', '-')}`} target="blank" id={`status-badge-${entry.id}`}>
            <i className="mdi mdi-information-outline text-white ms-2"></i>
          </a>
          <UncontrolledTooltip placement="top" target={`status-badge-${entry.id}`}>{entry.failReason}</UncontrolledTooltip>
        </React.Fragment>}
      </p>
      {canRetryPayment && <Button color="danger" outline className="btn-rounded btn-sm ms-2" onClick={handleRetryPayment(entry.id)}>Retry <i className="bx bx-sync" /></Button>}
    </>;
  }

  return (<Card className="paginated-table-card">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Payments</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="table">
        <Thead>
          <Tr>
            <Th>Payment ID</Th>
            <Th>Payment Type</Th>
            <Th>Statement ID</Th>
            <Th>Payment Method</Th>
            <Th>Payment Date</Th>
            <Th>Total</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {payments.length > 0 && payments.map((entry, index) => (
            <Tr key={entry.id}>
              <Td>{entry.id}</Td>
              <Td>
                {getPaymentTypeColumn(entry)}
              </Td>
              <Td>
                {!!entry.statementId ? <Link to={route(routes.view_dealer_statement, entry.statementId)}>{entry.statementId}</Link> : "--"}
              </Td>
              <Td>{DealerStore.getPaymentMethodName(entry.paymentMethod)}</Td>
              <Td>{entry.status === Payment.STATUS_FAILED || entry.status === Payment.STATUS_COMPLETED ? formatTimestamp(entry.updatedTs, formats.DATETIME) : "--"}</Td>
              <Td>
                <span>${entry.amount}</span>
              </Td>
              <Td>
                {getStatusColumn(entry)}
              </Td>
            </Tr>
          ))}
          {payments.length === 0 && !paymentsError && !isLoadInProgress && <Tr>
            <Td className="table-empty" colSpan="7">No payments found</Td>
          </Tr>
          }
          {!!paymentsError && <Tr>
            <Td className="table-empty" colSpan="7">
              <Alert color="danger" className="fade show text-center mb-0">
                <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load payments
              </Alert>
            </Td>
          </Tr>}
          {isLoadInProgress && <Tr>
            <Td className="table-empty" colSpan="7">
              <SpinnerChase className="sm" />
            </Td>
          </Tr>}
        </Tbody>
      </Table>
    </CardBody>
    {
      !!payments.length &&
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <Button color="light" className="text-primary" onClick={goToPaymentDt}>
          View All <i className="bx bx-right-arrow-alt" />
        </Button>
      </CardFooter>
    }
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Retry"
      onConfirm={() => {
        setIsConfirmationVisible(false)
        retry();
      }}
      onCancel={() => {
        setIsConfirmationVisible(false);
        setPaymentToRetry(null);
      }}>
      {`Make sure the card balance is enough and the details are up to date. After triggering the Payment retry, please give it a couple of minutes to be processed and the Payment status to be updated.`}
    </Confirmation>}
  </Card>)
}

SectionPayments.propTypes = {
  id: PropTypes.number,
};

export default SectionPayments;