import moment from 'moment-timezone';

export const formats = {
  DATE: 'MMM D',
  LONG_DATE: 'MMM D, Y',
  MEDIUM_DATE: 'MMM D, YYYY',
  CHAT_DATE: 'ddd, MMMM D',
  DATETIME: 'MMM D Y, h:mm a z',
  DATETIME_FULL: 'MMM D Y, h:mm:ss a z',
  US_DATE: 'MM/DD/Y',
  SHORT_US_DATETIME: 'M/D h:mm a',
  SIGNATURE_DATE: 'MMM D, Y | h:mm a z',
  TRACKING_DATE: 'MMM D, Y h:mm A',
  MONTH: 'MMMM',
  HOUR: 'kk:mm',
  FULL_DAY: 'dddd',
  DATE_PT_FORMAT: 'MM/DD/YYYY h:mm A [PT]',
  SHORT_DATE_FORMAT: 'DD MMM, YYYY',
  APPOINTMENT_DATE_FORMAT: 'MMM D, Y z',
  APPOINTMENT_TIME_FORMAT: 'h:mm a z'
}

export const initTimezone = zone => moment.tz?.setDefault(zone);

export const formatDate = (date, format) => moment(date).format(format);

export const formatUtcDate = (date, format) => moment.utc(date).format(format);

export const formatTimestamp = (timestamp, format) => moment.unix(timestamp).format(format);

export const formatTimestampTz = (timestamp, format, timezone) => moment.unix(timestamp).tz(timezone).format(format);


export const timestamp = date => Math.floor((date || Date.now()) / 1000);

export const dateFromTimestamp = timestamp => new Date(timestamp * 1000);

export const getStartOfPrevMonth = (date = new Date()) => {
  const newDate = getEndOfPrevMonth(date);
  newDate.setDate(1); // 1 will result in the first day of the month
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getEndOfPrevMonth = (date = new Date()) => {
  date.setDate(0); // 0 will result in the last day of the previous month
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getMonthsInRange = (startTs, endTs) => {
  if (!startTs || !endTs) return []
  // this is a temporary fix
  // all dates should be in the timezone set by the user
  const startDate = moment(dateFromTimestamp(startTs)).local();
  const endDate = moment(dateFromTimestamp(endTs)).local();

  const result = [];

  // add double digits for month, so that Date object can be created from string
  let format = "YYYY-MM";

  // if range is included in one month, return it
  if (startDate.year() === endDate.year() && startDate.month() === endDate.month()) {
    return [startDate.format(format)];
  }

  // start from begining of month
  const startMonth = moment(dateFromTimestamp(startTs)).startOf('month').local();
  while (startMonth.isBefore(endDate)) {
    result.push(startMonth.format(format));
    startMonth.add(1, 'month');
  }

  return result;
};

export const getMonthName = (dateStr, isSameYear) => {
  const date = moment.tz(dateStr, 'YYYY-MM', moment.tz.guess());

  return date.format(isSameYear ? 'MMMM' : 'MMMM YYYY');
};

// receives a date as INT and returns an ISO string
// for NULL timestamp, returns an empty string
export const isoFromTimestamp = timestamp => timestamp ? moment.unix(timestamp).toISOString() : "";

// receives a date as STRING and returns an INT or NULL
export const timestampFromIso = date => date ? Math.floor((new Date(date).getTime()) / 1000) : null;

export const timeSince = (ts1, ts2 = timestamp(), minValue = 'now') => {
  let seconds = ts2 - ts1;

  let interval = seconds / 31536000;

  if (interval > 1) {
    if (interval < 2) return "1 year";
    return Math.floor(interval) + " years";
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    if (interval < 2) return "1 month";
    return Math.floor(interval) + " months";
  }

  interval = seconds / 86400;

  if (interval > 1) {
    if (interval < 2) return "1 day";
    return Math.floor(interval) + " days";
  }

  interval = seconds / 3600;

  if (interval > 1) {
    if (interval < 2) return "1 hour";
    return Math.floor(interval) + " hours";
  }

  interval = seconds / 60;

  if (interval > 1) {
    if (interval < 2) return "1 minute";
    return Math.floor(interval) + " minutes";
  }

  return Math.floor(seconds) === 0 ? minValue : (Math.floor(seconds) === 1 ? "1 second" : Math.floor(seconds) + " seconds");
}

export const timeSinceShort = (ts1, ts2 = timestamp()) => {
  let seconds = ts2 - ts1;

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y";
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return Math.floor(interval) + "mo";
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return Math.floor(interval) + "d";
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return Math.floor(interval) + "h";
  }

  interval = seconds / 60;

  if (interval > 1) {
    return Math.floor(interval) + "m";
  }

  return Math.floor(seconds) + "s";
}

export const timeAgo = date => {
  const timeElapsed = timeSince(date);
  if (timeElapsed != 'now') {
    return `${timeElapsed} ago`;
  }
  return timeElapsed;
}

// compares two timestamps and returns true if they represent the same date
// if only 1 arg is received, compares it to the current date
export const isSameDate = (ts1, ts2 = timestamp(new Date())) => {
  return moment(dateFromTimestamp(ts1)).isSame(dateFromTimestamp(ts2), "day");
}

export const isPast7days = ts => {
  return moment(dateFromTimestamp(ts)).isAfter(moment().subtract(7, 'd'))
}

export const getMessageDateFormat = ts => {
  if (isSameDate(ts)) return formats.HOUR;
  if (isPast7days(ts)) return formats.FULL_DAY;
  return formats.US_DATE;
}

export const subtractHoursFromNow = hours => {
  const now = new Date();
  return timestamp(now.setHours(now.getHours() - hours));
}


// receives a Date and returns an object with the date components as keys
export const extractDateComponents = date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return { year, month, day, hours, minutes };
}

// receives a Date and changes it's time zone to the user time zone, while keeping the values for year, month, day, hours and minutes
export const localDateToTimezoneTs = date => {
  // extract the values selected by the user
  const { year, month, day, hours, minutes } = extractDateComponents(date);

  // by creating a moment out of the selected data, we are initializing it with the timezone of the user
  const tzDate = moment({ year, month, day, hours, minutes });

  return tzDate.unix();
};

// receives a moment and returns an object with the date components as keys
export const extractMomentComponents = moment => {
  const year = moment.year();
  const month = moment.month();
  const day = moment.date();
  const hours = moment.hour();
  const minutes = moment.minute();

  return { year, month, day, hours, minutes };
};

// receives a unix timestamp and changes it's time zone to the local (browser) time zone, while keeping the values for year, month, day, hours and minutes
// returns a Date
export const timezoneTsToLocalDate = ts => {
  // create moment from unix timestamp
  const selectedMoment = moment.unix(ts);

  // extract the values selected by the user
  const { year, month, day, hours, minutes } = extractMomentComponents(selectedMoment);

  // the new date will be initialized with the local time zone
  return new Date(year, month, day, hours, minutes, 0, 0);
};

export const getStartOfPrevMonthTs = () => moment().subtract(1, "months").startOf("month").unix();

export const getEndOfPrevMonthTs = () => moment().subtract(1, "months").endOf("month").unix();

export const getStartOfMonthString = () => moment().startOf("month").format(formats.MEDIUM_DATE);

export const getEndOfMonthString = () => moment().endOf("month").format(formats.MEDIUM_DATE);

export const getCurrentMonthInteger = () => Number(moment().month());

// for each month of the year up until and including the current month,
// create an option object that consists of
// value - int representation of the month (0 indexed - january is month 0)
// label - name of the month
// interval - array that contains starting timestamp and ending timestamp
export const getMonthOptions = () => {
  const options = [];
  const currentMonth = getCurrentMonthInteger();

  for (let i = 0; i <= currentMonth; i++) {
    const momentInMonth = moment({ years: moment().year(), months: i });

    const startTs = momentInMonth.startOf("month").unix();
    const endTs = momentInMonth.endOf("month").unix();

    options.push({ value: i, label: moment().month(i).format("MMMM"), interval: [startTs, endTs] });
  }
  return options;
};

export const getMonthFromTs = ts => moment.unix(ts).month();