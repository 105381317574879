import React, { useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderInkSignDocsCleanup, getOrderInkSignDocs } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Alert } from "bootstrap";
import { formats, formatTimestamp } from "helpers/dateHelper";

const InkSignInformation = props => {

  const { orderId } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const orderDocs = useSelector(state => state.Order.InkSignDocs.docs);
  const orderDocsError = useSelector(state => state.Order.InkSignDocs.docsError);
  const isLoadInProgress = useSelector(state => state.Order.InkSignDocs.isLoadInProgress);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    dispatch(getOrderInkSignDocs(orderId));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderInkSignDocsCleanup());
    }
  }, [orderId]);

  /********** OTHER **********/

  const getDocsAsArray = () => {
    return Object.entries(orderDocs);
  }

  return <React.Fragment>
    {orderDocs && <>
      <div className="docs-title">Ink Paper Signed Documents</div>
      <Table className="table seal-table docs-table mb-4">
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Document Type</th>
            <th>Signed Date</th>
            <th>Accepted Date</th>
            <th>Notary Confirmation</th>
            <th>No of pages</th>
          </tr>
        </thead>
        <tbody>
          {getDocsAsArray().map(([index, doc]) =>
            <tr key={index}>
              <td>{doc.name}</td>
              <td>Ink Paper Signed Document</td>
              <td>{formatTimestamp(doc.signedTs, formats.DATETIME)}</td>
              <td>{formatTimestamp(doc.acceptedTs, formats.DATETIME)}</td>
              <td>{formatTimestamp(doc.returnedByNotaryTs, formats.DATETIME)}</td>
              <td><div className="badge badge-lg rounded-pill doc-item-badge">
                {doc.numOfPages ? doc.numOfPages : Object.keys(doc.pages).length} <i className="mdi mdi-file-multiple-outline ms-1" /></div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>}
    {isLoadInProgress && <Row>
      <Col>
        <SpinnerChase className="sm mt-3 mb-5" />
      </Col>
    </Row>}
    {orderDocsError && <Row>
      <Col>
        <Alert color="danger" className="fade show text-center">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load ink sign docs
        </Alert>
      </Col>
    </Row>}
  </React.Fragment>
}

InkSignInformation.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default InkSignInformation;
