import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table } from "reactstrap";
import Col from "components/Shared/Col";
import { formats, formatTimestamp } from 'helpers/dateHelper';
import PropTypes from 'prop-types';
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";
import { perms, useAccess } from 'context/access';
import Order from 'model/order';

const SectionScheduler = ({ order }) => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Scheduler</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">Scheduler Name</td>
              <td className={classnames('section-row-value ps-4')}>
                {!!order.schedulerFullName ? <Link to={route(routes.view_user, order.schedulerId)}>{order.schedulerFullName}</Link> : '--'}
              </td>
            </tr>
            <tr>
              <td className="section-row-label">Assigned Date</td>
              <td className="section-row-value ps-4">{order.schedulerAssignedTs ? formatTimestamp(order.schedulerAssignedTs, formats.DATETIME) : '--'}</td>
            </tr>
          </tbody>
        </Table>
        <div className="text-end mt-4">
          {iAmGranted(perms.assign_schedulers) && order.status >= Order.STATUS_NEW && <Link to={route(routes.view_order_scheduler, order.id)} className='btn btn-primary'>Assign Scheduler <i className="mdi mdi-arrow-right ms-1"></i></Link>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionScheduler.propTypes = {
  order: PropTypes.object,
};

export default SectionScheduler;