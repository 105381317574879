import {
  GET_USER_ROLE_LIST,
  GET_USER_ROLE_LIST_OK,
  GET_USER_ROLE_LIST_ERR,
  DO_USER_ROLE_LIST_CLEANUP,
} from "./actionTypes";

/********* LIST *********/

export const getUserRoleList = () => ({
  type: GET_USER_ROLE_LIST,
});

export const getUserRoleListOk = response => ({
  type: GET_USER_ROLE_LIST_OK,
  payload: { response },
});

export const getUserRoleListErr = error => ({
  type: GET_USER_ROLE_LIST_ERR,
  payload: { error },
});

export const doUserRoleListCleanup = () => ({
  type: DO_USER_ROLE_LIST_CLEANUP,
});