import React, { useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { MobileView } from "react-device-detect";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { useParams } from "react-router-dom";
import { perms, useAccess } from "context/access";
import { useDispatch, useSelector } from "react-redux";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import { route, routes } from "helpers/routeHelper";
import { doOrderMessagesCleanup, doOrderSingleCleanup, getChatMembers, getOrderMessages, getOrderWithCustomerSigners } from "store/actions";
import socketEvent from "constants/socketEvent";
import "react-perfect-scrollbar/dist/css/styles.css";
import useAllowedChannels from "hooks/allowedChannels";
import ChatOrderInfo from "components/Shared/Chat/OrderInfo";
import ChatConversationMobile from "components/Shared/Chat/ConversationMobile";

const ViewMessages = () => {

  let { id, channel } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  const { iAmScheduler } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { messages, isLoadInProgress: isMessagesLoadInProgress, messagesError, members, unreadMessagesCount } = useSelector(state => state.Order.Messages);

  // hook that gets the list of channels the user has access to, considering his role and the type of order
  const channels = useAllowedChannels(order?.isNotaryRequired);

  /********** OTHER /**********/

  const getAllMessages = useCallback(() => {
    Object.keys(channels).map(chId => {
      dispatch(getOrderMessages(id, chId));
    });
  }, [id, channels]);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => getAllMessages(), [getAllMessages]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data and messages
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  useEffect(() => {
    // we need to fetch all messages from the start so we can display the latest message in the sidebar
    getAllMessages();
    return () => {
      dispatch(doOrderMessagesCleanup());
    }
  }, [id, channels]);

  useEffect(() => {
    // fetch chat members
    refreshChatMembers();
  }, [id, channel]);

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getOrderWithCustomerSigners(id));

  const refreshChatMembers = () => dispatch(getChatMembers(id, channel));

  if (!!order && !isLoadInProgress && !!channels && Object.keys(channels).length > 0 && !Object.keys(channels).find(id => id === channel)) {
    return <Error title="Channel not found" />
  }

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col lg={6} xs={12}>
              <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
            </Col>
            <Col lg={6} xs={12}>
              {iAmScheduler() && <ChatOrderInfo order={order} />}
            </Col>
          </Row>
          <MobileView className="h-100">
            <Row>
              <Col>
                <div className="d-lg-flex">
                  <ChatConversationMobile id={+id} channelId={+channel} messages={messages[channel]} isLoadInProgress={isMessagesLoadInProgress} members={members[channel]} messagesError={messagesError} channels={channels} />
                </div>
              </Col>
            </Row>
          </MobileView>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
      {messagesError && <Error error={messagesError} title404="Messages not found" />}
    </div>}
    {iAmNotGranted(perms.view_order_messages) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: order?.signers?.length > 0 ? order.signers[0].fullName : "Signer",
  url: route(routes.view_order, order.id),
}, {
  title: 'Edit order',
  url: route(routes.view_order, order.id),
}, {
  title: 'Messages',
}];

export default ViewMessages;