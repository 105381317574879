import {
  getOrderStats,
  getTotalMessages,
  getAppointments
} from "helpers/backendHelper";

import { takeEvery, put, call } from "redux-saga/effects";

import {
  getSupportStatsOk,
  getSupportStatsErr,

  getAppointmentsListOk,
  getAppointmentsListErr,

} from "./actions";

import {
  GET_SUPPORT_STATS,

  GET_APPOINTMENTS_LIST
} from "./actionTypes";

/********** STATS **********/

function* onGetStats() {
  try {
    const { workInProgress, remoteVid, sealed, remote, instore } = yield call(getOrderStats);
    const { totalMessages } = yield call(getTotalMessages);

    yield put(getSupportStatsOk({ workInProgress, remoteVid, sealed, remote, instore, totalMessages }));
  } catch (error) {
    yield put(getSupportStatsErr(error));
  }
}

/********** APPOINTMENTS LIST **********/

function* onGetAppointmentsList({ payload: { params } }) {
  try {
    const response = yield call(getAppointments, params);
    yield put(getAppointmentsListOk(response));
  } catch (error) {
    yield put(getAppointmentsListErr(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_SUPPORT_STATS, onGetStats);
  yield takeEvery(GET_APPOINTMENTS_LIST, onGetAppointmentsList);
}

export default dashboardSaga;