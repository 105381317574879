import {
  GET_MESSAGE_DT,
  GET_MESSAGE_DT_OK,
  GET_MESSAGE_DT_ERR,
  DO_MESSAGE_DT_CLEANUP,

  CREATE_MESSAGE,
  CREATE_MESSAGE_OK,
  CREATE_MESSAGE_ERR,
} from "./actionTypes";

export const getMessageDt = params => ({
  type: GET_MESSAGE_DT,
  payload: { params }
});

export const getMessageDtOk = response => ({
  type: GET_MESSAGE_DT_OK,
  payload: { response }
});

export const getMessageDtErr = error => ({
  type: GET_MESSAGE_DT_ERR,
  payload: { error }
});

export const doMessageDtCleanup = () => ({
  type: DO_MESSAGE_DT_CLEANUP,
});

export const createMessage = data => ({
  type: CREATE_MESSAGE,
  payload: { data }
});

export const createMessageOk = () => ({
  type: CREATE_MESSAGE_OK,
});

export const createMessageErr = error => ({
  type: CREATE_MESSAGE_ERR,
  payload: { error }
});