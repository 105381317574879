import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getFullOrder } from 'store/actions';
import NotaryAppointmentDate from '../Form/Edit/NotaryAppointmentDate';
import NotaryInfoView from '../View/NotaryInfoVIew';

const SectionNotaryInfo = ({ isLoadBusy, id, order, isLocked }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  /********** OTHER **********/

  const [isRefreshing, setIsRefreshing] = useState(false);

  /********** EFFECTS **********/
  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  const refreshOrder = () => dispatch(getFullOrder(order.id));

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
    }
  }

  return <React.Fragment>
    {isEditModeActive && <NotaryAppointmentDate finishedHandler={toggleEditMode} defaultValues={order} id={id} refreshHandler={refreshOrder} />}
    {!isEditModeActive && <NotaryInfoView id={id} order={order} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} isLocked={isLocked} />}
  </React.Fragment >
}

SectionNotaryInfo.propTypes = {
  order: PropTypes.object,
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  isLocked: PropTypes.bool,
};

export default SectionNotaryInfo;