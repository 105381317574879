import React from 'react';
import PropTypes from 'prop-types';
import Order from 'model/order';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';

const OrderStatusChanged = props => {

  const { log } = props;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {!!log.author && <React.Fragment>
          <AuthorName log={log} /> changed order status to <b>{Order.getStatusName(log.subject.status)}</b>.
        </React.Fragment>}
        {!log.author && <span>Order status changed to <b>{Order.getStatusName(log.subject.status)}</b></span>}
        <p className="mb-0"><EventTime log={log} /></p>
      </div>
    </div>
  </React.Fragment>
}

OrderStatusChanged.propTypes = {
  log: PropTypes.object,
}

export default OrderStatusChanged;