import { combineReducers } from "redux";
import {
  GET_DEALER_GROUP_DT,
  GET_DEALER_GROUP_DT_OK,
  GET_DEALER_GROUP_DT_ERR,
  DO_DEALER_GROUP_DT_CLEANUP,
  APPLY_DEALER_GROUP_DT_FILTERS,
  CLEAR_DEALER_GROUP_DT_FILTERS,
  GET_DEALER_GROUP_LIST,
  GET_DEALER_GROUP_LIST_OK,
  GET_DEALER_GROUP_LIST_ERR,
  DO_DEALER_GROUP_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  dealerGroups: [],
  dealerGroupsError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_DEALER_GROUP_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        dealerGroupsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_GROUP_DT_OK:
      state = {
        ...state,
        dealerGroups: action.payload.response.dealerGroups,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_GROUP_DT_ERR:
      state = {
        ...state,
        dealerGroups: [],
        totalCount: 0,
        dealerGroupsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_GROUP_DT_CLEANUP:
      state = {
        ...state,
        dealerGroups: [],
        dealerGroupsError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  name: '',
  state: '',
  email: '',
  phone: '',
  createdBetween: '',
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_DEALER_GROUP_DT_FILTERS:
      state = action.payload.filters;
      break
    case CLEAR_DEALER_GROUP_DT_FILTERS:
      state = { ...defaultDtFiltersState };
      break
  }
  return state;
}

/********* LIST *********/

const defaultListState = {
  groups: [],
  groupsError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_DEALER_GROUP_LIST:
      state = {
        ...state,
        groupsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_GROUP_LIST_OK:
      state = {
        ...state,
        groups: action.payload.response.dealerGroups,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_GROUP_LIST_ERR:
      state = {
        ...state,
        groups: [],
        groupsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_GROUP_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
  List,
});