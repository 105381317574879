import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SectionProcessInfo from "./Partial/Section/ProcessInfo";
import { getProcessLogs } from "helpers/backendHelper";

const ViewProcess = () => {

  const { id } = useParams();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [process, setProcess] = useState(null);
  const [processError, setProcessError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [channel, setChannel] = useState('out');
  const [lines, setLines] = useState(30);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshProcess();
  }, [id]);

  /********** OTHER **********/

  const refreshProcess = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getProcessLogs(id, channel, lines)
      .then(response => {
        setProcess({
          name: id,
          output: response.output,
        });
      })
      .catch(ex => {
        setProcessError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    {iAmGranted(perms.view_system_processes) && <div className="page-content">
      {!!process && <React.Fragment>
        <MetaTitle>{id} | Processes</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(process)} />
          <Row>
            <Col>
              <SectionProcessInfo
                process={process}
                refreshHandler={refreshProcess}
                channel={channel}
                setChannelHandler={setChannel}
                lines={lines}
                setLinesHandler={setLines}
                isLoadInProgress={isLoadInProgress}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !process && <Preloader className="inner" />}
      {processError && <Error error={processError} title404="Process not found" />}
    </div>}
    {iAmNotGranted(perms.view_system_processes) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = process => [{
  title: 'PROCESSES',
  url: route(routes.list_sys_processes),
}, {
  title: process.name,
}];

export default ViewProcess;
