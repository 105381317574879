import {
  GET_USER_DT,
  GET_SCHEDULER_DT,
  GET_USER_DT_OK,
  GET_USER_DT_ERR,
  DO_USER_DT_CLEANUP,
  APPLY_USER_DT_FILTERS,
  PATCH_USER_DT_FILTERS,
  CLEAR_USER_DT_FILTERS,
  UNSET_USER_DT_FILTERS,

  GET_DEALER_GROUP_MANAGER_LIST,
  GET_DEALER_REGION_MANAGER_LIST,
  GET_DEALER_STORE_USER_LIST,
  GET_USER_LIST_OK,
  GET_USER_LIST_ERR,
  DO_USER_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getUserDt = params => ({
  type: GET_USER_DT,
  payload: { params },
});

export const getSchedulerDt = params => ({
  type: GET_SCHEDULER_DT,
  payload: { params },
});

export const getUserDtOk = response => ({
  type: GET_USER_DT_OK,
  payload: { response },
});

export const getUserDtErr = error => ({
  type: GET_USER_DT_ERR,
  payload: { error },
});

export const doUserDtCleanup = () => ({
  type: DO_USER_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyUserDtFilters = filters => ({
  type: APPLY_USER_DT_FILTERS,
  payload: { filters },
});

export const patchUserDtFilters = filters => ({
  type: PATCH_USER_DT_FILTERS,
  payload: { filters },
});

export const clearUserDtFilters = () => ({
  type: CLEAR_USER_DT_FILTERS,
});

export const unsetUserDtFilters = () => ({
  type: UNSET_USER_DT_FILTERS,
});

/********* LIST *********/

export const getDealerGroupManagerList = groupId => ({
  type: GET_DEALER_GROUP_MANAGER_LIST,
  payload: { groupId }
});

export const getDealerRegionManagerList = regionId => ({
  type: GET_DEALER_REGION_MANAGER_LIST,
  payload: { regionId }
});

export const getDealerStoreUserList = storeId => ({
  type: GET_DEALER_STORE_USER_LIST,
  payload: { storeId }
});

export const getUsersOk = response => ({
  type: GET_USER_LIST_OK,
  payload: { response },
});

export const getUsersErr = error => ({
  type: GET_USER_LIST_ERR,
  payload: { error },
});

export const doUsersCleanup = () => ({
  type: DO_USER_LIST_CLEANUP,
});