import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table, Alert, Label, FormFeedback, Button } from "reactstrap";
import Col from "components/Shared/Col";
import TextareaAutosize from "react-textarea-autosize";
import Confirmation from 'components/Shared/Confirmation';
import skipVidIcon from "assets/images/skip-vid-icon.svg";
import { route, routes } from 'helpers/routeHelper';
import SpinnerChase from 'components/Shared/SpinnerChase';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import classnames from "classnames";
import VidRequest from 'model/vidRequest';
import Order from 'model/order';
import { showError, showSuccess } from 'helpers/utilHelper';
import { getOrderVidStatus, skipVid } from "helpers/backendHelper";
import { perms, useAccess } from "context/access"
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { getFullOrder } from 'store/actions';

const SectionIdentityVerification = ({ id, order }) => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // hooks that check permissions
  const { iAmGranted, iAmGrantedAny } = useAccess();

  /********** STATE **********/

  const [vidStatus, setVidStatus] = useState(null);
  const [vidStatusError, setVidStatusError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [skipConfirmationVisible, setSkipConfirmationVisible] = useState(false);


  /********** FORM CONFIG **********/

  const formInitialValues = {
    skipReason: VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE,
    skipDetails: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      skipReason: Yup.number().required("Field is required"),
      skipDetails: Yup.string().nullable(),
    }),
    onSubmit: values => {
      skipVidOrder(values);
    },
  });

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** Status verification **********/

  // do not skip if vid is passed or already skipped
  const isVidStatusValidForSkip = vidStatus?.status != VidRequest.STATUS_PASSED &&
    vidStatus?.status != VidRequest.STATUS_SKIPPED;

  // only pending vid orders cand be vid skipped
  const isOrderStatusValidForSkip = order.status == Order.STATUS_PENDING_VID;

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshOrderVidStatus();
  }, [id]);

  /********** OTHER **********/

  const refreshOrderVidStatus = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the data
    getOrderVidStatus(id)
      .then(response => {
        setVidStatus(response.vid);
      })
      .catch(ex => {
        setVidStatusError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const refreshOrder = () => dispatch(getFullOrder(id));

  /********** EVENT HANDLERS **********/

  const skipVidOrder = () => {
    const data = {
      skipReason: formik.values.skipReason,
      skipDetails: formik.values.skipDetails || null,
    };

    skipVid(order.id, data)
      .then(() => {
        showSuccess("ID verification for this order is now skipped");
        setSkipConfirmationVisible(false);
        formik.resetForm();
        refreshOrderVidStatus();
        refreshOrder();
      })
      .catch((error) => {
        showError("Unable to skip ID verification");
      });
  };

  return <>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Identity Validation Overview</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        {vidStatus && <Table className="section-rows table bt-1">
          <tbody>
            <tr>
              <td className="section-row-label">Status</td>
              <td className={`section-row-value text-uppercase text-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName || '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Date</td>
              <td className="section-row-value">{vidStatus.date ? formatTimestamp(vidStatus.date, formats.DATETIME) : '--'}</td>
            </tr>
            <tr>
              <td className="section-row-label">Remaining tries<div>(signer one)</div></td>
              <td className="section-row-value text-center">{order.vidRequestRetriesLeft}</td>
            </tr>
            {!!order.vidRequest2RetriesLeft && <tr>
              <td className="section-row-label">Remaining tries<div>(signer two)</div></td>
              <td className="section-row-value text-center">{order.vidRequest2RetriesLeft}</td>
            </tr>}
          </tbody>
        </Table>}
        {vidStatusError && <div>
          <Alert color="danger" className="fade show text-center">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
          </Alert>
        </div>}
        {isLoadInProgress && <SpinnerChase className="sm mt-2 mb-2" />}
        <div className="text-end mt-4">
          {iAmGranted(perms.skip_vid) &&
            <span>
              {order.isNotaryRequired && order.isVidRequired && isVidStatusValidForSkip && isOrderStatusValidForSkip &&
                <Button color="primary" className={classnames('btn btn-primary me-3')} onClick={() => { setSkipConfirmationVisible(true) }}>Skip VID <i className="mdi mdi-chevron-double-right ms-1"></i></Button>
              }
            </span>
          }
          <Link to={route(routes.view_order_vid, id)} className={classnames('btn btn-primary', { disabled: !vidStatus || vidStatus.status === null })}>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </CardBody>
    </Card>
    {skipConfirmationVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600 }}
      title="Skip VID Reason"
      customIcon={skipVidIcon}
      confirmBtnText="Confirm"
      onConfirm={() => {
        formik.handleSubmit();
      }}
      onCancel={() => {
        setSkipConfirmationVisible(false);
        formik.resetForm();
      }}
      reverseButtons={false}
      closeOnClickOutside={false}
    >
      <div className="my-3 mx-4 text-start">
        <Label className="form-label">Reason:</Label>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="customerUncooperative"
            id="customerUncooperative"
            value={VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE}
            checked={formik.values.skipReason == VidRequest.SKIP_REASON_CUSTOMER_UNCOOPERATIVE}
            onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
          />
          <label className="form-check-label" htmlFor="customerUncooperative">Customer Uncooperative</label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="customerUnableToComplete"
            id="customerUnableToComplete"
            value={VidRequest.SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE}
            checked={formik.values.skipReason == VidRequest.SKIP_REASON_CUSTOMER_UNABLE_TO_COMPLETE}
            onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
          />
          <label className="form-check-label" htmlFor="customerUnableToComplete">Customer Unable to Complete (tech/ability)</label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="dealershipOverride"
            id="dealershipOverride"
            value={VidRequest.SKIP_REASON_DEALERSHIP_OVERRIDE}
            checked={formik.values.skipReason == VidRequest.SKIP_REASON_DEALERSHIP_OVERRIDE}
            onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
          />
          <label className="form-check-label" htmlFor="dealershipOverride">Dealership Override</label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            name="manualApproval"
            id="manualApproval"
            value={VidRequest.SKIP_REASON_MANUAL_APPROVAL}
            checked={formik.values.skipReason == VidRequest.SKIP_REASON_MANUAL_APPROVAL}
            onChange={e => formik.setFieldValue('skipReason', Number(e.target.value))}
          />
          <label className="form-check-label" htmlFor="manualApproval">Manual Approval (Images provided)</label>
        </div>
      </div>
      <div className="my-4 mx-4 text-start">
        <Label className="form-label">Additional Info:</Label>
        <TextareaAutosize
          minRows={3}
          placeholder='Your message'
          maxRows={7}
          className={classnames("form-control", { "is-invalid": !!formik.errors.skipDetails })}
          name="skipDetails"
          onChange={formik.handleChange}
          onFocus={onFieldFocused}
          value={formik.values.skipDetails}
        />
      </div>
      {!!formik.errors.skipDetails && <FormFeedback type="invalid" className="d-block">{formik.errors.skipDetails}</FormFeedback>}
    </Confirmation>}
  </>
}

SectionIdentityVerification.propTypes = {
  id: PropTypes.number,
  order: PropTypes.object,
};

export default SectionIdentityVerification;
