import React from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import DataTable from "./DataTable";

const List = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  return <>
    {iAmGranted(perms.view_orders) && <>
      <div className="page-content">
        <MetaTitle>Remote Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="REMOTE ORDERS" />
          <Row>
            <Col>
              <DataTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </>
}

export default List;
