export default class Statement {

  static TYPE_DEALER_SINGLE_ORDER = 1;
  static TYPE_DEALER_COLLECTIVE_REMOTE = 2;
  static TYPE_DEALER_COLLECTIVE_INSTORE = 3;
  static TYPE_DEALER_SUBSCRIPTION = 4;
  static TYPE_NOTARY_ORDER = 5;

  static getStatementTypeMap() {
    return {
      [this.TYPE_DEALER_SINGLE_ORDER]: "Notary",
      [this.TYPE_DEALER_COLLECTIVE_REMOTE]: "Weekly Remote",
      [this.TYPE_DEALER_COLLECTIVE_INSTORE]: "Monthly Instore",
      [this.TYPE_DEALER_SUBSCRIPTION]: "Subscription",
    };
  }

  static getStatementTypeName(id) {
    return this.getStatementTypeMap()[id];
  }

  static isCollectiveType(type) {
    return [this.TYPE_DEALER_COLLECTIVE_REMOTE, this.TYPE_DEALER_COLLECTIVE_INSTORE].includes(type);
  }
}
