import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import config from 'config';
import { goActive, goIdle } from 'store/actions';

const ActivityStatusMonitor = props => {

  const dispatch = useDispatch();
  const idleTimeout = useRef();
  const isIdle = useRef(false);

  useEffect(() => {
    setup();
    return () => cleanup();
  }, []);

  const setup = () => {
    document.addEventListener('mousemove', switchToActive, false);
    document.addEventListener('mousedown', switchToActive, false);
    document.addEventListener('keypress', switchToActive, false);
    document.addEventListener('DOMMouseScroll', switchToActive, false);
    document.addEventListener('mousewheel', switchToActive, false);
    document.addEventListener('touchmove', switchToActive, false);
    document.addEventListener('MSPointerMove', switchToActive, false);
    startTimer();
  };

  const cleanup = () => {
    document.removeEventListener('mousemove', switchToActive);
    document.removeEventListener('mousedown', switchToActive);
    document.removeEventListener('keypress', switchToActive);
    document.removeEventListener('DOMMouseScroll', switchToActive);
    document.removeEventListener('mousewheel', switchToActive);
    document.removeEventListener('touchmove', switchToActive);
    document.removeEventListener('MSPointerMove', switchToActive);
    clearTimeout(idleTimeout.current);
  };

  const startTimer = () => {
    idleTimeout.current = setTimeout(switchToIdle, config.USER_IDLE_DELAY * 1000);
  }

  const switchToActive = () => {
    clearTimeout(idleTimeout.current);
    startTimer();
    if (isIdle.current) {
      isIdle.current = false;
      dispatch(goActive());
    }
  }

  const switchToIdle = () => {
    if (!isIdle.current) {
      isIdle.current = true;
      dispatch(goIdle());
    }
  }

  return <React.Fragment>
    {props.children}
  </React.Fragment>
}

ActivityStatusMonitor.propTypes = {
  children: PropTypes.any,
}

export default ActivityStatusMonitor;