import React from "react";
import { useAuth } from "context/auth";
import { getInitialsFromName } from "helpers/utilHelper";
import User from "model/user";
import UserAvatarWithActivityStatus from "../UserAvatarWithActivityStatus";

const UserHeader = () => {

  // get user info
  const { user } = useAuth();

  return (
    <div className="py-2">
      <div className="d-flex">
        <div className="align-self-center me-3">
          <UserAvatarWithActivityStatus
            avatar={{
              id: user.id,
              image: user.image,
              initials: getInitialsFromName(user.fullName),
            }}
            activityStatus={{
              id: user.id,
              status: User.ACTIVITY_STATUS_ACTIVE,
              autoUpdate: true,
            }}
            size="sm" />
        </div>
        <div className="flex-grow-1 my-auto">
          <h5 className="font-size-15 mt-0 mb-1">
            {user.getFullName()}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default UserHeader;