export default {
  [611]: 'This authenticator determines the authenticity and integrity of PDF417 barcodes found on USA driving licenses and ID cards',
  [709]: 'Performs a comparison between data elements found on both the front and rear of an identity document. (Example between Front and Back of DLID)',
  [202]: 'Same as Page Ensemble Authenticator but focuses on the whole document instead of individual pages or images',
  [106]: 'Runs for documents with 2 images and returns fraudulent if the images are not of the same document front & back',
  [102]: 'Evaluates if the ID document is a black and white copy',
  [105]: 'Evaluates if our Science was successful able to classify the ID document',
  [201]: 'An advanced authenticator that combines the results from all the authenticator test, how well they performed and image quality to determine authenticity. (At page or image level)',
  [300]: 'Evaluates if the document contains a real human face',
  [101]: 'Evaluates the likelihood that an ID is a part of a set of blacklisted ID',
  [104]: 'Evaluates for font inconsistencies in an ID document',
  [100]: 'Evaluates the originality of the identity document; did the consumer just capture the image or did they get it from the internet?',
  [301]: 'A feature of Mobile Verify that will automatically compare the portrait on an identity document with a selfie provided by the consumer',
};