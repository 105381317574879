import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_DEALER_GROUP_REGION_LIST,
} from "./actionTypes";

import {
  getDealerRegionListOk,
  getDealerRegionListErr,
} from "./actions";

import {
  getDealerGroupRegions,
} from "helpers/backendHelper";

/********* LIST *********/

function* onGetDealerGroupRegionList({ payload: { groupId } }) {
  try {
    const response = yield call(getDealerGroupRegions, groupId);
    yield put(getDealerRegionListOk(response));
  } catch (error) {
    yield put(getDealerRegionListErr(error));
  }
}

function* dealerRegionSaga() {
  yield takeEvery(GET_DEALER_GROUP_REGION_LIST, onGetDealerGroupRegionList);
}

export default dealerRegionSaga;