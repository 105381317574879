import React from "react";
import { useSelector } from "react-redux";
import OrderNotary from "../../../../../../model/orderNotary";

const StepReturnToDealership = () => {

  /********** STATE **********/
  const { order } = useSelector(state => state.Order.Single);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const getDocsStatus = (returnedNotaryTs) => {
   if(order.notaries[0].status === OrderNotary.STATUS_DOCS_UPLOADED) {
    if(returnedNotaryTs) return 'Document Shipped';
    return 'Signed'
   }
   return 'Pending Signature';
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center section-title mb-4">
        <i className="bx bx-file font-size-20" />
        <h6 className="mb-0 ps-2">Contract information:</h6>
      </div>
      <table className="table table-sm zebra-striped-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {inkSignDocs?.map((item, index) => <tr key={index + 1} style={{ verticalAlign: "middle" }} >
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td><span className="badge rounded-pill bg-success">{getDocsStatus(item.returnedByNotaryTs)}</span></td>
          </tr>)}

          {!inkSignDocs?.length && (
            <tr>
              <td className="table-empty" colSpan="4">No contracts found</td>
            </tr>
          )}
        </tbody>
      </table>

    </React.Fragment>
  )
}

export default StepReturnToDealership;