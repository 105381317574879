/********* SCHEDULER REPORT *********/

export const GET_SCHEDULER_REPORT = "GET_SCHEDULER_REPORT";
export const GET_SCHEDULER_REPORT_OK = "GET_SCHEDULER_REPORT_OK";
export const GET_SCHEDULER_REPORT_ERR = "GET_SCHEDULER_REPORT_ERR";
export const DO_SCHEDULER_REPORT_CLEANUP = "DO_SCHEDULER_REPORT_CLEANUP";

/********* SCHEDULER REPORT FILTERS *********/

export const APPLY_SCHEDULER_REPORT_FILTERS = "APPLY_SCHEDULER_REPORT_FILTERS";
export const CLEAR_SCHEDULER_REPORT_FILTERS = "CLEAR_SCHEDULER_REPORT_FILTERS";
export const DO_SCHEDULER_REPORT_FILTERS_CLEANUP = "DO_SCHEDULER_REPORT_FILTERS_CLEANUP";

/********* GROUP USAGE REPORT *********/

export const GET_GROUP_USAGE_REPORT = "GET_GROUP_USAGE_REPORT";
export const GET_GROUP_USAGE_REPORT_OK = "GET_GROUP_USAGE_REPORT_OK";
export const GET_GROUP_USAGE_REPORT_ERR = "GET_GROUP_USAGE_REPORT_ERR";
export const DO_GROUP_USAGE_REPORT_CLEANUP = "DO_GROUP_USAGE_REPORT_CLEANUP";

/********* GROUP USAGE REPORT FILTERS *********/

export const APPLY_GROUP_USAGE_REPORT_FILTERS = "APPLY_GROUP_USAGE_REPORT_FILTERS";
export const CLEAR_GROUP_USAGE_REPORT_FILTERS = "CLEAR_GROUP_USAGE_REPORT_FILTERS";
export const DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP = "DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP";

/********* DEALER SALES REPORT *********/

export const GET_DEALER_SALES_REPORT = "GET_DEALER_SALES_REPORT";
export const GET_DEALER_SALES_REPORT_OK = "GET_DEALER_SALES_REPORT_OK";
export const GET_DEALER_SALES_REPORT_ERR = "GET_DEALER_SALES_REPORT_ERR";
export const DO_DEALER_SALES_REPORT_CLEANUP = "DO_DEALER_SALES_REPORT_CLEANUP";

/********* DEALER SALES REPORT FILTERS *********/

export const APPLY_DEALER_SALES_REPORT_FILTERS = "APPLY_DEALER_SALES_REPORT_FILTERS";
export const CLEAR_DEALER_SALES_REPORT_FILTERS = "CLEAR_DEALER_SALES_REPORT_FILTERS";
export const DO_DEALER_SALES_REPORT_FILTERS_CLEANUP = "DO_DEALER_SALES_REPORT_FILTERS_CLEANUP";

/********* NEW DEALERS REPORT *********/

export const GET_NEW_DEALERS_REPORT = "GET_NEW_DEALERS_REPORT";
export const GET_NEW_DEALERS_REPORT_OK = "GET_NEW_DEALERS_REPORT_OK";
export const GET_NEW_DEALERS_REPORT_ERR = "GET_NEW_DEALERS_REPORT_ERR";
export const DO_NEW_DEALERS_REPORT_CLEANUP = "DO_NEW_DEALERS_REPORT_CLEANUP";

/********* NEW DEALERS REPORT FILTERS *********/

export const APPLY_NEW_DEALERS_REPORT_FILTERS = "APPLY_NEW_DEALERS_REPORT_FILTERS";
export const CLEAR_NEW_DEALERS_REPORT_FILTERS = "CLEAR_NEW_DEALERS_REPORT_FILTERS";
export const DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP = "DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP";
