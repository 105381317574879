import { takeEvery, put, call } from "redux-saga/effects";
import {

	getOrderAvailableFeesErr,
	getOrderAvailableFeesOk,

	getOrderFeesErr,
	getOrderFeesOk,

	saveDealerErr,
	saveDealerOk,
	saveNotaryErr,
	saveNotaryOk,

} from "./actions";

import {
	getOrderFees,
	getOrderAvailableFees,
	updateDealerAccounting,
	updateNotaryAccounting,
	updateQuickbookAccounting,

} from 'helpers/backendHelper'

import {
	GET_ORDER_AVAILABLE_FEES,
	GET_ORDER_FEES,

	UPDATE_DEALER_ACCOUNTING,
	UPDATE_NOTARY_ACCOUNTING,
	UPDATE_QUICKBOOK_ACCOUNTING,
} from "./actionTypes";

/********** SINGLE **********/

function* onGetOrderFees({ payload: { id } }) {
	try {
		const response = yield call(getOrderFees, id);
		yield put(getOrderFeesOk(response));
	} catch (error) {
		yield put(getOrderFeesErr(error));
	}
}

/********** LIST **********/
function* onGetOrderAvailableFees({ payload: { id } }) {
	try {
		const response = yield call(getOrderAvailableFees, id);
		yield put(getOrderAvailableFeesOk(response));
	} catch (error) {
		yield put(getOrderAvailableFeesErr(error));
	}
}

/********** FORM **********/

function* onUpdateDealerAccounting({ payload: { id, data } }) {
	try {
		yield call(updateDealerAccounting, id, data);
		yield put(saveDealerOk());
	} catch (error) {
		yield put(saveDealerErr(error));
	}
}

function* onUpdateNotaryAccounting({ payload: { id, data } }) {
	try {
		yield call(updateNotaryAccounting, id, data);
		yield put(saveNotaryOk());
	} catch (error) {
		yield put(saveNotaryErr(error));
	}
}

/*function* onUpdateQuickbookAccounting({ payload: { data, id } }) {
	try {
		yield call(updateQuickbookAccounting, data, id);
		yield put(saveAccountingOk());
	} catch (error) {
		yield put(saveAccountingErr(error));
	}
}*/

function* orderFeesSaga() {
	yield takeEvery(GET_ORDER_FEES, onGetOrderFees);
	yield takeEvery(GET_ORDER_AVAILABLE_FEES, onGetOrderAvailableFees);
	yield takeEvery(UPDATE_DEALER_ACCOUNTING, onUpdateDealerAccounting);
	yield takeEvery(UPDATE_NOTARY_ACCOUNTING, onUpdateNotaryAccounting);
	/*yield takeEvery(UPDATE_QUICKBOOK_ACCOUNTING, onUpdateQuickbookAccounting);*/
}

export default orderFeesSaga;