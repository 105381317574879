import React, { useEffect } from "react";
import { Row, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";
import Col from "components/Shared/Col";
import DatePicker from "components/Shared/DatePicker";
import TextareaAutosize from "react-textarea-autosize";
import UsStates from "constants/usState";
import { useFormikContext } from "formik";
import { getYesNoOptionsAsNumbers, toSelectOptions } from "helpers/utilHelper";
import classnames from "classnames";

const UploadCommissionCertificate = () => {

  // consume formik instance from context
  const formik = useFormikContext();

  /********** EFFECTS **********/

  // remove the expiration date whenever `expiry` field switches to `no`
  useEffect(() => {
    if (formik.values.expiry === 0) if (formik.values.expiry === 0) delete formik.values.expirationTs;
  }, [formik.values.expiry]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <Row className="mb-4">
    <Col xs={12} md={6}>
      <Label className="col-form-label">Commission State</Label>
      <Select
        classNamePrefix="select2-selection"
        name="commissionState"
        options={usStates}
        onChange={selected => formik.setFieldValue("commissionState", selected.value)}
        onFocus={e => onFieldFocused(e, "commissionState")}
        value={usStates.find(option => option.value === formik.values.commissionState)}
        className={!!formik.errors.commissionState && "is-invalid"} />
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Commission Number*</Label>
      <Input type="text" className="form-control" name="commissionNo" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.commissionNo} invalid={!!formik.errors.commissionNo} />
      {!!formik.errors.commissionNo && <FormFeedback type="invalid">{formik.errors.commissionNo}</FormFeedback>}
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Upload file (pdf)*</Label>
      <Input type="file" className="form-control" name="file" onChange={e => formik.setFieldValue("file", e.currentTarget.files[0])} onFocus={onFieldFocused} invalid={!!formik.errors.file} />
      {!!formik.errors.file && <FormFeedback type="invalid">{formik.errors.file}</FormFeedback>}
    </Col>
    <Col xs={12} md={6} />
    <Col xs={12} md={6}>
      <Label className="col-form-label">Expiry*</Label>
      <Select
        classNamePrefix="select2-selection"
        name="expiry"
        options={getYesNoOptionsAsNumbers()}
        onChange={selected => formik.setFieldValue("expiry", selected.value)}
        onFocus={e => onFieldFocused(e, "expiry")}
        value={getYesNoOptionsAsNumbers().find(option => option.value === formik.values.expiry)}
        className={!!formik.errors.expiry && "is-invalid"} />
      {!!formik.errors.expiry && <FormFeedback type="invalid" className="d-block">{formik.errors.expiry}</FormFeedback>}
    </Col>
    <Col xs={12} md={6}>
      {!!formik.values.expiry && <>
        <Label className="col-form-label">Expiration Date*</Label>
        <DatePicker
          name="expirationTs"
          value={formik.values.expirationTs}
          onChange={selected => formik.setFieldValue("expirationTs", selected)}
          onFocus={onFieldFocused}
          minDate={new Date().setHours(0, 0, 0, 0)}
          invalid={!!formik.errors.expirationTs}
        />
        {!!formik.errors.expirationTs && <FormFeedback type="invalid" className="d-block">{formik.errors.expirationTs}</FormFeedback>}
      </>}
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Commission in another state?</Label>
      <Select
        classNamePrefix="select2-selection"
        name="commissionState2"
        options={usStates}
        onChange={selected => formik.setFieldValue("commissionState2", selected.value)}
        onFocus={e => onFieldFocused(e, "commissionState2")}
        value={usStates.find(option => option.value === formik.values.commissionState2)}
        className={!!formik.errors.commissionState2 && "is-invalid"} />
    </Col>
    <Col xs={12} md={6}>
      <Label className="col-form-label">Counties Covered</Label>
      <TextareaAutosize maxRows={15} className={classnames("form-control", { "is-invalid": !!formik.errors.countiesCovered })} name="countiesCovered" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.countiesCovered} />
      {!!formik.errors.countiesCovered && <FormFeedback type="invalid">{formik.errors.countiesCovered}</FormFeedback>}
    </Col>
  </Row>
}

const usStates = toSelectOptions(UsStates);

export default UploadCommissionCertificate;