import {

  GET_SUPPORT_STATS,
  GET_SUPPORT_STATS_OK,
  GET_SUPPORT_STATS_ERR,
  DO_SUPPORT_STATS_CLEANUP,

  GET_APPOINTMENTS_LIST,
  GET_APPOINTMENTS_LIST_OK,
  GET_APPOINTMENTS_LIST_ERR,
  DO_APPOINTMENTS_LIST_CLEANUP,

} from "./actionTypes";

/********** ORDER STATS **********/

export const getSupportStats = () => ({
  type: GET_SUPPORT_STATS,
});

export const getSupportStatsOk = response => ({
  type: GET_SUPPORT_STATS_OK,
  payload: { response },
});

export const getSupportStatsErr = error => ({
  type: GET_SUPPORT_STATS_ERR,
  payload: { error },
});

export const doSupportStatsCleanup = () => ({
  type: DO_SUPPORT_STATS_CLEANUP,
});

/********** APPOINTMENTS LIST **********/

export const getAppointmentsList = params => ({
  type: GET_APPOINTMENTS_LIST,
  payload: { params }
});

export const getAppointmentsListOk = response => ({
  type: GET_APPOINTMENTS_LIST_OK,
  payload: { response },
});

export const getAppointmentsListErr = error => ({
  type: GET_APPOINTMENTS_LIST_ERR,
  payload: { error },
});

export const doAppointmentsListCleanup = () => ({
  type: DO_APPOINTMENTS_LIST_CLEANUP
})
