import React from "react"
import { Container, Row, Col } from "reactstrap"
import { BrowserView, MobileView } from "react-device-detect";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import DataTable from "./Partial/Desktop/DataTable";
import { Link, useNavigate } from "react-router-dom";
import MessagesList from "./Partial/Mobile/MessagesList";
import backIcon from 'assets/images/back-icon.png';

const List = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  const navigate = useNavigate();

  return <React.Fragment>
    {iAmGranted(perms.view_messages) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>Messages</MetaTitle>
        <Container fluid>
          {/* Desktop Content */}
          <BrowserView className="h-100">
            <Breadcrumbs title="MESSAGES" />
            <Row>
              <Col>
                <DataTable />
              </Col>
            </Row>
          </BrowserView>
          {/* Mobile Content */}
          <MobileView>
            <Row className="py-2">
              <Col xs={4}>
                <Link onClick={() => navigate(-1)} >
                  <img className="mt-1" src={backIcon} />
                </Link>
              </Col>
              <Col xs={8}>
                <h3 className="mb-4">Messages</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <MessagesList />
              </Col>
            </Row>
          </MobileView>
        </Container>
      </div>
    </React.Fragment>}
    {iAmNotGranted(perms.view_messages) && <AccessDenied />}
  </React.Fragment>
}

export default List;
