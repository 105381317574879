import { takeEvery, put, call } from "redux-saga/effects";

import {
  getPaymentDtOk,
  getPaymentDtErr,
} from "./actions";

import {
  GET_PAYMENT_DT,
} from "./actionTypes";

import {
  getPaymentDt,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetPaymentDt({ payload: { params } }) {
  try {
    const response = yield call(getPaymentDt, params);
    yield put(getPaymentDtOk(response));
  } catch (error) {
    yield put(getPaymentDtErr(error));
  }
}

function* paymentSaga() {
  yield takeEvery(GET_PAYMENT_DT, onGetPaymentDt);
}

export default paymentSaga;