import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledTooltip } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { perms, useAccess } from "context/access";

const ViewBilling = props => {

  const { toggleEditMode, notary, isLoadInProgress,
    qbVendor, qbVendorError, isQbVendorLoadInProgress } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Billing/Payment Information</CardTitle>
        </Col>
        {iAmGranted(perms.edit_notaries_QB) && <Col xs="auto" className="text-end">
          <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
            <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
              <i className="bx bx-dots-horizontal-rounded" />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>}
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Billing/Payment Address</td>
            <td className="section-row-value ps-4">{notary.billingAddress || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">5 digit Zip Code</td>
            <td className="section-row-value ps-4">{notary.billingZip || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">City</td>
            <td className="section-row-value ps-4">{notary.billingCity || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">State</td>
            <td className="section-row-value ps-4">{notary.billingState || "---"}</td>
          </tr>
          {iAmGranted(perms.view_quickbooks) &&
            <tr>
              <td className="section-row-label no-border">QuickBooks Vendor</td>
              <td className="section-row-value ps-4 no-border">
                {!!notary.qbVendorId && notary.qbVendorId}
                {!!qbVendor && ` - ${qbVendor.DisplayName}`}
                {isQbVendorLoadInProgress && <i className="mdi mdi-spin mdi-loading ms-2" />}
                {!!qbVendorError && <React.Fragment>
                  <i id="invoice-item-warning" className="mdi mdi-alert-circle text-danger ms-1"></i>
                  <UncontrolledTooltip placement="top" target="invoice-item-warning">{qbVendorError}</UncontrolledTooltip>
                </React.Fragment>}
              </td>
            </tr>
          }
        </tbody>
      </Table>
    </CardBody>
    {isLoadInProgress && <CardPreloader />}
  </Card>)
}

ViewBilling.propTypes = {
  toggleEditMode: PropTypes.func,
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool,
  qbVendor: PropTypes.object,
  qbVendorError: PropTypes.string,
  isQbVendorLoadInProgress: PropTypes.bool,
};

export default ViewBilling;