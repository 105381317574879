import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ViewInfo from "../View/Info";
import FormEditInfo from "../Form/Info/Edit";

const SectionInfo = ({ isLoadBusy, id, customFee, refreshHandler }) => {

	// toggle edit-in-place on/off
	const [isEditModeActive, setIsEditModeActive] = useState(false);
	// TRUE after a save and until the data is refreshed
	const [isRefreshing, setIsRefreshing] = useState(false);

	useEffect(() => {
		if (!isLoadBusy) {
			setIsRefreshing(false);
		}
	}, [isLoadBusy]);

	// toggles the edit mode on and off
	const toggleEditMode = doRefresh => {
		setIsEditModeActive(prevMode => !prevMode);
		// see if the data has changed and we need to refresh (ex. after a successfull save)
		if (doRefresh === true) {
			setIsRefreshing(true);
			refreshHandler();
		}
	}

	return <React.Fragment>
		{isEditModeActive && <FormEditInfo id={id} defaultValues={customFee} finishedHandler={toggleEditMode} />}
		{!isEditModeActive && <ViewInfo id={id} customFee={customFee} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing}/>}
	</React.Fragment>
}

SectionInfo.propTypes = {
	isLoadBusy: PropTypes.bool,
	id: PropTypes.number,
	customFee: PropTypes.object,
	refreshHandler: PropTypes.func,
};

export default SectionInfo;
