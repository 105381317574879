import ioClient from 'socket.io-client';
import config from 'config';
import { buildUrl } from './utilHelper';

export const openSocket = (namespace, options) => {
  if (!namespace) {
    namespace = config.SOCKET_NAMESPACE;
  }
  const url = buildUrl(config.API_URL, namespace);
  return ioClient(url, {
    transports: ['websocket'],
    secure: true,
    path: config.SOCKET_PATH,
    ...options,
  });
}

export const closeSocket = client => {
  if (!client) {
    return;
  }
  client.removeAllListeners();
  client.close();
}