import React from 'react';
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import SectionOrderServices from './Partial/Services';
import SectionOrderReviews from './Partial/Reviews';

const SectionOrderActions = ({ order, id, refreshHandler }) => {

  return <Card>
    <CardBody>
      <div className="d-flex">
        <div>
          <SectionOrderServices order={order} id={id} refreshHandler={refreshHandler} />
        </div>
        <div className="flex-grow-1">
          <SectionOrderReviews order={order} id={id} refreshHandler={refreshHandler} />
        </div>
      </div>
    </CardBody>
  </Card>
}

SectionOrderActions.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
}

export default SectionOrderActions;