import { combineReducers } from "redux"
import {
  GET_NOTARY_DT,
  GET_NOTARY_DT_OK,
  GET_NOTARY_DT_ERR,
  DO_NOTARY_DT_CLEANUP,

  APPLY_NOTARY_DT_FILTERS,
  PATCH_NOTARY_DT_FILTERS,
  CLEAR_NOTARY_DT_FILTERS,
  UNSET_NOTARY_DT_FILTERS,
  DO_NOTARY_DT_FILTERS_CLEANUP,

  ADD_NOTARY_RATING,
  ADD_NOTARY_RATING_OK,
  ADD_NOTARY_RATING_ERR,
  DO_NOTARY_RATING_CLEANUP,

  GET_NOTARY_RATING_RESULTS,
  GET_SECOND_NOTARY_RATING_RESULTS,
  GET_NOTARY_RATING_RESULTS_OK,
  GET_SECOND_NOTARY_RATING_RESULTS_OK,
  GET_NOTARY_RATING_RESULTS_ERR,
  DO_NOTARY_RATING_RESULTS_CLEANUP,

  GET_MY_NOTARY_RATING,
  GET_MY_SECOND_NOTARY_RATING,
  GET_MY_NOTARY_RATING_OK,
  GET_MY_SECOND_NOTARY_RATING_OK,
  GET_MY_NOTARY_RATING_ERR,
  DO_MY_NOTARY_RATING_CLEANUP
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  notaries: [], // datatable rows
  notariesError: null, // datatable error
  totalCount: 0, // datatable total row count without limit needed for pagination
  listParams: {
    sortBy: "id", // the default datatable sort column
    sortDir: "desc", // the default datatable sort order
    pageSize: 50, // the default datatable page size
    page: 1, // the default datatable page
    search: null, // the default datatable search phrase
    filters: null, // the default datatable filters (do not set from here)
  }, // datatable params passed to backend
  isLoadInProgress: false, // whether a remote request is in progress
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_NOTARY_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        notariesError: null,
        isLoadInProgress: true,
      };
      break
    case GET_NOTARY_DT_OK:
      state = {
        ...state,
        notaries: action.payload.response.notaries,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_NOTARY_DT_ERR:
      state = {
        ...state,
        notaries: [],
        totalCount: 0,
        notariesError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_NOTARY_DT_CLEANUP:
      state = {
        ...state,
        notaries: [],
        notariesError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

// default datatable filters to be applied as the visitor first enters the screen
// most of the time we do not want to have default filters so these values will be empty
// however there may be times when we want the list filtered by default
const defaultDtFiltersState = {
  name: "",
  email: "",
  state: "",
  city: "",
  zip: "",
  status: null,
  qbId: "",
  type: [],
  expiredNotaryCredential: "",
  verified: "",
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_NOTARY_DT_FILTERS:
      state = {
        ...action.payload.filters,
        _set: true,
      };
      break
    case PATCH_NOTARY_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_NOTARY_DT_FILTERS:
      state = {
        ...defaultDtFiltersState,
        _set: true,
      };
      break
    case UNSET_NOTARY_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_NOTARY_DT_FILTERS_CLEANUP:
      state = {
        ...defaultDtFiltersState,
      };
      break
  }
  return state;
}

/********** RATING **********/

const defaultRatingState = {
  rating: null,
  saveError: null,
  isSaveInProgress: false,
  saved: null,
};

const Rating = (state = defaultRatingState, action) => {
  switch (action.type) {
    case ADD_NOTARY_RATING:
      state = {
        ...state,
        rating: action.payload.data,
        saveError: null,
        isSaveInProgress: true,
        saved: null,
      };
      break
    case ADD_NOTARY_RATING_OK:
      state = {
        ...state,
        isSaveInProgress: false,
        saved: true,
      };
      break
    case ADD_NOTARY_RATING_ERR:
      state = {
        ...state,
        saveError: action.payload.error,
        isSaveInProgress: false,
        saved: false,
      };
      break
    case DO_NOTARY_RATING_CLEANUP:
      state = { ...defaultRatingState };
      break
  }
  return state;
}

/********** RATING RESULTS **********/

const defaultRatingResultsState = {
  resultsNotary: null,
  resultsSecondNotary: null,
  resultsError: null,
  isResultsLoadInProgress: false,
};

const RatingResults = (state = defaultRatingResultsState, action) => {
  switch (action.type) {
    case GET_NOTARY_RATING_RESULTS:
    case GET_SECOND_NOTARY_RATING_RESULTS:
      state = {
        ...state,
        resultsError: null,
        isResultsLoadInProgress: true,
      };
      break
    case GET_NOTARY_RATING_RESULTS_OK:
      state = {
        ...state,
        resultsNotary: action.payload.response,
        isResultsLoadInProgress: false,
      };
      break
    case GET_SECOND_NOTARY_RATING_RESULTS_OK:
      state = {
        ...state,
        resultsSecondNotary: action.payload.response,
        isResultsLoadInProgress: false,
      };
      break
    case GET_NOTARY_RATING_RESULTS_ERR:
      state = {
        ...state,
        results: null,
        resultsError: action.payload.error,
        isResultsLoadInProgress: false,
      };
      break
    // cleanup
    case DO_NOTARY_RATING_RESULTS_CLEANUP:
      state = { ...defaultRatingResultsState };
      break
  }
  return state;
}

/********** MY NOTARY RATING **********/

const defaultMyNotaryRatingState = {
  myNotaryRating: null,
  mySecondNotaryRating: null,
  myRatingError: null,
  isLoadInProgress: false,
};

const MyNotaryRating = (state = defaultMyNotaryRatingState, action) => {
  switch (action.type) {
    case GET_MY_NOTARY_RATING:
    case GET_MY_SECOND_NOTARY_RATING:
      state = {
        ...state,
        myRatingError: null,
        isLoadInProgress: true,
      };
      break
    case GET_MY_NOTARY_RATING_OK:
      state = {
        ...state,
        myNotaryRating: action.payload.response.notaryRating,
        isLoadInProgress: false,
      };
      break
    case GET_MY_SECOND_NOTARY_RATING_OK:
      state = {
        ...state,
        mySecondNotaryRating: action.payload.response.notaryRating,
        isLoadInProgress: false,
      };
      break
    case GET_MY_NOTARY_RATING_ERR:
      state = {
        ...state,
        myRating: null,
        myRatingError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_MY_NOTARY_RATING_CLEANUP:
      state = { ...defaultMyNotaryRatingState };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
  DtFilters,
  Rating,
  RatingResults,
  MyNotaryRating,
});
