import { combineReducers } from "redux"
import {
  GET_BIDDING_DT,
  GET_BIDDING_DT_OK,
  GET_BIDDING_DT_ERR,
  DO_BIDDING_DT_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

const defaultDtState = {
  biddings: [], // datatable rows
  biddingsError: null, // datatable error
  totalCount: 0, // datatable total row count without limit needed for pagination
  listParams: {
    sortBy: "id", // the default datatable sort column
    sortDir: "desc", // the default datatable sort order
    pageSize: 50, // the default datatable page size
    page: 1, // the default datatable page
    search: null, // the default datatable search phrase
    filters: null, // the default datatable filters (do not set from here)
  }, // datatable params passed to backend
  isLoadInProgress: false, // whether a remote request is in progress
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_BIDDING_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        biddingsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_BIDDING_DT_OK:
      state = {
        ...state,
        biddings: action.payload.response.orders,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_BIDDING_DT_ERR:
      state = {
        ...state,
        biddings: [],
        totalCount: 0,
        biddingsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_BIDDING_DT_CLEANUP:
      state = {
        ...defaultDtState,
      };
      break
  }
  return state;
}

export default combineReducers({
  Dt,
});
