/********** DATATABLE **********/

export const GET_ORDER_DT = 'GET_ORDER_DT'
export const GET_ORDER_DT_OK = 'GET_ORDER_DT_OK'
export const GET_ORDER_DT_ERR = 'GET_ORDER_DT_ERR'
export const DO_ORDER_DT_CLEANUP = 'DO_ORDER_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_ORDER_DT_FILTERS = 'APPLY_ORDER_DT_FILTERS'
export const PATCH_ORDER_DT_FILTERS = 'PATCH_ORDER_DT_FILTERS'
export const CLEAR_ORDER_DT_FILTERS = 'CLEAR_ORDER_DT_FILTERS'
export const UNSET_ORDER_DT_FILTERS = 'UNSET_ORDER_DT_FILTERS'
export const DO_ORDER_DT_FILTERS_CLEANUP = 'DO_ORDER_DT_FILTERS_CLEANUP'

/********** INSTORE DATATABLE FILTERS **********/

export const APPLY_IN_STORE_ORDER_DT_FILTERS = 'APPLY_IN_STORE_ORDER_DT_FILTERS'
export const PATCH_IN_STORE_ORDER_DT_FILTERS = 'PATCH_IN_STORE_ORDER_DT_FILTERS'
export const CLEAR_IN_STORE_ORDER_DT_FILTERS = 'CLEAR_IN_STORE_ORDER_DT_FILTERS'
export const UNSET_IN_STORE_ORDER_DT_FILTERS = 'UNSET_IN_STORE_ORDER_DT_FILTERS'
export const DO_IN_STORE_ORDER_DT_FILTERS_CLEANUP = 'DO_IN_STORE_ORDER_DT_FILTERS_CLEANUP'

/********** INSTORE DATATABLE FILTERS **********/

export const APPLY_PENDING_REVIEW_ORDER_DT_FILTERS = 'APPLY_PENDING_REVIEW_ORDER_DT_FILTERS'
export const PATCH_PENDING_REVIEW_ORDER_DT_FILTERS = 'PATCH_PENDING_REVIEW_ORDER_DT_FILTERS'
export const CLEAR_PENDING_REVIEW_ORDER_DT_FILTERS = 'CLEAR_PENDING_REVIEW_ORDER_DT_FILTERS'
export const UNSET_PENDING_REVIEW_ORDER_DT_FILTERS = 'UNSET_PENDING_REVIEW_ORDER_DT_FILTERS'
export const DO_PENDING_REVIEW_ORDER_DT_FILTERS_CLEANUP = 'DO_PENDING_REVIEW_ORDER_DT_FILTERS_CLEANUP'

/********** LIST **********/

export const GET_INACTIVE_ORDER_LIST = 'GET_INACTIVE_ORDER_LIST'
export const GET_ORDER_LIST_OK = 'GET_ORDER_LIST_OK'
export const GET_ORDER_LIST_ERR = 'GET_ORDER_LIST_ERR'
export const DO_ORDER_LIST_CLEANUP = 'DO_ORDER_LIST_CLEANUP'

/********** SINGLE **********/

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS = 'GET_ORDER_WITH_CUSTOMER_SIGNERS'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES'
export const GET_FULL_ORDER = 'GET_FULL_ORDER'
export const GET_ORDER_OK = 'GET_ORDER_OK'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_OK = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_OK'
export const GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK = 'GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK'
export const GET_FULL_ORDER_OK = 'GET_FULL_ORDER_OK'

export const GET_ORDER_ERR = 'GET_ORDER_ERR'

export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_OK = "DELETE_ORDER_OK"
export const DELETE_ORDER_ERR = "DELETE_ORDER_ERR"

export const DO_ORDER_SINGLE_CLEANUP = 'DO_ORDER_SINGLE_CLEANUP'

export const RESET_ORDER_ACTION_FLAG = 'RESET_ORDER_ACTION_FLAG'

export const RESEND_CUSTOMER_NOTIFICATIONS = 'RESEND_CUSTOMER_NOTIFICATIONS'
export const RESEND_CUSTOMER_NOTIFICATIONS_OK = 'RESEND_CUSTOMER_NOTIFICATIONS_OK'
export const RESEND_CUSTOMER_NOTIFICATIONS_ERR = 'RESEND_CUSTOMER_NOTIFICATIONS_ERR'

export const START_CUSTOMER_MEETING = 'START_CUSTOMER_MEETING'
export const START_CUSTOMER_MEETING_OK = 'START_CUSTOMER_MEETING_OK'
export const START_CUSTOMER_MEETING_ERR = 'START_CUSTOMER_MEETING_ERR'

export const START_NOTARY_MEETING = 'START_NOTARY_MEETING'
export const START_NOTARY_MEETING_OK = 'START_NOTARY_MEETING_OK'
export const START_NOTARY_MEETING_ERR = 'START_NOTARY_MEETING_ERR'

/********** FORM **********/

export const UPDATE_ORDER_CUSTOMER = 'UPDATE_ORDER_CUSTOMER'
export const UPDATE_ORDER_VEHICLE = 'UPDATE_ORDER_VEHICLE'
export const UPDATE_ORDER_CONTRACT = 'UPDATE_ORDER_CONTRACT'
export const SAVE_ORDER_OK = 'SAVE_ORDER_OK'
export const SAVE_ORDER_ERR = 'SAVE_ORDER_ERR'
export const DO_ORDER_FORM_CLEANUP = 'DO_ORDER_FORM_CLEANUP'
export const SET_ORDER_IS_RURAL = 'SET_ORDER_IS_RURAL'

/********** VID **********/

export const GET_ORDER_VID = 'GET_ORDER_VID'
export const GET_ORDER_VID_OK = 'GET_ORDER_VID_OK'
export const GET_ORDER_VID_ERR = 'GET_ORDER_VID_ERR'
export const DO_ORDER_VID_CLEANUP = 'DO_ORDER_VID_CLEANUP'

/********** INK-SIGN DOCS **********/

export const GET_ORDER_INK_SIGN_DOCS = 'GET_ORDER_INK_SIGN_DOCS'
export const GET_ORDER_INK_SIGN_DOCS_OK = 'GET_ORDER_INK_SIGN_DOCS_OK'
export const GET_ORDER_INK_SIGN_DOCS_ERR = 'GET_ORDER_INK_SIGN_DOCS_ERR'
export const DO_ORDER_INK_SIGN_DOCS_CLEANUP = 'DO_ORDER_INK_SIGN_DOCS_CLEANUP'

/********** E-SIGN DOCS **********/

export const GET_ORDER_E_SIGN_DOCS = 'GET_ORDER_E_SIGN_DOCS'
export const GET_ORDER_E_SIGN_DOCS_OK = 'GET_ORDER_E_SIGN_DOCS_OK'
export const GET_ORDER_E_SIGN_DOCS_ERR = 'GET_ORDER_E_SIGN_DOCS_ERR'
export const DO_ORDER_E_SIGN_DOCS_CLEANUP = 'DO_ORDER_E_SIGN_DOCS_CLEANUP'

/********** SUPPORTING DOCS **********/

export const GET_ORDER_SUPPORTING_DOCS = 'GET_ORDER_SUPPORTING_DOCS'
export const GET_ORDER_SUPPORTING_DOCS_OK = 'GET_ORDER_SUPPORTING_DOCS_OK'
export const GET_ORDER_SUPPORTING_DOCS_ERR = 'GET_ORDER_SUPPORTING_DOCS_ERR'
export const DO_ORDER_SUPPORTING_DOCS_CLEANUP = 'DO_ORDER_SUPPORTING_DOCS_CLEANUP'

/********** PREVIEW DOCS **********/

export const GET_ORDER_PREVIEW_DOCS = 'GET_ORDER_PREVIEW_DOCS'
export const GET_ORDER_PREVIEW_DOCS_OK = 'GET_ORDER_PREVIEW_DOCS_OK'
export const GET_ORDER_PREVIEW_DOCS_ERR = 'GET_ORDER_PREVIEW_DOCS_ERR'
export const DO_ORDER_PREVIEW_DOCS_CLEANUP = 'DO_ORDER_PREVIEW_DOCS_CLEANUP'

/********** ACTIVITY **********/

export const GET_ORDER_ACTIVITY = 'GET_ORDER_ACTIVITY'
export const GET_ORDER_ACTIVITY_OK = 'GET_ORDER_ACTIVITY_OK'
export const GET_ORDER_ACTIVITY_ERR = 'GET_ORDER_ACTIVITY_ERR'
export const DO_ORDER_ACTIVITY_CLEANUP = 'DO_ORDER_ACTIVITY_CLEANUP'

/********** MESSAGES **********/

export const GET_ORDER_MESSAGES = 'GET_ORDER_MESSAGES'
export const GET_ORDER_MESSAGES_OK = 'GET_ORDER_MESSAGES_OK'
export const GET_ORDER_MESSAGES_ERR = 'GET_ORDER_MESSAGES_ERR'
export const DO_ORDER_MESSAGES_CLEANUP = 'DO_ORDER_MESSAGES_CLEANUP'
export const GET_CHAT_MEMBERS = 'GET_CHAT_MEMBERS'
export const GET_CHAT_MEMBERS_OK = 'GET_CHAT_MEMBERS_OK'
export const GET_CHAT_MEMBERS_ERR = 'GET_CHAT_MEMBERS_ERR'
export const RESET_CHANNEL_UNREAD_MESSAGES = "RESET_CHANNEL_UNREAD_MESSAGES"

/********** SCHEDULER **********/

export const ASSIGN_ORDER_SCHEDULER = 'ASSIGN_ORDER_SCHEDULER'
export const ASSIGN_ORDER_SCHEDULER_OK = 'ASSIGN_ORDER_SCHEDULER_OK'
export const ASSIGN_ORDER_SCHEDULER_ERR = 'ASSIGN_ORDER_SCHEDULER_ERR'
export const DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP = 'DO_ORDER_SCHEDULER_ASSIGNMENT_CLEANUP'

/********** NOTARY BID **********/

export const AWARD_NOTARY_BID = 'AWARD_NOTARY_BID'
export const AWARD_NOTARY_BID_OK = 'AWARD_NOTARY_BID_OK'
export const AWARD_NOTARY_BID_ERR = 'AWARD_NOTARY_BID_ERR'
export const DECLINE_NOTARY_BID = 'DECLINE_NOTARY_BID'
export const DECLINE_NOTARY_BID_OK = 'DECLINE_NOTARY_BID_OK'
export const DECLINE_NOTARY_BID_ERR = 'DECLINE_NOTARY_BID_ERR'
export const NOTIFY_NOTARY_BID = 'NOTIFY_NOTARY_BID'
export const NOTIFY_NOTARY_BID_OK = 'NOTIFY_NOTARY_BID_OK'
export const NOTIFY_NOTARY_BID_ERR = 'NOTIFY_NOTARY_BID_ERR'
export const ADD_ORDER_NOTARY_BID = 'ADD_ORDER_NOTARY_BID'
export const ADD_ORDER_NOTARY_BID_OK = 'ADD_ORDER_NOTARY_BID_OK'
export const ADD_ORDER_NOTARY_BID_ERR = 'ADD_ORDER_NOTARY_BID_ERR'
export const START_ORDER_NOTARY_BID = 'START_ORDER_NOTARY_BID'
export const START_ORDER_NOTARY_BID_OK = 'START_ORDER_NOTARY_BID_OK'
export const START_ORDER_NOTARY_BID_ERR = 'START_ORDER_NOTARY_BID_ERR'
export const START_NOTARY_REQUEUE = 'START_NOTARY_REQUEUE'
export const START_NOTARY_REQUEUE_OK = 'START_NOTARY_REQUEUE'
export const START_NOTARY_REQUEUE_ERROR = 'START_NOTARY_REQUEUE_ERROR'
export const DO_NOTARY_BID_CLEANUP = 'DO_NOTARY_BID_CLEANUP'

/********** NOTARY BID DATATABLE **********/

export const GET_ORDER_NOTARY_BID_DT = 'GET_ORDER_NOTARY_BID_DT'
export const GET_ORDER_NOTARY_BID_DT_OK = 'GET_ORDER_NOTARY_BID_DT_OK'
export const GET_ORDER_NOTARY_BID_DT_ERR = 'GET_ORDER_NOTARY_BID_DT_ERR'
export const DO_ORDER_NOTARY_BID_DT_CLEANUP = 'DO_ORDER_NOTARY_BID_DT_CLEANUP'

/********** DATATABLE **********/

export const GET_ORDER_NOTARY_DT = 'GET_ORDER_NOTARY_DT'
export const GET_ORDER_NOTARY_DT_OK = 'GET_ORDER_NOTARY_DT_OK'
export const GET_ORDER_NOTARY_DT_ERR = 'GET_ORDER_NOTARY_DT_ERR'
export const DO_ORDER_NOTARY_DT_CLEANUP = 'DO_ORDER_NOTARY_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_ORDER_NOTARY_DT_FILTERS = 'APPLY_ORDER_NOTARY_DT_FILTERS'
export const CLEAR_ORDER_NOTARY_DT_FILTERS = 'CLEAR_ORDER_NOTARY_DT_FILTERS'
export const PATCH_ORDER_NOTARY_DT_FILTERS = 'PATCH_ORDER_NOTARY_DT_FILTERS'
export const UNSET_ORDER_NOTARY_DT_FILTERS = 'UNSET_ORDER_NOTARY_DT_FILTERS'
export const DO_ORDER_NOTARY_DT_FILTERS_CLEANUP = 'DO_ORDER_NOTARY_DT_FILTERS_CLEANUP'

/********** NOTARY ORDERSDATATABLE **********/

export const GET_NOTARY_ORDER_DT = 'GET_NOTARY_ORDER_DT'
export const GET_NOTARY_ORDER_DT_OK = 'GET_NOTARY_ORDER_DT_OK'
export const GET_NOTARY_ORDER_DT_ERR = 'GET_NOTARY_ORDER_DT_ERR'
export const DO_NOTARY_ORDER_DT_CLEANUP = 'DO_NOTARY_ORDER_DT_CLEANUP'