import React, { useState } from "react";
import PropTypes from "prop-types";
import ViewAdditionalInfo from "../View/AdditionalInfo";
import FormEditAdditionalInfo from "../Form/Edit/AdditionalInfo";

const SectionAdditionalInfo = props => {

  const { id, refreshHandler, notary, isLoadInProgress } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      refreshHandler();
    }
  }

  return <React.Fragment>
    {isEditModeActive && <FormEditAdditionalInfo id={id} finishedHandler={toggleEditMode} notary={notary} />}
    {!isEditModeActive && <ViewAdditionalInfo id={id} toggleEditMode={toggleEditMode} notary={notary} isLoadInProgress={isLoadInProgress} />}
  </React.Fragment>
}

SectionAdditionalInfo.propTypes = {
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
  notary: PropTypes.object,
  isLoadInProgress: PropTypes.bool
};

export default SectionAdditionalInfo;
