import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap"
import Select from "react-select";
import { getTimezoneOptions, nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import UserRole from "model/userRole";
import useFirstRender from "hooks/firstRender";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { getDealerGroupRegions, getDealerGroups, getDealerStores, getUserRoles, updateUser } from "helpers/backendHelper";
import { perms, useAccess } from "context/access";

const FormInfoEdit = props => {

  const { defaultValues, id, finishedHandler } = props;

  // we are listening for changes on some fields
  // to load and show other dependent fields
  // however formik setting its initial values also triggers a change
  // we call that field initialization, which happens on the first render
  // in our listener we want to handle this particular case differently
  // and we can do that by checking this flag which is TRUE at the mount time then always FALSE
  const { isNotFirstRender } = useFirstRender();

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [roles, setRoles] = useState([]);
  const [rolesError, setRolesError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupsError, setGroupsError] = useState(null);
  const [regions, setRegions] = useState([]);
  const [regionsError, setRegionsError] = useState(null);
  const [stores, setStores] = useState([]);
  const [storesError, setStoresError] = useState(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    userRoleId: '',
    isActive: false,
    title: '',
    phone: '',
    timezone: '',
    dealerGroupId: '',
    dealerRegionId: '',
    dealerStoreId: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('Field is required'),
      lastName: Yup.string().trim().required('Field is required'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
      userRoleId: Yup.number().required('Field is required'),
      isActive: Yup.boolean(),
      title: Yup.string().trim(),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
        'Field requires exactly 10 digits',
        ((value) => phoneHasNoOfDigits(value))
      ),
      timezone: Yup.string().required('Field is required'),
      dealerGroupId: Yup.number()
        // make this field mandatory when the selected user role requires it
        .when('userRoleId', {
          is: value => roleRequiresGroup(parseInt(value)),
          then: Yup.number().required('Field is required')
        }),
      dealerRegionId: Yup.number()
        // make this field mandatory when the selected user role requires it
        .when('userRoleId', {
          is: value => roleRequiresRegion(value),
          then: Yup.number().required('Field is required')
        }),
      dealerStoreId: Yup.number()
        // make this field mandatory when the selected user role requires it
        .when('userRoleId', {
          is: value => roleRequiresStore(value),
          then: Yup.number().required('Field is required')
        }),
    }),
    onSubmit: values => saveUser(values, id),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the role field options
    getUserRoleList();
  }, []);

  // runs whenever 'rolesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (rolesError) {
      // set an error on the form field
      formik.setFieldError('userRoleId', 'Unable to load roles');
    }
  }, [rolesError]);

  // runs whenever 'groupsError' changes
  useEffect(() => {
    if (groupsError) {
      // set an error on the form field
      formik.setFieldError('dealerGroupId', 'Unable to load groups');
    }
  }, [groupsError]);

  // runs whenever 'regionsError' changes
  useEffect(() => {
    if (regionsError) {
      // set an error on the form field
      formik.setFieldError('dealerRegionId', 'Unable to load regions');
    }
  }, [regionsError]);

  // runs whenever 'storesError' changes
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError('dealerStoreId', 'Unable to load stores');
    }
  }, [storesError]);

  // runs whenever the role changes
  useEffect(() => {
    const roleId = formik.values.userRoleId;
    if (!roleRequiresRegion(roleId) && isNotFirstRender) {
      // clear the selected region if this role does not require the user to select a region
      // make sure we don't do that on the first render as it would clear any default value set by a parent component
      formik.setFieldValue('dealerRegionId', '');
    }
    if (roleRequiresGroup(roleId)) {
      // load the list of groups if this role requires the user to select a group
      // and if the list is not already loaded
      if (groups.length == 0) {
        getDealerGroupList();
      }
    } else if (isNotFirstRender) {
      // clear the selected group if this role does not require the user to select a group
      // make sure we don't do that on the first render as it would clear any default value set by a parent component
      formik.setFieldValue('dealerGroupId', '');
    }
    if (roleRequiresStore(roleId)) {
      // load the list of stores if this role requires the user to select a store
      // and if the list is not already loaded
      if (stores.length == 0) {
        getDealerStoreList();
      }
    } else if (isNotFirstRender) {
      // clear the selected store if this role does not require the user to select a store
      // make sure we don't do that on the first render as it would clear any default value set by a parent component
      formik.setFieldValue('dealerStoreId', '');
    }
  }, [formik.values.userRoleId]);

  // runs whenever the group changes
  useEffect(() => {
    // check if the 'group' field has been initialized (a.k.a. this is not the first render)
    // because if not, this effect might be triggered by the very initialization itself
    // and we do not want to clear the value of the 'region' field in that case
    // because it would clear any default value set by a parent component
    if (isNotFirstRender) {
      // the 'group' field has already been initialized
      // so let's clear the value of the 'region' field
      // otherwise even if a new list of regions is displayed
      // the field still holds the old value until the user makes a new selection
      formik.setFieldValue('dealerRegionId', '');
    }
    const groupId = formik.values.dealerGroupId;
    // if the selected group is not empty
    if (groupId) {
      getDealerGroupRegionList(groupId);
    }
  }, [formik.values.dealerGroupId]);

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const getUserRoleList = () => {
    getUserRoles()
      .then(response => {
        setRoles(response.userRoles);
      })
      .catch(ex => {
        setRolesError(ex);
      });
  }

  const getDealerGroupList = () => {
    getDealerGroups()
      .then(response => {
        setGroups(response.dealerGroups);
      })
      .catch(ex => {
        setGroupsError(ex);
      });
  }

  const getDealerGroupRegionList = groupId => {
    getDealerGroupRegions(groupId)
      .then(response => {
        setRegions(response.dealerRegions);
      })
      .catch(ex => {
        setRegionsError(ex);
      });
  }

  const getDealerStoreList = () => {
    getDealerStores()
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      });
  }

  const saveUser = (values, id) => {
    setIsSaveInProgress(true);
    updateUser(values, id)
      .then(response => {
        showSuccess(`User "${values.firstName} ${values.lastName}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save user');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  // prepares the list of roles to be used as select options
  const getRoleOptions = () => {
    // one can only choose from roles that are ranked below himself
    // notary is a special type of user and this role can be set only at creation
    const availableRoles = roles.filter(role => UserRole.isSameType(role.id, defaultValues.userRoleId) && iAmGranted(perms.create_user, { userRoleId: role.id }));
    return toSelectOptions(availableRoles);
  }

  // prepares the list of groups to be used as select options
  const getGroupOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(groups)];

  // prepares the list of regions to be used as select options
  const getRegionOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(regions)];

  // prepares the list of stores to be used as select options
  const getStoreOptions = () => [{ label: 'Choose', value: '' }, ...toSelectOptions(stores)];

  const roleRequiresGroup = roleId => UserRole.isDealerUpperManager(roleId);

  const roleRequiresRegion = roleId => UserRole.isDealerRegionalManager(roleId);

  const roleRequiresStore = roleId => UserRole.isDealerStoreUser(roleId);

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{defaultValues.fullName}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save user
              </Button>
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">First name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. John" name="firstName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.firstName} invalid={!!formik.errors.firstName} />
                  {!!formik.errors.firstName && <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Last name *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Doe" name="lastName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.lastName} invalid={!!formik.errors.lastName} />
                  {!!formik.errors.lastName && <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Title</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Manager" name="title" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.title} invalid={!!formik.errors.title} />
                  {!!formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Email *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
                  {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Phone *</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 097 568 3578" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
                  {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Timezone *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="timezone"
                    options={getTimezoneOptions()}
                    onChange={selected => formik.setFieldValue('timezone', selected.value)}
                    onFocus={e => onFieldFocused(e, 'timezone')}
                    value={getTimezoneOptions().find(option => option.value === formik.values.timezone)}
                    className={!!formik.errors.timezone && 'is-invalid'} />
                  {!!formik.errors.timezone && <FormFeedback type="invalid">{formik.errors.timezone}</FormFeedback>}
                </Col>
              </Row>
              {formik.values.isEmailVerified && <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Active</Label>
                <Col sm={9}>
                  <div className="form-check form-switch form-switch-lg mb-3">
                    <Input type="checkbox" className="form-check-input" id="activeSwitch" name="isActive" onChange={formik.handleChange} defaultChecked={formik.values.isActive} />
                    <Label className="form-check-label" htmlFor="activeSwitch" />
                  </div>
                </Col>
              </Row>}
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Role *</Label>
                <Col sm={9}>
                  {iAmNotGranted(perms.change_user_role, defaultValues) && <Input type="text" value={defaultValues.userRoleName} readOnly />}
                  {iAmGranted(perms.change_user_role, defaultValues) && <Select
                    classNamePrefix="select2-selection"
                    name="userRoleId"
                    disabled={iAmNotGranted(perms.change_user_role, { userRoleId: defaultValues.userRoleId })}
                    options={getRoleOptions()}
                    onChange={selected => formik.setFieldValue('userRoleId', selected.value)}
                    onFocus={e => onFieldFocused(e, 'userRoleId')}
                    value={getRoleOptions().find(option => option.value === formik.values.userRoleId)}
                    className={!!formik.errors.userRoleId && 'is-invalid'} />}
                  {!!formik.errors.userRoleId && <FormFeedback type="invalid">{formik.errors.userRoleId}</FormFeedback>}
                </Col>
              </Row>
              {roleRequiresGroup(formik.values.userRoleId) && <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Group *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="dealerGroupId"
                    options={getGroupOptions()}
                    onChange={selected => formik.setFieldValue('dealerGroupId', selected.value)}
                    onFocus={e => onFieldFocused(e, 'dealerGroupId')}
                    value={getGroupOptions().find(option => option.value === formik.values.dealerGroupId)}
                    className={!!formik.errors.dealerGroupId && 'is-invalid'} />
                  {!!formik.errors.dealerGroupId && <FormFeedback type="invalid">{formik.errors.dealerGroupId}</FormFeedback>}
                </Col>
              </Row>}
              {roleRequiresRegion(formik.values.userRoleId) > 0 && <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Region *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="dealerRegionId"
                    options={getRegionOptions()}
                    onChange={selected => formik.setFieldValue('dealerRegionId', selected.value)}
                    onFocus={e => onFieldFocused(e, 'dealerRegionId')}
                    value={getRegionOptions().find(option => option.value === formik.values.dealerRegionId) ?? 0}
                    className={!!formik.errors.dealerRegionId && 'is-invalid'} />
                  {!!formik.errors.dealerRegionId && <FormFeedback type="invalid">{formik.errors.dealerRegionId}</FormFeedback>}
                </Col>
              </Row>}
              {roleRequiresStore(formik.values.userRoleId) > 0 && <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Store *</Label>
                <Col sm={9}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="dealerStoreId"
                    options={getStoreOptions()}
                    onChange={selected => formik.setFieldValue('dealerStoreId', selected.value)}
                    onFocus={e => onFieldFocused(e, 'dealerStoreId')}
                    value={getStoreOptions().find(option => option.value === formik.values.dealerStoreId) ?? 0}
                    className={!!formik.errors.dealerStoreId && 'is-invalid'} />
                  {!!formik.errors.dealerStoreId && <FormFeedback type="invalid">{formik.errors.dealerStoreId}</FormFeedback>}
                </Col>
              </Row>}
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormInfoEdit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormInfoEdit;