export default class SupportCaseService {

  static VID = 1;
  static E_SIGN = 2;
  static INK_SIGN = 3;
  static SEAL = 4;
  static PLATFORM = 5;
  static BILLING = 6;

  static getServiceMap() {
    return {
      [this.VID]: 'VerifyID',
      [this.E_SIGN]: 'e-Signature',
      [this.INK_SIGN]: 'Wet signature',
      [this.SEAL]: 'Seal',
      [this.PLATFORM]: 'Platform',
      [this.BILLING]: 'Billing',
    };
  }

  static getServiceName(id) {
    return this.getServiceMap()[id];
  }
}