import React, { useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useSelector, useDispatch } from "react-redux";
import { dateFromTimestamp, formatTimestamp, formats, getMonthName } from "helpers/dateHelper";
import moment from "moment";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { Row, Col, Alert } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { doNewDealersReportCleanup, doNewDealersReportFiltersCleanup, getNewDealersReport } from "store/actions";
const DealerCountReport = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { dealers: rows, reportError: rowsError, isLoadInProgress, totalCount, listParams } = useSelector(state => state.Report.NewDealers);
  const filters = useSelector(state => state.Report.NewDealersFilters);
  // check if the start date and end date selected are in the same year
  // this is needed, because if the data spans over multiple year, we will display the year as well in the column headers
  const isSameYear = moment(dateFromTimestamp(filters.startTs)).year() === moment(dateFromTimestamp(filters.endTs)).year();

  // PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 },
    ],
  });

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doNewDealersReportCleanup());
      dispatch(doNewDealersReportFiltersCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getNewDealersReport({
      ...listParams,
      pageSize: newState.sizePerPage,
      page: newState.page,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return (
    <div className="mb-3 paginated-table-card">
      {isLoadInProgress && (
        <SpinnerChase className="sm mt-2 mb-2" />
      )}
      {!isLoadInProgress && !rowsError && !rows.length && <div className="mt-3">No dealers added in the selected period</div>}
      {!!rowsError && (
        <Alert color="danger" className="fade show text-center mb-0">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load data
        </Alert>
      )}
      {!isLoadInProgress && !!rows?.length && (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <Row>
                <Col>
                  <div className="table-responsive report-table-paginated dealer-sales-report-table">
                    <BootstrapTable
                      keyField='dealerStoreId'
                      columns={columns(isSameYear)}
                      data={rows}
                      noDataIndication={!rowsError && "No stores found"}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                  </div>
                  {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                  {!!rowsError && <Alert color="danger" className="fade show text-center">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load dealers
                  </Alert>}
                </Col>
              </Row>
              <Row className="align-items-md-center mt-3">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone
                      {...paginationProps}
                      variation="dropup"
                    />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </PaginationProvider>
      )}
    </div>
  )
}

const columns = (isSameYear) => {
  const columnsArray = [
    {
      dataField: 'month',
      text: 'Month',
      formatter: (cellContent, row) => {
        return getMonthName(cellContent, isSameYear);
      }
    }, {
      dataField: 'fullName',
      text: 'Dealer Name',
    }, {
      dataField: 'createdTs',
      text: 'Date Of Registration',
      formatter: cellContent => formatTimestamp(cellContent, formats.LONG_DATE),
    }, {
      dataField: 'dealerStoreName',
      text: 'Store Name',
    }, {
      dataField: 'dealerGroupName',
      text: 'Store Group',
    }, {
      dataField: 'city',
      text: 'City',
    }, {
      dataField: 'state',
      text: 'State',
    }, {
      dataField: 'address',
      text: 'Address',
    }, {
      dataField: 'phone',
      text: 'Phone Number',
    },];
  return columnsArray;
}

export default DealerCountReport;