import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { getStatement } from "helpers/backendHelper";
import SectionInfo from "./Partial/Section/Info";
import SectionAccountingNotes from "./Partial/Section/AccountingNotes";
import { route, routes } from "helpers/routeHelper";

const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [statement, setStatement] = useState(null);
  const [statementError, setStatementError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isReferenceSaved, setIsReferenceSaved] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshStatement();
  }, [id, isReferenceSaved]);

  /********** OTHER **********/

  const refreshStatement = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getStatement(id)
      .then(response => {
        setStatement(response.statement);
      })
      .catch(ex => {
        setStatementError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const handleReferenceSaved = (isSaved) => setIsReferenceSaved(isSaved);

  return <React.Fragment>
    {iAmGranted(perms.view_statements) && <div className="page-content">
      {statement && <React.Fragment>
        <MetaTitle>#{id} | Statements</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs} />
          <SectionAccountingNotes statement={statement} />
          <SectionInfo statement={statement} setReferenceSaved={handleReferenceSaved} refreshStatement={refreshStatement} />
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !statement && <Preloader className="inner" />}
      {statementError && <Error error={statementError} title404="Statement not found" />}
    </div>}
    {iAmNotGranted(perms.view_statements) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = [{
  title: "STATEMENT",
  url: route(routes.list_notary_statements),
}, {
  title: "DETAILS",
}];

export default View;
