import React, { useState } from "react";
import { Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import Order from "model/order";
import { getNotarizationRequired } from "../../../../../../helpers/utilHelper";

const StepDuringSigning = () => {

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);
  const [collectedCustomerPayment] = useState(order.notaries[0]?.agreements && order.notaries[0].agreements.collectedCustomerPayment);
  const [checkPaymentType] = useState(order.notaries[0]?.agreements && order.notaries[0].agreements.checkPaymentType);
  const [notaryCustomerCheckType] = useState(order.notaries[0]?.agreements && order.notaries[0].agreements.notaryCustomerCheckType);
  const [didYouCollect] = useState({
    dlCopy: order.notaries[0]?.agreements && order.notaries[0].agreements.dlCopy,
    secondIdCopy: order.notaries[0]?.agreements && order.notaries[0].agreements.secondIdCopy,
    insuranceCopy: order.notaries[0]?.agreements && order.notaries[0].agreements.insuranceCopy,
    proofOfIncome: order.notaries[0]?.agreements && order.notaries[0].agreements.proofOfIncome,
    proofOfResidence: order.notaries[0]?.agreements && order.notaries[0].agreements.proofOfResidence
  });

  return (
    <React.Fragment>
      <h6>Make sure you collected:</h6>
      <ul>
        {inkSignDocs?.map(doc => (
          <li key={doc.id}>{doc.name}</li>
        ))}
        {order.itemsToCollect?.map(doc => <li key={doc}>{Order.getItemToCollectName(doc)}</li>)}
      </ul>

      {order?.additionalItemsToCollect && <div className="mb-3 mt-4">
        <div className="divider-faded divider-sm" />
        <p className="collect-form-title mt-2">Additional Items to Collect:</p>
        <p>{order?.additionalItemsToCollect}</p>
      </div>}

      <div className="divider-faded divider-sm" />

      <div className="d-flex align-items-center section-title mb-4">
        <i className="bx bx-file font-size-20" />
        <h6 className="mb-0 ps-2">Contract Information:</h6>
      </div>
      <table className="table table-sm zebra-striped-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Requires Notarization</th>
            <th>Initials</th>
            <th>Signatures</th>
            <th>No of pages</th>
          </tr>
        </thead>
        <tbody>
          {inkSignDocs?.map((entry, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{entry.name}</td>
              <td>{getNotarizationRequired(entry.isNotarizationRequired)}</td>
              <td>{entry.numOfInitials}</td>
              <td>{entry.numOfSignatures}</td>
              <td>{entry.numOfPages}</td>
            </tr>
          ))}

          {!inkSignDocs?.length && (
            <tr>
              <td className="table-empty" colSpan="4">No contracts found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="mt-4 mb-4">
        <div className="form-check my-2 mt-4">
          <div>
            <Input
              type="checkbox"
              name="collectedCustomerPayment"
              id="collectedCustomerPayment"
              className="form-check-input-lg me-2"
              checked={collectedCustomerPayment}
              disabled
            />
            <Label htmlFor="collectedCustomerPayment">Did you collect Payment from the customer</Label>
          </div>
        </div>
        {collectedCustomerPayment && <>
          <div className="form-check-area">
            <p className="collect-form-title">Form of Payment</p>
            <div className="inline-container">
              <div>
                <Input
                  type="radio"
                  name="personal"
                  id="personal"
                  value="personal"
                  className="form-check-input me-2"
                  checked={checkPaymentType === 'personal'}
                  disabled
                />
                <Label htmlFor="personal">Personal Check</Label>
              </div>
              <div className="form-check-area">
                <Input
                  type="radio"
                  name="cashier"
                  id="cashier"
                  value="cashier"
                  className="form-check-input me-2"
                  checked={checkPaymentType === 'cashier'}
                  disabled
                />
                <Label htmlFor="cashier">{"Cashier's Check"}</Label>
              </div>
            </div>
            <p className="collect-form-title mt-2">The check was</p>
            <div className="inline-container">
              <div>
                <Input
                  type="radio"
                  name="checkSecureToDocuments"
                  value="checkSecure"
                  id="checkSecureToDocuments"
                  className="form-check-input me-2"
                  checked={notaryCustomerCheckType === 'checkSecure'}
                  disabled
                />
                <Label htmlFor="checkSecureToDocuments">Secured to documents</Label>
              </div>
              <div className="form-check-area">
                <Input
                  type="radio"
                  name="checkEnclosedInMailing"
                  value="checkEnclosed"
                  id="checkEnclosedInMailingEnvelope"
                  className="form-check-input me-2"
                  checked={notaryCustomerCheckType === 'checkEnclosed'}
                  disabled
                />
                <Label htmlFor="checkEnclosedInMailingEnvelope">Enclosed in a Mailing Envelope</Label>
              </div>
            </div>
          </div>
        </>}
      </div>

    </React.Fragment>
  )
};

export default StepDuringSigning;