import React, { useState } from "react";
import { Input, Button, InputGroup } from "reactstrap"
import HelpSidebarContent from "./HelpSidebarContent";

const HelpSidebar = () => {

  const [searchTerm, setSearchTerm] = useState("");

  const closeHelpSidebar = () => {
    var body = document.body;
    body.classList.toggle("help-sidebar-enable");
  }

  return (
    <React.Fragment>
      <div id="help_sidebar">
        <div className="d-flex justify-content-between align-items-center">
          <span className="help-title">Help</span>
          <Button type="button" color="none" className="float-end square-icon-btn" onClick={closeHelpSidebar}>
            <i className="mdi mdi-close" />
          </Button>
        </div>
        <InputGroup className="mt-3">
          <Button color="primary" className="help-search-btn">
            <i className="mdi mdi-magnify" />
          </Button>
          <Input
            type="search"
            className="form-control help-search-input"
            placeholder="Search ..."
            aria-label="Search in chat"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <HelpSidebarContent searchTerm={searchTerm} />
      </div>
    </React.Fragment>
  )
}

export default HelpSidebar;