import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Modal, TabContent, Nav, NavItem, NavLink, TabPane, Label } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import Col from "components/Shared/Col";
import PropTypes from 'prop-types';
import classnames from "classnames";
import { addNotaryRating } from 'store/actions';
import { isEmpty } from 'lodash';
import { perms, useAccess } from "context/access";
import { nullsToEmptyStrings, showSuccess, showError } from "helpers/utilHelper";
import { updateNotaryRating } from 'helpers/backendHelper';


const NotaryRating = ({isModalOpen, toggleModal, refreshMyRating}) => {

  const dispatch = useDispatch();
  const { iAmGranted, iAmNotGranted } = useAccess();

  const { myNotaryRating, mySecondNotaryRating, isLoadInProgress  } = useSelector(state => state.Notary.MyNotaryRating);
  const { order } = useSelector(state => state.Order.Single);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const selectedRating = customActiveTab === '1' ? myNotaryRating : mySecondNotaryRating;

  const isNotaryRated = !isEmpty(myNotaryRating);
  const isSecondNotaryRated = !isEmpty(mySecondNotaryRating);
  const hasComments = !!selectedRating?.positiveComments && !!selectedRating?.negativeComments;
  const starStyle = {};

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      tabId: null,
      timing: 0,
      quality: 0,
      communication: 0,
      responsiveness: 0,
      availability: 0,
      positiveComments: '',
      negativeComments: '',
      ...nullsToEmptyStrings(selectedRating)
    },
    validationSchema: Yup.object({
      timing: Yup.number().required().min(1),
      quality: Yup.number().required().min(1),
      responsiveness: Yup.number().required().min(1),
      availability: Yup.number().required().min(1),
      communication: Yup.number().required().min(1),
    }),
    onSubmit: (values, { resetForm }) => { addRating(values);  resetForm();}
  });

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      formik.setFieldValue('tabId', tab);
    }
  };

  const updateComments = () => {
    setIsSaveInProgress(true);
    updateNotaryRating(selectedRating.id, {...formik.values })
      .then(() => {
        showSuccess('Comments edited');
        refreshMyRating();
      })
      .catch(() => {
        showError('Unable to save comments');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const addRating = (values) => {
    if(customActiveTab === "1"){
      dispatch(addNotaryRating(order?.notaries[0]?.notaryId, { orderId: order.id,  ...values }))
    }
    else if(customActiveTab === "2"){
      dispatch(addNotaryRating(order?.notaries[1]?.notaryId, { orderId: order.id, ...values }))
    }

  }

  return (
    <>
      <Modal
        isOpen = { isModalOpen }
        toggle={ toggleModal }
        centered
        className="modal-size">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Rate Notary</h5>
          <button type="button" onClick={ toggleModal } className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">{order?.notaries[0]?.notaryFullName}</span>
            </NavLink>
          </NavItem>
            {order?.notaries[1]?.notaryFullName && <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
              <span className="d-block d-sm-none">
                <i className="far fa-user"></i>
              </span>
              <span className="d-none d-sm-block">{order?.notaries[1]?.notaryFullName}</span>
            </NavLink>
          </NavItem>}
        </Nav>
        <TabContent activeTab={customActiveTab} className="p-3">
          <TabPane tabId="1">
            <Row>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Timing</Label>
                <RatingTooltip
                  max={5}
                  name="timing"
                  defaultRating={myNotaryRating?.timing}
                  disabled={isNotaryRated}
                  onChange={rate => {
                    formik.setFieldValue('timing', rate);
                  }}
                  ActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-warning mb-2"
                      style={starStyle}
                    />
                  }
                  InActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-muted mb-2"
                      style={starStyle}
                    />
                  }
                />
              </Col>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Quality of work</Label>
                  <RatingTooltip
                    max={5}
                    name='quality'
                    defaultRating={myNotaryRating?.quality}
                    disabled={isNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('quality', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Communication</Label>
                  <RatingTooltip
                    max={5}
                    name='communication'
                    defaultRating={myNotaryRating?.communication}
                    disabled={isNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('communication', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Responsiveness</Label>
                  <RatingTooltip
                    max={5}
                    name="responsiveness"
                    defaultRating={myNotaryRating?.responsiveness}
                    disabled={isNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('responsiveness', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Availability</Label>
                  <RatingTooltip
                    max={5}
                    name='availability'
                    defaultRating={myNotaryRating?.availability}
                    disabled={isNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('availability', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Positive comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={myNotaryRating?.positiveComments}
                    readOnly={iAmGranted(perms.edit_notary_rating_comments) ? myNotaryRating?.positiveComments : isNotaryRated}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="positiveComments"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Negative comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={myNotaryRating?.negativeComments}
                    readOnly={iAmGranted(perms.edit_notary_rating_comments) ? myNotaryRating?.negativeComments : isNotaryRated}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="negativeComments"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Timing</Label>
                <RatingTooltip
                  max={5}
                  name="timing"
                  defaultRating={mySecondNotaryRating?.timing}
                  disabled={isSecondNotaryRated}
                  onChange={rate => {
                    formik.setFieldValue('timing', rate);
                  }}
                  ActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-warning mb-2"
                      style={starStyle}
                    />
                  }
                  InActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-muted mb-2"
                      style={starStyle}
                    />
                  }
                />
              </Col>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Quality of work</Label>
                  <RatingTooltip
                    max={5}
                    name='quality'
                    defaultRating={mySecondNotaryRating?.quality}
                    disabled={isSecondNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('quality', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Communication</Label>
                  <RatingTooltip
                    max={5}
                    name='communication'
                    defaultRating={mySecondNotaryRating?.communication}
                    disabled={isSecondNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('communication', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Responsiveness</Label>
                  <RatingTooltip
                    max={5}
                    name="responsiveness"
                    defaultRating={mySecondNotaryRating?.responsiveness}
                    disabled={isSecondNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('responsiveness', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Availability</Label>
                  <RatingTooltip
                    max={5}
                    name='availability'
                    defaultRating={mySecondNotaryRating?.availability}
                    disabled={isSecondNotaryRated}
                    onChange={rate => {
                      formik.setFieldValue('availability', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Positive comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={mySecondNotaryRating?.positiveComments}
                    readOnly={iAmGranted(perms.edit_notary_rating_comments) ? !!mySecondNotaryRating?.positiveComments : isSecondNotaryRated}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="positiveComments"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Negative comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={mySecondNotaryRating?.negativeComments}
                    readOnly={iAmGranted(perms.edit_notary_rating_comments) ? mySecondNotaryRating?.negativeComments : isSecondNotaryRated}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="negativeComments"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={ toggleModal } className="btn btn-secondary " data-dismiss="modal">Close</button>
          {iAmGranted(perms.edit_notary_rating_comments) && (!isLoadInProgress && ((!isNotaryRated && customActiveTab === "1") || (!isSecondNotaryRated && customActiveTab === "2")) ?
            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting}>Add Review<i className="mdi mdi-star-outline ms-1"></i></button> :
            <button type="button" className="btn btn-primary" onClick={updateComments} disabled={isSaveInProgress || hasComments}>Edit Review<i className="mdi mdi-star-outline ms-1"></i></button>
          )}
          {iAmNotGranted(perms.edit_notary_rating_comments) && !isLoadInProgress && ((!isNotaryRated && customActiveTab === "1") || (!isSecondNotaryRated && customActiveTab === "2")) &&
            <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting}>Add Review<i className="mdi mdi-star-outline ms-1"></i></button>}
        </div>
      </Modal>
    </>
  )
}

NotaryRating.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  refreshMyRating: PropTypes.func,
};

export default NotaryRating
