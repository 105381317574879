import {
  GET_AUTH_USER,
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  LOGOUT_USER_OK,
  IMPERSONATE_USER,
  IMPERSONATE_USER_OK,
  IMPERSONATE_USER_ERR,
} from "./actionTypes";

/********** LOGIN **********/

export const getAuthUser = () => ({
  type: GET_AUTH_USER,
});

export const getAuthUserOk = response => ({
  type: GET_AUTH_USER_OK,
  payload: { response },
});

export const getAuthUserErr = error => ({
  type: GET_AUTH_USER_ERR,
  payload: { error },
});

export const logoutUserOk = () => ({
  type: LOGOUT_USER_OK,
});

/********** IMPERSONATION **********/

export const impersonateUser = id => ({
  type: IMPERSONATE_USER,
  payload: { id },
});

export const impersonateUserOk = response => ({
  type: IMPERSONATE_USER_OK,
  payload: { response },
});

export const impersonateUserErr = error => ({
  type: IMPERSONATE_USER_ERR,
  payload: { error },
});