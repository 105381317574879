import { takeEvery, put, call } from "redux-saga/effects";

import {
  getNotaryDtOk,
  getNotaryDtErr,

  addNotaryRatingOk,
  addNotaryRatingErr,

  getNotaryRatingResultsOk,
  getSecondNotaryRatingResultsOk,
  getNotaryRatingResultsErr,

  getMyNotaryRatingOk,
  getMySecondNotaryRatingOk,
  getMyNotaryRatingErr
} from "./actions";

import {
  GET_NOTARY_DT,
  ADD_NOTARY_RATING,
  GET_NOTARY_RATING_RESULTS,
  GET_SECOND_NOTARY_RATING_RESULTS,
  GET_MY_NOTARY_RATING,
  GET_MY_SECOND_NOTARY_RATING
} from "./actionTypes";

import {
  getNotaryDt,
  addNotaryRating,
  getNotaryRating,
  getMyNotaryRating
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetNotaryDt({ payload: { params } }) {
  try {
    const response = yield call(getNotaryDt, params);
    yield put(getNotaryDtOk(response));
  } catch (error) {
    yield put(getNotaryDtErr(error));
  }
}

/********** RATING **********/

function* onAddNotaryRating({ payload: { data, id } }) {
  try {
    yield call(addNotaryRating, id, data);
    yield put(addNotaryRatingOk());
  } catch (error) {
    yield put(addNotaryRatingErr(error));
  }
}

/********** RATING RESULTS **********/

function* onGetNotaryRatingResults({ payload: { id } }) {
  try {
    const response = yield call(getNotaryRating, id);
    yield put(getNotaryRatingResultsOk(response));
  } catch (error) {
    yield put(getNotaryRatingResultsErr(error));
  }
}

function* onGetSecondNotaryRatingResults({ payload: { id } }) {
  try {
    const response = yield call(getNotaryRating, id);
    yield put(getSecondNotaryRatingResultsOk(response));
  } catch (error) {
    yield put(getNotaryRatingResultsErr(error));
  }
}

/********** MY NOTARY RATING **********/

function* onGetMyNotaryRating({ payload: { notaryId, orderId } }) {
  try {
    const response = yield call(getMyNotaryRating, notaryId, orderId);
    yield put(getMyNotaryRatingOk(response));
  } catch (error) {
    yield put(getMyNotaryRatingErr(error));
  }
}

function* onGetMySecondNotaryRating({ payload: { notaryId, orderId } }) {
  try {
    const response = yield call(getMyNotaryRating, notaryId, orderId);
    yield put(getMySecondNotaryRatingOk(response));
  } catch (error) {
    yield put(getMyNotaryRatingErr(error));
  }
}

function* notarySaga() {
  yield takeEvery(GET_NOTARY_DT, onGetNotaryDt);
  yield takeEvery(ADD_NOTARY_RATING, onAddNotaryRating);
  yield takeEvery(GET_NOTARY_RATING_RESULTS, onGetNotaryRatingResults);
  yield takeEvery(GET_SECOND_NOTARY_RATING_RESULTS, onGetSecondNotaryRatingResults);
  yield takeEvery(GET_MY_NOTARY_RATING, onGetMyNotaryRating);
  yield takeEvery(GET_MY_SECOND_NOTARY_RATING, onGetMySecondNotaryRating);
}

export default notarySaga;
