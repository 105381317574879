import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Modal, TabContent, Nav, NavItem, NavLink, TabPane, Label } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import Col from "components/Shared/Col";
import PropTypes from 'prop-types';
import { nullsToEmptyStrings, showError, showSuccess } from "helpers/utilHelper";
import { updateNotaryRating } from 'helpers/backendHelper';


const EditRating = (props) => {

  const { isModalOpen, toggleModal, defaultRating, notary, refreshReviews} = props;

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      timing: 0,
      quality: 0,
      communication: 0,
      responsiveness: 0,
      availability: 0,
      positiveComments: '',
      negativeComments: '',
      ...nullsToEmptyStrings(defaultRating),
    },
    validationSchema: Yup.object({
      timing: Yup.number().required().min(1),
      quality: Yup.number().required().min(1),
      responsiveness: Yup.number().required().min(1),
      availability: Yup.number().required().min(1),
      communication: Yup.number().required().min(1),
    }),
    onSubmit: (values) => { editRating(values);}
  });

  const starStyle = {};

  const editRating = (values) => {
    setIsSaveInProgress(true);
    updateNotaryRating(!!defaultRating && defaultRating.id, {...values })
      .then(() => {
        showSuccess('The new rating has been saved');
        refreshReviews();
      })
      .catch(() => {
        showError('Unable to save the new rating');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return (
    <>
      <Modal
        isOpen = { isModalOpen }
        toggle={ toggleModal }
        centered
        className="modal-size">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Rate Notary</h5>
          <button type="button" onClick={ toggleModal } className="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">{notary.userFullName}</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="p-3">
          <TabPane>
            <Row>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Timing</Label>
                <RatingTooltip
                  max={5}
                  name="timing"
                  defaultRating={defaultRating?.timing}
                  onChange={rate => {
                    formik.setFieldValue('timing', rate);
                  }}
                  ActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-warning mb-2"
                      style={starStyle}
                    />
                  }
                  InActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-muted mb-2"
                      style={starStyle}
                    />
                  }
                />
              </Col>
              <Col xxl="2" className="align-stars">
                <Label className="form-label mt-3 mb-0">Quality of work</Label>
                  <RatingTooltip
                    max={5}
                    name='quality'
                    defaultRating={defaultRating?.quality}
                    onChange={rate => {
                      formik.setFieldValue('quality', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Communication</Label>
                  <RatingTooltip
                    max={5}
                    name='communication'
                    defaultRating={defaultRating?.communication}
                    onChange={rate => {
                      formik.setFieldValue('communication', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Responsiveness</Label>
                  <RatingTooltip
                    max={5}
                    name="responsiveness"
                    defaultRating={defaultRating?.responsiveness}
                    onChange={rate => {
                      formik.setFieldValue('responsiveness', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
                <Col xxl="2" className="align-stars">
                  <Label className="form-label mt-3 mb-0">Availability</Label>
                  <RatingTooltip
                    max={5}
                    name='availability'
                    defaultRating={defaultRating?.availability}
                    onChange={rate => {
                      formik.setFieldValue('availability', rate);
                    }}
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Positive comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={defaultRating?.positiveComments}
                    readOnly={true}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="positiveComments"
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xxl="6">
                  <Label className="form-label mt-3 mb-2">Negative comments</Label>
                  <textarea
                    className="input-large form-control"
                    value={defaultRating?.negativeComments}
                    readOnly={true}
                    id="message"
                    rows="3"
                    placeholder="Enter a message ..."
                    name="negativeComments"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={ toggleModal } className="btn btn-secondary " data-dismiss="modal">Close</button>
          <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={isSaveInProgress || !formik.isValid}>Edit Review<i className="mdi mdi-star-outline ms-1"></i></button>
        </div>
      </Modal>
    </>
  )
}

EditRating.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  defaultRating: PropTypes.object,
  notary: PropTypes.object,
  refreshReviews: PropTypes.func,
};

export default EditRating;
