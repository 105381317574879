import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import logo from "assets/images/mavsign-logo-blue.svg";
import StoreInformation from "./StoreInformation";
import TransactionTable from "./TransactionTable";
import PaymentMethod from "./PaymentMethod";
import PaymentReceivedTable from "./PaymentsReceivedTable";
import DealerStore from "model/dealerStore";
import { showSuccess, showError, getBeUrl } from "../../../../../helpers/utilHelper";
import { sendStatementToCoupa, sendStatementToQb } from "../../../../../helpers/backendHelper";
import { useParams } from "react-router-dom";
import downloadDocumentIcon from 'assets/images/download-icon.svg';
import { perms, useAccess } from "../../../../../context/access";
import {
  STATEMENT_NOT_CLOSED,
  STATEMENT_ALREADY_INVOICED,
  STATEMENT_QB_CUSTOMER_ID_MISSING,
  STATEMENT_QB_INVOICE_ITEM_ID_MISSING,
  STATEMENT_QB_VENDOR_ID_MISSING,
  STATEMENT_PAYMENT_METHOD_INVALID_FOR_COUPA,
  COUPA_UNABLE_CREATE_INVOICE,
  STATEMENT_NOT_SENT_QB,
  QB_BILLING_EMAIL_MISSING
} from "helpers/errorHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import Statement from "model/statement";

const SectionInfo = ({ statement, setReferenceSaved, refreshStatement }) => {

  let { id } = useParams();
  id = parseInt(id);

  const hasTransactions = !!statement.lines.length;
  const hasPayments = !!statement.payments.length;
  const isCheckMethod = statement.paymentMethod === DealerStore.PAYMENT_METHOD_TYPE_CHECK;
  const isCreditCardMethod = statement.paymentMethod === DealerStore.PAYMENT_METHOD_TYPE_CARD;

  const { iAmGranted } = useAccess();

  /********** EVENT HANDLERS **********/

  const sendToQb = () => {
    sendStatementToQb(id)
      .then(() => {
        showSuccess(`Statement has been succesfully sent to QB`);
        refreshStatement();
      })
      .catch((err) => {
        if (err.code == STATEMENT_NOT_CLOSED) {
          showError("This statement has invalid time frame");
          return
        }
        if (err.code == STATEMENT_ALREADY_INVOICED) {
          showError("Statement already invoiced");
          return;
        }
        if (err.code == STATEMENT_QB_CUSTOMER_ID_MISSING) {
          showError("Dealership ID not found");
          return;
        }
        if (err.code == STATEMENT_QB_VENDOR_ID_MISSING) {
          showError("Notary ID  not found");
          return;
        }
        if (err.code == STATEMENT_QB_INVOICE_ITEM_ID_MISSING) {
          showError("Invoice item missing ID");
          return;
        }
        if (err.code == QB_BILLING_EMAIL_MISSING) {
          showError("Cannot send statement to QB without billing address");
          return;
        }
        showError("Unable to send statement to QB");
      })
  };

  const sendToCoupa = () => {
    sendStatementToCoupa(id)
      .then(() => {
        showSuccess(`Statement has been succesfully sent to Coupa`);
        refreshStatement();
      })
      .catch((err) => {
        if (err.code == STATEMENT_PAYMENT_METHOD_INVALID_FOR_COUPA) {
          showError("Payment method invalid for Coupa");
          return
        }
        if (err.code == STATEMENT_NOT_CLOSED) {
          showError("This statement has invalid time frame");
          return
        }
        if (err.code == STATEMENT_ALREADY_INVOICED) {
          showError("Statement already invoiced");
          return;
        }
        if (err.code == STATEMENT_NOT_SENT_QB) {
          showError("Statement was not sent to QB");
          return;
        }
        if (err.code == COUPA_UNABLE_CREATE_INVOICE) {
          showError("Unable to create Coupa invoice");
          return;
        }
        showError("Unable to send statement to Coupa");
      })
  };

  const downloadStatementPdf = () => {
    // for check payment method, can download pdf only if statement has reference
    if (isCheckMethod && (!hasPayments || !statement.payments[0].refId)) {
      showError("Please complete the Reference number before downloading the Statement");
      return;
    }
    window.open(getBeUrl(`statement/${statement.id}/download`), "_blank", "noreferrer");
  }

  const getStatementNotClosedText = (statement) => {
    switch (statement.type) {
      case Statement.TYPE_DEALER_COLLECTIVE_INSTORE:
        return "The statement can be sent to QB once the statement is completed - at the end of the month";
      case Statement.TYPE_DEALER_COLLECTIVE_REMOTE:
        return "The statement can be sent to QB once the statement is completed - at the end of the week"
      default:
        return "The statement can be sent to QB once the statement is completed"
    }
  }

  const getStatementInvoiceText = () => {
    if (!!isCreditCardMethod) {
      return 'Sales Receipt:';
    } else {
      return 'Invoice No:';
    }
  }

  return <Row>
    <Col lg="12">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col sm={6}>
              <img src={logo} alt="logo" height="30" />
            </Col>
            <Col sm={6} className="text-end">
              {statement.qbDocNo != null
                ? <span className="qb-id align-middle">
                  {getStatementInvoiceText()} {statement.qbDocNo}
                </span>
                : <>
                  {iAmGranted(perms.send_statements_QB) && !statement.isClosed && <div className="statement-not-closed-text">
                    {getStatementNotClosedText(statement)}
                  </div >}
                  {iAmGranted(perms.send_statements_QB) && statement.isClosed && <React.Fragment>
                    <span id="send-to-qb-btn" style={{ display: 'inline-block' }}>
                      <Button type="button" color="primary" onClick={sendToQb} disabled={!statement.canSendToQb} id="send-to-qb-btn">Send to QB</Button>
                    </span >
                    {!statement.canSendToQb && <UncontrolledTooltip placement="top" target="send-to-qb-btn">This statement has errors and cannot be sent to QuickBooks</UncontrolledTooltip>}
                  </React.Fragment >}
                </>
              }
              {isCheckMethod && !!statement.usesCoupa && !!statement.qbDocNo && <>
                {statement.coupaDocId != null
                  ? <span className="qb-id ms-5 align-middle">
                    <span>Coupa ID: {statement.coupaDocId}</span>
                    <div className="font-size-13">{formatTimestamp(statement.coupaDocCreatedTs, formats.LONG_DATE)}</div>
                  </span>
                  : iAmGranted(perms.send_statements_QB) && statement.isClosed && <React.Fragment>
                    <span id="send-to-qb-btn" className="ms-5">
                      <Button type="button" color="primary" onClick={sendToCoupa} disabled={!statement.canSendToCoupa} id="send-to-qb-btn">Send to Coupa</Button>
                    </span >
                    {!statement.canSendToCoupa && <UncontrolledTooltip placement="top" target="send-to-qb-btn">This statement has errors and cannot be sent to Coupa</UncontrolledTooltip>}
                  </React.Fragment >
                }
              </>}
            </Col>
          </Row >
          <hr />
          <Row className="mb-4">
            <Col sm={12} className="mb-4">
              <StoreInformation statement={statement} />
            </Col>

            {hasTransactions && <Col sm={12} className="mb-4">
              <TransactionTable statement={statement} />
            </Col>}

            <Col sm={12} className="mb-4">
              <PaymentMethod paymentMethod={statement.paymentMethod} paymentRef={statement.paymentRefId} setReferenceSaved={setReferenceSaved} />
            </Col>

            {hasPayments && <Col sm={12} className="mb-4">
              <PaymentReceivedTable statement={statement} />
            </Col>}
          </Row>
          <Button type="button" color="primary" className="float-end" onClick={downloadStatementPdf}>
            <span>Download</span>
            <img className='ps-2' src={downloadDocumentIcon} />
          </Button>
        </CardBody >
      </Card >
    </Col >
  </Row >
}

SectionInfo.propTypes = {
  statement: PropTypes.object,
  setReferenceSaved: PropTypes.func,
  refreshStatement: PropTypes.func
};

export default SectionInfo;