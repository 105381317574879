import {
  GET_NOTARY_STATEMENT_DT,
  GET_NOTARY_STATEMENT_DT_OK,
  GET_NOTARY_STATEMENT_DT_ERR,
  DO_NOTARY_STATEMENT_DT_CLEANUP,
  APPLY_NOTARY_STATEMENT_DT_FILTERS,
  PATCH_NOTARY_STATEMENT_DT_FILTERS,
  CLEAR_NOTARY_STATEMENT_DT_FILTERS,
  UNSET_NOTARY_STATEMENT_DT_FILTERS,
} from "./actionTypes";

/********** DATATABLE **********/

export const getNotaryStatementDt = params => ({
  type: GET_NOTARY_STATEMENT_DT,
  payload: { params },
});

export const getNotaryStatementDtOk = response => ({
  type: GET_NOTARY_STATEMENT_DT_OK,
  payload: { response },
});

export const getNotaryStatementDtErr = error => ({
  type: GET_NOTARY_STATEMENT_DT_ERR,
  payload: { error },
});

export const doNotaryStatementDtCleanup = () => ({
  type: DO_NOTARY_STATEMENT_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyNotaryStatementDtFilters = filters => ({
  type: APPLY_NOTARY_STATEMENT_DT_FILTERS,
  payload: { filters },
});

export const patchNotaryStatementDtFilters = filters => ({
  type: PATCH_NOTARY_STATEMENT_DT_FILTERS,
  payload: { filters },
});

export const clearNotaryStatementDtFilters = () => ({
  type: CLEAR_NOTARY_STATEMENT_DT_FILTERS,
});

export const unsetNotaryStatementDtFilters = () => ({
  type: UNSET_NOTARY_STATEMENT_DT_FILTERS,
});