import { Table } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import { formats, formatTimestamp } from "helpers/dateHelper";
import Statement from "model/statement";

const getTotalPrice = payments => payments.reduce((accum, val) => accum + Number(val.amount), 0);

const PaymentReceivedTable = ({ statement }) => {

  const isCollectiveStatement = Statement.isCollectiveType(statement.type);

  return <React.Fragment>
    {statement && <React.Fragment>
      <h3 className="font-size-15 fw-bold">{statement.payments.length > 1 ? "Payments" : "Payment"} Received</h3>
      <div className="table-responsive mb-3">
        <Table className="table-nowrap">
          <thead>
            <tr>
              <th className="ps-0">Date</th>
              {isCollectiveStatement && <th className="ps-0">Order ID</th>}
              <th>Payment Description</th>
              <th className="text-end">Amount ($)</th>
            </tr>
          </thead>
          <tbody>
            {statement.payments.map((payment, index) => <tr key={index}>
              <td className="ps-0">{formatTimestamp(payment.updatedTs, formats.SHORT_DATE_FORMAT)}</td>
              {isCollectiveStatement && <td>{payment.orderId}</td>}
              <td>{payment.refId || "--"}</td>
              <td className="text-end">{`$${payment.amount}`}</td>
            </tr>)}
          </tbody>
        </Table>
      </div>
      <div className="mb-2">
        <div className="total-price-wrapper d-flex justify-content-between align-items-center">
          <div><strong>Total Price</strong></div>
          <h4 className="m-0">${getTotalPrice(statement.payments)}</h4>
        </div>
      </div>
    </React.Fragment>}
  </React.Fragment>
}

PaymentReceivedTable.propTypes = {
  statement: PropTypes.object,
};

export default PaymentReceivedTable;