import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Row, Col, Button, CardHeader, Form, Label, Input, FormFeedback } from "reactstrap"
import { showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { route, routes } from "helpers/routeHelper";
import { createDealerRegion } from "helpers/backendHelper";

const FormInfoNew = props => {

  const { defaultValues } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  let formInitialValues = {
    name: '',
    dealerGroupId: '',
  };
  formInitialValues = {
    ...formInitialValues,
    dealerGroupId: defaultValues.id
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Field is required'),
      dealerGroupId: Yup.number().required('Field is required'),
    }),
    onSubmit: values => saveDealerRegion(values),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerRegion = values => {
    setIsSaveInProgress(true);
    createDealerRegion(values)
      .then(response => {
        showSuccess(`Dealer region "${values.name}" has been saved`);
        navigate(route(routes.view_dealer_group, defaultValues.id));
      })
      .catch(ex => {
        showError('Unable to save dealer region');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save region
              </Button>
              <Link to={route(routes.view_dealer_group, defaultValues.id)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Name</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. North-east" name="name" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.name} invalid={!!formik.errors.name} />
                  {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Group</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" value={defaultValues ? defaultValues.name : '--'} readOnly />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormInfoNew.propTypes = {
  defaultValues: PropTypes.object,
};

export default FormInfoNew;