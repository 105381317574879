import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Button, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { doNotaryOrderDtCleanup, getNotaryOrderDt } from "store/actions";
import { getDtFooterRowCount, getSharedPaginationOptions, getGranularStatusName, getVIDStatusImage } from "helpers/utilHelper";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link } from "react-router-dom";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import coSigners from 'assets/images/co-signer-icon.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import noThumbprintIcon from 'assets/images/no-thumbprint-icon.svg';
import Order from 'model/order';

const NotaryOrder = {
  inProgress: 'in-progress',
  completed: 'completed',
  cancelled: 'cancelled'
}

const SectionOrders = ({ notary }) => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { orders: rows, ordersError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Order.NotaryOrderDt);

  // Holds the toggled filter
  const [toggleFilter, setToggleFilter] = useState(NotaryOrder.inProgress);
  // PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ],
  });

  const ordersFilters = {
    notaryId: notary.id,
    orderStatus: toggleFilter,
  }

  /********* EFFECTS *********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getNotaryOrderDt(notary.id, {
      ...listParams,
      filters: ordersFilters,
      page: 1,
      pageSize: 10,
    }));
    return () => {
      // state cleanup on component unmount
      dispatch(doNotaryOrderDtCleanup());
    }
  }, [notary.id, toggleFilter]);

  // runs whenever "totalCount" changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let"s update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getNotaryOrderDt(notary.id, {
      ...listParams,
      pageSize: newState.sizePerPage,
      page: newState.page === 0 ? newState.page + 1 : newState.page,
      filters: ordersFilters,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  // refresh the list manually
  const refreshList = () => {
    dispatch(getNotaryOrderDt(notary.id, {
      ...listParams,
      filters: ordersFilters,
      page: 1,
      pageSize: 10,
    }));
  };

  return (<Card className="paginated-table-card">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Orders</CardTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            className="btn-primary mb-2"
            onClick={refreshList}
          >
            <i className="mdi mdi-refresh me-1" />
            Refresh list
          </Button>
        </Col>
        <Col xs={"auto"} >
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex">
              <Button
                color="primary"
                className={classnames("btn btn-sm me-2", { "active bt-primary": toggleFilter === NotaryOrder.inProgress, "btn-light": toggleFilter !== NotaryOrder.inProgress })}
                onClick={() => setToggleFilter(NotaryOrder.inProgress)}
              >
                in progress
              </Button>
              <Button
                color="primary"
                className={classnames("btn btn-sm me-2", { "active bt-primary": toggleFilter === NotaryOrder.completed, "btn-light": toggleFilter !== NotaryOrder.completed })}
                onClick={() => setToggleFilter(NotaryOrder.completed)}
              >
                completed
              </Button>
              <Button
                color="primary"
                className={classnames("btn btn-sm me-2", { "active bg-primary": toggleFilter === NotaryOrder.cancelled, "btn-light": toggleFilter !== NotaryOrder.cancelled })}
                onClick={() => setToggleFilter(NotaryOrder.cancelled)}
              >
                cancelled
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row>
        {(
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <Row>
                  <Col>
                    <div className="table-responsive">
                      <BootstrapTable
                        bordered={false}
                        striped={false}
                        keyField='id'
                        columns={columns()}
                        data={rows}
                        noDataIndication={!rowsError && "No orders found"}
                        onTableChange={handleTableChange}
                        remote={true}
                        {...paginationTableProps}
                      />
                    </div>
                    {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                    {!!rowsError && <div className="text-center">Unable to load orders</div>}
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-3">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                        variation="dropup"
                      />
                      <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, paginationProps.sizePerPage, totalCount)}</div>
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </PaginationProvider>
        )}
      </Row>
    </CardBody>
  </Card>)
}

const columns = () => ([{
  dataField: 'id',
  text: 'Signing ID',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_order, row.id)}>{cellContent}</Link>,
}, {
  dataField: 'orderSignerFullName',
  text: 'Customer Name',
  sort: false,
}, {
  dataField: 'dummy3',
  text: 'Services included',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <div>
    {row.isVidRequired && getVIDStatusImage(row.vidStatus, row)}
    {row.isEsignRequired && <><img id={`e-sign${row.id}`} src={eSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`e-sign${row.id}`}>E sign</UncontrolledTooltip></>}
    {row.isInkSignRequired && <><img id={`ink-sign${row.id}`} src={inkSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`ink-sign${row.id}`}>Ink sign</UncontrolledTooltip></>}
    {row.hasAdditionalSigner && <><img id={`co-signers${row.id}`} src={coSigners} className="me-2" /><UncontrolledTooltip placement="top" target={`co-signers${row.id}`}>Co-signers</UncontrolledTooltip></>}
    {row.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
      <><img id={`doc-delivery-shipping${row.id}`} src={docDeliveryShippingIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping${row.id}`}>Documents shipping</UncontrolledTooltip></>
    }{row.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
      <><img id={`doc-delivery-upload${row.id}`} src={docDeliveryUploadIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload${row.id}`}>Documents upload</UncontrolledTooltip></>
    }
    {row.isRushRequired && <><img id={`rush-order${row.id}`} src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`rush-order${row.id}`}>Rush order</UncontrolledTooltip></>}
    {row.notaryMustUploadDocs && <><img id={`must-upload-docs${row.id}`} src={mustUploadDocsIcon} className="me-2 must-upload-icon" /><UncontrolledTooltip placement="top" target={`must-upload-docs${row.id}`}>Notary must upload docs</UncontrolledTooltip></>}
    {!row.isAoiThumbprintRequired && row.isNotaryRequired && <><img id={`no-thumbprint-aoi${row.id}`} src={noThumbprintIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`no-thumbprint-aoi${row.id}`}>No Thumbprint AOI</UncontrolledTooltip></>}
  </div>,
}, {
  dataField: 'orderNotaryAssignedTs',
  text: 'Date Assigned',
  sort: false,
  formatter: (cellContent, row) => cellContent ? formatTimestamp(cellContent, formats.DATETIME) : '--',
}, {
  dataField: 'granularStatusSortOrder',
  text: 'Status',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: (_cellContent, row) => {
    const color = Order.getGranularStatusColor(row.granularStatus);
    return <span className={`badge badge-lg w-100 rounded-pill bg-${color}`}>{getGranularStatusName(row.granularStatus)}</span>;
  },
}, {
  dataField: 'dealerStoreName',
  text: 'Dealership',
  sort: false,
}, {
  dataField: 'dealerFullName',
  text: 'Creator',
  sort: false,
}]);


SectionOrders.propTypes = {
  notary: PropTypes.object,
};

export default SectionOrders;