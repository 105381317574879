import React, { useState } from "react";
import PropTypes from "prop-types"
import Select from "react-select";
import { Button } from "reactstrap";
import UserAvatar from "components/Shared/UserAvatar";
import { getInitialsFromName } from "helpers/utilHelper";

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 180,
    margin: 8,
  }),
  menu: provided => ({
    ...provided,
    borderRadius: "0px 0px 4px 4px",
  }),
};

const SelectButton = ({ value: selected, onChange, options, showAvatar, hasError, isLoading, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          color="light"
          className="select-btn"
          onClick={() => setIsOpen(current => !current)}
          active={isOpen}
          size="sm"
        >
          {showAvatar && !!selected && <UserAvatar id={selected.value} initials={getInitialsFromName(selected.label)} size="sm me-2" />}
          {selected ? selected.label : "Unassigned"}
          {isLoading ? <i className="mdi mdi-spin mdi-loading ms-2" /> : <i className="bx bxs-down-arrow ms-2" />}
        </Button>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={selected => {
          setIsOpen(false);
          onChange(selected);
        }}
        options={options}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        value={selected}
        isLoading={isLoading}
        {...rest}
        // eslint-disable-next-line react/display-name
        {...hasError && { noOptionsMessage: () => <div className="text-danger">Unable to load options</div> }}
      />
    </Dropdown>
  );
};

SelectButton.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  showAvatar: PropTypes.bool,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const Menu = props => {
  return (
    <div
      className="select-menu"
      {...props}
    />
  );
};

const Blanket = props => (
  <div
    className="select-blanket"
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="position-relative d-inline">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  target: PropTypes.object,
  onClose: PropTypes.func,
}

export default SelectButton;
