export default class DealerGroup {

  static SPECIAL_INTSTRUCTIONS_APPLY_TO_ADMIN = 1;
  static SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER = 2;
  static SPECIAL_INTSTRUCTIONS_APPLY_TO_NOTARY = 3;

  static getSpecialInstructionsTargets() {
    return {
      [this.SPECIAL_INTSTRUCTIONS_APPLY_TO_ADMIN]: 'Admin',
      [this.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER]: 'Dealer',
      [this.SPECIAL_INTSTRUCTIONS_APPLY_TO_NOTARY]: 'Notary',
    }
  }

  static getSpecialInstructionsTargetsName(id) {
    return this.getSpecialInstructionsTargets()[id];
  }
}