import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { toSelectOptions, hasNonEmpty, isNotAllOrNone, dictionaryToSelectOptions } from "helpers/utilHelper";
import { applyDealerStoreDtFilters, clearDealerStoreDtFilters, doDealerGroupListCleanup, doDealerRegionListCleanup, doPaymentPlanListCleanup, getDealerGroupList, getDealerGroupRegionList, getPaymentPlanList } from 'store/actions';
import DealerStore from 'model/dealerStore';
import UsStates from "constants/usState";

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.DealerStore.DtFilters);
  const { groups, groupsError } = useSelector(state => state.DealerGroup.List);
  const { regions, regionsError } = useSelector(state => state.DealerRegion.List);
  const { paymentPlans, paymentPlansError } = useSelector(state => state.PaymentPlan.List);
  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the group filter options
    dispatch(getDealerGroupList());
    dispatch(getPaymentPlanList());
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerGroupListCleanup());
      dispatch(doDealerRegionListCleanup());
      dispatch(doPaymentPlanListCleanup());
    }
  }, []);

  // runs whenever 'groupsError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (groupsError) {
      // set an error on the form field
      formik.setFieldError('group', 'Unable to load groups');
    }
  }, [groupsError]);

  // runs whenever 'regionsError' changes
  // which may happen after each group change
  // which in turn triggers a 'get-regions' remote call
  useEffect(() => {
    if (regionsError) {
      // set an error on the form field
      formik.setFieldError('region', 'Unable to load regions');
    }
  }, [regionsError]);

  // runs whenever 'paymentPlansError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (paymentPlansError) {
      // set an error on the form field
      formik.setFieldError('paymentPlan', 'Unable to load payment plans');
    }
  }, [paymentPlansError]);


  // runs whenever the group changes
  useEffect(() => {
    // clear the value of the region field
    formik.setFieldValue('region', '');
    const groupId = formik.values.group;
    // if the selected group is not All or None
    if (isNotAllOrNone(groupId)) {
      dispatch(getDealerGroupRegionList(groupId));
    } else {
      dispatch(doDealerRegionListCleanup());
    }
  }, [formik.values.group]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyDealerStoreDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearDealerStoreDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const hasFilters = () => hasNonEmpty(filters);

  // adds 'All' to the list of groups
  // prepares the list to be used as select options
  const getGroupOptions = () => [{ label: 'All', value: '' }, { label: 'None', value: -1 }, ...toSelectOptions(groups)];

  // adds 'All' to the list of regions
  // prepares the list to be used as select options
  const getRegionOptions = () => [{ label: 'All', value: '' }, { label: 'None', value: -1 }, ...toSelectOptions(regions)];

  // adds 'All' to the list of payment plans
  // prepares the list to be used as select options
  const getPaymentPlanOptions = () => [{ label: 'All', value: '' }, ...toSelectOptions(paymentPlans)];

  // adds 'All' to the list of states
  // prepares the list to be used as select options
  const getStateOptions = () => [{ label: 'All', value: '' }, ...toSelectOptions(UsStates),];


  // prepares the list to be used as select options
  const getPaymentSetupStatusOptions = () => [{ label: 'All', value: '' }, ...dictionaryToSelectOptions(DealerStore.getPaymentSetupStatusMap())];

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Name</Label>
            <Input type="text" className="form-control" name="name" onChange={formik.handleChange} value={formik.values.name} invalid={!!formik.errors.name} />
            {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Group</Label>
            <Select
              classNamePrefix="select2-selection"
              name="group"
              options={getGroupOptions()}
              onChange={selected => formik.setFieldValue('group', selected.value)}
              value={getGroupOptions().find(option => option.value === formik.values.group)}
              className={!!formik.errors.group && 'is-invalid'} />
            {!!formik.errors.group && <FormFeedback type="invalid">{formik.errors.group}</FormFeedback>}
          </div>
          {regions.length > 0 && <div className="mb-3">
            <Label>Region</Label>
            <Select
              classNamePrefix="select2-selection"
              name="region"
              options={getRegionOptions()}
              onChange={selected => formik.setFieldValue('region', selected.value)}
              value={getRegionOptions().find(option => option.value === formik.values.region)}
              className={!!formik.errors.region && 'is-invalid'} />
            {!!formik.errors.region && <FormFeedback type="invalid">{formik.errors.region}</FormFeedback>}
          </div>}
          <div className="mb-3">
            <Label>Payment Plan</Label>
            <Select
              classNamePrefix="select2-selection"
              name="paymentPlan"
              options={getPaymentPlanOptions()}
              onChange={selected => formik.setFieldValue('paymentPlan', selected.value)}
              value={getPaymentPlanOptions().find(option => option.value === formik.values.paymentPlan)}
              className={!!formik.errors.paymentPlan && 'is-invalid'} />
            {!!formik.errors.paymentPlan && <FormFeedback type="invalid">{formik.errors.paymentPlan}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Payment Setup Status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="paymentSetupStatus"
              options={getPaymentSetupStatusOptions()}
              onChange={selected => formik.setFieldValue('paymentSetupStatus', selected.value)}
              value={getPaymentSetupStatusOptions().find(option => option.value === formik.values.paymentSetupStatus)}
              className={!!formik.errors.paymentSetupStatus && 'is-invalid'} />
            {!!formik.errors.paymentSetupStatus && <FormFeedback type="invalid">{formik.errors.paymentSetupStatus}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>State</Label>
            <Select
              classNamePrefix="select2-selection"
              name="state"
              options={getStateOptions()}
              onChange={selected => formik.setFieldValue('state', selected.value)}
              value={getStateOptions().find(option => option.value === formik.values.state)}
              className={!!formik.errors.state && 'is-invalid'} />
            {!!formik.errors.state && <FormFeedback type="invalid">{formik.errors.state}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;