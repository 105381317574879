import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, Input, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { useFormik } from "formik";
import { toSelectOptions, hasNonEmpty, getYesNoOptions, extractDtFiltersFromUrl } from "helpers/utilHelper";
import { applyUserDtFilters, clearUserDtFilters, patchUserDtFilters, unsetUserDtFilters } from 'store/actions';
import { isEmpty, omit } from 'lodash';
import { getDealerGroups, getDealerStores, getUserRoles } from 'helpers/backendHelper';
import User from 'model/user';

const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.User.DtFilters);

  const [roles, setRoles] = useState([]);
  const [rolesError, setRolesError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupsError, setGroupsError] = useState(null);
  const [stores, setStores] = useState([]);
  const [storesError, setStoresError] = useState(null);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchUserDtFilters(filters));
    }

    // make the initial remote call to get the role filter options
    getUserRoleList();
    getDealerGroupList();
    getDealerStoreList();
    return () => {
      // switch back the '_set' flag
      dispatch(unsetUserDtFilters());
    }
  }, []);

  // runs whenever 'rolesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (rolesError) {
      // set an error on the form field
      formik.setFieldError('role', 'Unable to load roles');
    }
  }, [rolesError]);

  // runs whenever 'groupsError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (groupsError) {
      // set an error on the form field
      formik.setFieldError('group', 'Unable to load groups');
    }
  }, [groupsError]);

  // runs whenever 'storesError' changes
  // which may happen after the first remote call
  useEffect(() => {
    if (storesError) {
      // set an error on the form field
      formik.setFieldError('store', 'Unable to load stores');
    }
  }, [storesError]);

  /********** EVENT HANDLERS **********/

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyUserDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues(formik.initialValues);
    // reset state
    dispatch(clearUserDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const getUserRoleList = () => {
    getUserRoles()
      .then(response => {
        setRoles(response.userRoles);
      })
      .catch(ex => {
        setRolesError(ex);
      });
  }

  const getDealerGroupList = () => {
    getDealerGroups()
      .then(response => {
        setGroups(response.dealerGroups);
      })
      .catch(ex => {
        setGroupsError(ex);
      });
  }

  const getDealerStoreList = () => {
    getDealerStores()
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      });
  }

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  // adds 'All' to the list of roles
  // prepares the list to be used as select options
  const getRoleOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(roles)];
  }

  // adds 'All' to the list of groups
  // prepares the list to be used as select options
  const getGroupOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(groups)];
  }

  // adds 'All' to the list of stores
  // prepares the list to be used as select options
  const getStoreOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(stores)];
  }

  const getActivityStatusOptions = () => {
    return [
      { label: 'All', value: '' },
      { label: 'Active', value: User.ACTIVITY_STATUS_ACTIVE },
      { label: 'Idle', value: User.ACTIVITY_STATUS_IDLE },
      { label: 'Offline', value: User.ACTIVITY_STATUS_OFFLINE },
    ];
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Name</Label>
            <Input type="text" className="form-control" name="name" onChange={formik.handleChange} value={formik.values.name} invalid={!!formik.errors.name} />
            {!!formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Email</Label>
            <Input type="text" className="form-control" name="email" onChange={formik.handleChange} value={formik.values.email} invalid={!!formik.errors.email} />
            {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Role</Label>
            <Select
              classNamePrefix="select2-selection"
              name="role"
              options={getRoleOptions()}
              onChange={selected => formik.setFieldValue('role', selected.value)}
              value={getRoleOptions().find(option => option.value === formik.values.role)}
              className={!!formik.errors.role && 'is-invalid'} />
            {!!formik.errors.role && <FormFeedback type="invalid">{formik.errors.role}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Group</Label>
            <Select
              classNamePrefix="select2-selection"
              name="group"
              options={getGroupOptions()}
              onChange={selected => formik.setFieldValue('group', selected.value)}
              value={getGroupOptions().find(option => option.value === formik.values.group)}
              className={!!formik.errors.group && 'is-invalid'} />
            {!!formik.errors.group && <FormFeedback type="invalid">{formik.errors.group}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Store</Label>
            <Select
              classNamePrefix="select2-selection"
              name="store"
              options={getStoreOptions()}
              onChange={selected => formik.setFieldValue('store', selected.value)}
              value={getStoreOptions().find(option => option.value === formik.values.store)}
              className={!!formik.errors.store && 'is-invalid'} />
            {!!formik.errors.store && <FormFeedback type="invalid">{formik.errors.store}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <Select
              classNamePrefix="select2-selection"
              name="isActive"
              options={getYesNoOptions(true)}
              onChange={selected => formik.setFieldValue('isActive', selected.value)}
              value={getYesNoOptions(true).find(option => option.value === formik.values.isActive)}
              className={!!formik.errors.isActive && 'is-invalid'} />
            {!!formik.errors.isActive && <FormFeedback type="invalid">{formik.errors.isActive}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Activated</Label>
            <Select
              classNamePrefix="select2-selection"
              name="isActivated"
              options={getYesNoOptions(true)}
              onChange={selected => formik.setFieldValue('isActivated', selected.value)}
              value={getYesNoOptions(true).find(option => option.value === formik.values.isActivated)}
              className={!!formik.errors.isActivated && 'is-invalid'} />
            {!!formik.errors.isActivated && <FormFeedback type="invalid">{formik.errors.isActivated}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Activity Status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="activityStatus"
              options={getActivityStatusOptions()}
              onChange={selected => formik.setFieldValue('activityStatus', selected.value)}
              value={getActivityStatusOptions().find(option => option.value === formik.values.activityStatus)}
              className={!!formik.errors.activityStatus && 'is-invalid'} />
            {!!formik.errors.activityStatus && <FormFeedback type="invalid">{formik.errors.activityStatus}</FormFeedback>}
          </div>
          <div className="mb-3">
            <Label>Bounce</Label>
            <Select
              classNamePrefix="select2-selection"
              name="hasEmailAlert"
              options={getYesNoOptions(true)}
              onChange={selected => formik.setFieldValue('hasEmailAlert', selected.value)}
              value={getYesNoOptions(true).find(option => option.value === formik.values.hasEmailAlert)}
              className={!!formik.errors.hasEmailAlert && 'is-invalid'} />
            {!!formik.errors.hasEmailAlert && <FormFeedback type="invalid">{formik.errors.hasEmailAlert}</FormFeedback>}
          </div>
          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;