import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBiddingDt, doBiddingDtCleanup } from "store/actions";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import manualSearchIcon from "assets/images/white-search-icon.svg";
import viewBiddingIcon from "assets/images/white-view-icon.svg";
import assignNotaryIcon from "assets/images/notary-service-white-icon.svg";

const Biddings = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { biddings, isLoadInProgress, biddingsError } = useSelector(state => state.Bidding.Dt);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get biddings
    dispatch(getBiddingDt({ pageSize: 6, sortBy: "id", sortDir: "desc" }));
    return () => {
      // state cleanup on component unmount
      dispatch(doBiddingDtCleanup());
    }
  }, []);

  return <>
    <Card className="subscriptions-card">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Biddings</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Customer name</th>
              <th>Customer address</th>
              <th>Rush order</th>
              <th>Bidding status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {biddings.length > 0 && biddings.map((entry, index) => (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>
                  <Link to={route(routes.view_order, entry.id)}>{entry.id}</Link>
                </td>
                <td>{entry.customerName}</td>
                <td>{entry.customerAddress}</td>
                <td>{entry.isRushRequired ? <span>YES</span> : <span>NO</span>}</td>
                <td>{entry.biddingStatus}</td>
                <td>
                  {entry.biddingStatus === 'No results' ? <Link to={route(routes.view_order_notary_manual_search, entry.id)}>
                    <span className={`badge badge-lg bg-primary bg-primary`}>
                      <><img className="me-2" src={manualSearchIcon} />Manual search</>
                    </span>
                  </Link> : <Link to={route(routes.view_order_notary, entry.id)}>
                    <div className={`badge badge-lg bg-primary bg-primary`}> {entry.biddingStatus === 'In progress' ?
                      <><img className="me-2" src={viewBiddingIcon} />View bidding</> :
                      <><img className="me-2" src={assignNotaryIcon} />Assign Notary</>}
                    </div>
                  </Link>}
                </td>
              </tr>
            ))}
            {biddings.length === 0 && !biddingsError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="8">No biddings found</td>
            </tr>
            }
            {!!biddingsError && <tr>
              <td className="table-empty" colSpan="8">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load biddings
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </>
}

export default Biddings;