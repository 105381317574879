/********** DATATABLE **********/

export const GET_DEALER_STATEMENT_DT = 'GET_DEALER_STATEMENT_DT'
export const GET_DEALER_STATEMENT_DT_OK = 'GET_DEALER_STATEMENT_DT_OK'
export const GET_DEALER_STATEMENT_DT_ERR = 'GET_DEALER_STATEMENT_DT_ERR'
export const DO_DEALER_STATEMENT_DT_CLEANUP = 'DO_DEALER_STATEMENT_DT_CLEANUP'

/********** DATATABLE FILTERS **********/

export const APPLY_DEALER_STATEMENT_DT_FILTERS = 'APPLY_DEALER_STATEMENT_DT_FILTERS'
export const CLEAR_DEALER_STATEMENT_DT_FILTERS = 'CLEAR_DEALER_STATEMENT_DT_FILTERS'