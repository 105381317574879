import React, { useEffect, useState } from "react";
import Breadcrumbs from "components/Common/Breadcrumb2";
import { Card, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Col from "../../../components/Shared/Col";
import Preloader from "../../../components/Shared/Preloader";
import AccessDenied from "../../Error/AccessDenied";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "../../../context/access";
import Error from "../../Error";
import { route, routes } from "helpers/routeHelper";
import InformationHeader from "../Partial/NotaryInformation/InformationHeader";
import OrderFlowAccordion from "../Partial/NotaryInformation/Flow/OrderFlowAccordion";
import {
  doOrderSingleCleanup, getFullOrder,
} from "../../../store/order/actions";
import classnames from "classnames";

const NotaryInformationStatus = () => {
  /********** STATE **********/
  const { order, isLoadInProgress, orderError } = useSelector(state => state.Order.Single);
  const [customActiveTab, setcustomActiveTab] = useState('1');

  /********** OTHERS **********/
  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab.toString());
    }
  };

  const generateNavItem = (item) => {
    return (
      <NavItem key={item.id} className={item.id.toString()}>
        <NavLink
          className={classnames({
            active: customActiveTab === item.id.toString(),
          })}
          onClick={() => {
            toggleCustom(item.id);
          }}
        >
          {item.notaryFullName}
        </NavLink>
      </NavItem>
    )
  }

  const generateTabContent = (item, index) => {
    return (
      <TabPane key={item.id} tabId={item.id.toString()}>
        <InformationHeader order={order} orderKey={index} />
        <OrderFlowAccordion order={order} orderKey={index} />
      </TabPane>
    )
  }

  const generateSingleNotaryInfo = () => {
    return (
      <Col xs={12}>
        <Card id="order_card" className="mav-card mb-3">
          <InformationHeader order={order} orderKey={0} />
        </Card>

        <OrderFlowAccordion order={order} orderKey={0} />
      </Col>
    )
  }

  const generateMultipleNotaryInfo = () => {
    return (
      <div className='nav-container'>
        <Nav vertical>
          {order.notaries.map((item, index) => generateNavItem(item))}
        </Nav>
        <TabContent activeTab={customActiveTab}>
          {order.notaries.map((item, index) => generateTabContent(item, index))}
        </TabContent>
      </div>
    )
  }

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    dispatch(getFullOrder(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);


  return (
    <>
      {iAmGranted(perms.view_orders) && <div className="page-content">
        {order && <React.Fragment>
          <Container fluid>
            <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
            <Row className='notary-information-container vertical-tabs-page'>
              <h1 className='page-title'>Notary information Status</h1>
              {
                order.notaries.length > 1 ? generateMultipleNotaryInfo() : generateSingleNotaryInfo()
              }
            </Row>
          </Container>
        </React.Fragment>
        }

        {/* Show this prealoder only on the first fetch */}
        {isLoadInProgress && !order && <Preloader className="inner" />}
        {orderError && <Error error={orderError} title404="Order not found" />}
      </div>}
      {iAmNotGranted(perms.view_orders) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = order => [{
  title: '< Back',
  url: route(routes.view_order, order.id),
}];

export default NotaryInformationStatus;