import React from "react";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { getSsoAppUrl } from "helpers/utilHelper";
import warningIcon from "assets/images/warning-red-icon.svg";
import User from "model/user";
import { checkReasonType } from "helpers/utilHelper";

const BounceEmailAlert = ({ user }) => {
  const alertMessage = checkReasonType(user);

  return (
    <Alert className="d-flex align-items-center" color="danger">
      <img src={warningIcon} alt="warning-icon" />
      <div className="ms-2 font-size-13">
        <span>Your email address seems to bounce and cannot receive emails from Mavsign. </span>
        {alertMessage}
        {(user.bounceSubtype === User.EMAIL_ALERT_GENERAL ||
          user.complaintSubtype === User.EMAIL_ALERT_GENERAL) && (
          <Link className="ms-1 alert-danger text-decoration-underline info-alert-link" to={getSsoAppUrl('me')}>
            Click here to update
          </Link>
        )}
      </div>
    </Alert>
  );
}

BounceEmailAlert.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BounceEmailAlert;
