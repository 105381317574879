import {

  GET_ADDITIONAL_FEE_LIST,
  GET_ADDITIONAL_FEE_LIST_OK,
  GET_ADDITIONAL_FEE_LIST_ERR,
  DO_ADDITIONAL_FEE_LIST_CLEANUP,

} from './actionTypes';

/********* LIST *********/

export const getAdditionalFeeList = () => ({
  type: GET_ADDITIONAL_FEE_LIST,
});

export const getAdditionalFeeListOk = response => ({
  type: GET_ADDITIONAL_FEE_LIST_OK,
  payload: { response },
});

export const getAdditionalFeeListErr = error => ({
  type: GET_ADDITIONAL_FEE_LIST_ERR,
  payload: { error },
});

export const doAdditionalFeeListCleanup = () => ({
  type: DO_ADDITIONAL_FEE_LIST_CLEANUP,
});