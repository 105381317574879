import React, { useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useSelector, useDispatch } from "react-redux";
import { dateFromTimestamp, getMonthName, getMonthsInRange } from "helpers/dateHelper";
import moment from "moment";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { Row, Col, Alert } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { doGroupUsageReportCleanup, getGroupUsageReport } from "store/actions";

const OrderCountReport = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { stores: rows, reportError: rowsError, isLoadInProgress, totalCount, listParams } = useSelector(state => state.Report.GroupUsage);
  const filters = useSelector(state => state.Report.GroupUsageFilters);
  // check if the start date and end date selected are in the same year
  // this is needed, because if the data spans over multiple year, we will display the year as well in the column headers
  const isSameYear = moment(dateFromTimestamp(filters.startTs)).year() === moment(dateFromTimestamp(filters.endTs)).year();
  // when stats are loaded, build an array of the months included in the selected range
  // in order to render them as columns
  // the months are formatted as "YYYY-M"
  const monthsArray = !!rows?.length ? getMonthsInRange(filters.startTs, filters.endTs) : [];

  // range is ready if both start and end dates have been selected
  const isRangeSet = !!(filters.startTs && filters.endTs);

  // PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 },
    ],
  });

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doGroupUsageReportCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // refresh the list data based on the new filters
    if (isRangeSet) {
      dispatch(getGroupUsageReport({
        ...listParams,
        ...filters,
        // reset the page number when filtering
        // otherwise the current page number might be higher than the total number of pages after the filtering
        page: 1,
      }));
      // update the pagination with the new page number
      setPaginationOptions(options => ({
        ...options,
        page: 1,
      }));
    }
  }, [filters]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getGroupUsageReport({
      ...listParams,
      pageSize: newState.sizePerPage,
      page: newState.page,
      ...filters
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return (
    <div className="mb-3 paginated-table-card">
      {!isLoadInProgress && !rowsError && !rows.length && <div className="mt-3">No data provided for the selected period</div>}
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Row>
              <Col>
                <div className="table-responsive report-table-paginated">
                  <BootstrapTable
                    keyField='dealerStoreId'
                    columns={columns(monthsArray, isSameYear)}
                    data={rows}
                    noDataIndication={!rowsError && "No stores found"}
                    onTableChange={handleTableChange}
                    remote={true}
                    {...paginationTableProps}
                  />
                </div>
                {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                {!!rowsError && <Alert color="danger" className="fade show text-center">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stores
                </Alert>}
              </Col>
            </Row>
            <Row className="align-items-md-center mt-3">
              <Col className="inner-custom-pagination d-flex">
                <div className="d-inline">
                  <SizePerPageDropdownStandalone
                    {...paginationProps}
                    variation="dropup"
                  />
                </div>
                <div className="text-md-right ms-auto">
                  <PaginationListStandalone
                    {...paginationProps}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </PaginationProvider>
    </div>
  )
}


const columns = (monthsArray, isSameYear) => {
  const columnsArray = [{
    dataField: 'dealerGroupName',
    text: 'Group',
  }, {
    dataField: 'dealerRegionName',
    text: 'Region',
  }, {
    dataField: 'dealerStoreName',
    text: 'Store',
  }];
  monthsArray.map(month => {
    columnsArray.push({
      dataField: `numOfOrders.${month}`,
      text: getMonthName(month, isSameYear),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (cellContent) return <span>{cellContent}</span>
        return <span>--</span>
      },
    })
  })
  columnsArray.push({
    dataField: `total`,
    text: "Total",
  })
  return columnsArray;
}

export default OrderCountReport;