import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Table} from "reactstrap";
import Col from "components/Shared/Col";
import classnames from "classnames";
import config from "config";

const SectionAdditionalFee = ({ order }) => {

  return <React.Fragment>
    <Card className="expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Additional Fee</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="px-0 pb-0">
        <div className={classnames('card-section')}>
          <Table className={classnames('section-rows table')}>
            <tbody>
              <tr>
                <td className="section-row-label">Rush:</td>
                <td className="section-row-value">${config.ORDER_RUSH_SERVICE_FEE}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionAdditionalFee.propTypes = {
  order: PropTypes.object,
};

export default SectionAdditionalFee;
