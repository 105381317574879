import React from "react"
import { Row, Col } from "reactstrap";
import MyProfile from "./Section/MyProfile";
import Biddings from "./Section/Bidding";
import InProgressOrders from "./Section/InProgressOrders";
import PendingMessages from "./Section/PendingMessages";
import CustomerPerformance from "./Section/CustomerPerformance";
import Appointments from "./Section/Appointments";


const Scheduler = () => {

  return (
    <>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={4} className="mb-4"><MyProfile /></Col>
        <Col xs={12} xl={8} className="mb-4 mb-xl-0"><Biddings /></Col>
      </Row>
      <Row className="d-flex align-items-stretch">
        <Col xs={12} xl={12} className="mb-4"><PendingMessages /></Col>
        <Col xs={12} xl={12} className="mb-4"><Appointments /></Col>
        <Col xs={12} xl={12} className="mb-4"><InProgressOrders /></Col>
        <Col xs={12} xl={12} className="mb-4"><CustomerPerformance /></Col>
      </Row>
    </>
  )
}

export default Scheduler;
