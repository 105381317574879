import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, CardTitle } from "reactstrap";
import { nullsToEmptyStrings, phoneHasNoOfDigits, showBriefError, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import regx from "constants/regx";
import { updateDealerStoreContact2 } from "helpers/backendHelper";

const FormContact2Edit = props => {

  const { defaultValues, id, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactPhone2: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      contactEmail: Yup.string().trim().email('Invalid email address'),
      contactPhone: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone1',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
      contactPhone2: Yup.string().trim().matches(regx.phone, 'Invalid phone number').test('phone2',
        'Field requires exactly 10 digits',
        ((value) => value ? phoneHasNoOfDigits(value) : true)
      ),
    }),
    onSubmit: values => saveDealerStoreContact2(values, id),
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveDealerStoreContact2 = (values, id) => {
    setIsSaveInProgress(true);
    updateDealerStoreContact2(values, id)
      .then(response => {
        showSuccess(`Dealer store "${values.name}" has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save dealer store');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);

      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <div className="pb-4">
      <Row>
        <Col>
          <CardTitle>Alternate contact</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={finishedHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
    <Row>
      <Col>
        <Form>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Contact name</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. John Doe" name="contactName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactName} invalid={!!formik.errors.contactName} />
              {!!formik.errors.contactName && <FormFeedback type="invalid">{formik.errors.contactName}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Email</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. john@domain.com" name="contactEmail" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactEmail} invalid={!!formik.errors.contactEmail} />
              {!!formik.errors.contactEmail && <FormFeedback type="invalid">{formik.errors.contactEmail}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 909-410-5017" name="contactPhone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactPhone} invalid={!!formik.errors.contactPhone} />
              {!!formik.errors.contactPhone && <FormFeedback type="invalid">{formik.errors.contactPhone}</FormFeedback>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-4 col-form-label">Alternate phone</Label>
            <Col sm={8}>
              <Input type="text" className="form-control" placeholder="ex. 909-410-5017" name="contactPhone2" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.contactPhone2} invalid={!!formik.errors.contactPhone2} />
              {!!formik.errors.contactPhone2 && <FormFeedback type="invalid">{formik.errors.contactPhone2}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </React.Fragment>
}

FormContact2Edit.propTypes = {
  defaultValues: PropTypes.object,
  id: PropTypes.number,
  finishedHandler: PropTypes.func,
};

export default FormContact2Edit;