import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import OrderDoc from "model/orderDoc";
import NoImg from "components/Shared/NoImg";
import 'photoswipe/dist/photoswipe.css';
import { Item } from 'react-photoswipe-gallery';
import InkSignPreviewModal from "./PreviewModal";
import { useInkSignPage } from "./PageContext";
import { perms, useAccess } from "context/access";
import classnames from "classnames";
import { showError } from "helpers/utilHelper";
import { clearOrderDocPage, uploadOrderDocPage } from "helpers/backendHelper";
import Confirmation from "components/Shared/Confirmation";

const InkSignPage = ({ onImageError }) => {

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const fileInput = useRef(null);

  const { docId, pageNum, page } = useInkSignPage();

  // get redux state from the store
  const doc = useSelector(state => state.OrderDoc.NormList.orderDocs[docId]);

  const [isClearConfirmationVisible, setIsClearConfirmationVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const [isImageError, setIsImageError] = useState(false);

  const handleImageError = () => {
    setIsImageError(true);
    // flag the doc as having an error to restrict users from downloading/viewing it
    // this is useful when doc hasn't been uploaded in the platform, but was received by the dealer
    onImageError();
  };

  const canSubmitPage = () => iAmGranted(perms.edit_orders) && doc.status < OrderDoc.STATUS_COMPLETE && page.status == OrderDoc.PAGE_STATUS_NOT_SUBMITTED;

  const canClearPage = () => iAmGranted(perms.edit_orders) && doc.status < OrderDoc.STATUS_COMPLETE && [OrderDoc.PAGE_STATUS_PENDING_REVIEW, OrderDoc.PAGE_STATUS_REJECTED].includes(page.status);

  const fileSelected = async e => {
    const formData = new FormData();
    formData.append("pageImg", e.target.files[0]);
    setIsUploading(true);
    try {
      await uploadOrderDocPage(formData, doc.id, pageNum);
    } catch (err) {
      showError("Unable to upload file");
    } finally {
      setIsUploading(false);
    }
  }

  const clearPage = async () => {
    setIsClearing(true);
    try {
      await clearOrderDocPage(doc.id, pageNum);
    } catch (err) {
      showError("Unable to clear page");
    } finally {
      setIsClearing(false);
    }
  };

  return <>
    <div className="ink-sign-page">
      <div className="ink-sign-page-title">Page {pageNum}</div>
      <div className="ink-sign-page-thumb">
        {(!!page.image && !doc.isWithdrawn) && <Item content={<InkSignPreviewModal page={page} />}>
          {({ ref, open }) => (<img src={page.image} className="ink-sign-page-img" ref={ref} onClick={open} onError={handleImageError} />)}
        </Item>}
        {doc.isWithdrawn && <div className="text-center">Image no longer available.</div>}
        {!page.image && <NoImg />}
        {canSubmitPage() && <React.Fragment>
          <Button type="button" color="secondary" className={classnames("ink-sign-page-btn ink-sign-page-upl-btn", { 'd-block': isUploading })} id={'ink-sign-page-upl-btn-' + doc.id + '-' + pageNum} onClick={() => fileInput.current.click()} disabled={isUploading}>
            {isUploading && <i className="mdi mdi-spin mdi-loading" />}
            {!isUploading && <i className="mdi mdi-file-upload"></i>}
          </Button>
          <UncontrolledTooltip placement="top" target={'ink-sign-page-upl-btn-' + doc.id + '-' + pageNum}>Upload Page</UncontrolledTooltip>
        </React.Fragment>}
        {canClearPage() && <React.Fragment>
          <Button type="button" color="secondary" className={classnames("ink-sign-page-btn ink-sign-page-clr-btn", { 'd-block': isClearing })} id={'ink-sign-page-clr-btn-' + doc.id + '-' + pageNum} onClick={() => setIsClearConfirmationVisible(true)} disabled={isClearing}>
            {isClearing && <i className="mdi mdi-spin mdi-loading" />}
            {!isClearing && <i className="mdi mdi-delete"></i>}
          </Button>
          <UncontrolledTooltip placement="top" target={'ink-sign-page-clr-btn-' + doc.id + '-' + pageNum}>Clear Page</UncontrolledTooltip>
        </React.Fragment>}
      </div>
      {page.status == OrderDoc.PAGE_STATUS_NOT_SUBMITTED && <Button type="button" color="secondary" className="w-100 mt-2 cursor-default">
        Not submitted
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
      {page.status == OrderDoc.PAGE_STATUS_ACCEPTED && <Button type="button" color="primary" className="w-100 mt-2 cursor-default">
        Accepted page
        <i className="bx bx-check-circle ms-2 align-middle"></i>
      </Button>}
      {page.status == OrderDoc.PAGE_STATUS_REJECTED && <Button type="button" color="danger" className="w-100 mt-2 cursor-default">
        Rejected page
        <i className="bx bx-x-circle ms-2 align-middle"></i>
      </Button>}
    </div>
    <input type="file" accept="image/*" onChange={fileSelected} className="d-none" ref={fileInput} />
    {isClearConfirmationVisible && <Confirmation
      confirmBtnText="Clear"
      reverseButtons={false}
      onConfirm={() => {
        setIsClearConfirmationVisible(false);
        clearPage();
      }}
      onCancel={() => setIsClearConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to clear page &quot;{pageNum}&quot;?</span>
    </Confirmation>}
  </>
}

InkSignPage.propTypes = {
  onImageError: PropTypes.func,
};

export default InkSignPage;
