import { combineReducers } from "redux";
import {

  GET_SUPPORT_STATS,
  GET_SUPPORT_STATS_OK,
  GET_SUPPORT_STATS_ERR,
  DO_SUPPORT_STATS_CLEANUP,

  GET_APPOINTMENTS_LIST_OK,
  GET_APPOINTMENTS_LIST_ERR,
  DO_APPOINTMENTS_LIST_CLEANUP,
  GET_APPOINTMENTS_LIST

} from "./actionTypes"

/********** SUPPORT STATS **********/

const defaultSupportStatsState = {
  totalMessages: null,
  workInProgress: null,
  remoteVid: null,
  remote: null,
  instore: null,
  sealed: null,
  isLoadInProgress: false,
  statsError: null,
}

const SupportStats = (state = defaultSupportStatsState, action) => {
  switch (action.type) {
    case GET_SUPPORT_STATS:
      state = { ...state, statsError: null, isLoadInProgress: true };
      break
    case GET_SUPPORT_STATS_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        workInProgress: action.payload.response.workInProgress,
        remote: action.payload.response.remote,
        instore: action.payload.response.instore,
        remoteVid: action.payload.response.remoteVid,
        sealed: action.payload.response.sealed
      };
      break
    case GET_SUPPORT_STATS_ERR:
      state = { ...state, isLoadInProgress: false, statsError: action.payload.error };
      break
    case DO_SUPPORT_STATS_CLEANUP:
      state = { ...defaultSupportStatsState };
      break
  }

  return state;
}

/********** APPOINTMENTS LIST **********/

const defaultAppointmentList = {
  appointments: [],
  appointmentsError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 10,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const AppointmentsList = (state = defaultAppointmentList, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS_LIST:
      state = {
        ...state,
        listParams: action.payload.params,
        appointmentsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_APPOINTMENTS_LIST_OK:
      state = {
        ...state,
        appointments: action.payload.response.appointments,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_APPOINTMENTS_LIST_ERR:
      state = {
        ...state,
        appointments: [],
        totalCount: 0,
        appointmentsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_APPOINTMENTS_LIST_CLEANUP:
      state = {
        ...state,
        appointments: [],
        appointmentsError: null,
      };
      break
  }
  return state;
}

export default combineReducers({
  SupportStats,
  AppointmentsList
});