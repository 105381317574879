import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { doOrderESignDocsCleanup, getOrderESignDocs } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Alert } from "bootstrap";
import { getBeUrl } from "helpers/utilHelper";
import {formats, formatTimestamp} from "../../../../helpers/dateHelper";

const ESignDocs = props => {

  const { orderId } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/

  // get redux state from the store
  const orderDocs = useSelector(state => state.Order.ESignDocs.docs);
  const orderDocsError = useSelector(state => state.Order.ESignDocs.docsError);
  const isLoadInProgress = useSelector(state => state.Order.ESignDocs.isLoadInProgress);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    dispatch(getOrderESignDocs(orderId));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderESignDocsCleanup());
    }
  }, [orderId]);

  /********** OTHER **********/
  const getImageUrl = (docId, pageNumber) => getBeUrl(`order-doc/${docId}/e-sign/page/${pageNumber}.png`);

  const renderDocPages = (doc) => {

    const docPages = [];
    for (let index = 1; index <= doc.numOfPages; index++) {
      docPages.push(
       <div key={index} className="doc-wrapper">
         <Row className='doc-wrapper-head'>
           <Col md="3"><div className='head-row'>Document Name</div></Col>
           <Col md="3"><div className='head-row'>Document Type</div></Col>
           <Col md="3"><div className='head-row'>Signed Date</div></Col>
           <Col md="3"><div className='head-row'>Accepted Date</div></Col>
         </Row>
         <Row className='doc-wrapper-body'>
           <Col md="3"><div className='body-row'>{doc.name}</div></Col>
           <Col md="3"><div className='body-row'>Ink Paper Signed Document</div></Col>
           <Col md="3"><div className='body-row'>{formatTimestamp(doc.signedTs, formats.DATETIME)}</div></Col>
           <Col md="3"><div className='body-row'>{formatTimestamp(doc.acceptedTs, formats.DATETIME)}</div></Col>
         </Row>
         <Row>
           <img className="doc-image" src={getImageUrl(doc.id, index)} alt={`Page ${index}`} />
         </Row>
       </div>
      );
    }
    return docPages;
  }

  return <React.Fragment>
    {orderDocs && (
     orderDocs.map(doc => renderDocPages(doc))
    )}
    {isLoadInProgress && <Row>
      <Col>
        <SpinnerChase className="sm mt-3 mb-5" />
      </Col>
    </Row>}
    {orderDocsError && <Row>
      <Col>
        <Alert color="danger" className="fade show text-center">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load e-sign docs
        </Alert>
      </Col>
    </Row>}
  </React.Fragment>
}

ESignDocs.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default ESignDocs;
