export default class SupportCaseVid {

  static FRONT_ID = 1;
  static BACK_ID = 2;
  static SELFIE = 3;
  static PASSPORT = 4;
  static SMS = 5;

  static getVidMap() {
    return {
      [this.FRONT_ID]: 'Front ID',
      [this.BACK_ID]: 'Back ID',
      [this.SELFIE]: 'Selfie',
      [this.PASSPORT]: 'Passport',
      [this.SMS]: 'SMS',
    };
  }

  static getVidName(id) {
    return this.getVidMap()[id];
  }
}