import React from "react";
import { useCountdown } from "hooks/useCountdown";
import PropTypes from "prop-types";

const addLeadingZero = value => value < 10 ? `0${value}` : value;

const CountdownTimer = ({ targetDate, stateChanger }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);

  if (hours + minutes + seconds === 0) {
    stateChanger(true);
  }

  if (hours + minutes + seconds <= 0) return <i className="bx bxs-time-five text-secondary font-size-20"></i>

  return (
    <div className="show-counter">
      <div className="countdown">
        <p>{addLeadingZero(minutes)}</p>
      </div>
      <p>:</p>
      <div className="countdown">
        <p>{addLeadingZero(seconds)}</p>
      </div>
    </div>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.number,
  stateChanger: PropTypes.any,
}

export default CountdownTimer;

