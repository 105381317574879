export default class SupportCase {

  /* STATUS */
  static STATUS_OPEN = 0;
  static STATUS_AWAITING_SUPPORT_INFO = 1;
  static STATUS_AWAITING_CUSTOMER_INFO = 2;
  static STATUS_RESOLVED = 3;

  /* INTERNAL STATUS */
  static INTERNAL_STATUS_NONE = 0;
  static INTERNAL_STATUS_ESCALATED = 1;
  static INTERNAL_STATUS_FRAUD = 2;

  /* PRIORITY */
  static PRIO_LOWEST = 0;
  static PRIO_LOW = 1;
  static PRIO_HIGH = 2;
  static PRIO_HIGHEST = 3;

  /* REPORTED VIA */
  static REPORTED_VIA_CHAT = 1;
  static REPORTED_VIA_PHONE_CALL = 2;
  static REPORTED_VIA_SMS = 3;
  static REPORTED_VIA_SCHEDULING = 4;
  static REPORTED_VIA_SALES = 5;
  static REPORTED_VIA_CONTACT_FORM = 6;

  /* CREATED DATE */
  static DATE_MINUS_ONE_DAY = -24;
  static DATE_ONE_DAY = 24;
  static DATE_TWO_DAYS = 48;
  static DATE_TWO_DAYS_PLUS = 56;
  static DATE_THREE_DAYS = 72;

  static getStatusMap() {
    return {
      [this.STATUS_OPEN]: 'Open',
      [this.STATUS_AWAITING_SUPPORT_INFO]: 'Awaiting support info',
      [this.STATUS_AWAITING_CUSTOMER_INFO]: 'Awaiting customer info',
      [this.STATUS_RESOLVED]: 'Resolved',
    };
  }

  static getStatusName(id) {
    return this.getStatusMap()[id];
  }

  static getStatusColor(status) {
    switch (status) {
      case this.STATUS_OPEN:
        return 'success-dark';
      case this.STATUS_AWAITING_SUPPORT_INFO:
        return 'primary-dark';
      case this.STATUS_AWAITING_CUSTOMER_INFO:
        return 'orange';
      case this.STATUS_RESOLVED:
      default:
        return 'dark';
    }
  }

  static getPriorityMap() {
    return {
      [this.PRIO_LOWEST]: 'Lowest',
      [this.PRIO_LOW]: 'Low',
      [this.PRIO_HIGH]: 'High',
      [this.PRIO_HIGHEST]: 'Highest',
    };
  }

  static getPriorityName(id) {
    return this.getPriorityMap()[id];
  }

  static getPriorityColor(priority) {
    switch (priority) {
      case this.PRIO_LOWEST:
        return 'success';
      case this.PRIO_LOW:
        return 'warning';
      case this.PRIO_HIGH:
        return 'orange';
      case this.PRIO_HIGHEST:
        return 'danger';
      default:
        return 'dark';
    }
  }

  static getInternalStatusMap() {
    return {
      [this.INTERNAL_STATUS_ESCALATED]: 'Escalated',
      [this.INTERNAL_STATUS_FRAUD]: 'Fraud',
      [this.INTERNAL_STATUS_NONE]: 'None',
    };
  }

  static getInternalStatusName(id) {
    return this.getInternalStatusMap()[id];
  }

  static getReporterTypeMap() {
    return {
      [this.REPORTER_TYPE_DEALER]: 'Dealer',
      [this.REPORTER_TYPE_CUSTOMER]: 'Customer',
      [this.REPORTER_TYPE_NOTARY]: 'Notary',
    };
  }

  static getReporterTypeName(id) {
    return this.getReporterTypeMap()[id];
  }

  static getReportedViaMap() {
    return {
      [this.REPORTED_VIA_CHAT]: 'Chat',
      [this.REPORTED_VIA_PHONE_CALL]: 'Phone call',
      [this.REPORTED_VIA_SMS]: 'SMS',
      [this.REPORTED_VIA_SCHEDULING]: 'Scheduling',
      [this.REPORTED_VIA_SALES]: 'Sales',
      [this.REPORTED_VIA_CONTACT_FORM]: 'Contact form',
    };
  }

  static getReportedViaName(id) {
    return this.getReportedViaMap()[id];
  }

  static isResolved = statusId => statusId == this.STATUS_RESOLVED;

  static getDateMap() {
    return {
      [this.DATE_MINUS_ONE_DAY]: '<24 hours ',
      [this.DATE_ONE_DAY]: '24H+',
      [this.DATE_TWO_DAYS]: '48H+',
      [this.DATE_TWO_DAYS_PLUS]: '56H+',
      [this.DATE_THREE_DAYS]: '72H+',
    };
  }
}