import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";
import ViewInfo from '../View/Info';
import FormInfoEdit from '../Form/Info/Edit';

const SectionInfo = props => {

  const { isLoadBusy, id, dealerStore, refreshHandler } = props;

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  // TRUE after a save and untill the data is refreshed
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (!isLoadBusy) {
      setIsRefreshing(false);
    }
  }, [isLoadBusy]);

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successfull save)
    if (doRefresh === true) {
      setIsRefreshing(true);
      refreshHandler();
    }
  }

  // note that dealerStore is null before the fetch request completes or if it fails
  const formProps = dealerStore ? {
    // one must not be allowed to move a store from one region/group to another
    // because store users are also linked to the region and group
    forceValues: {
      dealerGroupId: dealerStore.dealerGroupId,
      dealerRegionId: dealerStore.dealerRegionId,
    },
  } : null;

  return <React.Fragment>
    {isEditModeActive && <Card>
      <CardBody className="pt-3">
        <FormInfoEdit id={id} defaultValues={dealerStore} finishedHandler={toggleEditMode} {...formProps} />
      </CardBody>
    </Card>}
    {!isEditModeActive && <ViewInfo id={id} dealerStore={dealerStore} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

SectionInfo.propTypes = {
  isLoadBusy: PropTypes.bool,
  id: PropTypes.number,
  dealerStore: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default SectionInfo;