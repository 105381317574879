import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_USER_DT,
  GET_SCHEDULER_DT,
  GET_DEALER_GROUP_MANAGER_LIST,
  GET_DEALER_REGION_MANAGER_LIST,
  GET_DEALER_STORE_USER_LIST,
} from "./actionTypes";

import {
  getUserDtOk,
  getUserDtErr,
  getUsersOk,
  getUsersErr,
} from "./actions";

import {
  getUserDt,
  getSchedulerDt,
  getDealerGroupManagers,
  getDealerRegionManagers,
  getDealerStoreUsers,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetUserDt({ payload: { params } }) {
  try {
    const response = yield call(getUserDt, params);
    yield put(getUserDtOk(response));
  } catch (error) {
    yield put(getUserDtErr(error));
  }
}

function* onGetSchedulerDt({ payload: { params } }) {
  try {
    const response = yield call(getSchedulerDt, params);
    yield put(getUserDtOk(response));
  } catch (error) {
    yield put(getUserDtErr(error));
  }
}

/********* LIST *********/

function* onGetDealerGroupManagerList({ payload: { groupId } }) {
  try {
    const response = yield call(getDealerGroupManagers, groupId);
    yield put(getUsersOk(response));
  } catch (error) {
    yield put(getUsersErr(error));
  }
}

function* onGetDealerRegionManagerList({ payload: { regionId } }) {
  try {
    const response = yield call(getDealerRegionManagers, regionId);
    yield put(getUsersOk(response));
  } catch (error) {
    yield put(getUsersErr(error));
  }
}

function* onGetDealerStoreUserList({ payload: { storeId } }) {
  try {
    const response = yield call(getDealerStoreUsers, storeId);
    yield put(getUsersOk(response));
  } catch (error) {
    yield put(getUsersOk(error));
  }
}

function* userSaga() {
  yield takeEvery(GET_USER_DT, onGetUserDt);
  yield takeEvery(GET_SCHEDULER_DT, onGetSchedulerDt);
  yield takeEvery(GET_DEALER_GROUP_MANAGER_LIST, onGetDealerGroupManagerList);
  yield takeEvery(GET_DEALER_REGION_MANAGER_LIST, onGetDealerRegionManagerList);
  yield takeEvery(GET_DEALER_STORE_USER_LIST, onGetDealerStoreUserList);
}

export default userSaga;
