import { combineReducers } from "redux";
import {
  DO_ORDER_DOC_NORM_LIST_CLEANUP,
  GET_E_SIGN_ORDER_DOC_NORM_LIST,
  GET_FINANCE_ORDER_DOC_NORM_LIST,
  GET_SUPPORTING_ORDER_DOC_NORM_LIST,
  GET_INK_SIGN_ORDER_DOC_NORM_LIST,
  GET_ORDER_DOC_NORM_LIST_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM,
  GET_ORDER_DOC_NORM_LIST_ITEM_ERR,
  GET_ORDER_DOC_NORM_LIST_ITEM_OK,
  GET_ORDER_DOC_NORM_LIST_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_OK,
  DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK,
  REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR,
  UPLOAD_ORDER_DOC,
  UPLOAD_ORDER_DOC_OK,
  UPLOAD_ORDER_DOC_ERR,
  ADD_ORDER_DOC,
  ADD_ORDER_DOC_OK,
  ADD_ORDER_DOC_ERR,
  DO_ORDER_DOC_FORM_CLEANUP,
  ACCEPT_ALL_INK_SIGN_DOCS,
  ACCEPT_ALL_INK_SIGN_DOCS_OK,
  ACCEPT_ALL_INK_SIGN_DOCS_ERR,
  DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP,
} from "./actionTypes";

/********** NORMALIZED LIST **********/

const defaultNormListState = {
  orderDocIds: [],
  orderDocs: [],
  orderDocsError: null,
  isLoadInProgress: false,
};

const NormList = (state = defaultNormListState, action) => {
  switch (action.type) {
    // get list
    case GET_E_SIGN_ORDER_DOC_NORM_LIST:
    case GET_INK_SIGN_ORDER_DOC_NORM_LIST:
    case GET_FINANCE_ORDER_DOC_NORM_LIST:
    case GET_SUPPORTING_ORDER_DOC_NORM_LIST:
      state = {
        ...state,
        orderDocsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_DOC_NORM_LIST_OK:
      const ids = [];
      const docs = action.payload.response.orderDocs.reduce((prev, curr) => {
        ids.push(curr.id);
        return { ...prev, [curr.id]: curr };
      }, {});
      state = {
        ...state,
        orderDocIds: ids,
        orderDocs: docs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DOC_NORM_LIST_ERR:
      state = {
        ...state,
        orderDocs: [],
        orderDocsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // get doc
    case GET_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isFetchInProgress = true;
      doc.fetchError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case GET_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: action.payload.response.orderDoc,
        },
      };
      break;
    }
    case GET_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isFetchInProgress = false;
      doc.fetchError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // delete doc
    case DELETE_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = null;
      doc.isDeleteInProgress = true;
      doc.deleteError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = true;
      doc.isDeleteInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case DELETE_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isDeleted = false;
      doc.isDeleteInProgress = false;
      doc.deleteError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // reprocess doc
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = null;
      doc.isReprocessInProgress = true;
      doc.reprocessError = null;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM_OK: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = true;
      doc.isReprocessInProgress = false;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    case REPROCESS_ORDER_DOC_NORM_LIST_ITEM_ERR: {
      const docId = action.payload.id;
      const doc = state.orderDocs[docId];
      doc.isReprocessed = false;
      doc.isReprocessInProgress = false;
      doc.reprocessError = action.payload.error;
      state = {
        ...state,
        orderDocs: {
          ...state.orderDocs,
          [docId]: { ...doc },
        },
      };
      break;
    }
    // cleanup
    case DO_ORDER_DOC_NORM_LIST_CLEANUP:
      state = { ...defaultNormListState };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  orderDoc: null,
  uploaded: null,
  uploadError: null,
  isUploadInProgress: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case UPLOAD_ORDER_DOC:
    case ADD_ORDER_DOC:
      state = {
        ...state,
        uploaded: null,
        uploadError: null,
        isUploadInProgress: true,
      };
      break
    case UPLOAD_ORDER_DOC_OK:
    case ADD_ORDER_DOC_OK:
      state = {
        ...state,
        orderDoc: {
          ...state.orderDoc,
          id: action.payload.response.id,
        },
        uploaded: true,
        isUploadInProgress: false,
      };
      break
    case UPLOAD_ORDER_DOC_ERR:
    case ADD_ORDER_DOC_ERR:
      state = {
        ...state,
        uploaded: false,
        uploadError: action.payload.error,
        isUploadInProgress: false,
      };
      break
    case DO_ORDER_DOC_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** ACCEPT ALL **********/

const defaultAcceptAllState = {
  accepted: null,
  acceptError: null,
  isAcceptInProgress: false,
};

const AcceptAll = (state = defaultAcceptAllState, action) => {
  switch (action.type) {
    // save
    case ACCEPT_ALL_INK_SIGN_DOCS:
      state = {
        ...state,
        accepted: null,
        acceptError: null,
        isAcceptInProgress: true,
      };
      break
    case ACCEPT_ALL_INK_SIGN_DOCS_OK:
      state = {
        ...state,
        accepted: true,
        isAcceptInProgress: false,
      };
      break
    case ACCEPT_ALL_INK_SIGN_DOCS_ERR:
      state = {
        ...state,
        accepted: false,
        acceptError: action.payload.error,
        isAcceptInProgress: false,
      };
      break
    case DO_ACCEPT_ALL_INK_SIGN_DOCS_CLEANUP:
      state = { ...defaultAcceptAllState };
      break
  }
  return state;
}

export default combineReducers({
  NormList,
  Form,
  AcceptAll,
})