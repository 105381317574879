import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import CardPreloader from "components/Shared/CardPreloader";


const ViewInfo = props => {

  const { cronJob, refreshHandler, isLoadInProgress } = props;

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <h3>{cronJob.name}</h3>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Link to={route(routes.list_cron_jobs)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Back
              </Link>
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={refreshHandler}>
                <i className="mdi mdi-reload me-1" />Refresh
              </Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <pre>{cronJob.output}</pre>
      </CardBody>
      {isLoadInProgress && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewInfo.propTypes = {
  cronJob: PropTypes.object,
  refreshHandler: PropTypes.func,
  isLoadInProgress: PropTypes.bool,
};

export default ViewInfo;
