import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { getInitialsFromName } from 'helpers/utilHelper';
import UserAvatarWithActivityStatus from 'components/Shared/UserAvatarWithActivityStatus';

const SectionImage = props => {

  const { user } = props;

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <CardTitle>Profile image</CardTitle>
      </CardHeader>
      <CardBody className="text-center">
        <UserAvatarWithActivityStatus
          avatar={{
            id: user.id,
            image: user.image,
            initials: getInitialsFromName(user.fullName),
          }}
          activityStatus={{
            id: user.id,
            status: user.activityStatus,
            autoUpdate: true,
          }}
          size="xxl" />
      </CardBody>
    </Card>
  </React.Fragment>
}

SectionImage.propTypes = {
  user: PropTypes.object,
};

export default SectionImage;
