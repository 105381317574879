import React, { useEffect, useMemo } from "react";
import { Card, CardBody, Row, Col, Alert, CardHeader, CardTitle } from "reactstrap";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useDispatch, useSelector } from "react-redux";
import { applyDealerGroupDtFilters, clearDealerGroupDtFilters, doDealerGroupDtCleanup, getDealerGroupDt } from "store/actions";
import UsStates from "constants/usState";
import Select from "react-select";
import { getMonthFromTs, getMonthOptions } from "helpers/dateHelper";
import { smallSelectStyles } from "helpers/utilHelper";

const Groups = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { dealerGroups, dealerGroupsError, isLoadInProgress } = useSelector(state => state.DealerGroup.Dt);
  const filters = useSelector(state => state.DealerGroup.DtFilters);

  const monthOptions = useMemo(getMonthOptions, []);
  const selectedMonth = filters.createdBetween && getMonthFromTs(filters.createdBetween.split("-")[0]);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // set default month filter to be the current month
    applyFilters({ createdBetween: monthOptions[monthOptions.length - 1].interval.join("-") });
    return () => {
      // state cleanup on component unmount
      dispatch(doDealerGroupDtCleanup());
      dispatch(clearDealerGroupDtFilters());
    }
  }, []);

  // runs anytime filters change
  useEffect(() => {
    // if the month hasn't been initialized yet, skip fetching groups
    if (!filters.createdBetween) return;
    // get groups
    dispatch(getDealerGroupDt({ pageSize: 10, page: 1, filters }));
  }, [filters]);

  /********** HANDLERS **********/

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyDealerGroupDtFilters(values));

  const handleMonthChange = selected => {
    applyFilters({ ...filters, createdBetween: selected.interval.join("-") });
  };

  return <>
    <Card className="py-2">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row className="justify-content-between">
          <Col xs={10}>
            <CardTitle>New Groups</CardTitle>
          </Col>
          <Col xs={2} className="mb-1">
            <Select
              key={selectedMonth}
              classNamePrefix="select2-selection"
              styles={smallSelectStyles}
              options={monthOptions}
              onChange={handleMonthChange}
              value={monthOptions.find(option => option.value === selectedMonth)}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1 horizontal-scroll-table">
        <table className="table">
          <thead className="vertical-align-middle">
            <tr>
              <th>Group Name</th>
              <th>Group State</th>
              <th>Group Manager</th>
              <th>Group Email</th>
            </tr>
          </thead>
          <tbody>
            {dealerGroups.length > 0 && dealerGroups.map((entry, index) => (
              <tr key={index}>
                <td>{entry.name}</td>
                <td>{UsStates.find(option => option.id === entry.state).name}</td>
                <td>{entry.managerFullName || "--"}</td>
                <td>{entry.email}</td>
              </tr>
            ))}
            {dealerGroups.length === 0 && <tr>
              <td className="table-empty" colSpan="4">No groups found</td>
            </tr>
            }
            {!!dealerGroupsError && <tr>
              <td className="table-empty" colSpan="4">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load groups
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  </>
}

export default Groups;