import { takeEvery, put, call } from "redux-saga/effects";

import {
  getBiddingDtOk,
  getBiddingDtErr,
} from "./actions";

import {
  GET_BIDDING_DT,
} from "./actionTypes";

import {
  getBiddingDt,
} from "helpers/backendHelper";

/********** DATATABLE **********/

function* onGetBiddingDt({ payload: { params } }) {
  try {
    const response = yield call(getBiddingDt, params);
    yield put(getBiddingDtOk(response));
  } catch (error) {
    yield put(getBiddingDtErr(error));
  }
}

function* biddingSaga() {
  yield takeEvery(GET_BIDDING_DT, onGetBiddingDt);
}

export default biddingSaga;
