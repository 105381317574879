import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ExpanderToggle = props => {

  const { noDetails, isExpanded, setIsExpanded } = props;

  const canExpand = () => !noDetails;

  return <React.Fragment>
    {canExpand() && <>
      <span className="ms-3">|</span>
      {!isExpanded && <Button type="button" color="link" className="pt-1 pb-1" onClick={() => setIsExpanded(true)}>View details</Button>}
      {isExpanded && <Button type="button" color="link" className="pt-1 pb-1" onClick={() => setIsExpanded(false)}>Hide details</Button>}
    </>}
  </React.Fragment>
}

ExpanderToggle.propTypes = {
  noDetails: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
}

export default ExpanderToggle;