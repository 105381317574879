import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {getPaymentMethod, showError} from "../../../../../helpers/utilHelper";
import DealerStore from "../../../../../model/dealerStore";
import {Button, Input} from "reactstrap";
import {addPaymentRef} from "../../../../../helpers/backendHelper";
import {useParams} from "react-router-dom";

const PaymentMethod = ({paymentMethod, paymentRef, setReferenceSaved}) => {

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/
  const [referenceVal, setReferenceVal] = useState();
  const [referenceValSaved, setReferenceValSaved] = useState(false);
  const isCheckType =  paymentMethod === DealerStore.PAYMENT_METHOD_TYPE_CHECK;

  /********** EFFECTS **********/

  useEffect(() => {
    setReferenceSaved(referenceValSaved);
  },[referenceValSaved]);

  /********** EVENT HANDLERS **********/

  const savePaymentRef = () => {
    addPaymentRef(id, {paymentRefId: referenceVal})
    .then(() => setReferenceValSaved(true))
    .catch(() => showError('Unable to save payment reference'))
  }

  /********** OTHER **********/
  const generateInputElement = () => {
    return(<div className="d-flex col-3">
      <Input
        type="text"
        onChange={(e) => setReferenceVal(e.target.value)}
      />
      <Button type="button" color="primary" className="ms-2" onClick={savePaymentRef}>
        Save
      </Button>
    </div>)
  }

  const generatePaymentRef = () => paymentRef ? paymentRef : generateInputElement()

  return <React.Fragment>
    <div className="font-size-15 mb-2"><strong>Payment</strong></div>
    <div className="font-weight-semibold mb-2">Payment Method</div>
    <div className='mb-2'>{getPaymentMethod(paymentMethod)}</div>
    <div className="font-weight-semibold mb-2">Reference</div>
    {isCheckType ? generatePaymentRef() : <div className='mb-2'>{paymentRef || '--'}</div>}
  </React.Fragment>
}

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.number,
  paymentRef: PropTypes.string,
  setReferenceSaved: PropTypes.func,
}

export default PaymentMethod;