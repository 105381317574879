import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Row, CardHeader, Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import Col from "components/Shared/Col";
import PropTypes from 'prop-types';
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";
import { doNotaryRatingCleanup, getNotaryRatingResults, getSecondNotaryRatingResults, getMyNotaryRating, getMySecondNotaryRating, doMyNotaryRatingCleanup, startNotaryMeeting, getFullOrder } from 'store/actions';
import { showSuccess, showError, openInNewWindow } from "helpers/utilHelper";
import NotaryRating from '../Form/NotaryRating';
import { perms, useAccess } from "context/access";
import OrderNotary from "model/orderNotary";
import { CANNOT_DELETE_SINGLE_ORDER_NOTARY, ORDER_NOTARY_STATUS_INVALID_FOR_REMOVAL, ServerErrorException, UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF } from 'helpers/errorHelper';
import Order from 'model/order';
import { formatTimestampTz, formats } from "helpers/dateHelper";
import Confirmation from "components/Shared/Confirmation";
import { removeNotary } from 'helpers/backendHelper';
import { useAuth } from 'context/auth';

const SectionNotaryInfo = props => {

  const { toggleEditMode } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // router hook that helps redirect
  const navigate = useNavigate();

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // get the auth user
  const { user } = useAuth();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { order } = useSelector(state => state.Order.Single);

  const { resultsNotary, resultsSecondNotary, isResultsLoadInProgress } = useSelector(state => state.Notary.RatingResults);

  const { notaryMeetingStarted, notaryMeetingLink, notaryMeetingStartError } = useSelector(state => state.Order.Single);

  const { saved } = useSelector(state => state.Notary.Rating);

  const [isRemoveNotaryConfirmationVisible, setIsRemoveNotaryConfirmationVisible] = useState(false);
  const [selectedNotary, setSelectedNotary] = useState(null);

  const canRemoveNotary = (notary) => notary && notary.status <= OrderNotary.STATUS_DOCS_PRINTED && order.notaries?.length > 1;

  const hasNotaries = order.notaries?.length > 0;

  const isAwarded = order.notaries?.[0]?.id || order.notaries?.[1]?.id;

  // find active notary, and check status
  const isOrderCompletedByNotary = !!order?.notaries && order.notaries.filter(n => !n.isInactive)[0]?.status === OrderNotary.STATUS_ORDER_COMPLETE;

  const refreshMyRating = () => {
    // get ratings for first notary if they have been assigned
    if (order.notaries?.[0]?.notaryId) {
      dispatch(getMyNotaryRating(order.notaries[0].notaryId, order.id));
      dispatch(getNotaryRatingResults(order.notaries[0].notaryId));
    }
    // get ratings for second notary if they have been assigned
    if (order.notaries?.[1]?.notaryId) {
      dispatch(getMySecondNotaryRating(order.notaries[1].notaryId, order.id));
      dispatch(getSecondNotaryRatingResults(order.notaries[1].notaryId));
    }
  };

  const startMeeting = () => dispatch(startNotaryMeeting(order.id));

  /********** EFFECTS **********/

  useEffect(() => {
    return () => {
      dispatch(doNotaryRatingCleanup());
    }
  }, []);

  useEffect(() => {
    if (order.notaries?.[0]?.notaryId || order.notaries?.[1]?.notaryId) refreshMyRating();

    return () => {
      dispatch(doMyNotaryRatingCleanup());
    }
  }, [order.notaries?.[0]?.notaryId, order.notaries?.[1]?.notaryId]);

  useEffect(() => {
    if (saved === true) {
      showSuccess("The review has been saved");
      refreshMyRating();
    } else if (saved === false) {
      showError("Unable to save the review");
    }
  }, [saved]);

  // runs whenever the 'notaryMeetingStarted' flag changes
  // which happens after a start meeting attempt
  useEffect(() => {
    if (notaryMeetingStarted === true) {
      openInNewWindow(notaryMeetingLink);
    }
  }, [notaryMeetingStarted]);

  useEffect(() => {
    if (notaryMeetingStartError) {
      if (notaryMeetingStartError instanceof ServerErrorException) {
        if (notaryMeetingStartError.code === UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF) {
          // call has been saved but the notifications could not be sent (at least some of them)
          showError("Unable to send notifications");
          return;
        }
      }
      showError("Unable to initiate call");
    }
  }, [notaryMeetingStartError]);

  const [modal_center, setmodal_center] = useState(false);
  const starStyle = {};

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const displayAppointmentDate = (date, format, notaryIndex) => {
    // Display the Appointment Date/ Time if it was set by notary
    if (date >= OrderNotary.STATUS_APPOINTMENT_SET) return formatTimestampTz(order.notaries?.[notaryIndex]?.meetingTs, format, user.timezone)
    return 'Not set'
  }

  useEffect(() => {
    if (selectedNotary) {
      setIsRemoveNotaryConfirmationVisible(true);
    }
  }, [selectedNotary]);

  const removeNotaryFromOrder = () => {
    removeNotary(order.id, selectedNotary.notaryId)
      .then(res => {
        showSuccess(`Notary ${selectedNotary.notaryFullName} has been removed from order`);
        refreshOrder();
      })
      .catch(err => {
        if (err.code == CANNOT_DELETE_SINGLE_ORDER_NOTARY) {
          showError(`Cannot remove single order notary`);
          return;
        }
        if (err.code == ORDER_NOTARY_STATUS_INVALID_FOR_REMOVAL) {
          showError(`Notary status invalid for removal`);
          return;
        }
        showError(`Unable to remove notary ${selectedNotary.notaryFullName} from order`);
      }).finally(() => {
        setSelectedNotary(null);
      })
  }

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getFullOrder(order.id));

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Notary</div>
          </Col>
          {iAmGranted(perms.edit_appointment_date) && isAwarded && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="pb-4 d-flex flex-column justify-content-between">
        <Table className="section-rows table bt-1">
          <tbody>
            {iAmGranted(perms.view_order_notary_status) && (
              <tr>
                <td className="section-row-label">Status</td>
                <td className="section-row-value ps-4">{OrderNotary.getStatusName(order.notaries?.[0]?.status) || "--"}</td>
              </tr>
            )}
            <tr>
              <td className="section-row-label">Notary Name</td>
              <td className="section-row-value ps-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center pe-3">
                    {!!order.notaries?.[0]?.notaryFullName ? <Link to={route(routes.view_notary, order.notaries?.[0]?.notaryId)}>{order.notaries?.[0]?.notaryFullName}</Link> : 'Unassigned'}
                  </div>
                  {iAmGranted(perms.assign_notaries) && order.status >= Order.STATUS_NEW && !hasNotaries && (
                    <Button
                      color="primary"
                      onClick={() => navigate(route(routes.view_order_notary, order.id))}
                    >
                      Assign
                    </Button>
                  )}
                  {iAmGranted(perms.remove_notary) && canRemoveNotary(order.notaries?.[0]) && (
                    <Button
                      color="danger"
                      onClick={() => setSelectedNotary(order.notaries?.[0])}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              </td>
            </tr>
            {!!order.notaries?.[0] && <>
              <tr>
                <td className="section-row-label">Lead Comment</td>
                <td className="section-row-value ps-4"> {!!order.notaries?.[0]?.comments ? order.notaries?.[0]?.comments : '---'}</td>
              </tr>
              <tr>
                <td className="section-row-label">Appointment Date</td>
                <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[0]?.status, formats.APPOINTMENT_DATE_FORMAT, 0)}</td>
              </tr>
              <tr>
                <td className="section-row-label">Appointment Time</td>
                <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[0]?.status, formats.APPOINTMENT_TIME_FORMAT, 0)}</td>
              </tr>
              {iAmGranted(perms.create_notary_calls) && hasNotaries && (
                <tr>
                  <td className="section-row-label">Video Call</td>
                  <td className="section-row-value ps-4">
                    <Button color="primary" className="call-btn w-100" onClick={startMeeting}>
                      Start Video Call <i className="bx bx-video" />
                    </Button>
                  </td>
                </tr>
              )}
              {!isResultsLoadInProgress && !!resultsNotary?.ratings?.general?.score && order.notaries?.[0]?.id && <tr>
                <td className="section-row-label">Rating</td>
                <td className="section-row-value d-flex justify-content-start ps-4">
                  <RatingTooltip
                    max={5}
                    defaultRating={parseInt(resultsNotary.ratings.general.score)}
                    disabled
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </td>
              </tr>}
            </>}
            <tr>
              <td className="section-row-label">2nd Notary Name</td>
              <td className={classnames("section-row-value ps-4")}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center pe-3">
                    {!!order.notaries?.[1]?.notaryFullName ? <Link to={route(routes.view_notary, order.notaries?.[1]?.notaryId)}>{order.notaries?.[1]?.notaryFullName}</Link> : 'Unassigned'}
                  </div>
                  <div>
                    {iAmGranted(perms.assign_notaries) && order.status >= Order.STATUS_NEW && !order.notaries?.[1] && (
                      <Button
                        color="primary"
                        disabled={!order.notaries?.[0]}
                        onClick={() => navigate(route(routes.view_order_notary_manual_search, order.id))}
                      >
                        Assign
                      </Button>
                    )}
                    {iAmGranted(perms.remove_notary) && canRemoveNotary(order.notaries?.[1]) && (
                      <Button
                        color="danger"
                        onClick={() => setSelectedNotary(order.notaries?.[1])}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            {!!order.notaries?.[1] && <>
              <tr>
                <td className="section-row-label">Lead Comment</td>
                <td className="section-row-value ps-4">{!!order.notaries?.[1]?.comments ? order.notaries?.[1]?.comments : '---'}</td>
              </tr>
              <tr>
                <td className="section-row-label">Appointment Date</td>
                <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[1]?.status, formats.APPOINTMENT_DATE_FORMAT, 1)}</td>
              </tr>
              <tr>
                <td className="section-row-label">Appointment Time</td>
                <td className="section-row-value ps-4">{displayAppointmentDate(order.notaries?.[1]?.status, formats.APPOINTMENT_TIME_FORMAT, 1)}</td>
              </tr>
              {!isResultsLoadInProgress && !!resultsSecondNotary?.ratings?.general?.score && hasNotaries && <tr>
                <td className="section-row-label">Rating</td>
                <td className="section-row-value d-flex justify-content-start ps-4">
                  <RatingTooltip
                    max={5}
                    defaultRating={parseInt(resultsSecondNotary.ratings.general.score)}
                    disabled
                    ActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-warning mb-2"
                        style={starStyle}
                      />
                    }
                    InActiveComponent={
                      <i
                        className="mdi mdi-star-outline text-muted mb-2"
                        style={starStyle}
                      />
                    }
                  />
                </td>
              </tr>}
            </>}
          </tbody>
        </Table>
        <div className="d-flex flex-row-reverse justify-content-between mt-3">
          {iAmGranted(perms.review_notaries) && isAwarded &&
            <>
              <button type="button" className='btn btn-secondary notary-review-btn' onClick={() => { tog_center(); }}>Notary Review <i className="mdi mdi-star-outline ms-1"></i></button>
              <NotaryRating isModalOpen={modal_center} toggleModal={() => { tog_center(); }} refreshMyRating={refreshMyRating} />
            </>
          }
          {
            hasNotaries &&
            <Link to={route(routes.view_order_notary_information, order.id)} className='btn btn-primary'>View More Details <i className="mdi mdi-arrow-right ms-1"></i></Link>
          }
        </div>
      </CardBody>
    </Card>
    {
      isRemoveNotaryConfirmationVisible && <Confirmation
        confirmBtnText="Confirm"
        reverseButtons={false}
        onConfirm={() => {
          setIsRemoveNotaryConfirmationVisible(false)
          removeNotaryFromOrder();
        }}
        onCancel={() => {
          setIsRemoveNotaryConfirmationVisible(false)
          setSelectedNotary(null);
        }}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to remove notary <span className="font-weight-semibold">{selectedNotary.notaryFullName}</span> from this signing?</span>
      </Confirmation>
    }
  </React.Fragment >
}

SectionNotaryInfo.propTypes = {
  isRefreshing: PropTypes.bool,
  isLocked: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  order: PropTypes.object,
};

export default SectionNotaryInfo;