import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody, Button, Form, Input, CardTitle } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showBriefError, showError, showSuccess, toSelectOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import Select from "react-select";

import { createPaymentPlanFee, getAdditionalFeesList, getPaymentPlanFees } from "../../../../../helpers/backendHelper";

const FormCustomFee = ({ id }) => {

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/
  const [additionalFeesListError, setAdditionalFeesListError] = useState(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [paymentPlanFees, setPaymentPlanFees] = useState(null);

  const [customFeesList, setCustomFeesList] = useState([]);
  const [isLoadingPaymentPlan, setIsLoadingPaymentPlan] = useState(false);

  const isListEmpty = customFeesList.length === 0

  /********** FORM CONFIG **********/

  const formInitialValues = {
    additionalFeeId: null,
    price: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      additionalFeeId: Yup.number(),
      price: Yup.number(),
    }),
    onSubmit: values => {
      addFeeToPaymentPlan(values, id);
    }
  });

  /********** EFFECTS **********/
  useEffect(() => {
    refreshPaymentPlanFees();
  }, []);

  useEffect(() => {
    refreshAdditionalFeesList();
  }, [isLoadingPaymentPlan]);

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** OTHER **********/
  const refreshPaymentPlanFees = () => {
    setIsLoadingPaymentPlan(true);
    getPaymentPlanFees(id)
      .then(response => {
        setPaymentPlanFees(response.fees);
      })
      .catch(err => {
        showError('Unable to load custom fees');
      })
      .finally(() => {
        setIsLoadingPaymentPlan(false);
      })
  }

  const refreshAdditionalFeesList = () => {
    if (!isLoadingPaymentPlan) {
      getAdditionalFeesList()
        .then(response => {
          // Convert additional fees list to select options
          const feesList = toSelectOptions(response.additionalFees);

          // Filter out fees that have already been added to paymentPlanFees
          const filteredFeesList = feesList.filter(feeItem => {
            return !paymentPlanFees.some(fee => fee.additionalFeeId === feeItem.value);
          });
          setCustomFeesList(filteredFeesList);
        })
        .catch(err => {
          setAdditionalFeesListError(err)
        })
    }
  }

  const addFeeToPaymentPlan = (values, id) => {
    setIsSaveInProgress(true);
    createPaymentPlanFee(values, id)
      .then(response => {
        showSuccess('Fee has been saved');
        navigate(route(routes.view_payment_plan, id));
      })
      .catch(error => {
        showError('Unable to save fee');
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      })
  }

  /********** EVENT HANDLERS **********/
  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row className='align-items-center'>
          <Col sm='5'>
            <CardTitle>Choose a fee below</CardTitle>
          </Col>
          <Col sm='7'>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting || isListEmpty}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save fee
              </Button>
              <Link to={route(routes.view_payment_plan, id)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {!isListEmpty ? (<Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Col sm={8}>
                  <Select
                    classNamePrefix="select2-selection"
                    name="additionalFeeId"
                    options={customFeesList}
                    onChange={selected => formik.setFieldValue('additionalFeeId', selected.value)}
                    onFocus={e => onFieldFocused(e, 'additionalFeeId')}
                    value={customFeesList.find(option => option.value === formik.values.additionalFeeId)}
                    className={!!formik.errors.additionalFeeId && 'is-invalid'}
                  />
                </Col>
                <Col sm={4}>
                  <Input type="number" className="form-control" placeholder="ex. 100" name="price" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.price} invalid={!!formik.errors.price} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>) : (<Row><h4>You added all the fees.</h4></Row>)}
      </CardBody>
    </Card>
  </React.Fragment>
}

FormCustomFee.propTypes = {
  id: PropTypes.number,
};

export default FormCustomFee;
