import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, Button } from "reactstrap";
import { Link } from "react-router-dom";
import SpinnerChase from 'components/Shared/SpinnerChase';
import config from 'config';
import { getManagedStores } from "helpers/backendHelper";
import { route, routes } from "helpers/routeHelper";

const SectionManagedStores = () => {

  let { id } = useParams();
  id = parseInt(id);

  /********** STATE **********/

  const [stores, setStores] = useState([]);
  const [storesError, setStoresError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getStores();
  }, []);

  /********** EVENT HANDLERS **********/

  const toggleExpanded = () => setIsExpanded(expanded => !expanded);

  /********** OTHER **********/

  const getTableRows = () => isExpanded ? stores : stores.slice(0, config.COLLAPSED_TBL_ROWS);

  const canExpand = () => !isExpanded && stores.length > config.COLLAPSED_TBL_ROWS;

  const canCollapse = () => isExpanded;

  const getStores = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the stores data
    getManagedStores(id)
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Managed Stores</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-1">
        <table className="table">
          <thead>
            <tr>
              <th>Store Name</th>
              <th>Group Name</th>
              <th>Region</th>
              <th>Store Manager</th>
              <th>Store Address</th>
            </tr>
          </thead>
          <tbody>
            {getTableRows().length > 0 && getTableRows().map((entry, index) => (
              <tr key={index}>
                <td><Link to={route(routes.view_dealer_store, entry.id)}>{entry.name}</Link></td>
                <td>{entry.dealerGroupName ? <Link to={route(routes.view_dealer_group, entry.dealerGroupId)}>{entry.dealerGroupName}</Link> : "--"}</td>
                <td>{entry.dealerRegionName ? <Link to={route(routes.view_dealer_region, entry.dealerRegionId)}>{entry.dealerRegionName}</Link> : "--"}</td>
                <td>{entry.managerFullName || "--"}</td>
                <td>{entry.address || "--"}</td>
              </tr>
            ))}
            {getTableRows().length == 0 && !storesError && !isLoadInProgress && <tr>
              <td className="table-empty" colSpan="5">No stores found</td>
            </tr>}
            {!!storesError && <tr>
              <td className="table-empty" colSpan="5">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load stores
                </Alert>
              </td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="3">
                <SpinnerChase className="sm" />
              </td>
            </tr>}
          </tbody>
        </table>
        <div className="text-center">
          {canExpand() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-down me-1"></i>View more</Button>}
          {canCollapse() && <Button type="button" color="link" size="sm" onClick={toggleExpanded}><i className="mdi mdi-chevron-up me-1"></i>View less</Button>}
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
}

export default SectionManagedStores;