import React from 'react';
import PropTypes from 'prop-types';
import { formats, formatTimestamp } from 'helpers/dateHelper';
import { UncontrolledTooltip } from "reactstrap";

const EventTime = props => {

  const { log, showFull } = props;

  return <React.Fragment>
    <span className="text-muted event-time" id={`evt-timestamp-${log._id}`}>{formatTimestamp(log.timestamp, formats.DATETIME)}</span>
    {showFull && <UncontrolledTooltip placement="top" target={`evt-timestamp-${log._id}`}>{formatTimestamp(log.timestamp, formats.DATETIME_FULL)}</UncontrolledTooltip>}
  </React.Fragment>
}

EventTime.propTypes = {
  log: PropTypes.object,
  showFull: PropTypes.bool,
}

export default EventTime;