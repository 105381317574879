import {
  GET_DEALER_STORE_DT,
  GET_DEALER_STORE_DT_OK,
  GET_DEALER_STORE_DT_ERR,
  DO_DEALER_STORE_DT_CLEANUP,

  APPLY_DEALER_STORE_DT_FILTERS,
  CLEAR_DEALER_STORE_DT_FILTERS,

  GET_DEALER_STORE_LIST,
  GET_DEALER_STORE_LIST_OK,
  GET_DEALER_STORE_LIST_ERR,
  DO_DEALER_STORE_LIST_CLEANUP,

  GET_SUBSCRIPTION_DT,
  GET_SUBSCRIPTION_DT_OK,
  GET_SUBSCRIPTION_DT_ERR,
  DO_SUBSCRIPTION_DT_CLEANUP,
  GET_DEALER_REGION_STORE_LIST,
  GET_DEALER_GROUP_STORE_LIST,
} from "./actionTypes";

/********** DATATABLE **********/

export const getDealerStoreDt = params => ({
  type: GET_DEALER_STORE_DT,
  payload: { params }
})

export const getDealerStoreDtOk = response => ({
  type: GET_DEALER_STORE_DT_OK,
  payload: { response },
});

export const getDealerStoreDtErr = error => ({
  type: GET_DEALER_STORE_DT_ERR,
  payload: { error },
});

export const doDealerStoreDtCleanup = () => ({
  type: DO_DEALER_STORE_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyDealerStoreDtFilters = filters => ({
  type: APPLY_DEALER_STORE_DT_FILTERS,
  payload: { filters },
});

export const clearDealerStoreDtFilters = () => ({
  type: CLEAR_DEALER_STORE_DT_FILTERS,
});

/********* LIST *********/

export const getDealerStoreList = () => ({
  type: GET_DEALER_STORE_LIST,
});

export const getDealerGroupStoreList = groupId => ({
  type: GET_DEALER_GROUP_STORE_LIST,
  payload: { groupId },
});

export const getDealerRegionStoreList = regionId => ({
  type: GET_DEALER_REGION_STORE_LIST,
  payload: { regionId },
});

export const getDealerStoreListOk = response => ({
  type: GET_DEALER_STORE_LIST_OK,
  payload: { response },
});

export const getDealerStoreListErr = error => ({
  type: GET_DEALER_STORE_LIST_ERR,
  payload: { error },
});

export const doDealerStoreListCleanup = () => ({
  type: DO_DEALER_STORE_LIST_CLEANUP,
});

/********* SUBSCRIPTIONS DATATABLE *********/

export const getSubscriptionDt = params => ({
  type: GET_SUBSCRIPTION_DT,
  payload: { params },
});

export const getSubscriptionDtOk = response => ({
  type: GET_SUBSCRIPTION_DT_OK,
  payload: { response },
});

export const getSubscriptionDtErr = error => ({
  type: GET_SUBSCRIPTION_DT_ERR,
  payload: { error },
});

export const doSubscriptionDtCleanup = () => ({
  type: DO_SUBSCRIPTION_DT_CLEANUP,
});