import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Table, Button, UncontrolledTooltip } from "reactstrap";
import { map } from "lodash";
import logo from "assets/images/mavsign-logo-blue.svg";
import DealerStore from "model/dealerStore";
import { showSuccess, showError, getBeUrl } from "../../../../../helpers/utilHelper";
import { sendStatementToQb } from "../../../../../helpers/backendHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { useParams, Link } from "react-router-dom";
import downloadDocumentIcon from 'assets/images/download-icon.svg';
import { perms, useAccess } from "../../../../../context/access";
import PaymentMethod from "./PaymentMethod";
import PaymentReceivedTable from "./PaymentsReceivedTable";
import { STATEMENT_NOT_CLOSED } from 'helpers/errorHelper';
import { route, routes } from "helpers/routeHelper";

const SectionInfo = ({ statement, setReferenceSaved, refreshStatement }) => {

  const { lines, amount, paymentMethod, notaryName, notaryAddress, notaryEmail, notaryCity, notaryState, notaryZip, notaryQbVendorId } = statement;

  const isCheckMethod = paymentMethod === DealerStore.PAYMENT_METHOD_TYPE_CHECK;
  const hasPayments = !!(statement && statement.payments?.length);


  let { id } = useParams();
  id = parseInt(id);

  const { iAmGranted } = useAccess();

  /********** EVENT HANDLERS **********/

  const sendToQb = () => {
    sendStatementToQb(id)
      .then(() => {
        showSuccess(`Statement has been succesfully sent in QB`);
        refreshStatement();
      })
      .catch((err) => {
        if (err.code == STATEMENT_NOT_CLOSED) {
          showError("This statement has invalid time frame");
          return
        }
        showError("Unable to send statement in QB");
      })
  };

  const downloadStatementPdf = () => {
    // for check payment method, can download pdf only if statement has reference
    if (isCheckMethod && (!hasPayments || !statement.payments[0].refId)) {
      showError("Please complete the Reference number before downloading the Statement");
      return;
    }
    window.open(getBeUrl(`statement/${statement.id}/download`), "_blank", "noreferrer");
  }

  const displayPrice = (feeCount, price) => {
    if(feeCount > 1) return `${feeCount} x $${price}`;
    return `$${price}`;
  }

  return <Row>
    <Col lg="12">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col sm={6}>
              <img src={logo} alt="logo" height="30" />
            </Col>
            {isCheckMethod && <Col sm={6} className="text-end">
              {statement.qbDocNo != null
                ? <div className="qb-id">
                  QB Bill for: {statement.qbDocNo}
                </div>
                : iAmGranted(perms.send_statements_QB) && statement.isClosed && <React.Fragment>
                  <div id="send-to-qb-btn" style={{ display: 'inline-block' }}>
                    <Button type="button" color="primary" onClick={sendToQb} disabled={!statement.canSendToQb} id="send-to-qb-btn">Send to QB</Button>
                  </div>
                  {!statement.canSendToQb && <UncontrolledTooltip placement="top" target="send-to-qb-btn">This statement has errors and cannot be sent to QuickBooks</UncontrolledTooltip>}
                </React.Fragment>
              }
            </Col>}
          </Row>
          <hr />
          <Row className="mb-5">
            <Col xs="6">
              <p>
                <strong>Notary Information</strong>
                {!notaryQbVendorId && <React.Fragment>
                  <i id="vendor-warning" className="mdi mdi-alert-circle text-danger ms-2"></i>
                  <UncontrolledTooltip placement="top" target="vendor-warning">This notary is not attached to a QuickBooks vendor</UncontrolledTooltip>
                </React.Fragment>}
              </p>
              <address>
                <strong>Name</strong>
                <div>{notaryName}</div>
              </address>
              <address>
                <strong>Address</strong>
                <div>{notaryAddress}</div>
                <div>{`${notaryCity}, ${notaryState} ${notaryZip}`}</div>
              </address>
              <address>
                <strong>Email</strong>
                <div>{notaryEmail || "--"}</div>
              </address>
            </Col>
          </Row>

          <Row className="mb-5">
            <div className="py-2 mt-3">
              <h3 className="font-size-15 fw-bold">Transaction Detail</h3>
            </div>
            <div className="table-responsive">
              <Table className="table-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: "70px" }}>No.</th>
                    <th>Order ID</th>
                    <th>Completion Date</th>
                    <th>Notary Service</th>
                    <th>Customer Name</th>
                    <th className="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {map(
                    lines,
                    (line, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td><Link to={route(routes.view_order, line.orderId)}>{line.orderId}</Link></td>
                        <td>{formatTimestamp(line.date, formats.LONG_DATE)}</td>
                        <td>
                          {line.description}
                          {!line.qbLineItemId && <React.Fragment>
                            <i id={`line-item-warning-${index}`} className="mdi mdi-alert-circle text-danger ms-2"></i>
                            <UncontrolledTooltip placement="top" target={`line-item-warning-${index}`}>This service is not attached to a QuickBooks line item</UncontrolledTooltip>
                          </React.Fragment>}
                        </td>
                        <td>{line.customerName}</td>
                        <td className="text-end">{displayPrice(line.additionalFeeCount, line.additionalFeePrice)}</td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td colSpan="5" className="border-0 text-end">
                      Sub Total Price
                    </td>
                    <td className="border-0 text-end">
                      <h6 className="m-0">
                        {`$${amount}`}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" className="border-0 text-end">
                      <strong>Total Price</strong>
                    </td>
                    <td className="border-0 text-end">
                      <h4 className="m-0">
                        {`$${amount}`}
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>

          <Col sm={12} className="mb-4">
            <PaymentMethod paymentMethod={statement.paymentMethod} paymentRef={statement.paymentRefId} setReferenceSaved={setReferenceSaved} />
          </Col>

          {hasPayments && <Col sm={12} className="mb-4">
            <PaymentReceivedTable statement={statement} />
          </Col>}

          <Button type="button" color="primary" className="float-end" onClick={downloadStatementPdf}>
            <span>Download</span>
            <img className='ps-2' src={downloadDocumentIcon} />
          </Button>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

SectionInfo.propTypes = {
  statement: PropTypes.object,
  setReferenceSaved: PropTypes.func,
  refreshStatement: PropTypes.func,
};

export default SectionInfo;