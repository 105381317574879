import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import CardPreloader from 'components/Shared/CardPreloader';

const ViewContact2 = props => {

  const { isRefreshing, dealerStore, toggleEditMode } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Alternate contact</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6">
            <Row className="mb-3">
              <label className="mb-0">Contact name</label>
              <div>{dealerStore.contactName || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Email</label>
              <div>{dealerStore.contactEmail || '--'}</div>
            </Row>
          </Col>
          <Col xs="6">
            <Row className="mb-3">
              <label className="mb-0">Phone</label>
              <div>{dealerStore.contactPhone || '--'}</div>
            </Row>
            <Row className="mb-3">
              <label className="mb-0">Alternate phone</label>
              <div>{dealerStore.contactPhone2 || '--'}</div>
            </Row>
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewContact2.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewContact2;