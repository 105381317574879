import { combineReducers } from "redux";
import {
  GET_DEALER_GROUP_REGION_LIST,
  GET_DEALER_REGION_LIST_OK,
  GET_DEALER_REGION_LIST_ERR,
  DO_DEALER_REGION_LIST_CLEANUP,
} from "./actionTypes";

/********* LIST *********/

const defaultListState = {
  regions: [],
  regionsError: null,
  isLoadInProgress: false,
};

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_DEALER_GROUP_REGION_LIST:
      state = {
        ...state,
        regionsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_DEALER_REGION_LIST_OK:
      state = {
        ...state,
        regions: action.payload.response.dealerRegions,
        isLoadInProgress: false,
      };
      break
    case GET_DEALER_REGION_LIST_ERR:
      state = {
        ...state,
        regions: [],
        regionsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_DEALER_REGION_LIST_CLEANUP:
      state = { ...defaultListState };
      break
  }
  return state;
}

export default combineReducers({
  List,
})