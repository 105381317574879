import {
  GET_PAYMENT_PLAN_DT,
  GET_PAYMENT_PLAN_DT_OK,
  GET_PAYMENT_PLAN_DT_ERR,
  DO_PAYMENT_PLAN_DT_CLEANUP,
  APPLY_PAYMENT_PLAN_DT_FILTERS,
  CLEAR_PAYMENT_PLAN_DT_FILTERS,
  GET_PAYMENT_PLAN_LIST,
  GET_PAYMENT_PLAN_LIST_OK,
  GET_PAYMENT_PLAN_LIST_ERR,
  DO_PAYMENT_PLAN_LIST_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getPaymentPlanDt = params => ({
  type: GET_PAYMENT_PLAN_DT,
  payload: { params }
})

export const getPaymentPlanDtOk = response => ({
  type: GET_PAYMENT_PLAN_DT_OK,
  payload: { response },
});

export const getPaymentPlanDtErr = error => ({
  type: GET_PAYMENT_PLAN_DT_ERR,
  payload: { error },
});

export const doPaymentPlanDtCleanup = () => ({
  type: DO_PAYMENT_PLAN_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyPaymentPlanDtFilters = filters => ({
  type: APPLY_PAYMENT_PLAN_DT_FILTERS,
  payload: { filters },
});

export const clearPaymentPlanDtFilters = () => ({
  type: CLEAR_PAYMENT_PLAN_DT_FILTERS,
});

/********* LIST *********/

export const getPaymentPlanList = () => ({
  type: GET_PAYMENT_PLAN_LIST,
});

export const getPaymentPlanListOk = response => ({
  type: GET_PAYMENT_PLAN_LIST_OK,
  payload: { response },
});

export const getPaymentPlanListErr = error => ({
  type: GET_PAYMENT_PLAN_LIST_ERR,
  payload: { error },
});

export const doPaymentPlanListCleanup = () => ({
  type: DO_PAYMENT_PLAN_LIST_CLEANUP,
});