import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classnames from "classnames";

const Templates = ({ activeIndex, templateMessages, onItemClick, numberOfMultipleItems, onItemChange }) => {

  // Function that truncates the text
  const truncateText = (text) => {
    return text.length > 100 ? text.substring(0, 100) + "..." : text;
  }

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={false}
      centerSlidePercentage={100 / numberOfMultipleItems}
      centerMode
      selectedItem={activeIndex}
      onClickItem={(item) => {
        onItemChange(item)
      }}
    >
      {templateMessages.map((item, idx) => {
        return (
          <div key={idx}>
            <div className={classnames("carousel-item-template", { "me-3 ms-1": numberOfMultipleItems !== 1 })} onClick={() => onItemClick(item)}>{truncateText(item)}</div>
          </div>
        )
      })}
    </Carousel>
  )
}

Templates.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  templateMessages: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  numberOfMultipleItems: PropTypes.number.isRequired,
  onItemChange: PropTypes.func.isRequired
}

export default Templates;