import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_ERR, DELETE_NOTIFICATION_OK, DO_NOTIFICATION_SINGLE_CLEANUP, GET_NOTIFICATION, GET_NOTIFICATION_ERR, GET_NOTIFICATION_OK, MARK_NOTIFICATION_ERR, MARK_NOTIFICATION_READ, MARK_NOTIFICATION_READ_OK, MARK_NOTIFICATION_UNREAD, MARK_NOTIFICATION_UNREAD_OK, CHANGE_NOTIFICATIONS_PAGE, DELETE_NOTIFICATIONS, DELETE_NOTIFICATIONS_ERR, DELETE_NOTIFICATIONS_OK, DO_NOTIFICATION_DT_CLEANUP, DO_NOTIFICATION_LIST_CLEANUP, GET_NOTIFICATION_DT, GET_NOTIFICATION_DT_ERR, GET_NOTIFICATION_DT_OK, GET_NOTIFICATION_LIST, GET_NOTIFICATION_LIST_ERR, GET_NOTIFICATION_LIST_OK, MARK_NOTIFICATIONS_ERR, MARK_NOTIFICATIONS_READ, MARK_NOTIFICATIONS_READ_OK, MARK_NOTIFICATIONS_UNREAD, MARK_NOTIFICATIONS_UNREAD_OK } from "./actionTypes";

/********** DATATABLE **********/

export const getNotificationDt = params => ({
  type: GET_NOTIFICATION_DT,
  payload: { params },
});

export const getNotificationDtOk = response => ({
  type: GET_NOTIFICATION_DT_OK,
  payload: { response },
});

export const getNotificationDtErr = error => ({
  type: GET_NOTIFICATION_DT_ERR,
  payload: { error },
});

export const doNotificationDtCleanup = () => ({
  type: DO_NOTIFICATION_DT_CLEANUP,
});

export const changeNotificationsPage = page => ({
  type: CHANGE_NOTIFICATIONS_PAGE,
  payload: { page },
});

export const markNotificationsRead = ids => ({
  type: MARK_NOTIFICATIONS_READ,
  payload: { ids }
});

export const markNotificationsUnread = ids => ({
  type: MARK_NOTIFICATIONS_UNREAD,
  payload: { ids }
});

export const markNotificationsReadOk = response => ({
  type: MARK_NOTIFICATIONS_READ_OK,
  payload: { response },
});

export const markNotificationsUnreadOk = response => ({
  type: MARK_NOTIFICATIONS_UNREAD_OK,
  payload: { response },
});

export const markNotificationsErr = error => ({
  type: MARK_NOTIFICATIONS_ERR,
  payload: { error },
});

export const deleteNotifications = ids => ({
  type: DELETE_NOTIFICATIONS,
  payload: { ids },
});

export const deleteNotificationsOk = () => ({
  type: DELETE_NOTIFICATIONS_OK,
});

export const deleteNotificationsErr = error => ({
  type: DELETE_NOTIFICATIONS_ERR,
  payload: { error }
});

/********** LIST **********/

export const getNotificationList = () => ({
  type: GET_NOTIFICATION_LIST,
});

export const getNotificationListOk = response => ({
  type: GET_NOTIFICATION_LIST_OK,
  payload: { response },
});

export const getNotificationListErr = error => ({
  type: GET_NOTIFICATION_LIST_ERR,
  payload: { error },
});

export const doNotificationListCleanup = () => ({
  type: DO_NOTIFICATION_LIST_CLEANUP,
});

/********** SINGLE **********/

export const getNotification = id => ({
  type: GET_NOTIFICATION,
  payload: { id },
});

export const getNotificationOk = response => ({
  type: GET_NOTIFICATION_OK,
  payload: { response },
});

export const getNotificationErr = error => ({
  type: GET_NOTIFICATION_ERR,
  payload: { error },
});

export const deleteNotification = id => ({
  type: DELETE_NOTIFICATION,
  payload: { id },
});

export const deleteNotificationOk = () => ({
  type: DELETE_NOTIFICATION_OK,
});

export const deleteNotificationErr = error => ({
  type: DELETE_NOTIFICATION_ERR,
  payload: { error }
});

export const markNotificationRead = id => ({
  type: MARK_NOTIFICATION_READ,
  payload: { id }
});

export const markNotificationUnread = id => ({
  type: MARK_NOTIFICATION_UNREAD,
  payload: { id }
});

export const markNotificationReadOk = response => ({
  type: MARK_NOTIFICATION_READ_OK,
  payload: { response },
});

export const markNotificationUnreadOk = response => ({
  type: MARK_NOTIFICATION_UNREAD_OK,
  payload: { response },
});

export const markNotificationErr = error => ({
  type: MARK_NOTIFICATION_ERR,
  payload: { error },
});

export const doNotificationSingleCleanup = () => ({
  type: DO_NOTIFICATION_SINGLE_CLEANUP,
});