import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody, Input } from "reactstrap";
import { useFormik } from "formik";
import { hasNonEmpty, extractDtFiltersFromUrl, toSelectOptions, showError } from "helpers/utilHelper";
import { applyNotaryStatementDtFilters, clearNotaryStatementDtFilters, patchNotaryStatementDtFilters, unsetNotaryStatementDtFilters } from "store/actions";
import { isEmpty, omit } from "lodash";
import DateRangePicker from "components/Shared/DateRangePicker";
import * as Yup from "yup";
import { getNotaryUserList } from "helpers/backendHelper";
import Select from 'react-select';
import Order from "model/order";
import { useNavigate } from "react-router-dom";


const Filters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.NotaryStatement.DtFilters);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);
  const [notaries, setNotaries] = useState([]);

  /********** FORM CONFIG **********/

  // range state is only for helping us build the createdBetween filter
  // we won't keep it in redux state
  const formInitialValues = {
    ...filters,
    range: filters.createdBetween ? filters.createdBetween.split("-") : [],
    notaryId: '',
    orderStatus: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      range: Yup.array(),
    }),
    initialValues: formInitialValues,
    onSubmit: values => {
      applyFilters({ ...omit(values, "range"), createdBetween: values.range.join("-") })
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    getNotaryListOptions();
    // extract filters from url
    // they will be applied by default as the user enters the screen
    const filters = extractDtFiltersFromUrl();
    // if url filters are not empty
    if (!isEmpty(filters)) {
      // in this case, replace the old filters with the relevant ones
      applyFilters(filters);
    } else {
      // make sure to call this even if there are no filters in the url
      // because we need to switch the '_set' flag
      dispatch(patchNotaryStatementDtFilters(filters));
    }

    return () => {
      // switch back the '_set' flag
      dispatch(unsetNotaryStatementDtFilters());
    }
  }, []);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    formik.setFieldValue("range", selected);
  };

  const handleClearRange = () => {
    formik.setFieldValue("range", []);
  };

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the 'apply-filters' button
  const applyFilters = values => dispatch(applyNotaryStatementDtFilters(values));

  // event handler for the 'clear-filters' button
  const clearFilters = () => {
    // reset form fields
    formik.setValues({
      ...formik.initialValues,
    });
    // reset state
    dispatch(clearNotaryStatementDtFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(formInitialValues);

  const hasFilters = () => hasNonEmpty(omit(filters, "_set"));

  /************* HELPERS ************/

  const getNotaryListOptions = () => {
    getNotaryUserList()
      .then((response) => {
        // Transform the response array of notaries
        // to be like {id: notaryId, name: notaryName} for the select component
        const notariesList = response.map(notary => ({ id: notary.id, name: notary.userFullName }));
        setNotaries(notariesList);
      })
      .catch(() => {
        showError("Unable to load data.");
      })
  }

  // adds 'All' to the list of notaries
  // prepares the list to be used as select options
  const getNotaryOptions = () => {
    return [{ label: 'All', value: '' }, ...toSelectOptions(notaries)];
  }

  // adds 'All' to the list of order statuses
  // prepares the list to be used as select options
  const getOrderStatusesOptions = () => {
    return [{ label: 'All', value: '' }, ...Order.getStatusOptionsList()];
  }

  return <React.Fragment>
    <div className="btn-group ms-2 mb-2" >
      <Button type="button" color="dark" onClick={toggleFilters}>
        <i className="mdi mdi-filter-variant me-1" />Filters
      </Button>
      {hasFilters() && <Button type="button" color="dark" onClick={clearFilters}>
        <i className="mdi mdi-close" />
      </Button>}
    </div>
    <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
      <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="mb-3">
            <Label>Generated Between</Label>
            <DateRangePicker
              onChange={handleChangeRange}
              value={formik.values.range}
              onClear={handleClearRange}
            />
            {!!formik.errors.range && <FormFeedback type="invalid">{formik.errors.range}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Notary name</Label>
            <Select
              classNamePrefix="select2-selection"
              name="notaryId"
              options={getNotaryOptions()}
              onChange={selected => formik.setFieldValue("notaryId", selected.value)}
              value={getNotaryOptions().find(option => option.value === formik.values.notaryId)}
              className={!!formik.errors.notaryId && "is-invalid"} />
            {!!formik.errors.notaryId && <FormFeedback type="invalid">{formik.errors.notaryId}</FormFeedback>}
          </div>
          <div className="mt-2 mb-3">
            <Label>Order status</Label>
            <Select
              classNamePrefix="select2-selection"
              name="orderStatus"
              options={getOrderStatusesOptions()}
              onChange={selected => formik.setFieldValue("orderStatus", selected.value)}
              value={getOrderStatusesOptions().find(option => option.value === formik.values.orderStatus)}
              className={!!formik.errors.orderStatus && "is-invalid"} />
            {!!formik.errors.orderStatus && <FormFeedback type="invalid">{formik.errors.orderStatus}</FormFeedback>}
          </div>

          <div className="mb-3">
            <Label>Order ID</Label>
            <Input type="text" className="form-control" name="orderId" onChange={formik.handleChange} value={formik.values.orderId} invalid={!!formik.errors.orderId} />
            {!!formik.errors.orderId && <FormFeedback type="invalid">{formik.errors.orderId}</FormFeedback>}
          </div>

          <div className="text-end">
            <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
              <i className="mdi mdi-filter me-1" />Apply
            </Button>
            <Button type="button" color="warning" onClick={clearFilters}>
              <i className="mdi mdi-eraser me-1" />Clear
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  </React.Fragment>
}

export default Filters;