import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table, Card, CardBody, Alert } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { getErrCodeList } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";

const ListErrCodes = () => {

  const { iAmGranted, iAmNotGranted } = useAccess();

  const [errCodes, setErrCodes] = useState([]);
  const [errCodesError, setErrCodesError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  useEffect(() => {
    refreshErrCodes();
  }, []);

  const refreshErrCodes = () => {
    setIsLoadInProgress(true);
    getErrCodeList()
      .then(response => {
        setErrCodes(response.errCodes);
      })
      .catch(ex => {
        setErrCodesError('Unable to load error codes');
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }

  return <React.Fragment>
    {iAmGranted(perms.view_error_codes) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>Error Codes</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="ERROR CODES" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!errCodes.length && errCodes.map(entry => <tr key={entry.code}>
                        <td>{entry.code}</td>
                        <td>{entry.name}</td>
                      </tr>)}
                      {!errCodes.length && !isLoadInProgress && !errCodesError && <tr>
                        <td colSpan="2" className="text-center">No items to display</td>
                      </tr>}
                      {isLoadInProgress && <tr>
                        <td colSpan="2">
                          <SpinnerChase className="sm mt-2 mb-2" />
                        </td>
                      </tr>}
                      {!!errCodesError && <tr>
                        <td colSpan="2">
                          <Alert color="danger" className="fade show text-center">
                            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load error codes
                          </Alert>
                        </td>
                      </tr>}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    }
    {iAmNotGranted(perms.view_error_codes) && <AccessDenied />}
  </React.Fragment >
}

export default ListErrCodes;
