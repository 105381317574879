import {
  GET_NOTARY_DT,
  GET_NOTARY_DT_ERR,
  GET_NOTARY_DT_OK,
  DO_NOTARY_DT_CLEANUP,

  APPLY_NOTARY_DT_FILTERS,
  CLEAR_NOTARY_DT_FILTERS,
  PATCH_NOTARY_DT_FILTERS,
  UNSET_NOTARY_DT_FILTERS,

  DO_NOTARY_DT_FILTERS_CLEANUP,

  ADD_NOTARY_RATING,
  ADD_NOTARY_RATING_OK,
  ADD_NOTARY_RATING_ERR,
  DO_NOTARY_RATING_CLEANUP,

  GET_NOTARY_RATING_RESULTS,
  GET_SECOND_NOTARY_RATING_RESULTS,
  GET_NOTARY_RATING_RESULTS_OK,
  GET_SECOND_NOTARY_RATING_RESULTS_OK,
  GET_NOTARY_RATING_RESULTS_ERR,
  DO_NOTARY_RATING_RESULTS_CLEANUP,

  GET_MY_NOTARY_RATING,
  GET_MY_SECOND_NOTARY_RATING,
  GET_MY_NOTARY_RATING_OK,
  GET_MY_SECOND_NOTARY_RATING_OK,
  GET_MY_NOTARY_RATING_ERR,
  DO_MY_NOTARY_RATING_CLEANUP,
} from "./actionTypes";

/********** DATATABLE **********/

export const getNotaryDt = params => ({
  type: GET_NOTARY_DT,
  payload: { params },
});

export const getNotaryDtOk = response => ({
  type: GET_NOTARY_DT_OK,
  payload: { response },
});

export const getNotaryDtErr = error => ({
  type: GET_NOTARY_DT_ERR,
  payload: { error },
});

export const doNotaryDtCleanup = () => ({
  type: DO_NOTARY_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyNotaryDtFilters = filters => ({
  type: APPLY_NOTARY_DT_FILTERS,
  payload: { filters },
});

export const patchNotaryDtFilters = filters => ({
  type: PATCH_NOTARY_DT_FILTERS,
  payload: { filters },
});

export const clearNotaryDtFilters = () => ({
  type: CLEAR_NOTARY_DT_FILTERS,
});

export const unsetNotaryDtFilters = () => ({
  type: UNSET_NOTARY_DT_FILTERS,
});

export const doNotaryDtFiltersCleanup = () => ({
  type: DO_NOTARY_DT_FILTERS_CLEANUP,
});


/********** RATING **********/

export const addNotaryRating = (id, data) => ({
  type: ADD_NOTARY_RATING,
  payload: { id, data },
});

export const addNotaryRatingOk = response => ({
  type: ADD_NOTARY_RATING_OK,
  payload: { response },
})

export const addNotaryRatingErr = error => ({
  type: ADD_NOTARY_RATING_ERR,
  payload: { error },
})

export const doNotaryRatingCleanup = () => ({
  type: DO_NOTARY_RATING_CLEANUP,
})

/********** RATING RESULTS **********/

export const getNotaryRatingResults = id => ({
  type: GET_NOTARY_RATING_RESULTS,
  payload: { id },
});

export const getSecondNotaryRatingResults = id => ({
  type: GET_SECOND_NOTARY_RATING_RESULTS,
  payload: { id },
});

export const getNotaryRatingResultsOk = response => ({
  type: GET_NOTARY_RATING_RESULTS_OK,
  payload: { response },
});

export const getSecondNotaryRatingResultsOk = response => ({
  type: GET_SECOND_NOTARY_RATING_RESULTS_OK,
  payload: { response },
});

export const getNotaryRatingResultsErr = error => ({
  type: GET_NOTARY_RATING_RESULTS_ERR,
  payload: { error },
});

export const doNotaryRatingResultsCleanup = () => ({
  type: DO_NOTARY_RATING_RESULTS_CLEANUP,
});


/********** MY NOTARY RATING **********/

export const getMyNotaryRating = (notaryId, orderId) => ({
  type: GET_MY_NOTARY_RATING,
  payload: { notaryId, orderId },
});

export const getMySecondNotaryRating = (notaryId, orderId) => ({
  type: GET_MY_SECOND_NOTARY_RATING,
  payload: { notaryId, orderId },
});

export const getMyNotaryRatingOk = response => ({
  type: GET_MY_NOTARY_RATING_OK,
  payload: { response },
});

export const getMySecondNotaryRatingOk = response => ({
  type: GET_MY_SECOND_NOTARY_RATING_OK,
  payload: { response },
});

export const getMyNotaryRatingErr = error => ({
  type: GET_MY_NOTARY_RATING_ERR,
  payload: { error },
});

export const doMyNotaryRatingCleanup = () => ({
  type: DO_MY_NOTARY_RATING_CLEANUP,
});