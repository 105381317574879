import React from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper } from "google-maps-react";

const GoogleApiContext = React.createContext();

const GoogleApiProvider = props => (
  <GoogleApiContext.Provider value={{ google: props.google }} {...props} />
);

GoogleApiProvider.propTypes = {
  google: PropTypes.object,
}

// helper hook that makes context data available
export const useGoogleApi = () => React.useContext(GoogleApiContext);

// use GoogleApiWrapper higher-order component to configure GoogleApi
// this HOC will inject the `google` object as a prop in GoogleApiProvider
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleApiProvider);