import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import regx from "constants/regx";
import { phoneHasNoOfDigits, showError, showSuccess } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { route, routes } from "helpers/routeHelper";
import { createDealerStoreSigner } from "helpers/backendHelper";

const FormNew = props => {

  const { id } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('Field is required'),
      lastName: Yup.string().trim().required('Field is required'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
        'Field requires exactly 10 digits',
        ((value) => phoneHasNoOfDigits(value))
      ),
    }),
    onSubmit: values => saveSigner({
      ...values,
      dealerStoreId: id,
    }),
  });

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const saveSigner = values => {
    setIsSaveInProgress(true);
    createDealerStoreSigner(values)
      .then(response => {
        showSuccess(`Signer "${values.firstName} ${values.lastName}" has been saved`);
        navigate(route(routes.view_dealer_store, id));
      })
      .catch(ex => {
        showError('Unable to save signer');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  return <React.Fragment>
    <Card>
      <CardHeader className="bg-transparent pt-3">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="primary" className="mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Save signer
              </Button>
              <Link to={route(routes.view_dealer_store, id)} className="btn btn-secondary ms-2 mb-2">
                <i className="mdi mdi-chevron-left me-1" />Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Form>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">First name</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. John" name="firstName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.firstName} invalid={!!formik.errors.firstName} />
                  {!!formik.errors.firstName && <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Last name</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. Doe" name="lastName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.lastName} invalid={!!formik.errors.lastName} />
                  {!!formik.errors.lastName && <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Email</Label>
                <Col sm={9}>
                  <Input type="email" className="form-control" placeholder="ex. name@domain.com" name="email" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.email} invalid={!!formik.errors.email} />
                  {!!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-3 col-form-label">Phone</Label>
                <Col sm={9}>
                  <Input type="text" className="form-control" placeholder="ex. 5417543010" name="phone" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.phone} invalid={!!formik.errors.phone} />
                  {!!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormNew.propTypes = {
  id: PropTypes.number,
};

export default FormNew;
