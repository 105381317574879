import React from "react";
import PropTypes from "prop-types";

const AccountingNotes = ({title, message}) => {
	return(
		<div className="card-notes">
			<h6 className="title">{title}</h6>
			<div className="alert-msg">{message}</div>
		</div>
	)
}

AccountingNotes.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
};

export default AccountingNotes;