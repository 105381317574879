import React from "react";
import PropTypes from "prop-types";

const TotalCosts = ({ label, value, className }) => {
  return (
    <div className={`card-total-cost ${className}`}>
      <label className="total-cost-label">{label}</label>
      <label className="total-cost-value">{`${value}$` || '0$'}</label>
    </div>
  )
}

TotalCosts.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default TotalCosts;