import {
  GET_SCHEDULER_REPORT,
  GET_SCHEDULER_REPORT_ERR,
  GET_SCHEDULER_REPORT_OK,
  DO_SCHEDULER_REPORT_CLEANUP,

  APPLY_SCHEDULER_REPORT_FILTERS,
  CLEAR_SCHEDULER_REPORT_FILTERS,
  DO_SCHEDULER_REPORT_FILTERS_CLEANUP,

  GET_GROUP_USAGE_REPORT,
  GET_GROUP_USAGE_REPORT_OK,
  GET_GROUP_USAGE_REPORT_ERR,
  DO_GROUP_USAGE_REPORT_CLEANUP,

  APPLY_GROUP_USAGE_REPORT_FILTERS,
  CLEAR_GROUP_USAGE_REPORT_FILTERS,
  DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,

  GET_DEALER_SALES_REPORT,
  GET_DEALER_SALES_REPORT_OK,
  GET_DEALER_SALES_REPORT_ERR,
  DO_DEALER_SALES_REPORT_CLEANUP,

  APPLY_DEALER_SALES_REPORT_FILTERS,
  CLEAR_DEALER_SALES_REPORT_FILTERS,
  DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
  GET_NEW_DEALERS_REPORT,
  GET_NEW_DEALERS_REPORT_OK,
  GET_NEW_DEALERS_REPORT_ERR,
  DO_NEW_DEALERS_REPORT_CLEANUP,
  APPLY_NEW_DEALERS_REPORT_FILTERS,
  CLEAR_NEW_DEALERS_REPORT_FILTERS,
  DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP,

} from "./actionTypes";

/********** SCHEDULER REPORT **********/

export const getSchedulerReport = params => ({
  type: GET_SCHEDULER_REPORT,
  payload: { params },
});

export const getSchedulerReportOk = response => ({
  type: GET_SCHEDULER_REPORT_OK,
  payload: { response },
});

export const getSchedulerReportErr = error => ({
  type: GET_SCHEDULER_REPORT_ERR,
  payload: { error },
});

export const doSchedulerReportCleanup = () => ({
  type: DO_SCHEDULER_REPORT_CLEANUP,
});

/********** SCHEDULER REPORT FILTERS **********/

export const applySchedulerReportFilters = filters => ({
  type: APPLY_SCHEDULER_REPORT_FILTERS,
  payload: { filters },
});

export const clearSchedulerReportFilters = () => ({
  type: CLEAR_SCHEDULER_REPORT_FILTERS,
});

export const doSchedulerReportFiltersCleanup = () => ({
  type: DO_SCHEDULER_REPORT_FILTERS_CLEANUP,
});

/********** GROUP USAGE REPORT **********/

export const getGroupUsageReport = params => ({
  type: GET_GROUP_USAGE_REPORT,
  payload: { params },
});

export const getGroupUsageReportOk = response => ({
  type: GET_GROUP_USAGE_REPORT_OK,
  payload: { response },
});

export const getGroupUsageReportErr = error => ({
  type: GET_GROUP_USAGE_REPORT_ERR,
  payload: { error },
});

export const doGroupUsageReportCleanup = () => ({
  type: DO_GROUP_USAGE_REPORT_CLEANUP,
});

/********** GROUP USAGE REPORT FILTERS **********/

export const applyGroupUsageReportFilters = filters => ({
  type: APPLY_GROUP_USAGE_REPORT_FILTERS,
  payload: { filters },
});

export const clearGroupUsageReportFilters = () => ({
  type: CLEAR_GROUP_USAGE_REPORT_FILTERS,
});

export const doGroupUsageReportFiltersCleanup = () => ({
  type: DO_GROUP_USAGE_REPORT_FILTERS_CLEANUP,
});

/********** DEALER SALES REPORT **********/

export const getDealerSalesReport = params => ({
  type: GET_DEALER_SALES_REPORT,
  payload: { params },
});

export const getDealerSalesReportOk = response => ({
  type: GET_DEALER_SALES_REPORT_OK,
  payload: { response },
});

export const getDealerSalesReportErr = error => ({
  type: GET_DEALER_SALES_REPORT_ERR,
  payload: { error },
});

export const doDealerSalesReportCleanup = () => ({
  type: DO_DEALER_SALES_REPORT_CLEANUP,
});

/********** DEALER SALES REPORT FILTERS **********/

export const applyDealerSalesReportFilters = filters => ({
  type: APPLY_DEALER_SALES_REPORT_FILTERS,
  payload: { filters },
});

export const clearDealerSalesReportFilters = () => ({
  type: CLEAR_DEALER_SALES_REPORT_FILTERS,
});

export const doDealerSalesReportFiltersCleanup = () => ({
  type: DO_DEALER_SALES_REPORT_FILTERS_CLEANUP,
});

/********** NEW DEALERS REPORT **********/

export const getNewDealersReport = params => ({
  type: GET_NEW_DEALERS_REPORT,
  payload: { params },
});

export const getNewDealersReportOk = response => ({
  type: GET_NEW_DEALERS_REPORT_OK,
  payload: { response },
});

export const getNewDealersReportErr = error => ({
  type: GET_NEW_DEALERS_REPORT_ERR,
  payload: { error },
});

export const doNewDealersReportCleanup = () => ({
  type: DO_NEW_DEALERS_REPORT_CLEANUP,
});

/********** NEW DEALERS REPORT FILTERS **********/

export const applyNewDealersReportFilters = filters => ({
  type: APPLY_NEW_DEALERS_REPORT_FILTERS,
  payload: { filters },
});

export const clearNewDealersReportFilters = () => ({
  type: CLEAR_NEW_DEALERS_REPORT_FILTERS,
});

export const doNewDealersReportFiltersCleanup = () => ({
  type: DO_NEW_DEALERS_REPORT_FILTERS_CLEANUP,
});