import React, { useEffect, useState } from "react";
import { perms, useAccess } from "../../../context/access";
import MetaTitle from "../../../components/Shared/MetaTitle";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb2";
import AccessDenied from "../../Error/AccessDenied";
import QuickbooksHeader from "../Partial/Quickbooks/QuickbooksHeader";
import {
  acquireQBToken,
  getQBCheckConnection,
  getQBInitConnection
} from "../../../helpers/backendHelper";
import { showError } from "../../../helpers/utilHelper";
import { useLocation } from "react-router-dom";
import SpinnerChase from "components/Shared/SpinnerChase";
import { QB_UNABLE_ACQUIRE_TOKEN, QB_UNABLE_REFRESH_TOKEN } from "helpers/errorHelper";

const Quickbooks = () => {
  const { iAmGranted, iAmNotGranted } = useAccess();

  const location = useLocation();

  /********** STATE **********/
  const [isConnected, setIsConnected] = useState(null);
  const [isCheckingConnection, setIsCheckingConnection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAcquiringAuthUrl, setIsAcquiringAuthUrl] = useState(false);

  /********** EFFECTS **********/
  //check QB connection
  useEffect(() => {
    checkConnection();
  }, []);


  useEffect(() => {
    setIsLoading(true);

    // Function to extract all query parameters from the URL as a single token
    const extractTokenFromURL = () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      if (!code) {
        setIsLoading(false);
        return;
      }
      acquireQBToken({ params: location.search })
        .then((response) => {
          setIsConnected(response.connected);
        })
        .catch((ex) => {
          if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
            showError('Cannot re-establish QB connection');
            return;
          }
          if (ex.code == QB_UNABLE_ACQUIRE_TOKEN) {
            showError('Cannot establish QB connection');
            return;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // Call the function to extract and send the token
    extractTokenFromURL();
  }, [location.search]);

  /********** EVENT HANDLERS **********/

  const checkConnection = () => {
    setIsCheckingConnection(true);
    getQBCheckConnection()
      .then(response => {
        setIsConnected(response.connected);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        showError('Unable to check connection');
      })
      .finally(() => {
        setIsCheckingConnection(false);
      });
  }

  const handleLogIn = () => {
    setIsAcquiringAuthUrl(true);
    getQBInitConnection()
      .then(response => {
        window.location.href = response.authUrl;
      })
      .catch(() => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        showError('Unable to log in');
        setIsAcquiringAuthUrl(false);
      });
  }

  return <>
    {iAmGranted(perms.view_quickbooks) && <>
      <div className="page-content">
        <MetaTitle>Orders</MetaTitle>
        <Container fluid>
          <Breadcrumbs title="QUICKBOOKS" />
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-4">
                  {!isLoading && <Row>
                    <Col md={6}>
                      {isConnected !== null && <QuickbooksHeader isConnected={isConnected} />}
                      {isConnected === false && <Button type="button" color="primary" className="mb-2" onClick={handleLogIn} disabled={isAcquiringAuthUrl}>Log In</Button>}
                      {isCheckingConnection && <SpinnerChase className="mt-4" />}
                    </Col>
                  </Row>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>}
    {iAmNotGranted(perms.view_quickbooks) && <AccessDenied />}
  </>
}

export default Quickbooks;