import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, Label, FormFeedback, Offcanvas, OffcanvasHeader, OffcanvasBody, Row, Col } from "reactstrap";
import DateRangePicker from "components/Shared/DateRangePicker";
import { useFormik } from "formik";
import { hasNonEmpty, getBeUrl } from "helpers/utilHelper";
import { applySchedulerReportFilters, clearSchedulerReportFilters, doUserDtCleanup } from "store/actions";
import { omit } from "lodash";
import { getEndOfPrevMonthTs, getStartOfPrevMonthTs } from "helpers/dateHelper";
import downloadIcon from 'assets/images/download-icon.svg';
import { getSchedulerDt } from "helpers/backendHelper";

const SchedulerFilters = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const filters = useSelector(state => state.Report.SchedulerFilters);
  const [schedulers, setSchedulers] = useState([]);
  const [schedulersError, setSchedulersError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  // is the filters form visible or not
  // used to show/hide the filters form
  const [isVisible, setIsVisible] = useState(false);

  /********** FORM CONFIG **********/

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: values => applyFilters(values),
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // get the scheduler filter options
    getSchedulerList();
  }, []);

  // default date range to previous month
  useEffect(() => {
    if (!filters.startTs && !filters.endTs) {
      applyFilters({
        startTs: getStartOfPrevMonthTs(),
        endTs: getEndOfPrevMonthTs(),
      })
    }
  }, []);

  // runs whenever "schedulersError" changes
  // which may happen after the first remote call
  useEffect(() => {
    if (schedulersError) {
      // set an error on the form field
      formik.setFieldError("scheduler", "Unable to load schedulers");
    }
  }, [schedulersError]);

  /********** EVENT HANDLERS **********/

  const handleChangeRange = selected => {
    if (selected[0]) {
      formik.setFieldValue("startTs", selected[0]);
    } else {
      formik.setFieldValue("startTs", null);
    }

    if (selected[1]) {
      formik.setFieldValue("endTs", selected[1]);
    } else {
      formik.setFieldValue("endTs", null);
    }

    // if the range is complete, apply the changes
    if (selected?.length === 2) {
      applyFilters({ ...filters, startTs: selected[0], endTs: selected[1] });
    }
  };

  const handleClearRange = () => {
    formik.setFieldValue("startTs", null);
    formik.setFieldValue("endTs", null);
    applyFilters({ ...filters, startTs: null, endTs: null });
  };

  // shows/hides the filters form
  const toggleFilters = () => setIsVisible(!isVisible);

  // event handler for the "apply-filters" button
  const applyFilters = values => dispatch(applySchedulerReportFilters(values));

  // event handler for the "clear-filters" button
  const clearFilters = () => {
    // reset form fields
    formik.setFieldValue("schedulerId", "");
    // reset state
    dispatch(clearSchedulerReportFilters());
  }

  // load state filters into local filters
  // state filters = applied filters that are send to backend
  // local filters = state vars bound to form controls
  // this is fired each time the offcanvas is opened
  // to discard anything the user might have typed in the fields (and not applied) before closing the offcanvas
  const initLocalFilters = () => formik.setValues(filters);

  /********** OTHER **********/

  const getSchedulerList = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the users data
    getSchedulerDt({
      page: 1,
      pageSize: null,
      sortBy: "fullName",
      sortDir: "asc",
    })
      .then(response => {
        setSchedulers(response.users);
      })
      .catch(ex => {
        setSchedulersError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const hasFilters = () => hasNonEmpty(omit(filters, ["startTs", "endTs"]));

  const schedulerOptions = schedulers?.map(user => ({ label: user.fullName, value: user.id }));
  const hasTs = formik.values.startTs && formik.values.endTs;

  return (
    <Row className="justify-content-between">
      <Col xs={6} sm={"auto"}>
        <DateRangePicker
          onChange={handleChangeRange}
          value={[formik.values.startTs, formik.values.endTs].filter(ts => !!ts)}
          onClear={handleClearRange}
        />
      </Col>
      <Col xs={6} sm={"auto"}>
        <div className="ms-2 mb-4" >
          <a className={`btn btn-primary ${hasTs ? '' : 'disabled'}`} href={getBeUrl(`/report/scheduler-performance/download?startTs=${formik.values.startTs}&endTs=${formik.values.endTs}`)} target="_blank" rel="noreferrer">
            <span>Download</span>
            <img className='ps-2' src={downloadIcon} />
          </a>
          <Button type="button" color="dark" className='ms-3' onClick={toggleFilters}>
            <i className="mdi mdi-filter-variant me-1" />Filters
          </Button>
          {hasFilters() && <Button type="button" color="dark" className='ms-3' onClick={clearFilters}>
            <i className="mdi mdi-close" />
          </Button>}
        </div>
      </Col>
      <Offcanvas direction="end" isOpen={isVisible} toggle={toggleFilters} onOpened={initLocalFilters}>
        <OffcanvasHeader toggle={toggleFilters}>Filters</OffcanvasHeader>
        <OffcanvasBody>
          <Form>
            <div className="mb-3">
              <Label>Scheduler</Label>
              <Select
                key={formik.values.schedulerId || "select"}
                classNamePrefix="select2-selection"
                name="scheduler"
                options={schedulerOptions}
                onChange={selected => formik.setFieldValue("schedulerId", selected.value)}
                value={schedulerOptions.find(option => option.value === formik.values.schedulerId)}
                isLoading={isLoadInProgress}
                className={!!formik.errors.schedulerId && "is-invalid"} />
              {!!formik.errors.schedulerId && <FormFeedback type="invalid">{formik.errors.schedulerId}</FormFeedback>}
            </div>
            <div className="text-end">
              <Button type="button" color="primary" className="me-2" onClick={formik.handleSubmit}>
                <i className="mdi mdi-filter me-1" />Apply
              </Button>
              <Button type="button" color="warning" onClick={clearFilters}>
                <i className="mdi mdi-eraser me-1" />Clear
              </Button>
            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </Row>
  )
}

export default SchedulerFilters;