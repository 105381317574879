import { toUserModel } from "helpers/utilHelper";
import { combineReducers } from "redux";
import {
  GET_AUTH_USER_OK,
  GET_AUTH_USER_ERR,
  LOGOUT_USER_OK,
  IMPERSONATE_USER,
  IMPERSONATE_USER_OK,
  IMPERSONATE_USER_ERR,
} from "./actionTypes";

/********** LOGIN **********/

const defaultLoginState = {
  user: null,
  userError: null,
  isAuth: null,
}

const Login = (state = defaultLoginState, action) => {
  switch (action.type) {
    case GET_AUTH_USER_OK:
      state = {
        ...state,
        isAuth: true,
        user: toUserModel(action.payload.response.user),
      };
      break
    case GET_AUTH_USER_ERR:
      state = {
        ...state,
        isAuth: false,
        userError: action.payload.error,
      };
      break
    case LOGOUT_USER_OK:
      state = {
        ...state,
        user: null,
      }
      break
  }
  return state;
}

/********** IMPERSONATION **********/

const defaultImpersState = {
  impersResult: null,
  impersError: null,
  isImpersInProgress: false,
}

const Impers = (state = defaultImpersState, action) => {
  switch (action.type) {
    case IMPERSONATE_USER:
      state = {
        ...state,
        impersResult: null,
        impersError: null,
        isImpersInProgress: true,
      }
      break
    case IMPERSONATE_USER_OK:
      state = {
        ...state,
        impersResult: true,
        isImpersInProgress: false,
      };
      break
    case IMPERSONATE_USER_ERR:
      state = {
        ...state,
        impersResult: false,
        impersError: action.payload.error,
        isImpersInProgress: false,
      };
      break
  }
  return state;
}

export default combineReducers({
  Login,
  Impers,
})
