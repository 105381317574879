/********** CUSTOMER SUPPORT STATS **********/

export const GET_SUPPORT_STATS = "GET_SUPPORT_STATS"

export const GET_SUPPORT_STATS_OK = "GET_SUPPORT_STATS_OK"

export const GET_SUPPORT_STATS_ERR = "GET_SUPPORT_STATS_ERR"

export const DO_SUPPORT_STATS_CLEANUP = "DO_SUPPORT_STATS_CLEANUP"

/********** APPOINTMENTS LIST **********/

export const GET_APPOINTMENTS_LIST = "GET_APPOINTMENTS_LIST"

export const GET_APPOINTMENTS_LIST_OK = "GET_APPOINTMENTS_LIST_OK"

export const GET_APPOINTMENTS_LIST_ERR = "GET_APPOINTMENTS_LIST_ERR"

export const DO_APPOINTMENTS_LIST_CLEANUP = "DO_APPOINTMENTS_LIST_CLEANUP"