export default class Notary {

  static TYPE_UNAPPROVED = 0;
  static TYPE_MAVERICK = 1;
  static TYPE_EXPRESS = 2;
  static TYPE_SUPER_EXPRESS = 3;
  static TYPE_DO_NOT_USE = -1;

  static approvedTypeIds = [
    this.TYPE_MAVERICK,
    this.TYPE_EXPRESS,
    this.TYPE_SUPER_EXPRESS
  ];

  static getTypeMap() {
    return {
      [this.TYPE_DO_NOT_USE]: 'Do Not Use',
      [this.TYPE_UNAPPROVED]: 'Unapproved',
      [this.TYPE_MAVERICK]: 'Maverick',
      [this.TYPE_EXPRESS]: 'Express',
      [this.TYPE_SUPER_EXPRESS]: 'Super Express',
    };
  }

  static getTypeName(id) {
    return this.getTypeMap()[id];
  }

  static isApprovedType = typeId => this.approvedTypeIds.includes(typeId);
}