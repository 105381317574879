import React from 'react';
import PropTypes from 'prop-types';
import AuthorAvatar from '../AuthorAvatar';
import AuthorName from '../AuthorName';
import EventTime from '../EventTime';
import OrderDoc from 'model/orderDoc';

const OrderDocStatusChanged = props => {

  const { log } = props;

  return <React.Fragment>
    <div className="d-flex">
      <AuthorAvatar log={log} />
      <div className="flex-grow-1">
        {!!log.author && <React.Fragment>
          <AuthorName log={log} /> changed document <b>{log.subject.name}</b> status to <b>{OrderDoc.getStatusName(log.subject.status)}</b>.
        </React.Fragment>}
        {!log.author && <span>Document <b>{log.subject.name}</b> status changed to <b>{OrderDoc.getStatusName(log.subject.status)}</b></span>}
        <p className="mb-0"><EventTime log={log} /></p>
      </div>
    </div>
  </React.Fragment>
}

OrderDocStatusChanged.propTypes = {
  log: PropTypes.object,
}

export default OrderDocStatusChanged;