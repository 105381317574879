import React, { useEffect, useCallback } from "react";
import { Col, Card, CardBody, Row, CardHeader, CardTitle, Alert, CardFooter } from "reactstrap";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SpinnerChase from "components/Shared/SpinnerChase";
import { doMessageDtCleanup, getMessageDt } from "store/actions";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import { replaceTagsWithText } from "helpers/utilHelper";

const Messages = () => {

  const dispatch = useDispatch();

  const { messages: rows, isLoadInProgress, messagesError } = useSelector(state => state.Message.Dt);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(() => {
    dispatch(getMessageDt({ pageSize: 5 }));
  }, []);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getMessageDt({ pageSize: 5 }));

    return () => {
      // state cleanup on component unmount
      dispatch(doMessageDtCleanup());
    }
  }, []);

  return <>
    <Card className="messages-card">
      <CardHeader className="bg-transparent pt-2 pb-1">
        <Row>
          <Col>
            <CardTitle>Messages</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="horizontal-scroll-table p-0">
        <table className="table">
          <thead>
            <tr>
              <th>From</th>
              <th>Date</th>
              <th>Message</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!isLoadInProgress && !messagesError && rows.map((entry, index) => (<tr key={index}>
              <td>{entry.senderName}</td>
              <td>{formatTimestamp(entry.createdTs, formats.DATETIME)}</td>
              <td className="message-content">
                {entry.contentType === Message.CONTENT_TYPE_TEXT && replaceTagsWithText(entry.content)}
                {entry.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex"><i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span></div>}
              </td>
              <td>
                <Link to={route(routes.view_order_messages, [entry.orderId, entry.channel])}>
                  <span className={`badge badge-lg rounded-pill bg-primary`}>View Message</span>
                </Link>
              </td>
            </tr>))}
            {!isLoadInProgress && !messagesError && rows.length === 0 && <tr>
              <td className="table-empty" colSpan="4">No messages found</td>
            </tr>}
            {isLoadInProgress && <tr>
              <td className="table-empty" colSpan="4">
                <SpinnerChase />
              </td>
            </tr>}
            {messagesError && <tr>
              <td className="table-empty" colSpan="4">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
                </Alert>
              </td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end">
        <Link to={route(routes.list_messages)}>View all</Link>
      </CardFooter>
    </Card>
  </>
}

export default Messages