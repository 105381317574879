import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { Container, Row, Col, Alert } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SectionBilling from "./Partial/Section/Billing";
import SectionShipping from "./Partial/Section/Shipping";
import SectionContact from "./Partial/Section/Contact";
import SectionExperience from "./Partial/Section/Experience";
import SectionCredentials from "./Partial/Section/Credentials";
import SectionCredentialsDetails from "./Partial/Section/CredentialsDetails";
import SectionAccounting from "./Partial/Section/Accounting";
import SectionAdditionalInfo from "./Partial/Section/AdditionalInfo";
import SectionReviews from "./Partial/Section/Review";
import { getNotary, getQbNotaries, getQbVendor } from "helpers/backendHelper";
import { NotFoundException, QB_UNABLE_REFRESH_TOKEN } from "helpers/errorHelper";
import { showError } from "helpers/utilHelper";
import SectionOrders from "./Partial/Section/Orders";


const View = () => {

  let { id } = useParams();
  id = parseInt(id);

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const [notary, setNotary] = useState(null);
  const [notaryError, setNotaryError] = useState(null);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const [qbNotaries, setQbNotaries] = useState([]);
  const [qbNotariesError, setQbNotariesError] = useState(null);

  const [qbVendor, setQbVendor] = useState(null);
  const [qbVendorError, setQbVendorError] = useState(null);
  const [isQbVendorLoadInProgress, setIsQbVendorLoadInProgress] = useState(false);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the user data
    refreshNotary();
  }, [id]);

  useEffect(() => {
    getQbNotariesList();
  }, []);

  useEffect(() => {
    if (!!notary?.qbVendorId) {
      refreshQbVendor();
    } else if (!!notary) {
      setQbVendorError('Please add a vendor');
    }
  }, [notary?.qbVendorId]);

  /********** OTHER **********/

  const refreshNotary = () => {
    setIsLoadInProgress(true);
    // make the initial remote call to get the user data
    getNotary(id)
      .then(response => {
        setNotary(response.notary);
      })
      .catch(ex => {
        setNotaryError(ex);
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  };

  const getQbNotariesList = () => {
    getQbNotaries()
      .then(response => {
        setQbNotaries(response.QueryResponse.Vendor);
      })
      .catch(err => {
        if (err.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        setQbNotariesError(err)
      })
  }

  const refreshQbVendor = () => {
    setQbVendor(null);
    setQbVendorError(null);
    setIsQbVendorLoadInProgress(true);
    getQbVendor(notary.qbVendorId)
      .then(response => {
        setQbVendor(response);
      })
      .catch(ex => {
        if (ex.code == QB_UNABLE_REFRESH_TOKEN) {
          showError('Cannot re-establish QB connection');
          return;
        }
        if (ex instanceof NotFoundException) {
          setQbVendorError('Vendor not found');
          return;
        }
        setQbVendorError('Unable to load vendor');
      })
      .finally(() => {
        setIsQbVendorLoadInProgress(false);
      });
  }

  const alertMessage = () => {
    if(!notary.userIsActive) return <Alert color="danger"><span className="font-size-13">Notary user has not yet activated his account.</span></Alert>;

    if(!notary.isVerified) return <Alert color="danger"><span className="font-size-13">Notary user has not yet verified his account.</span></Alert>;

    if(!notary.isActive) return <Alert color="danger"><span className="font-size-13">Notary not active.</span></Alert>;

    return null;
  }

  return <React.Fragment>
    {iAmGranted(perms.view_notaries) && <div className="page-content">
      {notary && <React.Fragment>
        <MetaTitle>{notary.userFullName} | Notaries</MetaTitle>
        <Container fluid className='notary-profile'>
          {alertMessage()}
          <Breadcrumbs breadcrumbItems={breadcrumbs(notary)} />
          <Row>
            <Col xs={12} md={6}>
              <SectionBilling
                id={id}
                notary={notary}
                isLoadInProgress={isLoadInProgress}
                refreshHandler={refreshNotary}
                qbNotaries={qbNotaries}
                qbNotariesError={qbNotariesError}
                qbVendor={qbVendor}
                qbVendorError={qbVendorError}
                isQbVendorLoadInProgress={isQbVendorLoadInProgress} />
            </Col>
            <Col xs={12} md={6}>
              <SectionShipping notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <SectionContact notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col>
            <Col xs={12} md={6}>
              <SectionExperience notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <SectionReviews id={id} notary={notary} />
            </Col>
            <Col xs={12} md={4}>
              <SectionAdditionalInfo id={id} refreshHandler={refreshNotary} notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <SectionCredentials id={id} />
            </Col>
            <Col xs={12} md={4}>
              <SectionCredentialsDetails notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col>
          </Row>
          <Row>
            {iAmGranted(perms.view_statements) && <Col xs={12} md={12}>
              <SectionAccounting notary={notary} />
            </Col>}
            {/* <Col xs={12} md={4}>
              <SectionArea notary={notary} isLoadInProgress={isLoadInProgress} />
            </Col> */}
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <SectionOrders notary={notary} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !notary && <Preloader className="inner" />}
      {notaryError && <Error error={notaryError} title404="Notary not found" />}
    </div>}
    {iAmNotGranted(perms.view_notaries) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = notary => [{
  title: "NOTARIES",
  url: route(routes.list_notaries),
}, {
  title: notary.userFullName,
}];

export default View;
