import React from "react";
import { Table } from "reactstrap";
import PropTypes from 'prop-types';
import { formats, formatTimestamp } from "helpers/dateHelper";
import { getGranularStatusName } from "helpers/utilHelper";
import Order from "../../../../model/order";

const CustomerInfo = props => {

  const { order } = props;
  const hasNotariesAndShipping = () => order?.isNotaryRequired && order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING;

  return <React.Fragment>
    {order.signers.map((signer, index) =>
      <Table className="table seal-table mb-4" key={index}>
        <thead>
          <tr>
            <th className="label-col">Customer information</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name</td>
            <td className="tb-value">{signer.firstName}</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td className="tb-value">{signer.lastName}</td>
          </tr>
          {!!signer.email && <tr>
            <td>Email</td>
            <td className="tb-value">{signer.email}</td>
          </tr>}
          {!!signer.phone && <tr>
            <td>Phone number</td>
            <td className="tb-value">{signer.phone}</td>
          </tr>}
        </tbody>
      </Table>
    )}
    {order.vehicles.map((vehicle, index) =>
      <Table className="table seal-table mb-4" key={index}>
        <thead>
          <tr>
            <th className="label-col">Car information</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Stock Number</td>
            <td className="tb-value">{vehicle.stockNum}</td>
          </tr>
          <tr>
            <td>V.I.N #</td>
            <td className="tb-value">{vehicle.vin}</td>
          </tr>
        </tbody>
      </Table>
    )}
    <Table className="table seal-table mb-4">
      <thead>
        <tr>
          <th className="label-col">Order information</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Order ID</td>
          <td className="tb-value">{order.id}</td>
        </tr>
        <tr>
          <td>Order Status</td>
          <td className="tb-value">{getGranularStatusName(order.granularStatus)}</td>
        </tr>
        <tr>
          <td>Date ordered</td>
          <td className="tb-value">{formatTimestamp(order.createdTs, formats.DATETIME)}</td>
        </tr>
        <tr>
          <td>Person submitting order</td>
          <td className="tb-value">{order.userFullName}</td>
        </tr>
        <tr>
          <td>Cell Number for person submitting order</td>
          <td className="tb-value">{order.userPhone ?? '--'}</td>
        </tr>
        <tr>
          <td>Dealership Phone number</td>
          <td className="tb-value">{order.dealerStorePhone ?? '--'}</td>
        </tr>
      </tbody>
    </Table>
    {hasNotariesAndShipping() && <div className='tracking-shipping-table'><Table className="table seal-table mb-4">
      <thead>
        <tr>
          <th className="label-col tracking-shipping">Tracking and Shipping Information</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Shipping Company</td>
          <td className="tb-value">{order.shippingCompany || '--'}</td>
        </tr>
        <tr>
          <td>Shipping Package</td>
          <td className="tb-value">{order.shippingPackageAwb || '--'}</td>
        </tr>
        <tr>
          <td>Return Package</td>
          <td className="tb-value">{order.returnPackageAwb || '--'}</td>
        </tr>
      </tbody>
    </Table></div>}
  </React.Fragment>
}

CustomerInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default CustomerInfo;
